<template>
  <div class="atmo-music">
    <!-- <h2 class="atmo-page-header atmo-music__header">
      Atmo Music
    </h2> -->
    <section class="atmo-music__body">
      <aside class="atmo-music__sidebar">
        <!-- TODO: make this search do something -->
        <input type="text" placeholder="Search for item" class="atmo-input atmo-input--search" />
        <ul class="atmo-music__subroute-links">
          <router-link tag="li" class="atmo-music__subroute-link" to="/music/explore" @click="$store.commit('setHudHeader', 'Explore')"> Explore </router-link>
          <router-link tag="li" class="atmo-music__subroute-link" to="/music/new_releases" @click="$store.commit('setHudHeader', 'New Releases')"> New Releases </router-link>
          <router-link tag="li" class="atmo-music__subroute-link" to="/music/suggested" @click="$store.commit('setHudHeader', 'Suggested')"> Suggested </router-link>
          <router-link tag="li" class="atmo-music__subroute-link" to="/music/charts" @click="$store.commit('setHudHeader', 'Atmo Charts')"> Atmo Charts </router-link>
          <router-link tag="li" class="atmo-music__subroute-link" to="/music/concerts" @click="$store.commit('setHudHeader', 'Concerts')"> Concerts </router-link>
        </ul>
      </aside>
      <div class="atmo-music__main-content">
        <router-view />
      </div>
    </section>
  </div>
</template>

<script>
export default {
  created() {
    this.$store.commit("setHudHeader", "Atmo Music");
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-music {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  &__header {
    margin: 35px 0;
  }

  &__body {
    display: grid;
    justify-content: center;
    grid-template-columns: 12rem 1fr;
    gap: 1rem;
    width: 100%;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    width: 12rem;
    // margin-right: 20px;
    // margin-bottom: 50px;
    height: 100%;
  }

  $border-style: 1px solid rgba($atmo-purple--light, 0.4);

  &__subroute-links {
    display: flex;
    flex-direction: column;
    background-color: rgba($atmo-purple--dark, 0.1);
    border-radius: 5px;
    border: $border-style;
    list-style: none;
    align-items: center;
    overflow: hidden;
    margin-top: 12px;
    margin-right: -1px; // prevents a 1px gap from showing up when a link has a background color
  }

  &__subroute-link {
    padding: 25px 20px;
    border-bottom: $border-style;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;
    width: 100%;
    text-align: center;

    &:hover {
      background-color: rgba($atmo-purple--medium, 0.7);
    }

    &.router-link-active {
      background-color: $atmo-purple--medium;
      font-weight: 400;
    }

    &:last-child {
      border: none;
    }
  }

  &__main-content {
    // display: flex;
    // flex-grow: 1;
    // max-width: 600px;
  }
}
</style>
