import { createStore } from 'vuex'; // Use createStore from vuex
import Axios from "axios";
import { setDefaultAxiosHeaders } from '@/helpers/axios';
import playerModule from "./store/player";
import opsModule from "./store/ops";
import eventsModule from "./store/events";
import addressModule from "./store/address";
import contestsModule from "./store/contests";
import productsModule from "./store/products";
import accomplishmentsModule from "./store/accomplishments";
import notificationsModule from "./store/notifications";
import userFriendshipsModule from "./store/userFriendships";
import userRelationshipsModule from "./store/userRelationships";
import profilesModule from "./store/profiles";
import postsModule from "./store/posts";
import liveListeningRoomsModule from "./store/liveListeningRooms";
import chatRoomsModule from "./store/chatRooms";
import musicProfilesModule from "./store/musicProfiles";
import userActivitiesModule from "./store/userActivities";
import playlistsModule from "./store/playlists";
import chartsModule from "./store/charts";
import atmoRadioStationsModule from "./store/atmoRadioStations";
import atmoQueuesModule from "./store/atmoQueues";
import librariesModule from "./store/libraries";
import videoPlayerModule from "./store/videoPlayer";
import paymentsModule from "./store/payments";
import donationsModule from "./store/donations";
import directoryProfilesModule from "./store/directoryProfiles";
import platformAdsModule from "./store/platformAds";
// import User from "@/models/user";
import { callUserProfilesShow, callArtistProfilesShow, callLabelProfilesShow, callUsersShow } from '@/helpers/axiosCalls';

Axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;


// Define default state as a function
const defaultState = () => ({
  token: null,
  authenticated: false,
  authenticationPromise: null,
  currentUser: null,
  currentUserId: null,
  currentUserEmail: null,
  currentUserName: null,
  currentUserProfileId: null,
  currentUserProfileType: null,
  currentUserProfile: null,
  currentUserIsPaying: null,
  genreOptions: null,
  activeModal: null,
  AMIProcessing: false,
  atmoAdModalOpen: false,
  userMediaModalOpen: false,
  amiOptionsOpen: false,
  amiOptions: [],
  currentChatRoomId: null, // FOR AMI
  amiAudioMuted: false,
  amiSoundType: "fx",
  amiPosts: [],
  simulatedSpeechSentToAMI: false,
  simulatedAMISpeech: null,
  amiTestingOpen: false,
  sharePostModalOpen: false,
  sharePostModalPost: {},
  addSongsModalOpen: false,
  addSongsModalList: [],
  hudHeader: null,
});

// Create the Vuex store using createStore in Vue 3
export default createStore({
  modules: {
    player: playerModule,
    ops: opsModule,
    events: eventsModule,
    address: addressModule,
    contests: contestsModule,
    products: productsModule,
    accomplishments: accomplishmentsModule,
    notifications: notificationsModule,
    userFriendships: userFriendshipsModule,
    userRelationships: userRelationshipsModule,
    profiles: profilesModule,
    posts: postsModule,
    liveListeningRooms: liveListeningRoomsModule,
    chatRooms: chatRoomsModule,
    musicProfiles: musicProfilesModule,
    userActivities: userActivitiesModule,
    playlists: playlistsModule,
    charts: chartsModule,
    atmoRadioStations: atmoRadioStationsModule,
    atmoQueues: atmoQueuesModule,
    libraries: librariesModule,
    videoPlayer: videoPlayerModule,
    payments: paymentsModule,
    donations: donationsModule,
    directoryProfiles: directoryProfilesModule,
    platformAds: platformAdsModule
  },
  state: defaultState(),
  mutations: {
    // eslint-disable-next-line no-unused-vars
    resetStore() {
      const defState = {
        ...defaultState(),
        player: playerModule.state,
        ops: opsModule.state,
        events: eventsModule.state,
        address: addressModule.state,
        contests: contestsModule.state,
        products: productsModule.state,
        accomplishments: accomplishmentsModule.state,
        notifications: notificationsModule.state,
        userFriendships: userFriendshipsModule.state,
        userRelationships: userRelationshipsModule.state,
        profiles: profilesModule.state,
        posts: postsModule.state,
        liveListeningRooms: liveListeningRoomsModule.state,
        chatRooms: chatRoomsModule.state,
        musicProfiles: musicProfilesModule.state,
        userActivities: userActivitiesModule.state,
        playlists: playlistsModule.state,
        charts: chartsModule.state,
        atmoRadioStations: atmoRadioStationsModule.state,
        atmoQueues: atmoQueuesModule.state,
        libraries: librariesModule.state,
        videoPlayer: videoPlayerModule.state,
        payments: paymentsModule.state,
        donations: donationsModule.state,
        directoryProfiles: directoryProfilesModule.state,
        platformAds: platformAdsModule.state
      };
      this.replaceState(defState);
    },
    parseToken(state, token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const decodedToken = JSON.parse(window.atob(base64));
      const id = decodedToken.sub;
      const email = decodedToken.email;
      const name = decodedToken.name;
      const profile_id = decodedToken.profile_id;
      const profile_type = decodedToken.profile_type;
      const is_paying = decodedToken.is_paying;
  
      console.log("Parsed ID:", id);
  
      state.currentUserId = id;
      state.currentUserEmail = email;
      state.currentUserName = name;
      state.currentUserProfileId = profile_id;
      state.currentUserProfileType = profile_type;
      state.currentUserIsPaying = is_paying;
      state.token = token;
    },
    setAuthenticated(state, newValue) {
      state.authenticated = newValue;
    },
    setGenreOptions(state, genreOptions) {
      state.genreOptions = genreOptions;
    },
    showModal(state, name) {
      state.activeModal = name;
    },
    hideModal(state) {
      state.activeModal = null;
    },
    openSharePostModal(state) {
      state.sharePostModalOpen = true;
    },
    openAddSongsModal(state) {
      state.addSongsModalOpen = true;
    },
    setToken(state, token) {
      state.token = token;
    },
    setCurrentUser(state, user) {
      state.currentUser = user;
    },
    setCurrentUserId(state, id) {
      state.currentUserId = Number(id);
    },
    setCurrentUserName(state, name) {
      state.currentUserName = name;
    },
    setCurrentUserEmail(state, email) {
      state.currentUserEmail = email;
    },
    setCurrentUserProfileId(state, profileId) {
      state.currentUserProfileId = profileId;
    },
    setCurrentUserProfileType(state, profileType) {
      state.currentUserProfileType = profileType;
    },
    setCurrentUserProfile(state, profile) {
      state.currentUserProfile = profile;
    },
    setCurrentUserPlanType(state, planType) {
      state.currentUserIsPaying = planType;
    },
    showAMIProcessing(state, duration = 1000) {
      state.AMIProcessing = true;
      setTimeout(() => {
        state.AMIProcessing = false;
      }, duration);
    },
    toggleAtmoAdModalOpen(state) {
      state.atmoAdModalOpen = !state.atmoAdModalOpen;
    },
    getUserAmiProfileAttributes(state) {
      Axios.get(`/api/v1/user_ami_profiles/${state.currentUserId}`)
        .then(response => {
          state.amiAudioMuted = response.data.ami_audio_muted;
          state.amiAudioSoundType = response.data.ami_sound_type;
        })
        .catch(error => {
          console.error(error);
        });
    },
    setAmiAudioState(state, muted) {
      Axios({
        method: "patch",
        url: `/api/v1/user_ami_profiles/${state.currentUserId}`,
        data: {
          ami_audio_muted: muted
        }
      })
        .then(response => {
          state.amiAudioMuted = response.data.ami_audio_muted;
        })
        .catch(error => {
          console.error(error);
        });
    },
    setAmiSoundType(state, type) {
      Axios({
        method: "patch",
        url: `/api/v1/user_ami_profiles/${state.currentUserId}`,
        data: {
          ami_sound_type: type
        }
      })
        .then(response => {
          state.amiSoundType = response.data.ami_sound_type;
        })
        .catch(error => {
          console.error(error);
        });
    },
    openAmiOptions(state, options) {
      state.amiOptions = options;
      state.amiOptionsOpen = true;
    },
    closeAmiOptions(state) {
      state.amiOptionsOpen = false;
    },
    openAmiTestingModal(state) {
      state.amiTestingOpen = true;
    },
    closeAmiTestingModal(state) {
      state.amiTestingOpen = false;
    },
    sendSimulatedAMISpeech(state, speech) {
      console.log("Firing in store");
      state.simulatedSpeechSentToAMI = true;
      state.simulatedAMISpeech = speech;
      console.log(state.simulatedSpeechSentToAMI, state.simulatedAMISpeech);
    },
    clearSimulatedAMISpeech(state) {
      state.simulatedSpeechSentToAMI = false;
      state.simulatedAMISpeech = null;
    },
    setChatRoomId(state, roomId) {
      state.currentChatRoomId = roomId;
    },
    setAmiPosts(state, posts) {
      state.amiPosts = posts;
    },
    setSharePostModalPost(state, value) {
      state.sharePostModalPost = value;
    },
    setAddSongsModalList(state, data) {
      state.addSongsModalList = data;
    },
    setHudHeader(state, header) {
      state.hudHeader = header;
    }
  },
  actions: {
    async authenticate({ dispatch, state, commit }) {
      if (localStorage.token) {
        setDefaultAxiosHeaders(localStorage.token);
        commit("parseToken", localStorage.token); // Correctly committing parseToken as a mutation
  
        console.log("Current User ID after parsing token:", state.currentUserId);
  
        // Call the getAndSetUserAttributes action after token parsing
        if (state.currentUserId) {
          await dispatch("getAndSetUserAttributes"); // Dispatch getAndSetUserAttributes action
          commit('setAuthenticated', true);
        } else {
          console.error("currentUserId is not set after parsing the token");
          commit('setAuthenticated', false);
        }
      } else {
        commit('setAuthenticated', false);
        return false;
      }
    },
    async getAndSetUserAttributes({ state, commit, dispatch }) {
      if (!state.currentUserId) {
        console.error("No currentUserId available, cannot fetch user attributes.");
        return;
      }
    
      try {
        // Fetch the current user
        const currentUser = await callUsersShow(state.currentUserId);
        commit("setCurrentUser", currentUser);
    
        // Determine the appropriate profile call based on the profile type
        let currentUserProfile;
        switch (currentUser.profile_type) {
          case "UserProfile":
            currentUserProfile = await callUserProfilesShow(currentUser.profile_id);
            break;
          case "ArtistProfile":
            currentUserProfile = await callArtistProfilesShow(currentUser.profile_id);
            break;
          case "LabelProfile":
            currentUserProfile = await callLabelProfilesShow(currentUser.profile_id);
            break;
          default:
            console.error(`Unknown profile type: ${currentUser.profile_type}`);
            return;
        }
    
        // Commit the profile to the store
        commit("setCurrentUserProfile", currentUserProfile);
        commit("setAuthenticated", true);
    
        // Fetch additional attributes and dispatch actions
        console.log("STATE.USER IN GET/SET", state.currentUser);
        await dispatch("libraries/getLibrary");
        await dispatch("getGenreOptions");
        await dispatch("atmoQueues/getAtmoQueue");
        await dispatch("userFriendships/fetchUserFriendships", currentUser.id);
        await dispatch("chatRooms/getGroupChatRooms", currentUser.id);
        await dispatch("liveListeningRooms/getFriendLiveListeningChatRooms", currentUser.id);
        await dispatch("liveListeningRooms/getCurrentUserLiveListeningChatRoom", currentUser.id);
        await dispatch("liveListeningRooms/getLiveListeningChatRoomSongs", currentUser.id);
        commit("getUserAmiProfileAttributes");
        await dispatch("notifications/fetchUserNotifications", currentUser.id);
        await dispatch("notifications/connectToNotifications");
        await dispatch(
          "liveListeningRooms/connectToChatRoomUserRequests",
          currentUser.live_listening_chat_room.id
        );
        await dispatch("userActivities/connectToBroadcastPosts", {
          roomId: 1,
          params: {
            date_range: "all",
            limit: 30,
            show_current_user_posts: true,
          },
        });
        await dispatch("platformAds/fetchAds");
      } catch (error) {
        console.error("Error fetching user attributes:", error);
      }
    },
    setToken(context, token) {
      context.commit("setToken", token);
    },
    async getGenreOptions({ state, commit }) {
      if (!state.genreOptions) {
        const response = await Axios.get("/api/v1/genres");
        commit("setGenreOptions", response.data);
      }
    },
    stubAction() {
      const promise = Promise.reject(new Error());

      promise.catch(() => {
        console.error("Action not implemented yet");
      });

      return promise;
    },
  }
});
