<template>
  <component
    :is="componentType"
    :to="to"
    class="atmo-upload-card"
  >
    <component
      :is="isButton ? 'button' : 'div'"
      :class="{
        'atmo-button atmo-button--secondary atmo-upload-card__button': isButton,
        'atmo-upload-card__container': !isButton,
        'atmo-upload-card__container--small': size === 'small',
        'atmo-upload-card__container--dragover': isDraggingOver
      }"
      :style="componentStyle"
      :type="isButton ? 'button' : ''"
      @click="handleContainerClick"
      @dragenter="handleContainerDragenter"
      @dragover="handleContainerDragover"
      @dragleave="handleContainerDragleave"
      @drop="handleContainerDrop"
    >
      <input
        :ref="hiddenFileInputRef"
        type="file"
        :multiple="allowMultipleFiles"
        :accept="accept"
        style="display:none"
        @change="handleFilesInputted"
      >
      <img
        v-if="computedImageSrc"
        class="atmo-upload-card__preview-image"
        :src="computedImageSrc"
      >
      <img
        v-else
        class="atmo-upload-card__icon"
        :class="`atmo-upload-card__icon--${iconSize}`"
        :src="require(`@/assets/images/icons/${computedIcon}`)"
        :alt="alt"
      >
    </component>
    <div
      v-if="hasLabel"
      class="atmo-upload-card__label"
      :class="{
        'atmo-upload-card__label--small': size === 'small',
      }"
    >
      <slot />
    </div>
  </component>
</template>

<script>
  import { utilsGetImgUrl } from '@/helpers/utilityFunctions';
  import { v4 } from 'uuid';

  export default {
    props: {
      componentStyle: {
        type: String,
        default: 'min-height: 230px; width: 185px;'
      },
      size: {
        type: String,
        default: 'large',
        validator: function (value) {
          return [
            'small',
            'large',
            'button'
          ].includes(value);
        }
      },
      hasLabel: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: 'plus.png'
      },
      iconSize: {
        type: String,
        default: 'medium'
      },
      alt: {
        type: String,
        required: true
      },
      componentType: {
        type: String,
        default: 'div'
      },
      // Must pass in "to" if using as a router-link
      to: {
        type: [String, Object],
        default: null
      },
      // Pass this to use background image
      imageSrc: {
        type: String,
        default: null
      },
      // pass these options to allow file uploads
      onFilesSelected: {
        type: Function,
        default: null
      },
      allowMultipleFiles: {
        type: Boolean,
        default: false
      },
      accept: {
        type: String,
        default: 'image/*'
      },
      maxKb: {
        type: Number,
        default: Infinity
      }
    },

    data() {
      return {
        uid: v4(),
        imagePreviewData: null,
        isDraggingOver: false
      }
    },

    computed: {
      isButton() {
        return this.size === 'button';
      },
      hiddenFileInputRef() {
        return `atmo-upload-card__hidden-file-input--${this.uid}`;
      },
      computedIcon() {
        return this.isButton ? 'attachment' : this.icon;
      },
      computedImageSrc() {
        return this.imageSrc || this.imagePreviewData;
      }
    },

    methods: {
      getImgUrl(image) {
        return utilsGetImgUrl(image);
      },
      handleContainerClick() {
        const hiddenFileInput = this.$refs[this.hiddenFileInputRef];
        hiddenFileInput.click();
      },
      handleFilesInputted({ currentTarget }) {
        const files = currentTarget.files;
        this.handleFiles(files);
      },
      handleContainerDragenter(event) {
        event.stopPropagation();
        event.preventDefault();
      },
      handleContainerDragover(event) {
        event.stopPropagation();
        event.preventDefault();
        if (this.onFilesSelected) {
          this.isDraggingOver = true;
        }
      },
      handleContainerDragleave() {
        this.isDraggingOver = false;
      },
      handleContainerDrop(event) {
        event.stopPropagation();
        event.preventDefault();
        this.isDraggingOver = false;
        const files = event.dataTransfer.files;
        this.handleFiles(files);
      },
      handleFiles(files) {
        const handler = this.onFilesSelected;
        if (handler) {
          const file = files[0];
          const maxKb = this.maxKb;
          const fileKB = file.size / 1000;
          if (fileKB > maxKb) {
            this.$notify({
              type: 'error',
              group: 'vue-app',
              title: `File must be less than ${maxKb}KB`
            });
          } else {
            handler(files);
            if (files[0].type.includes('image')) {
              this.previewImage(files[0]);
            }
          }
        }
      },
      previewImage(file) {
        const reader = new FileReader();
        reader.onload = (() => {
          return (event) => {
            this.imagePreviewData = event.target.result;
          }
        })()
        reader.readAsDataURL(file);
      }
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .atmo-upload-card {

    &__container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed rgba(white, 0.7);
      border-radius: 10px;
      // min-height: 230px;
      // width: 185px;

      &--small {
        min-height: 70px;
        width: 60px;

        .atmo-upload-card__preview-image {
          object-fit: cover;
        }
      }

      &--dragover {
        background: rgba($atmo-blue--light, 0.5);
        border-style: solid;
      }

      img {
        border-radius: 10px;
      }
    }

    &__icon {
      height: 90px;

      &--small {
        height: 25px;
      }

      &--large {
        height: 110px;
      }
    }

    &__button {
      background-color: $atmo-purple--medium-dark;
      padding: 9px 14px;
      border: 1px solid rgba(white, 0.5);

      &:hover {
        border-color: white;
      }

      .atmo-upload-card__icon {
        height: 15px;
      }
    }

    &__preview-image {
      width: 100%;
      height: 100%;
    }

    &__label {
      padding: 15px;
      font-size: 1.2em;
      color: white;
      text-transform: uppercase;
      text-align: center;

      &--small {
        font-size: 0.8em;
        padding: 0;
        margin-top: 4px;
      }
    }

    &:hover {
      cursor: pointer;

      .atmo-upload-card__container {
        border-color: white;
      }

      :not(.atmo-upload-card__button) .atmo-upload-card__icon {
        height: 92px;

        &.atmo-upload-card__icon--small {
          height: 30px;
        }

        &.atmo-upload-card__icon--large {
          height: 115px;
        }
      }
    }
  }
</style>
