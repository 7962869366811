<template>
  <div class="invoices-page">
    <!-- <h2 class="atmo-page-header">Invoices</h2> -->

    <div v-if="invoices.length > 0">
      <div v-for="invoice in invoices" :key="invoice.id" class="invoice-card">
        <div class="invoice-card__details">
          <!-- <p><span class="label">Invoice ID:</span> {{ invoice.invoice_id }}</p> -->
          <p class="plan-name">{{ invoice.plan_name }}</p>
          <p><span class="label">Amount Paid:</span> ${{ invoice.amount }}</p>
          <p><span class="label">Date:</span> {{ formatDate(invoice.invoice_date) }}</p>
          <p><span class="label">Card Charged:</span> **** **** **** {{ invoice.card_last4 }}</p>
          <!-- <p><span class="label">Customer ID:</span> {{ invoice.customer_id }}</p> -->
        </div>
      </div>
    </div>

    <div v-else class="invoices-page__no-invoices">
      <p>No invoices found.</p>
    </div>
  </div>
</template>

<script>
import { callInvoicesIndex } from "@/helpers/axiosCalls";

export default {
  data() {
    return {
      invoices: [],
    };
  },
  created() {
    callInvoicesIndex(this.$store.state.currentUserId).then((response) => {
      this.invoices = response;
    });
    this.$store.commit("setHudHeader", "Invoices");
  },
  methods: {
    formatDate(date) {
      if (!date || isNaN(new Date(date).getTime())) {
        return "Invalid date"; // Return a default message for invalid dates
      }
      return new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(new Date(date));
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.invoices-page {
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;

  &__heading {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }

  &__no-invoices {
    text-align: center;
    font-size: 1rem;
    margin-top: 30px;
  }
}

.invoice-card {
  width: 30vw;
  background-color: $atmo-purple--medium-dark--faded;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 15px;

  .plan-name {
    margin-bottom: 1rem;
    font-weight: 600;
  }

  &__details {
    font-size: 1rem;
    color: white;

    p {
      margin: 5px 0;
      display: flex;
      justify-content: space-between;

      .label {
        font-weight: 600;
        color: white;
      }
    }
  }
}
</style>
