export const globalMixin = {
    methods: {
        generateRandomString(arr) {
            const randomIndex = Math.floor(Math.random() * arr.length);
            const string = arr[randomIndex];
            return string;
        },
        generateRandomImageWithPath(arr) {
            const randomIndex = Math.floor(Math.random() * arr.length);
            const imageString = require('@/assets/images/artists/' + arr[randomIndex]);
            return imageString;
        },
        generateRandomNumberFromRange(min, max) {
            return Math.floor(Math.random() * (max - min + 1) + min);
        },
        generateRandomIndexForArray(arr) {
            return Math.floor(Math.random() * arr.length);
        },
        generateRandomPurchaseAmount() {
            const randomAmount = Math.floor(Math.random() * 100) + 1;
            const formattedAmount = randomAmount.toFixed(2);
            return formattedAmount;
        }, 
        generateRandomDate() {
            const currentDate = new Date();
            const randomDays = Math.floor(Math.random() * 365 * 5);
            currentDate.setDate(currentDate.getDate() - randomDays);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
            const day = String(currentDate.getDate()).padStart(2, '0');
            const formattedDate = `${month}/${day}/${year}`;
            return formattedDate;
        },
        generateRandomSongLyrics() {
            const verses = [
                "I've been thinking 'bout you lately",
                "Walking down these lonely streets",
                "Feeling lost and so uncertain",
                "Trying to find my way back home",
                "Underneath the stars so bright",
                "I see your face in every light",
                "With every breath I take",
                "I know I'm not alone",
                "Every step I take",
                "Leads me closer to you",
                "Through the trials and the pain",
                "Our love remains",
                "In the darkness of the night",
                "You're the only one in sight",
                "I'll never let you go",
                "Together we'll face every foe",
                "From the mountain tops so high",
                "To the oceans deep and wide",
                "Our love will never die",
                "We'll keep the flame alive",
                "Lost in this maze of life",
                "I found my way through your eyes",
                "With every kiss and every touch",
                "I feel like I'm in paradise",
                "In your arms I feel so safe",
                "Nothing else can take your place",
                "I'm forever yours",
                "With you I'll face every force",
                "In this world of chaos and strife",
                "You are my light",
                "With you by my side",
                "I can conquer the night",
                "I see the world in a different light",
                "With you I know everything's alright",
                "We'll dance under the moon and stars",
                "And forget about our scars",
                "With you I'm free",
                "Together we'll be",
                "In this moment so divine",
                "I know that you're mine",
                "In this journey of life",
                "I'm glad you're my guide",
                "With you I feel complete",
                "Our love is the sweetest treat"
            ];

            const choruses = [
                "But I won't give up, no I won't give in",
                "I'll keep on fighting 'til the bitter end",
                "Together we can make it through this storm",
                "With love and faith we'll stay strong",
                "We'll rise above the pain and strife",
                "With you by my side, I can take on life",
                "Through the darkness, we'll find the light",
                "Our love will guide us, shining bright",
                "With every step we take, we'll grow",
                "Our love will flourish and continue to flow",
                "In this world of chaos and despair",
                "Our love will be the one thing that's fair",
                "No matter what life may throw our way",
                "Our love will conquer and win the day",
                "Through the highs and lows, we'll stay true",
                "Together we'll weather any storm that comes through",
                "In the face of adversity, we'll stand tall",
                "Our love will never falter, never fall",
                "Through the fire and the rain",
                "Our love will always remain",
                "With every beat of our hearts",
                "We'll never be torn apart",
                "In this world of uncertainty",
                "Our love will be our only guarantee",
                "Through the struggles and the pain",
                "Our love will always remain"
            ];

            const bridges = [
                "We'll rise above the fray",
                "The road ahead may be long",
                "Together we'll conquer it all",
                "In the darkness we'll find the light",
                "Our love will guide us through the night",
                "Through the storm we'll find our way",
                "With every step we take, we'll grow",
                "We'll keep on dancing, even on our own",
                "As long as I'm with you, we can make it through",
                "Sometimes love feels like a battlefield",
                "But we'll keep on fighting, we'll never yield",
                "Through the highs and lows, we'll stay true",
                "Our love will shine like the morning dew",
                "In the depths of the night",
                "We'll hold each other tight",
                "Our hearts beat as one",
                "Together we'll overcome",
                "In this world of uncertainty",
                "Our love will be our only guarantee",
                "Through the struggles and the pain",
                "Our love will always remain",
                "We'll take a leap of faith",
                "And trust in each other's embrace",
                "Through the fire and the rain",
                "Our love will always remain",
                "We'll dance under the moonlit sky",
                "And watch as the world goes by",
                "Together we'll create a symphony",
                "With our hearts as the only melody",
                "In this moment, nothing else matters",
                "Our love will be our shelter",
                "Through the twists and turns of life",
                "Our love will be our guiding light",
                "We'll build a fortress of love",
                "And keep the world at bay",
                "With every breath we take",
                "Our love will never fade",
                "We'll be each other's anchor",
                "In the midst of life's tempest",
                "With every step we take",
                "Our love will only grow stronger"
            ];

            let songLyrics = [];

            // generate random number of verse strings (between 24 and 26)
            const numVerses = Math.floor(Math.random() * 25) + 2;
            for (let i = 0; i < numVerses; i++) {
                let randomVerse = verses[Math.floor(Math.random() * verses.length)];
                songLyrics.push(randomVerse);
            }

            // generate random number of chorus strings (between 24 and 26)
            const numChoruses = Math.floor(Math.random() * 25) + 2;
            for (let i = 0; i < numChoruses; i++) {
                let randomChorus = choruses[Math.floor(Math.random() * choruses.length)];
                songLyrics.push(randomChorus);
            }

            // generate random number of bridge strings (between 9 and 11)
            const numBridges = Math.floor(Math.random() * 10);
            for (let i = 0; i < numBridges; i++) {
                let randomBridge = bridges[Math.floor(Math.random() * bridges.length)];
                songLyrics.push(randomBridge);
            }

            const concatenatedString = songLyrics.join(" ");
            return concatenatedString;
        }
    }
};
