import createChannel from '@/cable';

let callback; // A function to call when a message is received
let channel = null; // Holds the channel instance

// Connect to the LiveListeningSongsChannel using the room ID
function connectToLiveListeningSongsChannel(roomId) {
    console.log("[Connecting to LiveListeningSongsChannel] Room ID:", roomId);
    channel = createChannel(`LiveListeningSongsChannel`, roomId, {
    received(data) {
        console.log("[Live Listening Update Received]:", data);
      if (callback) callback(data);
    }
  });
}

// New function to send a songs update event
function sendSongsUpdate(roomId, userId) {
  console.log("sendSongsUpdate")
  if (!channel) {
    console.error('LiveListeningSongsChannel not initialized. Did you call connectToLiveListeningSongsChannel?');
    return;
  }
  channel.perform("songs_updated", { chat_room_id: roomId, user_id: userId });
  console.log("Songs update event sent");
}

// Set the callback that gets executed when a broadcast is received
function setCallback(fn) {
  callback = fn;
  console.log("Callback set for receiving songs update events.");
}

export { connectToLiveListeningSongsChannel, sendSongsUpdate, setCallback };
