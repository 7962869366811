<template>
  <div class="atmo-wrapper">
    <div class="atmo-items-wrapper">
      <div class="atmo-auth atmo-auth-signup">
        <div class="atmo-auth__header">
          <h1 class="atmo-auth__header-text">Welcome to</h1>
          <h2 class="atmo-auth__header-text2">
            The
            <span class="atmo-auth__header-a">Atmosphere</span>.
          </h2>
          <h3 class="atmo-auth__header-text3">Create your account by filling the form below.</h3>
        </div>

        <!-- Hide radio buttons on Step 2 -->
        <div class="atmo-auth__checkbox-container" v-if="currentStep === 1">
          <atmo-radio-button :checked="type === 'UserProfile'" @click="onRadioButtonClick('UserProfile')" label="User" />
          <atmo-radio-button :checked="type === 'ArtistProfile'" @click="onRadioButtonClick('ArtistProfile')" label="Artist" />
          <atmo-radio-button :checked="type === 'LabelProfile'" @click="onRadioButtonClick('LabelProfile')" label="Label" />
        </div>

        <form id="atmo-auth-form" @submit.prevent="handleSubmit">
          <!-- UserProfile Step 1 -->
          <div v-if="type === 'UserProfile' && currentStep === 1">
            <atmo-input v-model="email" onlyunder type="email" name="email" :maxlength="50" placeholder="theatmosphere@gmail.com" label="Email" />
            <atmo-input v-model="password" onlyunder type="password" name="password" :maxlength="50" label="Password" placeholder="••••••">
              <atmo-password-strength slot="extra" :password="password" />
            </atmo-input>
            <atmo-input v-model="confirmPassword" onlyunder type="password" :maxlength="50" name="confirmPassword" label="Confirm Password" placeholder="••••••" @input="validatePasswords" />
            <p v-if="passwordError" class="password-error">{{ passwordError }}</p>
          </div>

          <!-- UserProfile Step 2 -->
          <div v-if="type === 'UserProfile' && currentStep === 2">
            <atmo-input v-model="firstName" onlyunder type="text" name="firstname" :maxlength="50" placeholder="John" label="First Name" />
            <atmo-input v-model="lastName" onlyunder type="text" name="lastname" :maxlength="50" placeholder="Doe" label="Last Name" />
            <div class="atmo-auth__checkbox-container">
              <label class="atmo-auth__label">Gender:</label>
              <atmo-radio-button :checked="gender === 'Male'" @click="onGenderClick('Male')" label="Male" />
              <atmo-radio-button :checked="gender === 'Female'" @click="onGenderClick('Female')" label="Female" />
              <atmo-radio-button :checked="gender === 'Other'" @click="onGenderClick('Other')" label="Other" />
            </div>
            <atmo-input v-model="birthday" onlyunder type="text" name="birthday" :maxlength="10" placeholder="MM/DD/YYYY" label="Date of Birth" @input="formatBirthday" />

            <!-- Country Dropdown -->
            <div class="atmo-auth__address-dropdown-container">
              <label for="country">Country</label>
              <select id="country" v-model="addressData.country" @change="onCountryChange" class="dropdown-group">
                <option value="" disabled>Select Country*</option>
                <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
              </select>
            </div>

            <!-- State Dropdown -->
            <div class="atmo-auth__address-dropdown-container">
              <label for="state">State</label>
              <select id="state" v-model="addressData.state" :disabled="!states.length" @change="onStateChange" class="dropdown-group">
                <option value="" disabled>
                  {{ states.length ? "Select State*" : "No States Available" }}
                </option>
                <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
              </select>
            </div>

            <!-- City Dropdown -->
            <div class="atmo-auth__address-dropdown-container">
              <label for="city">City</label>
              <select id="city" v-model="addressData.city" class="dropdown-group">
                <option value="" disabled>Select City*</option>
                <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
              </select>
            </div>
          </div>

          <!-- Artist/Label Profile -->
          <div v-if="type !== 'UserProfile'">
            <atmo-input v-model="name" onlyunder type="text" name="name" :maxlength="50" placeholder="Your Name" label="Name" />
            <atmo-input v-model="email" onlyunder type="email" name="email" :maxlength="50" placeholder="theatmosphere@gmail.com" label="Email" />
            <atmo-input v-model="password" onlyunder type="password" name="password" :maxlength="50" label="Password" placeholder="••••••">
              <atmo-password-strength slot="extra" :password="password" />
            </atmo-input>
            <atmo-input v-model="confirmPassword" onlyunder type="password" :maxlength="50" name="confirmPassword" label="Confirm Password" placeholder="••••••" />
          </div>

          <div class="atmo-auth__button">
            <!-- Back Button for Step 2 -->
            <button v-if="currentStep === 2" type="button" class="atmo-auth__back-button" @click="goToPreviousStep">Back</button>
            <input v-if="!isNextDisabled" type="submit" :value="getSubmitButtonText()" class="atmo-auth__card-button" />
            <input v-if="isNextDisabled" type="submit" :value="getSubmitButtonText()" class="atmo-auth__card-button--disabled" :disabled="isNextDisabled" />
          </div>
        </form>
      </div>
      <background
        h1="Do you already have an account?"
        h2="Welcome back! You can login by clicking on the button below. You can sign in to your account using your login credentials."
        button-text="Login"
        to-route="login"
        ads="Are you a business looking to advertise your products/services on Atmosphere?"
      />
    </div>
  </div>
</template>

<script>
import AtmoInput from "@/components/atmo-input";
import AtmoPasswordStrength from "@/components/atmo-password-strength";
import AtmoRadioButton from "@/components/atmo-radio-button";
import Background from "./background";
import { callUserTokenCreate, callUsersCreate, callUsersShow } from "@/helpers/axiosCalls";
import { mapState, mapActions } from "vuex";
import { setDefaultAxiosAuth, setDefaultAxiosHeaders } from "@/helpers/axios";

export default {
  components: {
    Background,
    AtmoInput,
    AtmoPasswordStrength,
    AtmoRadioButton,
  },
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      name: null,
      firstName: null,
      lastName: null,
      gender: null,
      birthday: null,
      type: "UserProfile",
      currentStep: 1,
      addressData: {
        country: "",
        state: "",
        city: "",
      },
      passwordError: "",
    };
  },
  computed: {
    ...mapState("address", ["countries", "states", "cities"]),
    isNextDisabled() {
      // Disable button if passwords don't match or fields are empty
      return this.currentStep === 1 && this.type === "UserProfile" && (!this.password || this.password !== this.confirmPassword);
    },
  },
  created() {
    this.setToken();
    this.getUser();
    this.fetchCountries();
  },
  methods: {
    ...mapActions("address", ["fetchCountries", "fetchStates", "fetchCities"]),
    onCountryChange() {
      this.addressData.state = "";
      this.addressData.city = "";
      if (this.addressData.country) {
        this.fetchStates(this.addressData.country);
      }
    },
    onStateChange() {
      this.addressData.city = "";
      if (this.addressData.state) {
        this.fetchCities(this.addressData.state);
      }
    },
    validatePasswords() {
      if (this.password !== this.confirmPassword) {
        this.passwordError = "Passwords do not match.";
      } else {
        this.passwordError = "";
      }
    },
    parseToken(token = "") {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const id = JSON.parse(window.atob(base64)).sub;
      this.$store.commit("setCurrentUserId", id);
    },
    getToken() {
      const { email, password } = this;
      callUserTokenCreate({
        auth: {
          email,
          password,
        },
      })
        .then((response) => {
          const token = response.jwt;
          localStorage.setItem("token", response.jwt);
          setDefaultAxiosAuth(token);
          setDefaultAxiosHeaders(token);
          this.$router.push("/").then(() => {
            window.location.reload();
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSubmitButtonText() {
      if (this.type === "UserProfile") {
        return this.currentStep === 1 ? "Next" : "Sign Up";
      }
      return "Sign Up";
    },
    goToPreviousStep() {
      if (this.currentStep > 1) {
        this.currentStep -= 1;
      }
    },
    handleSubmit(event) {
      this.validatePasswords();

      if (this.passwordError) {
        return;
      }

      if (this.type === "UserProfile" && this.currentStep === 1) {
        this.currentStep = 2;
        return;
      }
      this.signUp(event);
    },
    signUp(event) {
      console.log("CLICKED");
      if (event) event.preventDefault();

      const { email, password, type, name, firstName, lastName, gender, birthday } = this;
      let formattedBirthday;

      if (this.type === "UserProfile") {
        const [month, day, year] = birthday.split("/");
        formattedBirthday = year && month && day ? `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}` : null;
      }

      const payload = {
        email,
        password,
        profile_type: type,
        user_profile:
          type === "UserProfile"
            ? {
                first_name: firstName,
                last_name: lastName,
                gender,
                birthday: formattedBirthday,
              }
            : {
                name,
              },
      };

      if (this.type === "UserProfile") {
        payload.address_attributes = this.addressData;
      }

      console.log("PAYLOAD", payload);

      callUsersCreate(payload)
        .then(() => {
          this.getToken();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    setToken() {
      const token = localStorage.getItem("token");
      if (token !== null) {
        this.$store.commit("setToken", token);
      } else {
        console.log("NO TOKEN");
      }
    },
    getUser() {
      if (this.$store.state.token !== null) {
        this.parseToken(this.$store.state.token);
        console.log("TOKEN FOUND; GETTING USER...");
        callUsersShow(this.$store.state.currentUserId)
          .then((response) => {
            this.user = response;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.log("USER NOT LOGGED IN");
      }
    },
    onRadioButtonClick(type) {
      this.type = type;
      this.currentStep = 1; // Reset to first step for UserProfile
      if (type !== "UserProfile") {
        this.firstName = null;
        this.lastName = null;
        this.gender = null;
        this.birthday = null;
      } else {
        this.name = null;
      }
    },
    onGenderClick(gender) {
      this.gender = gender;
    },
    formatBirthday(event) {
      let value = event.target.value.replace(/\D/g, ""); // Remove non-numeric characters
      if (value.length > 2) value = value.slice(0, 2) + "/" + value.slice(2);
      if (value.length > 5) value = value.slice(0, 5) + "/" + value.slice(5, 9);
      this.birthday = value.slice(0, 10); // Restrict to MM/DD/YYYY
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-auth-signup {
  .atmo-auth__address-dropdown-container {
    margin-top: 1rem;

    label {
      display: block;
      font-size: 0.75rem;
      font-weight: 100;
      color: #888888;
      margin-bottom: 0.5rem;
    }
  }

  .atmo-auth__card-button--disabled {
    text-transform: uppercase;
    padding: 0.5rem 2rem;
    font-size: 0.75rem;
    border-radius: 4px;
    border: unset;
    color: white;
    font-weight: 600;
    background: gray;
  }

  .atmo-auth__back-button {
    margin-right: 1rem;
    padding: 0.5rem 1rem;
    background: transparent;
    color: #333;
    cursor: pointer;
    font-size: 0.875rem;
    transition: background 0.3s ease;
    border: none;
    border-radius: 5px;

    &:hover {
      background: #f5f5f5;
    }
  }

  .password-error {
    color: #e74c3c;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
}
</style>
