<template>
  <div class="subscription-details">
    <!-- <h2 class="atmo-page-header">Your Subscription</h2> -->
    <div v-if="stripeSubscription && stripeSubscription.status" class="subscription-details__info">
      <div class="info-item"><span class="label">Name:</span> {{ atmoSubscription.name }}</div>
      <div class="info-item">
        <span class="label">Amount:</span>
        ${{ (atmoSubscription.amount / 100).toFixed(2) }} / {{ atmoSubscription.interval === "month" ? "month" : "year" }}
      </div>
      <div class="info-item"><span class="label">Trial Days:</span> {{ atmoSubscription.trial_period_days }}</div>
      <div class="info-item">
        <span class="label">Status:</span>
        <span :class="`status-${stripeSubscription.status}`">{{ stripeSubscription.status }}</span>
      </div>
    </div>
    <div v-if="stripeSubscription && stripeSubscription.created" class="subscription-details__dates">
      <div class="date-item"><span class="label">Created At:</span> {{ formatDate(stripeSubscription.created) }}</div>
      <div class="date-item"><span class="label">Period Start:</span> {{ formatDate(stripeSubscription.current_period_start) }}</div>
      <div class="date-item"><span class="label">Period End:</span> {{ formatDate(stripeSubscription.current_period_end) }}</div>
      <div class="date-item"><span class="label">Billing Cycle Anchor:</span> {{ formatDate(stripeSubscription.billing_cycle_anchor) }}</div>
      <div class="date-item"><span class="label">Canceled:</span> {{ stripeSubscription.canceled_at ? "Yes" : "No" }}</div>
      <div class="date-item"><span class="label">Set To Cancel:</span> {{ stripeSubscription.cancel_at_period_end ? "Yes" : "No" }}</div>
      <div class="date-item"><span class="label">Discount Applied:</span> {{ stripeSubscription.discount || "None" }}</div>
    </div>
    <div v-if="stripeSubscription && stripeSubscription.status" class="subscription-details__actions">
      <router-link
        v-if="$store.state.currentUserProfileId"
        class="atmo-button atmo-button--secondary"
        :to="{ name: 'subscriptions.user_invoices.index', params: { userProfileId: $store.state.currentUserProfileId, subscriptionId: atmoSubscription.id } }"
      >
        View Invoices
      </router-link>
      <button v-if="stripeSubscription.status !== 'canceled'" class="atmo-button atmo-button--danger" @click="cancelStripeSubscription(stripeSubscription.id)">Cancel Subscription</button>
      <button
        v-if="stripeSubscription.status !== 'canceled' && !stripeSubscription.cancel_at_period_end"
        class="atmo-button atmo-button--secondary"
        @click="setStripeSubscriptionToCancel(stripeSubscription.id)"
      >
        Set Subscription to Cancel
      </button>
      <button
        v-if="stripeSubscription.status !== 'canceled' && stripeSubscription.cancel_at_period_end"
        class="atmo-button atmo-button--primary"
        @click="reverseStripeSubscriptionCancellation(stripeSubscription.id)"
      >
        Reverse Cancellation
      </button>
      <router-link v-if="stripeSubscription.status === 'canceled'" class="atmo-button atmo-button--primary" :to="{ name: 'subscriptions.user_plans.index' }"> Resubscribe to Plus! </router-link>
    </div>
  </div>
</template>

<script>
import {
  callSubscriptionsShow,
  callSubscriptionsUpdate,
  callStripeSubscriptionsDelete,
  callStripeSubscriptionsPost,
  callStripeSubscriptionsGet,
  callStripeSubscriptionsPut,
} from "@/helpers/axiosCalls";

export default {
  data() {
    return {
      atmoSubscription: {},
      stripeSubscription: {
        status: "", // Default status to avoid undefined errors
        cancel_at_period_end: false,
        created: null,
        current_period_start: null,
        current_period_end: null,
        billing_cycle_anchor: null,
        canceled_at: null,
        discount: null,
      },
    };
  },
  created() {
    this.fetchSubscriptionDetails();
    this.$store.commit("setHudHeader", "Your Subscription");
  },
  methods: {
    fetchSubscriptionDetails() {
      callSubscriptionsShow(this.$store.state.currentUserId, this.$route.params.subscriptionId)
        .then((subRes) => {
          this.atmoSubscription = subRes;
          return callStripeSubscriptionsGet(this.atmoSubscription.stripe_subscription_id);
        })
        .then((stripeSubRes) => {
          this.stripeSubscription = stripeSubRes;
        })
        .catch((error) => {
          console.error("Error fetching subscription data:", error);
        });
    },

    formatDate(timestamp) {
      return new Date(timestamp * 1000).toLocaleDateString();
    },

    updateAtmoSubStatus(status) {
      callSubscriptionsUpdate(this.$store.state.currentUserId, this.$route.params.subscriptionId, { status })
        .then((response) => {
          console.log("Atmo subscription status updated:", response);
        })
        .catch((error) => {
          console.error("Error updating Atmo subscription status:", error);
        });
    },

    cancelStripeSubscription(subId) {
      callStripeSubscriptionsDelete(subId, { at_period_end: false })
        .then(() => {
          this.updateAtmoSubStatus("canceled");
          alert("Subscription Canceled");
          this.fetchSubscriptionDetails();
        })
        .catch((error) => {
          console.error("Error canceling subscription:", error);
        });
    },

    setStripeSubscriptionToCancel(subId) {
      callStripeSubscriptionsPut(subId, { cancel_at_period_end: true })
        .then((response) => {
          console.log("Subscription set to cancel at period end:", response);
          this.stripeSubscription = response || {};
          this.updateAtmoSubStatus("set to cancel");
          alert("Subscription Set To Cancel");
          // this.$router.push({ name: "subscriptions.index" });
        })
        .catch((error) => {
          console.error("Error setting subscription to cancel:", error);
        });
    },

    reverseStripeSubscriptionCancellation(subId) {
      callStripeSubscriptionsPut(subId, { cancel_at_period_end: false })
        .then((response) => {
          console.log("Subscription cancellation reversed:", response);
          this.stripeSubscription = response || {};
          this.updateAtmoSubStatus("active");
          alert("Subscription Cancellation Reversed");
          // this.$router.push({ name: "subscriptions.index" });
        })
        .catch((error) => {
          console.error("Error reversing subscription cancellation:", error);
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.subscription-details {
  max-width: 50vw;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  &__heading {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
  }

  &__info,
  &__dates {
    display: flex;
    flex-wrap: wrap;
    background-color: $atmo-purple--medium-dark--faded;
    border-radius: 5px;
    padding: 1rem;
    gap: 1rem;
  }

  .info-item,
  .date-item {
    width: 48%;
    font-size: 1rem;
    display: flex;
    justify-content: space-between;

    .label {
      font-weight: 600;
    }
  }

  &__actions {
    display: flex;
    gap: 0.8rem;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 1rem;

    .atmo-button--primary {
      background-color: white;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      font-weight: bold;
      transition: background-color 0.3s ease;
    }

    .atmo-button--secondary {
      background-color: white;
      color: #333;
      padding: 10px 20px;
      border-radius: 5px;
      font-weight: bold;
      transition: background-color 0.3s ease;
    }

    .atmo-button--danger {
      background-color: #dc3545;
      color: white;
      padding: 10px 20px;
      border-radius: 5px;
      font-weight: bold;
      transition: background-color 0.3s ease;
    }

    .atmo-button--primary:hover {
      background-color: #005bb5;
    }

    .atmo-button--secondary:hover {
      background-color: #bbb;
    }

    .atmo-button--danger:hover {
      background-color: #c82333;
    }
  }

  .status-active {
    color: $atmo-blue--light;
    font-weight: bold;
  }
  .status-canceled {
    color: $atmo-pink--medium;
    font-weight: bold;
  }
  .status-pending {
    color: $atmo-purple--medium;
    font-weight: bold;
  }
}
</style>
