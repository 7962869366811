<template>
  <div class="my-purchases">
    <!-- <h2 class="atmo-page-header my-purchases__header">ATMO Purchases</h2> -->
    <section class="my-purchases__body">
      <div class="my-purchases__main-content">
        <!-- Table Header -->
        <div class="purchases-header">
          <div class="purchases-header__cell">Date</div>
          <div class="purchases-header__cell">Description</div>
          <div class="purchases-header__cell">Total amount</div>
          <div class="purchases-header__cell">More</div>
        </div>

        <!-- Scrollable Table Body -->
        <perfect-scrollbar class="my-purchases__scroll">
          <div class="purchases-body">
            <div
              v-for="purchase in purchases"
              :key="purchase.id"
              class="purchases-body__row"
              component-type="router-link"
              :to="{ name: 'purchases.show', params: { userId: $route.params.userId, purchaseId: purchase.id } }"
            >
              <div class="purchases-body__cell">{{ formatdate(purchase.created_at) }}</div>
              <div class="purchases-body__cell">{{ purchase.description }}</div>
              <div class="purchases-body__cell">${{ formatAmount(purchase.amount) }}</div>
              <div class="purchases-body__cell button-container">
                <router-link v-if="purchase.transaction_type == 'store' || purchase.transaction_type == 'event'" :to="`purchases/${purchase.id}`" class="atmo-button atmo-button--primary" tag="button">
                  See more
                </router-link>
              </div>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </section>
    <template v-if="purchases.length === 0">
      <p>No purchases at this time.</p>
    </template>
  </div>
</template>

<script>
import { callTransactionsIndex } from "@/helpers/axiosCalls";
import Moment from "moment";

export default {
  data() {
    return {
      purchases: [],
      error: false,
    };
  },
  created() {
    callTransactionsIndex(this.$store.state.currentUserId)
      .then((response) => {
        this.purchases = response.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      })
      .catch((error) => {
        console.log(error);
        this.error = true;
      });

    this.$store.commit("setHudHeader", "Atmo Purchases");
  },
  methods: {
    formatdate(value) {
      return Moment(value).format("ddd, MMM DD. h:mm a");
    },
    formatAmount(value) {
      return (value / 100).toFixed(2); // Converts cents to dollars and formats with two decimal places
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.my-purchases {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  &__header {
    margin: 35px 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  &__main-content {
    min-height: 300px;
  }

  &__scroll {
    max-height: 400px; /* Adjust height as needed */
    overflow-y: auto;
  }

  .purchases-header {
    display: flex;
    padding: 8px 0;
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    background-color: $atmo-purple--medium;
    border-radius: 5px;
    margin-bottom: 1rem;
  }

  .purchases-header__cell,
  .purchases-body__cell {
    flex: 1;
    text-align: center;
    padding: 8px;
    font-weight: 500;

    &.button-container {
      display: flex;
      justify-content: center;
    }

    .atmo-button {
      text-transform: unset;
      max-width: 11rem;
      padding: 0.5rem;
      font-weight: 500;
    }
  }

  .purchases-body__row {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid #ddd;
    min-height: 4rem;
  }

  .purchases-body__row:hover {
    background-color: $atmo-purple--medium-dark--faded;
  }

  .atmo-button--primary {
    padding: 4px 8px;
    font-size: 14px;
  }
}
</style>
