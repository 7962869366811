<template>
  <section class="atmo-ad">
    <div class="atmo-ad__advertise_wrap" v-if="showAtmoAdvertLink">
      <router-link v-if="$store.state.currentUserId" :to="{ name: 'advertisements.index', params: { userId: $store.state.currentUserId } }"> Advertise on Atmo </router-link>
    </div>
    <div class="atmo-ad__ad-container" v-if="currentAd && currentAd.image">
      <div
        class="ad-container__ad"
        :style="{
          backgroundImage: 'linear-gradient(-270deg, rgba(68,174,220,0.5) 0%, rgba(217,82,167,0.5) 100%), url(' + currentAd.image + ')',
        }"
      ></div>
      <div class="ad-container__title-wrap">{{ currentAd.title }}</div>
      <div class="ad-container__description-wrap">{{ currentAd.copy }}</div>
      <a :href="currentAd.link" target="_blank" class="ad-container__link" @click="sendClickData()">
        {{ currentAd.link_label }}
      </a>
    </div>
    <div v-else>
      <p>No ads available</p>
    </div>
  </section>
</template>

<script>
import { UAParser } from "ua-parser-js";
import { callAdClicksCreate } from "@/helpers/axiosCalls";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    showAtmoAdvertLink: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      componentId: uuidv4(),
      userInfo: {
        browser: "",
        os: "",
        deviceType: "",
      },
    };
  },
  computed: {
    currentAd() {
      return this.$store.getters["platformAds/currentAd"](this.componentId);
    },
  },
  async created() {
    this.$store.dispatch("platformAds/registerComponent", this.componentId);
  },
  beforeDestroy() {
    this.$store.dispatch("platformAds/unregisterComponent", this.componentId);
  },
  methods: {
    getIpAddress() {
      return this.$store.state.currentUser.current_sign_in_ip || "127.0.0.1";
    },
    async sendClickData() {
      if (!this.currentAd) return;

      try {
        const parser = new UAParser();
        const result = parser.getResult();
        this.userInfo.browser = result.browser.name;
        this.userInfo.os = result.os.name;
        this.userInfo.deviceType = result.device.type || "desktop";

        const ipAddress = this.getIpAddress();
        const { browser, os, deviceType } = this.userInfo;

        await callAdClicksCreate(this.$store.state.currentUserId, {
          ad_type: "PlatformAd",
          ad_id: this.currentAd._id,
          user_id: this.$store.state.currentUserId,
          device_type: deviceType,
          browser: browser,
          os: os,
          ip_address: ipAddress,
          page_url: null,
        });
      } catch (error) {
        console.error("Error logging click:", error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-ad {
  margin-top: 3rem;

  &__advertise_wrap {
    display: flex;
    justify-content: center;
    a {
      color: $atmo-gray--light--faded;
      text-transform: uppercase;
    }
  }

  &__ad-container {
    margin-top: 10px;
    padding: 0.6rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    min-height: 14rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ad-container__ad {
    height: 5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 1rem;
  }

  .ad-container__title-wrap {
    font-size: 1rem;
    margin-bottom: 0.4rem;
    max-width: 8.5rem;
  }

  .ad-container__description-wrap {
    font-size: 0.8rem;
    margin-bottom: 1rem;
    max-width: 8.5rem;
  }

  .ad-container__link {
    margin-top: auto;
    display: inline-block;
    border: 1px solid white;
    border-radius: 3px;
    text-transform: uppercase;
    padding: 0.2rem 0.5rem 0.2rem 0.5rem;
    color: white;
    font-size: 0.7rem;
    width: 100%;
    text-align: center;
  }
}
</style>
