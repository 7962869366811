import Axios from 'axios';

// Fetch a Stripe subscription by ID from the Rails backend
export function callStripeSubscriptionsGet(subscriptionId) {
    return Axios.get(`/api/v1/stripe_subscriptions/${subscriptionId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

// Create a new Stripe subscription through the Rails backend
export function callStripeSubscriptionsPost(subscriptionData = {}) {
    return Axios.post(`/api/v1/stripe_subscriptions`, subscriptionData)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

// Update an existing Stripe subscription through the Rails backend
export function callStripeSubscriptionsPut(subscriptionId, subscriptionData = {}) {
    return Axios.put(`/api/v1/stripe_subscriptions/${subscriptionId}`, subscriptionData)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

// Delete a Stripe subscription through the Rails backend
export function callStripeSubscriptionsDelete(subscriptionId) {
    return Axios.delete(`/api/v1/stripe_subscriptions/${subscriptionId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}
