<template>
    <router-link v-if="randomDirectoryAd.id" class="directory-ad-container__directory-ad-wrap"
        :to="{ name: 'directory_profiles.show', params: { directoryProfileId: randomDirectoryAd.id } }">
        <div class="directory-ad-wrap__image-wrap">
            <img :src="randomDirectoryAd.image.url" alt="ad" class="image-wrap__image">
        </div>
        <div class="directory-ad-wrap__copy-wrap">
            <p>{{ randomDirectoryAd.copy }}</p>
        </div>
    </router-link>
</template>

<script>
import Axios from 'axios';

export default {
    data: function () {
        return {
            directoryAds: [],
            randomDirectoryAd: {},
        }
    },
    computed: {
    },
    created() {
        this.getDirectoryAds();
    },
    methods: {
        getDirectoryAds() {
            Axios.get(`/api/v1/users/1/directory_ads`, {
            })
                .then((response) => {
                    this.directoryAds = response.data;
                    this.randomDirectoryAd = this.directoryAds[Math.floor(Math.random() * this.directoryAds.length)];
                    // console.log("RANDOM AD: ", this.randomDirectoryAd);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    }
}
</script>


<style lang="scss" scoped>
.directory-ad-container__directory-ad-wrap {
    display: flex;
    align-items: center;
    gap: .5rem;
    color: white;
}

.directory-ad-wrap__image-wrap {
    img {
        height: 2.8rem;
        border-radius: 5px;
    }
}

.directory-ad-wrap__copy-wrap {
    font-weight: 500;
    max-width: 10rem;
}
</style>