<template>
  <div class="atmo-directory">
    <div class="atmo-directory__header-container">
      <div class="header-container__ad-container">
        <atmo-directory-ad />
        <atmo-directory-ad />
      </div>
      <!-- <div class="header-container__header-wrap">
        <h2 class="atmo-page-header atmo-directory__header">Atmo Directory</h2>
      </div> -->
      <div class="header-container__ad-container">
        <atmo-directory-ad />
        <atmo-directory-ad />
      </div>
    </div>
    <section class="atmo-directory__body">
      <div class="atmo-directory__controls">
        <form class="atmo-form">
          <input type="text" placeholder="Search Directory" class="atmo-directory__search atmo-input atmo-input--search" :value="query" @input="onSearch" />
        </form>
        <div class="atmo-directory__filters">
          <atmo-filter-controls :selected-filter-value="selectedFilterValue" :selected-filter-type="selectedFilterType" :on-filter-button-click="showFilterModal" :on-reset="resetFilter" />
        </div>
      </div>
      <div class="atmo-directory__main-content">
        <div v-if="isLoading" class="atmo-directory__empty">
          <atmo-loading />
        </div>
        <div v-else-if="filteredProfiles.length === 0" class="atmo-directory__empty">
          <img class="atmo-empty-state__icon" :src="`${allProfiles.length === 0 ? getImgUrl('no-directory-profiles') : getImgUrl('no-results')}`" />
          <span v-if="allProfiles.length === 0" class="atmo-empty-state__label"> There are no directory profiles </span>
          <span v-else class="atmo-empty-state__label"> There are no profiles that match your search </span>
        </div>
        <table v-else class="atmo-table">
          <thead class="atmo-table__thead">
            <tr>
              <th class="atmo-table__th">Name</th>
              <th class="atmo-table__th">Profession</th>
              <th class="atmo-table__th">Rate</th>
              <th class="atmo-table__th">Location</th>
              <th class="atmo-table__th">Contact</th>
              <th class="atmo-table__th">Website</th>
            </tr>
          </thead>
          <tbody v-if="filteredProfiles.length === 0">
            <img class="atmo-empty-state__icon" src="@/assets/images/icons/no-artists.png" />
            <span v-if="allProfiles.length === 0" class="atmo-empty-state__label"> Oops! There are no profiles! </span>
            <span v-else class="atmo-empty-state__label"> No matching profiles. Try again </span>
          </tbody>
          <tbody v-else>
            <tr v-for="(profile, index) in filteredProfiles" :key="index" :class="index % 2 !== 0 ? 'atmo-table__tr --dark' : 'atmo-table__tr --light'">
              <td class="atmo-table__td">
                <router-link v-if="profile.id" data-cy="cell-name" :to="{ name: 'directory_profiles.show', params: { directoryProfileId: profile.id } }">
                  {{ profile.name }}
                </router-link>
              </td>
              <td class="atmo-table__td" data-cy="cell-profession">
                {{ (professionById(profile.directory_profession_id) || {}).name }}
              </td>
              <td class="atmo-table__td" data-cy="cell-rate">
                <span v-if="profile.hourly_rate"> {{ returnCurrencyFormat(profile.hourly_rate) }}/hr </span>
              </td>
              <td class="atmo-table__td" data-cy="cell-location">
                {{ profile.location }}
              </td>
              <td class="atmo-table__td" data-cy="cell-email">
                {{ profile.contact_info }}
              </td>
              <td class="atmo-table__td" data-cy="cell-website">
                {{ profile.website }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
    <footer v-if="!isLoading" class="atmo-directory__footer">
      <button class="atmo-button atmo-button--tertiary" data-cy="edit-profile" @click="$store.dispatch('directoryProfiles/toggleEditProfileModal')">Edit Profile</button>
      <!-- NEED TO ADD DIRECTORY ADS TYPE TO THESE PAGES -->
      <router-link v-if="$store.state.currentUserId" class="atmo-button atmo-button--secondary" :to="{ name: 'advertisements.index', params: { userId: $store.state.currentUserId } }">
        <img class="atmo-button__icon" src="@/assets/images/icons/bullhorn.png" />
        <span>Create an Ad</span>
      </router-link>
    </footer>

    <edit-profile-modal
      v-if="!isLoading"
      :states-and-cities="statesAndCities"
      :professions="professions"
      :selected-name="myProfile.name"
      :selected-rate="myProfile.hourly_rate"
      :selected-email="myProfile.contact_info"
      :selected-website="myProfile.website"
      :selected-location="myProfile.location"
      :selected-profession="myProfession"
      :selected-is-public="myProfile.is_public"
      :on-submit="onSubmitEditProfile"
    />

    <filter-modal
      v-if="!isLoading"
      :selected-filter-type="selectedFilterType"
      :states-and-cities="statesAndCities"
      :professions="professions"
      :selected-state="selectedFilterState"
      :selected-city="selectedFilterCity"
      :selected-profession="selectedFilterProfession"
      :selected-min-price="selectedFilterMinPrice"
      :selected-max-price="selectedFilterMaxPrice"
      :on-submit="onSubmitFilterModal"
    />
  </div>
</template>

<script>
import Axios from "axios";
import {
  // directoryProfilesStub,
  statesAndCitiesStub,
} from "../music/data";
import AtmoLoading from "@/components/atmo-loading";
import AtmoFilterControls from "@/components/atmo-filter-controls";
import EditProfileModal from "./edit-profile-modal";
import FilterModal from "./filter-modal";
import AtmoDirectoryAd from "@/components/atmo-directory/atmo-directory-ad";
import { utilsGetImgUrl } from "@/helpers/utilityFunctions";
import devMockData from "@/devUtils/mockData.js";
import { callDirectoryProfilesIndex, callDirectoryProfilesShow, callDirectoryProfilesUpdate } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoLoading,
    AtmoFilterControls,
    EditProfileModal,
    FilterModal,
    AtmoDirectoryAd,
  },

  data: function () {
    return {
      isLoading: false,
      publicProfiles: null,
      myProfile: null,
      concerts: null,
      query: null,
      selectedFilterType: "location",
      selectedFilterState: null,
      selectedFilterCity: null,
      selectedFilterProfession: null,
      selectedFilterMinPrice: null,
      selectedFilterMaxPrice: null,
      devMockData: devMockData,
    };
  },

  computed: {
    selectedFilterValue() {
      // will be null if there is no filter selected
      switch (this.selectedFilterType) {
        case "location":
          return this.selectedFilterState;
        case "profession":
          return this.selectedFilterProfession.name;
        case "price":
          return this.formatPriceRange(this.selectedFilterMinPrice, this.selectedFilterMaxPrice);
        default:
          return null;
      }
    },
    allProfiles() {
      return [this.myProfile].concat(
        this.publicProfiles.filter((profile) => {
          return profile.id !== this.myProfile.id;
        })
      );
    },
    filteredProfiles() {
      return this.allProfiles.filter((profile) => {
        const matchesQuery = profile.name.toLowerCase().includes((this.query || "").toLowerCase());
        const matchesFilter = this._matchesFilter(profile);
        return matchesQuery && matchesFilter;
      });
    },
    myProfession() {
      return this.professionById(this.myProfile.directory_profession_id);
    },
  },

  created() {
    this.getInitialData();
    this.$store.commit("setHudHeader", "Atmo Directory");
  },

  methods: {
    getImgUrl(image) {
      return utilsGetImgUrl(image);
    },
    returnCurrencyFormat(number) {
      // Create number formatter (Javascript - Internationalization API)
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        //These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });

      return formatter.format(number);
    },
    async getInitialData() {
      this.isLoading = true;
      await Promise.all([this.getProfessions(), this.getDirectoryProfiles(), this.getStatesAndCities()]);
      this.isLoading = false;
    },
    async getDirectoryProfiles() {
      this.isLoading = true;
      await Promise.all([this.getPublicProfiles(), this.getMyProfile()]);
      this.isLoading = false;
    },
    async getPublicProfiles() {
      try {
        const response = await callDirectoryProfilesIndex({ public: true });
        this.publicProfiles = response;
      } catch (error) {
        console.error(error);
      }
    },
    async getMyProfile() {
      try {
        const response = await callDirectoryProfilesShow(this.$store.state.currentUserId);
        this.myProfile = response;
      } catch (error) {
        console.error(error);
      }
    },
    async getProfessions() {
      try {
        const response = await Axios.get("/api/v1/directory_professions");
        this.professions = response.data;
      } catch (error) {
        console.error(error);
      }
    },
    getStatesAndCities() {
      // TODO: Use real states and cities
      this.statesAndCities = statesAndCitiesStub;
    },
    professionById(id) {
      return this.professions.find((profession) => {
        return profession.id === id;
      });
    },
    formatPriceRange(minPrice, maxPrice) {
      return `$${minPrice} - $${maxPrice}`;
    },
    onSearch(event) {
      this.query = event.currentTarget.value;
    },
    showFilterModal() {
      this.$root.$emit("bv::show::modal", "directory-filter");
    },
    onSubmitFilterModal(newProps) {
      this.selectedFilterType = newProps.filterType;
      this.selectedFilterState = newProps.state;
      this.selectedFilterCity = newProps.city;
      this.selectedFilterProfession = newProps.profession;
      this.selectedFilterMinPrice = newProps.minPrice;
      this.selectedFilterMaxPrice = newProps.maxPrice;
      this.getDirectoryProfiles();
    },
    resetFilter() {
      this.selectedFilterType = "location";
      this.selectedFilterState = null;
      this.selectedFilterCity = null;
      this.selectedFilterProfession = null;
      this.selectedFilterMinPrice = null;
      this.selectedFilterMaxPrice = null;
      this.getDirectoryProfiles();
    },
    async onSubmitEditProfile(data) {
      data.user_id = this.$store.state.currentUserId;
      try {
        await callDirectoryProfilesUpdate(this.myProfile.id, data);
        this.$notify({ group: "vue-app", title: "Profile Saved" });
      } catch (error) {
        console.error(error);
        this.$notify({ group: "vue-app", type: "error", title: "Error Saving Profile" });
      }
      await this.getDirectoryProfiles();
    },
    _matchesFilter(profile) {
      const selectedFilterValue = this.selectedFilterValue;
      const rate = Number(profile.hourly_rate);
      if (!selectedFilterValue) {
        return true;
      }
      switch (this.selectedFilterType) {
        case "location":
          return profile.location && profile.location.includes(selectedFilterValue);
        case "profession":
          return selectedFilterValue === (this.professionById(profile.directory_profession_id) || {}).name;
        case "price":
          return rate >= this.selectedFilterMinPrice && rate <= this.selectedFilterMaxPrice;
        default:
          return true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-directory {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  .atmo-directory__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;

    .header-container__header-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;
    }

    .header-container__ad-container {
      border: 1px solid rgba(255, 255, 255, 0.4);
      border-radius: 5px;
      display: flex;
      gap: 1rem;
      padding: 1rem;
      min-height: 7rem; //FOR NOW
    }
  }

  &__header {
    // margin: 35px 0;
    min-width: 14ch;
    margin: 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .atmo-table__thead tr {
    background: rgba(0, 0, 0, 0);
    box-shadow: none;
  }

  .atmo-table__th {
    font-weight: 600;
  }

  .atmo-table__tr {
    font-weight: 500;

    &.--light {
      background: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    }
  }

  .atmo-table__td {
    a {
      color: white;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  &__main-content {
    min-height: 300px;
    margin-bottom: 1rem;
  }

  &__search.atmo-input.atmo-input--search {
    height: 32px;
    min-width: 150px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
