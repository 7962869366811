<template>
  <div class="friend-list">
    <!-- <h2 class="atmo-page-header friend-list__header">
      <span v-if="currentState === 'pending' && showPendingRequests">Pending Requests</span>
      <span v-else>Friends</span>
    </h2> -->
    <section class="friend-list__body">
      <div v-if="currentState === 'pending' && showPendingRequests" class="back-container" @click="backToAccepted()">
        <div class="back-container__icon-container">
          <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
        </div>
        <div class="back-container__text-container">Back to previous</div>
      </div>
      <form v-else-if="isMyProfile" class="atmo-form friend-list__controls">
        <input type="text" placeholder="Search for friends" class="friend-list__friend-search atmo-input atmo-input--search" :value="query" @input="onSearch" />
        <div v-if="numberOfPendingRequests > 0 && showPendingRequests" class="friend-list__pending-link" @click.prevent="currentState = 'pending'">
          <button class="atmo-button friend-list__pending-button">
            <img class="atmo-button__icon atmo-button__icon--small" src="@/assets/images/icons/user.png" />
            <div class="atmo-button__alert">{{ numberOfPendingRequests }}</div>
          </button>
          <span>Pending Requests</span>
        </div>
      </form>
      <div class="friend-list__main-content">
        <div>
          <template v-if="isEmpty">
            <atmo-loading v-if="isLoading" />
            <template v-else>
              <img class="atmo-empty-state__icon" src="@/assets/images/icons/no-friends.png" />
              <span class="atmo-empty-state__label">{{ emptyMessage }}</span>
            </template>
          </template>
          <perfect-scrollbar class="friends-grid">
            <div v-for="user in friendsByCurrentState" :key="user.id">
              <router-link class="friend-card-wrap" :to="getProfileLink(user)">
                <div class="friend-card">
                  <div class="friend-card__background-image" :style="{ 'background-image': 'url(' + getFeaturedImage(user.images, 'full') + ')' }"></div>
                  <div class="friend-card__footer">
                    <div class="footer__user-name">{{ user.name }}</div>
                  </div>
                </div>
              </router-link>
            </div>
          </perfect-scrollbar>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AtmoLoading from "@/components/atmo-loading";
import PaginationMixin from "@/mixins/pagination";
//import User from '@/models/user';
import { getFeaturedImage } from "@/helpers/utilityFunctions";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { AtmoLoading },
  mixins: [PaginationMixin],
  props: {
    showPendingRequests: {
      type: Boolean,
      default: false,
    },
    userProfileId: {
      type: Number,
      required: true,
    },
    profileType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentState: "accepted",
      query: null,
    };
  },
  computed: {
    ...mapGetters("profiles", ["getProfile", "getUser", "isLoading", "getProfileType"]),
    ...mapGetters("userFriendships", ["getUserFriendships", "getMutualFriends"]),
    user() {
      return this.getUser;
    },
    userProfile() {
      return this.getProfile;
    },
    userFriendships() {
      return this.getUserFriendships;
    },
    isMyProfile() {
      return this.$store.state.currentUserProfileId === this.userProfileId;
    },
    friendsByCurrentState() {
      return (this.userFriendships || [])
        .filter((friendship) => {
          return friendship.state === this.currentState;
        })
        .map((friendship) => {
          return friendship.friend;
        });
    },
    numberOfPendingRequests() {
      return (this.userFriendships || []).filter((friendship) => {
        return friendship.state === "pending";
      }).length;
    },
    fullCollection() {
      return this.friendsByCurrentState.filter((friend) => {
        return this.query === null || friend.name.toLowerCase().includes(this.query.toLowerCase());
      });
    },
    isEmpty() {
      return this.isLoading || this.Pagination_itemsForCurrentPage.length === 0;
    },
    emptyMessage() {
      if (this.friendsByCurrentState.length === 0) {
        if (this.currentState === "pending") {
          return "There are no pending requests!";
        } else {
          return "No current friendships.";
        }
      } else {
        return "No matching friends. Try again";
      }
    },
  },
  async created() {
    console.log("Received userProfileId:", this.userProfileId); // Debugging log
    console.log("Received profileType:", this.profileType); // Debugging log
    if (!this.userProfile) {
      await this.fetchProfile({ profileType: this.profileType, profileId: this.userProfileId });
    }
    if (!this.userFriendships) {
      await this.fetchUserFriendships(this.userProfileId);
    }

    if (this.currentState === "pending" && this.showPendingRequests) {
      this.$store.commit("setHudHeader", "Pending Requests");
    } else {
      this.$store.commit("setHudHeader", "Friends");
    }
  },
  methods: {
    ...mapActions("profiles", ["fetchProfile"]),
    ...mapActions("userRriendships", ["fetchUserFriendships"]),
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    backToAccepted() {
      this.currentState = "accepted";
    },
    onSearch(event) {
      this.Pagination_currentPageNumber = 1;
      this.query = event.target.value;
    },
    getProfileLink(user) {
      if (user.profile_type === "UserProfile") {
        return { name: "user_profiles.show", params: { userProfileId: user.profile_id } };
      } else if (user.profile_type === "ArtistProfile") {
        return { name: "artist_profiles.show", params: { artistProfileId: user.profile_id } };
      } else if (user.profile_type === "LabelProfile") {
        return { name: "label_profiles.show", params: { labelProfileId: user.profile_id } };
      }
      return "#";
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.friend-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__header {
    margin: 35px 0;
  }

  &__body {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
  }

  .atmo-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .atmo-empty-state__label {
    text-align: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
    width: 100%;
  }

  .back-container__icon-container {
    margin-right: 0.5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  &__controls {
    display: flex;
    align-items: center;
  }

  &__friend-search.atmo-input.atmo-input--search {
    height: 32px;
    min-width: 150px;
  }

  &__pending-link {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &__pending-button {
    background-color: $atmo-purple--medium-dark !important;
    padding: 5px 7px;
    margin: 0 10px;
  }

  &__pending-link:hover {
    color: $atmo-purple--light;

    .friend-list__pending-button {
      background-color: $atmo-purple--dark;
    }
  }

  &__main-content {
    margin: 0rem 1rem 0rem 1rem;
  }

  .atmo-music-items__empty {
    flex-direction: column;
  }

  .atmo-music-items .atmo-card__image-container {
    padding: 0px;
  }

  .atmo-card__background-image {
    height: 6.6rem;
  }

  .friends-grid {
    display: grid;
    gap: 1rem;
    margin-top: 1rem;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .friend-card-wrap {
    display: block;
    height: 100%;
  }

  .friend-card {
    height: 100%;
    cursor: pointer;
  }

  .friend-card__background-image {
    height: 6rem;
    background-color: rgba(190, 167, 221, 0.4);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 5px 5px 0 0;
    margin: auto;
  }

  .friend-card__footer {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(-270deg, rgba($atmo-blue--medium, 0.5) 0%, rgba($atmo-pink--medium, 0.5) 100%);
    font-weight: 300;
    padding: 8px;
    border-radius: 0 0 5px 5px;
  }

  .footer__user-name {
    font-weight: 500;
    color: white;
  }
}
</style>
