<template>
  <form>
    <div class="atmo-checkout__form-section-header">Shipping Address</div>
    <div class="atmo-checkout__input-row">
      <input v-model="addressData.firstName" placeholder="First Name*" class="atmo-input atmo-checkout__input" />
      <input v-model="addressData.lastName" placeholder="Last Name*" class="atmo-input atmo-checkout__input" />
    </div>
    <div class="atmo-checkout__input-row">
      <input v-model="addressData.addressLine1" placeholder="Address Line 1*" class="atmo-input atmo-checkout__input" />
    </div>
    <div class="atmo-checkout__input-row">
      <input v-model="addressData.addressLine2" placeholder="Address Line 2*" class="atmo-input atmo-checkout__input" />
    </div>
    <div class="atmo-checkout__input-row">
      <select v-model="addressData.country" @change="onCountryChange" class="atmo-input atmo-checkout__input atmo-checkout__input--purple dropdown-group">
        <option value="" disabled>Select Country*</option>
        <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
      </select>
      <select v-model="addressData.state" @change="onStateChange" class="atmo-input atmo-checkout__input dropdown-group">
        <option value="" disabled>Select State*</option>
        <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
      </select>
    </div>
    <div class="atmo-checkout__input-row">
      <select v-model="addressData.city" class="atmo-input atmo-checkout__input dropdown-group">
        <option value="" disabled>Select City*</option>
        <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
      </select>
      <input v-model="addressData.zipCode" placeholder="Zip Code*" class="atmo-input atmo-checkout__input" />
    </div>
    <div class="atmo-checkout__input-row">
      <input v-model="addressData.phoneNumber" placeholder="Phone Number*" class="atmo-input atmo-checkout__input" />
    </div>
  </form>
</template>

<script>
import { reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();

    const addressData = reactive({
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
      phoneNumber: "",
    });

    const countries = computed(() => store.state.address.countries);
    const states = computed(() => store.state.address.states);
    const cities = computed(() => store.state.address.cities);

    const selectedCountryAbbreviation = computed(() => {
      const country = countries.value.find((c) => c.id === addressData.country);
      return country ? country.abbreviation : "";
    });

    const selectedStateName = computed(() => {
      const state = states.value.find((s) => s.id === addressData.state);
      return state ? state.name : "";
    });

    const selectedCityName = computed(() => {
      const city = cities.value.find((c) => c.id === addressData.city);
      return city ? city.name : "";
    });

    // Fetch initial data
    onMounted(() => {
      store.dispatch("address/fetchCountries");
    });

    // Watch for changes in country and state
    const onCountryChange = () => {
      addressData.state = "";
      addressData.city = "";
      store.dispatch("address/fetchStates", addressData.country);
    };

    const onStateChange = () => {
      addressData.city = "";
      store.dispatch("address/fetchCities", addressData.state);
    };

    // Watch for changes in addressData and update the store
    watch(
      addressData,
      () => {
        store.dispatch("payments/setSelectedAddress", {
          ...addressData,
          country: selectedCountryAbbreviation.value,
          state: selectedStateName.value,
          city: selectedCityName.value,
        });
      },
      { deep: true }
    );

    return {
      addressData,
      countries,
      states,
      cities,
      onCountryChange,
      onStateChange,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";
.dropdown-group {
  background: $atmo-purple--dark;
  color: white;
  text-transform: uppercase;
  border: none;
  border-radius: 5px;
  padding: 0.5rem;
  font-weight: 500rem;
  cursor: pointer;
}
</style>
