<template>
  <VueFinalModal
    id="atmo-queue"
    ref="atmoQueueRef"
    title="Queue"
    content-class="atmo-modal atmo-queue atmo-modal-content"
    overlay-class="atmo-modal-overlay"
    :esc-to-close="true"
    v-model="$store.state.atmoQueues.queueOpen"
    @opened="updateSongHowls"
  >
    <header class="atmo-queue__header atmo-modal__header">
      <img
        class="atmo-queue__hide-queue"
        src="@/assets/images/icons/close_popup.png"
        alt="close"
        title="Hide Queue"
        @click="toggleQueueOpen"
      />
      <h2>Your Queue</h2>
      <span title="Queue Actions">
        <atmo-popover placement="bottom">
          <img
            slot="trigger"
            src="@/assets/images/icons/menu.png"
            alt="Queue Actions"
            class="atmo-queue__actions-trigger"
          />
          <div slot="content">
            <a
              class="atmo-popover__dropdown-action atmo-popover__dropdown-action--extra-padding"
              @click="saveQueueAsPlaylist"
            >
              Save as Playlist
            </a>
            <hr class="atmo-popover__hr" />
            <a
              class="atmo-popover__dropdown-action atmo-popover__dropdown-action--extra-padding"
              @click="clearQueue"
            >
              Clear Queue
            </a>
          </div>
        </atmo-popover>
      </span>
    </header>
    <div class="atmo-queue__songs">
      <div v-if="$store.getters['atmoQueues/queueLoading']">
        <p>SONGS LOADING</p>
      </div>
      <perfect-scrollbar class="songs-scrollbar" v-else>
        <draggable
          v-if="draggableQueue.length"
          v-model="draggableQueue"
          @end="onDragEnd"
          @update="onUpdate"
        >
          <div
            v-for="queueSong in queueWithDurations"
            :key="queueSong.song.id"
            class="atmo-queue__song"
          >
            <div
              v-bind:style="{
                'background-image': `linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%), url(${queueSong.song.album.image.url})`,
              }"
              class="atmo-queue__song-image"
              @click="navigateToAlbum(queueSong.song.primary_artist.id, queueSong.song.album.id)"
            />
            <div class="atmo-queue__song-info">
              <div class="atmo-queue__song-name">
                <a @click="playQueueSong(queueSong.song)">
                  {{ queueSong.song.name }}
                </a>
                <span
                  v-if="isCurrentSong(queueSong.song) && isQueueMode"
                  class="atmo-queue__song-now-playing"
                >
                  (Now Playing)
                </span>
              </div>
              <div class="atmo-queue__song-artist">
                <a
                  style="cursor: pointer"
                  @click="navigateToArtistProfile(queueSong.song.primary_artist.id)"
                >
                  {{ queueSong.song.primary_artist.user.name }}
                </a>
              </div>
              <div class="atmo-queue__song-length">{{ queueSong.duration }}</div>
            </div>
            <div class="atmo-queue__song-actions">
              <img
                src="@/assets/images/icons/delete.png"
                alt="Delete"
                title="Remove song"
                @click="removeSongFromQueue(queueSong)"
              />
            </div>
          </div>
        </draggable>
      </perfect-scrollbar>
    </div>
  </VueFinalModal>
</template>

<script>
import AtmoPopover from "@/components/atmo-popover";
import Axios from "axios";
import { VueDraggableNext } from 'vue-draggable-next'
import { callSongReferencesDelete, callSongReferencesCreate } from "@/helpers/axiosCalls";
import { getSongDuration } from "@/helpers/utilityFunctions";
import { mapMutations } from 'vuex';

export default {
  components: { 
    AtmoPopover, 
    draggable: VueDraggableNext,
  },
  computed: {
    queueWithDurations() {
      console.log('Filtered Queue:', this.filteredQueue);
      return (
        this.filteredQueue.map((queueSong) => {
          const duration = this.getSongDuration(queueSong.song);
          return { ...queueSong, duration };
        }) || []
      );
    },
    draggableQueue: {
      get() {
        const queue = this.$store.getters["atmoQueues/atmoQueue"] || [];
        console.log("Draggable Queue:", queue);
        return queue;
      },
      set(value) {
        this.$store.commit("atmoQueues/setAtmoQueue", value);
      },
    },
    filteredQueue() {
      const queue = this.draggableQueue || [];
      return queue.filter(
        (queueSong) => queueSong && queueSong.song && queueSong.song.album
      );
    },
    isQueueMode() {
      return this.$store.state.atmoQueues.queueMode === "queue";
    },
  },
  watch: {
    draggableQueue: {
      handler(newQueue) {
        if (Array.isArray(newQueue) && newQueue.length > 0) {
          const validQueue = newQueue.filter(
            (queueSong) => queueSong && queueSong.song && queueSong.song.id
          );
          if (validQueue.length > 0) {
            this.$store.commit("atmoQueues/setActiveQueue", validQueue);
            this.$store.dispatch(
              "player/updateSongHowls",
              validQueue.map((queueSong) => queueSong.song)
            );
            this.$store.dispatch("atmoQueues/saveQueueOrder");
          } else {
            console.warn("draggableQueue contains invalid items:", newQueue);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('atmoQueues', ['toggleQueueOpen']),
    updateSongHowls() {
      this.$store.dispatch(
        "player/updateSongHowls",
        this.draggableQueue.map((queueSong) => queueSong.song)
      );
    },
    getSongDuration(song) {
      return getSongDuration(song);
    },
    isCurrentSong(song) {
      return (
        this.$store.state.player.currentSong &&
        this.$store.state.player.currentSong.id === song.id
      );
    },
    playQueueSong(song) {
      this.$store.commit("atmoQueues/setQueueMode", "queue");
      this.$store.commit("atmoQueues/setActiveQueue", this.draggableQueue);
      this.$store.dispatch("player/playSong", song);
    },
    navigateToAlbum(profileId, artistAlbumId) {
      this.$refs.atmoQueueRef.hide();
      this.$router.push({
        name: "albums.show",
        params: { artistProfileId: profileId, albumId: artistAlbumId },
      });
    },
    navigateToArtistProfile(profileId) {
      this.$refs.atmoQueueRef.hide();
      this.$router.push({
        name: "artist_profiles.show",
        params: { artistProfileId: profileId },
      });
    },
    removeSongFromQueue(song) {
      this.$store.dispatch("atmoQueues/removeFromAtmoQueue", song);
    },
    clearQueue() {
      const promises = this.$store.getters["atmoQueues/atmoQueue"].map(
        (queueSong) => {
          return callSongReferencesDelete(queueSong.id).catch((error) => {
            console.error(error);
          });
        }
      );

      Promise.all(promises).then(() => {
        this.$store.dispatch("atmoQueues/getAtmoQueue");
        this.$notify({ group: "vue-app", title: "Queue Cleared" });
      });
    },
    saveQueueAsPlaylist() {
      const now = new Date();
      const nowString = now.toDateString();
      const hour = now.getHours();
      const minutes = now.getMinutes();

      const nowPlaylistString = `${nowString} (${hour}:${minutes})`;

      Axios({
        method: "post",
        url: `/api/v1/users/${this.$store.state.currentUserId}/playlists`,
        data: {
          user_id: this.$store.state.currentUserId,
          name: `Queue Playlist - ${nowPlaylistString}`,
        },
      })
        .then((response) => {
          this.$notify({ group: "vue-app", title: "Playlist Created" });
          this.$store.getters["atmoQueues/atmoQueue"].forEach((queueSong) => {
            callSongReferencesCreate({
              songable_id: response.data.id,
              songable_type: "Playlist",
              song_id: queueSong.song.id,
              user_id: this.$store.state.currentUserId,
            }).catch((error) => {
              console.error(error);
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onDragEnd() {
      console.log("Drag ended");
      // Handled by watcher
    },
    onUpdate(event) {
      console.log("Queue updated:", event);
      // Optional logic for handling update event
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-queue {
  // .atmo-modal-content {
  //   width: 550px;
  //   min-height: 400px;
  //   padding: 20px;
  // }

  .songs-scrollbar {
    max-height: 90vh;
  }

  &__toggle {
    margin-left: 20px;
  }

  // &__overlay {
  //   position: absolute;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   z-index: 2;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: rgba(43, 42, 60, 0.6);
  // }

  &__header {
    width: 100%;
    font-size: 0.9em;
    margin: 0;
    padding-bottom: 20px;
  }

  &__hide-queue {
    cursor: pointer;
    height: 15px;
    width: 15px;
    opacity: 0.5;
  }

  &__actions-trigger {
    height: 18px;
    cursor: pointer;
    padding: 5px 10px;
    box-sizing: content-box;
  }

  &__songs {
    width: 100%;
  }

  &__song {
    display: flex;
    align-items: center;
    background-color: rgba(96, 72, 117, 0.6);
    height: 70px;
    border-radius: 5px;
    overflow: hidden;
    margin: 8px 0;
  }

  &__song-image {
    background-color: white;
    min-width: 67px;
    height: 100%;
    background-size: 143%;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }

  &__song-info {
    flex-grow: 1;
    padding: 10px;

    a {
      color: white;
    }
  }

  &__song-actions {
    align-self: flex-start;
    padding: 10px;
    cursor: pointer;

    img {
      width: 17px;
      padding: 5px;
      box-sizing: content-box;
      margin: -5px;
    }
  }

  &__song-name {
    font-size: 1.2em;
    font-weight: 300;
    color: white;
    margin-bottom: 2px;

    a {
      cursor: pointer;
    }
  }

  &__song-now-playing {
    font-size: 0.75em;
    font-weight: 400;
    color: $atmo-pink--medium;
  }

  &__song-artist {
    font-size: 0.9em;
    font-weight: 400;
    color: $atmo-blue--medium;
    margin-bottom: 5px;

    a {
      color: $atmo-blue--medium;
    }
  }

  &__song-length {
    font-size: 0.75em;
    font-weight: 500;
    color: white;
  }
}
</style>
