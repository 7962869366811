<template>
  <div class="dashboard-fan-demos" data-cy="dashboard-fan-demos">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">
        Fan Dashboard
      </h2>
    </div> -->
    <router-link class="back-container" :to="{ name: 'artist_dashboards.show', params: { artistProfileId: artistProfileId } }">
      <div class="back-container__icon-container">
        <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
      </div>
      <div class="back-container__text-container">Back To Dashboard</div>
    </router-link>

    <div class="dashboard-fan-demos-grid-container">
      <div class="dashboard-fan-demos-grid-container__demo-container">
        <div class="demo-container__title-container">
          <div class="title-container__title-wrap">U.S. States Demographics</div>
        </div>
        <div class="demo-container__graph-container">
          <atmo-pie-chart id="stateChart" type="pie" width="380" :options="fanStateOptions" :series="fanStateSeries" :labels="fanStateLabels" />
        </div>
        <div class="demo-container__fan-stats-container">
          <div class="fan-stats-container__heading-wrap">
            <div class="heading-wrap__icon"></div>
            {{ selectedStateData.state }} ({{ selectedStateData.abbreviation }})
          </div>
          <div class="fan-stats-container__stat-wrap">
            <div class="stat-wrap__icon"><img src="@/assets/images/icons/artist_dashboard/fans.png" /></div>
            <div class="stat-wrap__number">{{ selectedStateData.fans }}</div>
            <div class="stat-wrap__text">Fans ({{ selectedStateData.fanPercentage }}% of total population)</div>
          </div>

          <div class="fan-stats-container__stat-wrap">
            <div class="stat-wrap__icon"><img src="@/assets/images/icons/artist_dashboard/downloads.png" /></div>
            <div class="stat-wrap__number">{{ selectedStateData.songPlays }}</div>
            <div class="stat-wrap__text">Song plays</div>
          </div>

          <div class="fan-stats-container__stat-wrap">
            <div class="stat-wrap__icon"><img src="@/assets/images/icons/artist_dashboard/map.png" /></div>
            <div class="stat-wrap__number">{{ selectedStateData.cityPercentage }}%</div>
            <div class="stat-wrap__text">of CA fans live in {{ selectedStateData.cityName }}</div>
          </div>
        </div>
      </div>
      <div class="dashboard-fan-demos-grid-container__genders-container">
        <div class="genders-container__title-container">
          <div class="title-container__title-wrap">Gender Demographics</div>
        </div>
        <div class="genders-container__gender-demo-container">
          <div class="gender-demo-container__fans-wrap">
            <div class="fans-wrap__number">{{ artistDashboardData.gender_data.male_count + artistDashboardData.gender_data.female_count }}</div>
            <div class="fans-wrap__fans">Fans</div>
          </div>
          <div class="gender-demo-container__gender-demos-wrap">
            <div class="gender-demos-wrap__icon-wrap">
              <img class="icon-wrap__icon" src="@/assets/images/icons/artist_dashboard/male.png" />
            </div>
            <div class="gender-demos-wrap__text-wrap">
              <div class="text-wrap__gender-text">Male</div>
              <div class="text-wrap__gender-percentage">{{ artistDashboardData.gender_data.male_percentage }}%</div>
            </div>
          </div>
          <div class="gender-demo-container__gender-demos-wrap">
            <div class="gender-demos-wrap__icon-wrap">
              <img class="icon-wrap__icon" src="@/assets/images/icons/artist_dashboard/female.png" />
            </div>
            <div class="gender-demos-wrap__text-wrap">
              <div class="text-wrap__gender-text">Female</div>
              <div class="text-wrap__gender-percentage">{{ artistDashboardData.gender_data.female_percentage }}%</div>
            </div>
          </div>
        </div>
        <div class="genders-container__age-demos-wrap">
          <div class="genders-container__title-container">
            <div class="title-container__title-wrap">Age Demographics</div>
          </div>
          <div class="age-demos-wrap__graph-container">
            <template>
              <atmo-pie-chart id="ageChart" type="pie" width="380" :options="fanAgeOptions" :series="fanAgeSeries" :labels="fanAgeLabels" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import devMockData from "@/devUtils/mockData.js";
//import User from '@/models/user';
import AtmoPieChart from "@/components/charts/atmo-pie-chart";
import { callUsersShow } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoPieChart,
  },
  data: function () {
    return {
      currentUserId: this.$store.state.currentUserId,
      artistProfile: {},
      user: {},
      artistProfileId: this.$route.params.artistProfileId,
      artistDashboardData: {},
      topLocations: [],
      devMockData,
      selectedStateData: {
        state: "Not selected",
        abbreviation: "N/A",
        fans: "0",
        fanPercentage: "0",
        songPlays: "0",
        cityName: "Not selected",
        cityPercentage: "0",
      },
      fanStateOptions: {
        legend: {
          // show: true,
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        fill: {
          // type: 'gradient',
          colors: ["#44aedc", "#d952a7"],
        },
        colors: ["#44aedc", "#d952a7"],
        // dataLabels: { style: { colors: ['#F44336', '#E91E63', '#9C27B0'] } },
        markers: { colors: ["#44aedc", "#d952a7"] },
        stroke: {
          width: 1,
          colors: ["#D8D8D8"],
        },
        chart: {
          type: "pie",
          events: {
            dataPointSelection: (event, chartContext, config) => {
              let stateObj = this.artistDashboardData.fan_state_data.find((obj) => {
                return obj.state_abbreviation == config.w.config.labels[config.dataPointIndex];
              });

              let songPlayObj = this.artistDashboardData.song_plays_by_state.find((obj) => {
                return obj.state_abbreviation == config.w.config.labels[config.dataPointIndex];
              });

              this.selectedStateData.state = stateObj.state_name;
              this.selectedStateData.abbreviation = config.w.config.labels[config.dataPointIndex];
              this.selectedStateData.fans = config.w.config.series[config.dataPointIndex];
              this.selectedStateData.fanPercentage = `${stateObj.percentage}`;
              this.selectedStateData.songPlays = songPlayObj?.count ? songPlayObj.count : 0;
              this.selectedStateData.cityName = stateObj.cities[0].city_name;
              this.selectedStateData.cityPercentage = stateObj.cities[0].percentage;
            },
          },
        },
        labels: [],
      },
      fanStateLabels: [],
      fanStateSeries: [],
      fanAgeOptions: {
        legend: {
          // show: true,
          show: false,
          showForSingleSeries: false,
          showForNullSeries: true,
          showForZeroSeries: true,
          position: "bottom",
          horizontalAlign: "center",
          floating: false,
          fontSize: "14px",
          fontFamily: "Helvetica, Arial",
          fontWeight: 400,
          formatter: undefined,
          inverseOrder: false,
          width: undefined,
          height: undefined,
          tooltipHoverFormatter: undefined,
          customLegendItems: [],
          offsetX: 0,
          offsetY: 0,
          labels: {
            colors: undefined,
            useSeriesColors: false,
          },
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            fillColors: undefined,
            radius: 12,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
          itemMargin: {
            horizontal: 5,
            vertical: 0,
          },
          onItemClick: {
            toggleDataSeries: true,
          },
          onItemHover: {
            highlightDataSeries: true,
          },
        },
        fill: {
          // type: 'gradient',
          colors: ["#7feb9b", "#5e9dd2", "#cc59ab", "#e69ab4", "#4dd19a", "#79678f", "#8aa96b"],
        },
        colors: ["#7feb9b", "#5e9dd2", "#cc59ab", "#e69ab4", "#4dd19a", "#79678f", "#8aa96b"],
        // dataLabels: { style: { colors: ['#F44336', '#E91E63', '#9C27B0'] } },
        markers: { colors: ["#7feb9b", "#5e9dd2", "#cc59ab", "#e69ab4", "#4dd19a", "#79678f", "#8aa96b"] },
        stroke: {
          width: 1,
          colors: ["#D8D8D8"],
        },
        chart: {
          type: "pie",
        },
        labels: [],
      },
      fanAgeLabels: [],
      fanAgeSeries: [],
    };
  },
  created() {
    this.getArtistProfile();
    this.getDashboardData();
    this.$store.commit("setHudHeader", "Fan Dashboard");
  },
  computed: {
    fanGenderSeries() {
      return [this.artistDashboardData.gender_data.male_count, this.artistDashboardData.gender_data.female_count];
    },
    currentUser() {
      return this.$store.state.user;
    },
    isMyProfile() {
      return this.$store.state.currentUserProfileId === this.userProfileId;
    },
    userProfileId() {
      return Number(this.$route.params.artistProfileId);
    },
  },
  methods: {
    getUser(userId) {
      callUsersShow(userId)
        .then((response) => {
          this.user = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getArtistProfile() {
      Axios.get(`/api/v1/artist_profiles/${this.$route.params.artistProfileId}`, {})
        .then((response) => {
          this.artistProfile = response.data;
          this.getUser(this.artistProfile.user.id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getFanStateData() {
      for (let i = 0; i < this.artistDashboardData.fan_state_data.length; i++) {
        this.fanStateLabels.push(this.artistDashboardData.fan_state_data[i].state_abbreviation);
      }

      for (let i = 0; i < this.artistDashboardData.fan_state_data.length; i++) {
        this.fanStateSeries.push(this.artistDashboardData.fan_state_data[i].count);
      }

      this.fanStateOptions.labels = this.fanStateLabels;
    },
    getFanAgeData() {
      for (let i = 0; i < this.artistDashboardData.fan_age_counts.length; i++) {
        this.fanAgeLabels.push(this.artistDashboardData.fan_age_counts[i].age);
      }

      for (let i = 0; i < this.artistDashboardData.fan_age_counts.length; i++) {
        this.fanAgeSeries.push(this.artistDashboardData.fan_age_counts[i].count);
      }

      this.fanAgeOptions.labels = this.fanAgeLabels;
    },
    getDashboardData() {
      Axios.get(`/api/v1/artist_profiles/${this.$route.params.artistProfileId}/artist_dashboards`, {})
        .then((response) => {
          this.artistDashboardData = response.data;
          this.getTopLocations(this.artistDashboardData.top_three_states);
          this.getFanStateData();
          this.getFanAgeData();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTopLocations(locationObj) {
      const keys = Object.keys(locationObj);
      for (let i = 0; i < keys.length; i++) {
        this.topLocations.push(keys[i]);
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.dashboard-fan-demos {
  margin: 0 75px;

  .ps {
    max-height: 40vh;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
    color: white;
    // border: 1px solid white;
  }

  .back-container__icon-container {
    margin-right: 0.5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .dashboard-fan-demos-grid-container {
    // border: 1px solid white;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 5px;
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
  }

  .demo-container__graph-container {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
    border-radius: 5px;
    min-height: 15rem;
    margin-bottom: 1rem;

    .apexcharts-menu-icon {
      color: white;

      svg {
        fill: white;
      }

      svg:hover {
        fill: $atmo-purple--dark;
      }
    }

    .apexcharts-menu {
      background: $atmo-purple--dark;
      border: none;
    }

    .apexcharts-menu-item:hover {
      background: $atmo-purple--medium;
    }
  }

  .graph-container__image-wrap {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 1rem;
    border-radius: 10px;
    height: 16rem;
  }

  .graph-container__text-wrap {
    .class-wrap__info-wrap {
      margin-bottom: 1.5rem;
    }

    .info-wrap__title {
      text-transform: uppercase;
      color: $atmo-gray--light--faded;
      margin-bottom: 0.5rem;
    }

    .info-wrap__value {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .demo-container__fan-stats-container {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
    border-radius: 5px;
    min-height: 8rem;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
  }

  .fan-stats-container__heading-wrap {
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .heading-wrap__icon {
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 100%;
    margin-right: 0.5rem;
    background: linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%);
  }

  .fan-stats-container__stat-wrap {
    display: flex;
    align-items: center;
  }

  .fan-stats-container__stat-wrap + .fan-stats-container__stat-wrap {
    margin-top: 1rem;
  }

  .stat-wrap__icon {
    margin-right: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 100%;
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 0.8rem;
    }
  }

  .stat-wrap__number {
    color: $atmo-blue--medium;
    font-size: 1.2rem;
    font-weight: 600;
    margin-right: 1rem;
  }

  .stat-wrap__text {
  }

  .demo-container__title-container,
  .genders-container__title-container {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;

    .title-container__title-wrap {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 700;
    }

    .title-container__more-wrap {
      text-transform: uppercase;
      padding: 0.4rem 1.5rem 0.4rem 1.5rem;
      border-radius: 20px;
      border: 1px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      margin-left: auto;

      &:hover {
        background: $atmo-purple--light;
        border: 1px solid $atmo-purple--light;
      }

      p {
        margin-top: 2px;
      }
    }

    .more-wrap__image {
      height: 1rem;
      margin-left: 0.4rem;
    }
  }

  .genders-container__gender-demo-container {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 2rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem;
    border-radius: 5px;
  }

  .gender-demo-container__fans-wrap {
    background: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .fans-wrap__number {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .fans-wrap__fans {
    text-transform: uppercase;
  }

  .gender-demo-container__gender-demos-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gender-demos-wrap__icon-wrap {
    margin-right: 1rem;

    .icon-wrap__icon {
      height: 2.5rem;
    }
  }

  .gender-demos-wrap__text-wrap {
    .text-wrap__gender-text {
      font-weight: 600;
      margin-bottom: 0.2rem;
      color: $atmo-white--medium-faded;
    }

    .text-wrap__gender-percentage {
      font-size: 1.2rem;
      font-weight: 600;
    }
  }

  .age-demos-wrap__graph-container {
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.5rem;
    border-radius: 5px;
    min-height: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
