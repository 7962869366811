<template>
  <div class="notification-message">
    <span>
      <router-link :to="userProfileLink" class="notification-link">
        {{ notification.sender.name }}
      </router-link>
      <span v-if="!notificationLink && !linkLabel">&nbsp;{{ simpleNotificationContent }} </span>
      <template v-else-if="notificationLink && notificationLink.params && notificationLink.params.postId">
        {{ preLinkContent }}
        <router-link :to="notificationLink" class="notification-link"> {{ linkLabel }}! </router-link>
      </template>
    </span>
  </div>
</template>

<script>
import { notificationMap } from "@/components/notifications/notificationMap";

export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    notificationMapping() {
      return notificationMap[this.notification.notification_type.type_name] || {};
    },
    userProfileLink() {
      return {
        name: "user_profiles.show",
        params: { userProfileId: this.notification.sender.id },
      };
    },
    notificationLink() {
      if (this.notificationMapping.link && this.notification.notifiable_id) {
        return {
          name: this.notificationMapping.link.name,
          params: this.notificationMapping.link.params(this.notification),
        };
      }
      return null;
    },
    linkLabel() {
      return this.notificationMapping.linkLabel || "";
    },
    preLinkContent() {
      return this.trimContentBeforePlaceholder();
    },
    simpleNotificationContent() {
      let content = this.notification.notification_type.content;
      content = content.replace("{user}", "");
      return content.trim();
    },
  },
  methods: {
    trimContentBeforePlaceholder() {
      let content = this.notification.notification_type.content;
      content = content.replace("{user}", "");

      if (content.includes("{billboard}")) {
        return content.split("{billboard}")[0].trim();
      } else if (content.includes("{comment}")) {
        return content.split("{comment}")[0].trim();
      } else if (content.includes("{playlist}")) {
        return content.split("{playlist}")[0].trim();
      }
      return content.trim();
    },
  },
};
</script>

<style lang="scss">
.notification-message {
  .notification-link {
    color: white;
    text-decoration: underline;
  }
}
</style>
