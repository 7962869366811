<template>
  <div>
    <!-- <h2 class="atmo-page-header atmo-checkout__header">Checkout</h2> -->
    <div class="atmo-checkout">
      <perfect-scrollbar class="atmo-checkout__body">
        <div class="atmo-checkout__main-column">
          <section class="atmo-checkout__cart-attributes">
            <template v-if="checkoutType === 'subscription'">
              <div class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label"> Name of Plan </span>
                <span class="atmo-checkout__cart-attribute-value">{{ checkoutDetails.name }}</span>
              </div>
              <div class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label"> Amount </span>
                <span class="atmo-checkout__cart-attribute-value">{{ formattedAmount }}</span>
              </div>
              <div class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label"> Billing Frequency </span>
                <span class="atmo-checkout__cart-attribute-value">{{ checkoutDetails.option }}</span>
              </div>
            </template>
            <template v-if="checkoutType === 'donation'">
              <div class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label"> Donation Amount </span>
                <span class="atmo-checkout__cart-attribute-value">{{ formattedAmount }}</span>
              </div>
            </template>
            <template v-if="checkoutType === 'ops_page'">
              <div class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label"> Campaign Name </span>
                <span class="atmo-checkout__cart-attribute-value">{{ opsPageDonationData.campaign?.campaign_name || "N/A" }}</span>
              </div>
              <div class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label"> Donation Amount </span>
                <span class="atmo-checkout__cart-attribute-value">{{ formattedAmount }}</span>
              </div>
              <!-- <div class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label"> Description </span>
                <span class="atmo-checkout__cart-attribute-value">{{ opsPageDonationData.description || "N/A" }}</span>
              </div> -->
            </template>
            <template v-if="checkoutType === 'event'">
              <div class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label">Amount </span>
                <span class="atmo-checkout__cart-attribute-value">{{ formattedAmount }}</span>
              </div>
            </template>
            <template v-if="checkoutType === 'store'">
              <div class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label"> Number of Items </span>
                <span class="atmo-checkout__cart-attribute-value">{{ checkoutDetails.numberOfItems }}</span>
              </div>
              <div class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label"> Shipping </span>
                <span class="atmo-checkout__cart-attribute-value">{{ formattedShippingCost }}</span>
              </div>
              <div v-if="checkoutType !== 'donation'" class="atmo-checkout__cart-attribute">
                <span class="atmo-checkout__cart-attribute-label"> Discount </span>
                <span class="atmo-checkout__cart-attribute-value">{{ formattedDiscount }}</span>
              </div>
            </template>
            <div class="atmo-checkout__cart-attribute">
              <span class="atmo-checkout__cart-attribute-label"> Tax ({{ checkoutDetails.taxRate * 100 }}%) </span>
              <span class="atmo-checkout__cart-attribute-value">{{ formattedTaxTotal }}</span>
            </div>
            <div class="atmo-checkout__cart-attribute atmo-checkout__cart-attribute--overall-total">
              <span class="atmo-checkout__cart-attribute-label"> Overall Total </span>
              <span class="atmo-checkout__cart-attribute-value">{{ formattedOverallTotal }}</span>
            </div>
          </section>
          <atmo-points v-if="checkoutType === 'store'" />
          <div class="atmo-checkout__action-buttons">
            <button class="atmo-button atmo-button--secondary" @click="cancel">Cancel</button>
            <button class="atmo-button atmo-button--primary" @click="submitPayment">Checkout</button>
          </div>
        </div>
        <aside class="atmo-checkout__sidebar">
          <select-card />
        </aside>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { ref, computed, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { formatPriceAsCurrency } from "@/helpers/utilityFunctions";
import SelectCard from "@/routes/subscriptions/cards/select-card.vue";
import AtmoPoints from "@/components/checkout/atmo-points.vue";

export default {
  components: {
    SelectCard,
    AtmoPoints,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const checkoutType = ref(route.params.checkoutType);

    // Map checkoutDetails and opsPageDonationData from stores
    const checkoutDetails = computed(() => store.state.payments.checkoutDetails);
    const opsPageDonationData = computed(() => store.state.ops.opsPageDonationData);
    const eventPurchaseData = computed(() => store.state.events.eventPurchaseData);

    const token = computed(() => store.getters["payments/getToken"]);
    const overallTotal = computed(() => store.getters["payments/getOverallTotal"]);

    // Derived and formatted values for display
    const formattedAmount = computed(() => formatPriceAsCurrency(checkoutDetails.value.amount || 0));
    const formattedTaxTotal = computed(() => formatPriceAsCurrency(checkoutDetails.value.taxTotal || 0));
    const formattedOverallTotal = computed(() => formatPriceAsCurrency(checkoutDetails.value.overallTotal || 0));
    const formattedShippingCost = computed(() => formatPriceAsCurrency(checkoutDetails.value.shippingCost || 0));

    const formattedDiscount = computed(() => {
      const discountAmount = checkoutDetails.value.discountAmount || 0;
      return discountAmount > 0 ? `- $${discountAmount.toFixed(2)}` : "$0.00";
    });

    const submitPayment = async () => {
      if (!token.value) {
        console.error("No token available for payment submission.");
        return;
      }

      const selectedCard = store.state.payments.selectedCard;
      const address = store.state.payments.selectedAddress;

      if (!selectedCard) {
        const requiredFields = ["firstName", "lastName", "addressLine1", "country", "state", "city", "zipCode", "phoneNumber"];
        const isAddressComplete = requiredFields.every((field) => address[field]?.trim() !== "");

        if (!isAddressComplete) {
          window.alert("Please provide a complete address before submitting the payment.");
          return;
        }
      }

      const accomplishmentPayload = {
        accomplishment_data: {
          payloads: [
            { user_id: store.state.currentUserId, category: "purchases", sub_category: "purchaseNumber" },
            { user_id: store.state.currentUserId, category: "purchases", sub_category: "amountSpent" },
            { user_id: store.state.currentUserId, category: "purchases", sub_category: "itemPurchaseNumber" },
            { user_id: store.state.currentUserId, category: "purchases", sub_category: "brandPurchaseNumber" },
            { user_id: store.state.currentUserId, category: "purchases", sub_category: "brandPurchaseAmount" },
            { user_id: store.state.currentUserId, category: "purchases", sub_category: "earlyPurchase" },
          ],
        },
      };

      try {
        const paymentData = {
          user_id: store.state.currentUserId,
          amount: Math.round(overallTotal.value * 100),
          description:
            checkoutDetails.value.type === "donation"
              ? "Donation to Artist"
              : checkoutDetails.value.type === "ops_page"
              ? `Campaign: ${opsPageDonationData.value.campaign?.campaign_name}`
              : checkoutDetails.value.type === "event"
              ? `Event: ${eventPurchaseData.value.event?.name}`
              : "Purchase at Atmo Store",
          token: token.value,
          transaction_type: checkoutDetails.value.type,
          address,
          used_points: checkoutDetails.value.usedPoints,
          ...accomplishmentPayload,
        };

        if (checkoutDetails.value.type === "store") {
          paymentData.cart = store.state.products.cart;
        }

        if (checkoutDetails.value.type === "event") {
          paymentData.tickets = store.state.events.eventPurchaseData.tickets;
        }

        if (checkoutDetails.value.type === "subscription") {
          const subscriptionData = {
            name: checkoutDetails.value.name,
            currency: "usd",
            interval: checkoutDetails.value.option,
            amount: Math.round(checkoutDetails.value.amount * 100),
            user_id: store.state.currentUserId,
            plan_id: null,
            stripe_subscription_id: null,
            status: null,
            card: null,
            token: token.value,
            address,
            used_points: 0,
            description: `Atmo Subscription: ${checkoutDetails.value.name}`,
            ...accomplishmentPayload,
          };
          await store.dispatch("payments/createSubscription", subscriptionData);
          router.push({ name: "subscriptions.index" });
        } else {
          await store.dispatch("payments/createOneTimePayment", paymentData);
          router.push({ name: "purchases.index", params: { userId: store.state.currentUserId } });
        }

        store.dispatch("payments/resetStore");
      } catch (error) {
        console.error("Error during payment submission:", error);
      }
    };

    const cancel = () => {
      router.back();
      store.commit("setHudHeader", "Atmo Cart");
    };

    onBeforeUnmount(() => {
      store.dispatch("payments/resetStore");

      if (checkoutType.value === "store") {
        store.dispatch("products/clearCart");
      }
    });

    store.commit("setHudHeader", "Checkout");

    return {
      checkoutType,
      checkoutDetails,
      opsPageDonationData,
      formattedAmount,
      formattedTaxTotal,
      formattedShippingCost,
      formattedOverallTotal,
      formattedDiscount,
      cancel,
      submitPayment,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-checkout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  &__header {
    margin: 35px 0;
  }

  &__body {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 750px;
    max-height: 70vh;
    gap: 1rem;
  }

  &__main-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__cart-attributes {
    display: flex;
    flex-direction: column;
  }

  &__cart-attribute {
    display: flex;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 500;
    text-transform: uppercase;
    padding: 15px 10px;
    border-bottom: 1px solid $atmo-gray--medium;

    &--total {
      color: $atmo-blue--medium;
    }

    &--overall-total {
      background-color: rgba($atmo-gray--light, 0.8);
      color: $atmo-purple--dark;
    }
  }

  &__atmo-points {
    display: flex;
    flex-direction: column;
    margin: 15px 0;
  }

  &__atmo-points-redemption-button {
    padding: 4px 7px;
    border-radius: 4px;
    background-color: $atmo-purple--medium-dark;
    color: white;
    font-size: 0.8em;
    margin: 0 5px;

    &:hover {
      background-color: $atmo-purple--dark;
    }
  }

  &__atmo-points-actions {
    display: flex;
    align-items: center;
    font-weight: 300;
  }

  &__atmo-points-redemption {
    display: flex;
    margin: 10px 0;
    align-items: center;
  }

  &__equals {
    margin: 0 5px;
  }

  &__apply-points-button {
    margin-left: auto;
  }

  &__action-buttons {
    display: flex;
    margin: auto -5px 0 auto;
    margin-top: 1rem;

    .atmo-button {
      margin: 0 5px;
    }
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 200px;
    max-width: 300px;
    background-color: rgba($atmo-purple--light, 0.3);
    border-radius: 5px;
    padding: 5px 10px;
  }

  &__input-row {
    display: flex;
    align-items: center;
    margin: 6px -3px;
    position: relative;
  }

  &__input {
    flex-grow: 1;
    border: 1px solid $atmo-gray--light;
    padding: 6px 9px;
    border-radius: 5px;
    font-size: 13px;
    height: 30px;
    margin: 0 3px 3px;
    min-width: 0;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $atmo-gray--light;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $atmo-gray--light;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $atmo-gray--light;
    }

    &--purple {
      background-color: $atmo-purple--dark;
    }

    &--points {
      font-size: 0.8em;
      width: 75px;
      height: auto;
      padding: 5px 7px;
      flex-grow: unset;
    }
  }

  &__form-section-header {
    margin-top: 10px;
  }

  &__inline-label {
    margin-left: 3px;
  }

  &__card-brand {
    position: absolute;
    right: 10px;
  }
}
</style>
