export default {
  namespaced: true,
  state: {
    debug: false,
    donationAmount: 0,
    taxRate: .075
  },
  mutations: {
    setDonationAmount(state, amount) {
      if (state.debug) console.log("setDonationAmount mutation", amount);
      state.donationAmount = amount;
    },
  },
  actions: {
    async setDonationAmount({ state, commit }, { amount }) {
      if (state.debug) console.log("setDonationAmount action", amount);
      commit("setDonationAmount", amount);
    },
  },
  getters: {
    getDonationAmount(state) {
      if (state.debug) console.log("getDonationAmount", state.donationAmount);
      return state.donationAmount;
    },
    getDonationTaxRate(state) {
      if (state.debug) console.log("getDonationTaxRate", state.taxRate);
      return state.taxRate;
    },
  },
};
