<template>
  <label class="atmo-checkbox" :class="{ checked: modelValue }">
    <span class="atmo-checkbox__checkmark">
      <input
        type="checkbox"
        :checked="modelValue"
        @change="$emit('update:modelValue', $event.target.checked)"
      >
    </span>
    <span class="atmo-checkbox__label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      required: true,
    },
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';
.atmo-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  margin-top: 10px;

  &__checkmark {
    position: relative;
    border-radius: 4px;
    height: 16px;
    width: 16px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #ccc;
    }
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
  &.checked  {
    color: $atmo-blue--medium;
    .atmo-checkbox__checkmark {
      background-color: $atmo-blue--medium;
      &:after {
        display: block;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 2.5px 2.5px 0;
        transform: rotate(45deg);
      }
    }
  }
  &__label {
    margin-left: 5px;
    font-weight: 100;
  }
}

</style>
