import { callUserMusicProfilesShow } from '@/helpers/axiosCalls';

export default {
    namespaced: true,
    state: {
        debug: false,
        musicProfileModalOpen: false,
        libCompositionSeries: [],
        listeningHabitsSeries: [],
        attributePlaysSeries: [],
        mostPlayedSongs: [],
        mostPlayedArtists: [],
        suggestedSongs: [],
        suggestedArtists: [],
        friendsTastesSeries: [],
        genreMaps: {
            libraryGenreMap: {},
            listeningHabitsGenreMap: {},
            friendsGenreMap: {},
        },
        libCompositionOptions: {
            tooltip: {
                y: {
                    formatter: (val, opts) => {
                        return `${val} songs`;
                    }
                },
                theme: 'dark'
            },
            legend: {
                show: false,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: 'bottom',
                horizontalAlign: 'center',
                floating: false,
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 400,
                formatter: undefined,
                inverseOrder: false,
                width: undefined,
                height: undefined,
                tooltipHoverFormatter: undefined,
                customLegendItems: [],
                offsetX: 0,
                offsetY: 0,
                labels: {
                    colors: undefined,
                    useSeriesColors: false
                },
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 0
                },
                onItemClick: {
                    toggleDataSeries: true
                },
                onItemHover: {
                    highlightDataSeries: true
                },
            },
            fill: {
                colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
            },
            colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
            markers: { colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'] },
            stroke: {
                width: 0
            },
            chart: {
                type: "donut"
            },
            labels: []
        },
        listeningHabitsOptions: {
            tooltip: {
                y: {
                    formatter: (val, opts) => {
                        return `${val} plays`;
                    }
                },
                theme: 'dark'
            },
            legend: {
                show: false,
                showForSingleSeries: false,
                showForNullSeries: true,
                showForZeroSeries: true,
                position: 'bottom',
                horizontalAlign: 'center',
                floating: false,
                fontSize: '14px',
                fontFamily: 'Helvetica, Arial',
                fontWeight: 400,
                inverseOrder: false,
                width: undefined,
                height: undefined,
                tooltipHoverFormatter: undefined,
                customLegendItems: [],
                offsetX: 0,
                offsetY: 0,
                labels: {
                    colors: undefined,
                    useSeriesColors: false
                },
                markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: '#fff',
                    fillColors: undefined,
                    radius: 12,
                    customHTML: undefined,
                    onClick: undefined,
                    offsetX: 0,
                    offsetY: 0
                },
                itemMargin: {
                    horizontal: 5,
                    vertical: 0
                },
                onItemClick: {
                    toggleDataSeries: true
                },
                onItemHover: {
                    highlightDataSeries: true
                },
                formatter: function (val, opts) {
                    return val + " - " + opts.w.globals.series[opts.seriesIndex]
                }
            },
            chart: {
                width: 380,
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 270
                }
            },
            fill: {
                colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
            },
            colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
            markers: { colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'] },
            stroke: {
                width: 0
            },
            labels: [],
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        },
        attributePlaysOptions: {
            theme: {
                monochrome: {
                    enabled: true,
                    color: '#7feb9b',
                    shadeTo: 'light',
                    shadeIntensity: 0.65
                }
            },
            chart: {
                type: 'area',
                height: 350,
                zoom: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            labels: [],
            tooltip: {
                theme: 'dark'
            },
            xaxis: {
                type: 'datetime',
            },
            yaxis: {
                opposite: true
            },
        },
        friendsTastesOptions: {
            chart: {
                height: 350,
                type: 'bubble',
                zoom: {
                    enabled: true,
                },
            },
            dataLabels: {
                enabled: false,
            },
            colors: ['#7feb9b', '#5e9dd2', '#cc59ab', '#e69ab4', '#4dd19a', '#79678f', '#8aa96b'],
            xaxis: {
                type: 'category',
                title: {
                    text: 'Genres',
                },
                labels: {
                    rotate: -45,
                    rotateAlways: true,
                },
            },
            yaxis: {
                title: {
                    text: 'Number of Plays',
                },
            },
            tooltip: {
                theme: 'dark'
            },
        },
    },
    mutations: {
        toggleMusicProfileOpen(state) {
            state.musicProfileModalOpen = !state.musicProfileModalOpen;
            if (state.debug) console.log("Setting musicProfileModalOpen:", state.musicProfileModalOpen);
        },
        setLibCompositionSeries(state, series) {
            if (state.debug) console.log("Setting Library Composition Series:", series);
            state.libCompositionSeries = series;
        },
        setListeningHabitsSeries(state, series) {
            if (state.debug) console.log("Setting Listening Habits Series:", series);
            state.listeningHabitsSeries = series;
        },
        setAttributePlaysSeries(state, series) {
            if (state.debug) console.log("Setting Attribute Plays Series:", series);
            state.attributePlaysSeries = series;
        },
        setMostPlayedSongs(state, songs) {
            if (state.debug) console.log("Setting Most Played Songs:", songs);
            state.mostPlayedSongs = songs;
        },
        setMostPlayedArtists(state, artists) {
            if (state.debug) console.log("Setting Most Played Artists:", artists);
            state.mostPlayedArtists = artists;
        },
        setSuggestedSongs(state, songs) {
            if (state.debug) console.log("Setting Suggested Songs:", songs);
            state.suggestedSongs = songs;
        },
        setSuggestedArtists(state, artists) {
            if (state.debug) console.log("Setting Suggested Artists:", artists);
            state.suggestedArtists = artists;
        },
        setGenreMaps(state, { libraryGenreMap, listeningHabitsGenreMap, friendsGenreMap }) {
            if (state.debug) console.log("Setting Genre Maps:", { libraryGenreMap, listeningHabitsGenreMap, friendsGenreMap });
            state.genreMaps.libraryGenreMap = libraryGenreMap;
            state.genreMaps.listeningHabitsGenreMap = listeningHabitsGenreMap;
            state.genreMaps.friendsGenreMap = friendsGenreMap;
        },
        setFriendsTastesSeries(state, series) {
            if (state.debug) console.log("Setting Friends' Tastes Series:", series);
            state.friendsTastesSeries = series;
        },
        updateLibCompositionLabels(state, labels) {
            if (state.debug) console.log("Updating Library Composition Labels:", labels);
            state.libCompositionOptions.labels = labels;
        },
        updateListeningHabitsLabels(state, labels) {
            if (state.debug) console.log("Updating Listening Habits Labels:", labels);
            state.listeningHabitsOptions.labels = labels;
        }
    },
    actions: {
        async fetchMusicProfileData({ commit, state }, userId) {
            if (state.debug) console.log("Fetching Music Profile Data for User:", userId);
            try {
                const response = await callUserMusicProfilesShow(userId);
                if (state.debug) console.log("Raw API Response:", response);

                // Create Genre Maps
                const libraryGenreMap = createGenreMap(response.user_music_profile.user_music_profile_records, "genreInLibrary", state.debug);
                const listeningHabitsGenreMap = createGenreMap(response.user_music_profile.user_music_profile_records, "genrePlayCounts", state.debug);
                const friendsGenreMap = createGenreMapFromSharedGenres(response.shared_genres, state.debug);

                commit("setGenreMaps", { libraryGenreMap, listeningHabitsGenreMap, friendsGenreMap });

                // Library Composition Series and Labels
                const genreInLibraryRecords = response.user_music_profile.user_music_profile_records.filter(
                    record => record.metric_type === "genreInLibrary"
                );
                const libCompositionSeries = genreInLibraryRecords.map(record => record.number_of_songs);
                const libCompositionLabels = genreInLibraryRecords.map(record => record.recordable_name);
                if (state.debug) console.log("Library Composition Series:", libCompositionSeries);
                if (state.debug) console.log("Library Composition Labels:", libCompositionLabels);

                commit("setLibCompositionSeries", libCompositionSeries);
                commit("updateLibCompositionLabels", libCompositionLabels);

                // Listening Habits Series and Labels
                const genrePlayCountRecords = response.user_music_profile.user_music_profile_records.filter(
                    record => record.metric_type === "genrePlayCounts"
                );
                const listeningHabitsSeries = genrePlayCountRecords.map(record => record.number_of_plays);
                const listeningHabitsLabels = genrePlayCountRecords.map(record => record.recordable_name);
                if (state.debug) console.log("Listening Habits Labels:", listeningHabitsLabels);

                commit("setListeningHabitsSeries", listeningHabitsSeries);
                commit("updateListeningHabitsLabels", listeningHabitsLabels);

                // Attribute Plays Series Data
                const trendDates = Object.keys(response.trend_data);
                const trendPlays = Object.values(response.trend_data);
                const attributePlaysSeries = [{ name: "Plays", data: trendDates.map((date, index) => [new Date(date).getTime(), trendPlays[index]]) }];
                commit("setAttributePlaysSeries", attributePlaysSeries);
                if (state.debug) console.log("Attribute Plays Series:", attributePlaysSeries);

                // Set most played and suggested songs/artists
                commit("setMostPlayedSongs", response.most_played_songs);
                commit("setMostPlayedArtists", response.most_played_artists);
                commit("setSuggestedSongs", response.suggested_songs);
                commit("setSuggestedArtists", response.suggested_artists);

                // Build Friends' Tastes Series
                const friendsTastesSeries = buildFriendsMusicTastesSeries(response.shared_genres, friendsGenreMap, state.debug);
                commit("setFriendsTastesSeries", friendsTastesSeries);

            } catch (error) {
                console.error("Error fetching music profile:", error);
            }
        }
    }

};

// Helper Functions
function createGenreMap(records, metricType, debug) {
    if (debug) console.log(`Creating Genre Map for Metric Type: ${metricType}`, records);
    return records.filter(record => record.metric_type === metricType)
        .reduce((map, record) => {
            map[record.recordable_id] = record.recordable_type || `Genre ${record.recordable_id}`;
            return map;
        }, {});
}

function createGenreMapFromSharedGenres(sharedGenres, debug) {
    if (debug) console.log("Creating Genre Map from Shared Genres:", sharedGenres);
    return sharedGenres.reduce((map, genre) => {
        map[genre.genre_id] = genre.genre_name;
        return map;
    }, {});
}

function buildFriendsMusicTastesSeries(sharedGenres, friendsGenreMap, debug) {
    if (debug) console.log("Building Friends' Music Tastes Series:", sharedGenres);
    const seriesData = {};
    sharedGenres.forEach((genre) => {
        genre.user_plays.forEach((userPlay) => {
            const genreName = friendsGenreMap[genre.genre_id] || `Genre ${genre.genre_id}`;
            if (!seriesData[userPlay.name]) seriesData[userPlay.name] = [];
            seriesData[userPlay.name].push({ x: genreName, y: userPlay.number_of_plays, z: genre.total_play_count });
        });
    });
    return Object.keys(seriesData).map((userName) => ({ name: userName, data: seriesData[userName] }));
}