import { UAParser } from "ua-parser-js";
import { callPlatformAdsIndex, callAdImpressionsCreate } from "@/helpers/axiosCalls";

export default {
  namespaced: true,
  state: {
    ads: [], // Ensures ads is always an array
    registeredComponents: {}, // Tracks ads assigned to each component
    adRotationIndex: 0, // Keeps track of ad rotation
    debug: false,
  },
  mutations: {
    setAds(state, ads) {
      if (state.debug) console.log("🔹 Setting Ads in Store:", ads);
      state.ads = Array.isArray(ads) ? ads : [];
      state.adRotationIndex = 0;
    },
    registerAdComponent(state, componentId) {
      if (!state.registeredComponents[componentId]) {
        if (state.debug) console.log(`🔹 Registering ad component: ${componentId}`);
        state.registeredComponents[componentId] = null;
      }
    },
    unregisterAdComponent(state, componentId) {
      if (state.debug) console.log(`🔸 Unregistering ad component: ${componentId}`);
      delete state.registeredComponents[componentId];
    },
    assignAdToComponent(state, { componentId, ad }) {
      if (state.debug) console.log(`🟢 Assigning ad to component ${componentId}:`, ad);
      state.registeredComponents[componentId] = ad;
    },
    rotateAd(state) {
      if (state.ads.length > 0) {
        state.adRotationIndex = (state.adRotationIndex + 1) % state.ads.length;
        if (state.debug) console.log("🔄 Rotating Ad Index:", state.adRotationIndex);
      } else {
        if (state.debug) console.warn("⚠️ No ads to rotate.");
      }
    },
  },
  actions: {
    async fetchAds({ commit, rootState, dispatch, state }) {
      try {
        const response = await callPlatformAdsIndex(rootState.currentUserId);
        if (state.debug) console.log("✅ Fetched Ads:", response);
        const ads = Array.isArray(response) ? response : [];
        commit("setAds", ads);
        dispatch("assignAdsToComponents");
      } catch (error) {
        if (state.debug) console.error("❌ Error fetching ads:", error);
        commit("setAds", []);
      }
    },
    startAdRotation({ dispatch }) {
      setInterval(() => {
        dispatch("assignAdsToComponents");
      }, 100000);
    },
    registerComponent({ commit, state, dispatch }, componentId) {
      commit("registerAdComponent", componentId);
      if (Object.keys(state.registeredComponents).length === 1) {
        dispatch("fetchAds").then(() => {
          dispatch("startAdRotation");
        });
      } else {
        dispatch("assignAdsToComponents");
      }
    },
    unregisterComponent({ commit }, componentId) {
      commit("unregisterAdComponent", componentId);
    },
    assignAdsToComponents({ state, commit, dispatch }) {
      const ads = Array.isArray(state.ads) ? state.ads : [];
      const componentIds = Object.keys(state.registeredComponents);
      if (ads.length === 0) {
        if (state.debug) console.warn("⚠️ No ads available to assign.");
        return;
      }
      componentIds.forEach((componentId) => {
        const assignedAd = ads[state.adRotationIndex];
        commit("assignAdToComponent", { componentId, ad: assignedAd });
        commit("rotateAd");
        dispatch("sendAdImpression", { ad: assignedAd, componentId });
      });
    },
    async sendAdImpression({ rootState, state }, { ad, componentId }) {
      if (!ad || !ad._id) {
        if (state.debug) console.warn(`⚠️ No valid ad found for component ${componentId}`);
        return;
      }
      try {
        if (state.debug) console.log(`📡 Sending ad impression for ad ID ${ad._id}, component ${componentId}`);
        const parser = new UAParser();
        const result = parser.getResult();
        await callAdImpressionsCreate(rootState.currentUserId, {
          ad_type: "PlatformAd",
          ad_id: ad._id,
          user_id: rootState.currentUserId,
          device_type: result.device.type,
          browser: result.browser.name,
          os: result.os.name,
          ip_address: rootState.currentUser.current_sign_in_ip,
          page_url: window.location.href,
        });
        if (state.debug) console.log(`✅ Ad impression recorded for ad ID ${ad._id}`);
      } catch (error) {
        if (state.debug) console.error("❌ Error sending ad impression:", error);
      }
    },
  },
  getters: {
    currentAd: (state) => (componentId) => {
      if (state.debug) console.log(`🔍 Fetching ad for component ${componentId}:`, state.registeredComponents[componentId]);
      return state.registeredComponents[componentId] || null;
    },
  },
};
