<template>
  <div class="atmo-auth__background-wrapper">
    <div class="atmo-auth__background">
      <div class="atmo-auth__introduction">
        <div class="atmo-auth__logo">
          <div class="atmo-auth__logo-img" />
        </div>
        <h1 class="atmo-auth__background-h1">
          {{ h1 }}
        </h1>
        <h2 class="atmo-auth__background-h2">
          {{ h2 }}
        </h2>
        <router-link :to="{ name: toRoute }" type="button" class="atmo-auth__button-signup">
          {{ buttonText }}
        </router-link>
        <div class="atmo-auth__ads">
          <div class="atmo-auth__ads-img" />
          <a href="http://localhost:9090" target="_blank" class="atmo-auth__ads-p">
            {{ ads }}
          </a>
        </div>
      </div>
      <div class="atmo-auth__avatar">
        <img src="@/assets/images/ATMOimage.png" class="atmo-auth__avatar-img" />
      </div>
    </div>
    <div class="atmo-auth__copyright">
      <p class="atmo-auth__copyright-p">{{ inc }}, Inc. Terms Privacy ...</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ads: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    toRoute: {
      type: String,
      required: true,
    },
    h1: {
      type: String,
      required: true,
    },
    h2: {
      type: String,
      required: true,
    },
  },
  computed: {
    inc() {
      return `©${new Date().getFullYear()} The Atmosphere`;
    },
  },
};
</script>
