import Axios from "axios";
import router from "./../routes";
import {
  callEventsIndex,
  callEventsShow,
  callEventsCreate,
  callEventsUpdate,
  callEventsDelete
} from '@/helpers/axiosCalls';

export default {
  namespaced: true,
  state: {
    debug: false,
    events: [],
    currentEvent: {},
    isLoaded: false,
    eventPurchaseData: {
      event: null,
      tickets: [], // Array to store ticket data
      totalQuantity: 0, // Total quantity of tickets
      totalPrice: 0, // Total price for all tickets
    },
  },
  mutations: {
    setEvents(state, events) {
      state.events = events;
      state.isLoaded = true;
    },
    setEvent(state, event) {
      state.currentEvent = event;
    },
    setEventPurchaseData(state, data) {
      console.log("Data received by setEventPurchaseData mutation:", data);
    
      state.eventPurchaseData.event = data.event || {}; // Ensure event is an object
      state.eventPurchaseData.tickets = Array.isArray(data.tickets) ? data.tickets : []; // Ensure tickets is an array
    
      // Recalculate totals
      state.eventPurchaseData.totalQuantity = state.eventPurchaseData.tickets.reduce(
        (sum, ticket) => sum + (ticket.quantity || 0),
        0
      );
      state.eventPurchaseData.totalPrice = state.eventPurchaseData.tickets.reduce(
        (sum, ticket) => sum + ticket.price * (ticket.quantity || 0),
        0
      );
    },
    clearEventPurchaseData(state) {
      if (state.debug) console.log("clearEventPurchaseData called");
      state.eventPurchaseData = {
        event: null,
        tickets: [],
        totalQuantity: 0,
        totalPrice: 0,
      };
    },
  },  
  actions: {
    async loadEvents({ commit }) {
      try {
        let events = await callEventsIndex();
        commit("setEvents", events.map(event => {
          let tickets = event.atmo_event_tickets.map(event => {
            return { ...event, quantity: 0 }
          })

          return { ...event, tickets }
        }));
      } catch (exception) {
        commit("setEvents", []);
      }
    },
    async deleteEvent({ state, dispatch }, event) {
      if (!window.confirm(`Are you sure you want to delete ${event.name}?`)) {
        return;
      }
      
      callEventsDelete(event.id)
        .then(response => {
          dispatch("loadEvents");
        })
        .catch(error => {
          console.error(error);
        });
    },
    async createEvent(context, { event }) {
      try {
        // 1: create address
        await Axios.post(`/api/v1/addresses`, {
          ...event.address,
          line_1: event.address.line1,
          line_2: event.address.line2
        });

        // 2: create event
        await Axios.post(`/api/v1/atmo_events`, {
          "name": event.title,
          "date": event.title,
          "image": "?",
          "details": event.description,
          "tickets": event.tickets
        });

        router.push(`/events`);
      } catch (exception) {
        // display error message
      }
    },
    // setEvent({ state, commit }, id) {
    //   callEventsShow(parseInt(id))
    //     .then(response => {
    //       // console.log()
    //       commit("setEvent", response.id);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // }
    updateEventPurchaseData({ commit, dispatch, state }, data) {
      if (state.debug) console.log("updateEventPurchaseData action called with:", data);
      commit("setEventPurchaseData", data);
    },
    clearEventPurchaseData({ commit, state }) {
      if (state.debug) console.log("clearEventPurchaseData action called");
      commit("clearEventPurchaseData");
    },
  },
  getters: {
    currentPage: state => state.currentPage,
    isLoaded: state => state.isLoaded
  }
};
