<template>
    <VueFinalModal 
        id="max-chat" 
        ref="maxChatRef" 
        v-model="$store.state.chatRooms.maxChatOpen"
        title="max-chat"
        content-class="atmo-modal max-chat-modal atmo-modal-content" 
        overlay-class="atmo-modal-overlay"
        :esc-to-close="true"
        @opened="getMaxChatData">
        <img class="max-chat__hide-modal" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Atmo Chat"
            @click="toggleMaxChatOpen">
        <header class="max-chat__header atmo-modal__header">
            <div class="atmo-page-header-container">
                <div class="atmo-page-header-container__header-icon-wrap">
                    <h2 class="atmo-page-header">
                        Atmo Chat
                    </h2>
                </div>
            </div>
        </header>
        <div class="max-chat-grid">
            <create-group-chat-modal />
            <!-- <delete-group-chat-modal /> -->
            <group-chat-settings-modal />
            <div class="max-chat-grid__friends-container">
                <div class="container__heading-wrap">Chats</div>
                <div>
                    <div class="friends-container__search-wrap">
                        <input class="search-field-rounded" type="text" placeholder="Search for a friend"
                            v-model="searchQuery" @input="filterList" />
                    </div>
                    <div class="friends-container__friends-wrap">
                        <div class="friends-wrap__header">Friends</div>
                        <div class="users-wrap">
                            <perfect-scrollbar class="friends-scroll-wrap">
                                <div v-if="filteredFriendList.length === 0">
                                    No friends available.
                                </div>
                                <div class="users-wrap__user-wrap" v-for="friendship in filteredFriendList"
                                    :key="friendship.id" style="cursor:pointer"
                                    @click="setChatRoomAttributes($store.state.currentUserId, friendship.friend);">
                                    <div class="user-wrap__image-wrap">
                                        <img class="image-wrap__image"
                                            :src="getFeaturedImage(friendship.friend.images, 'full')" />
                                        <notification-dot :chat-user="friendship.friend" dot-size="small" dot-id=""
                                            notification-type="Chat Message"
                                            style="position:absolute; top:7px; left:34px;"></notification-dot>
                                    </div>
                                    <div class="user-wrap__name-wrap">{{ friendship.friend.name }}</div>
                                </div>
                            </perfect-scrollbar>
                        </div>
                    </div>
                    <div class="friends-container__rooms-wrap">
                        <div class="rooms-wrap__header">
                            <span>Rooms</span>
                            <span class="create-room" @click="openCreateGroupChatModal()">Create Room</span>
                        </div>
                        <div class="users-wrap">
                            <perfect-scrollbar class="rooms-scroll-wrap">
                                <div v-if="groupChatRooms.length === 0">
                                    No rooms available.
                                </div>
                                <div class="users-wrap__user-wrap" v-for="(room, index) in groupChatRooms" :key="index"
                                    @click="getGroupRoomMessages(room)">
                                    <div class="user-wrap__image-wrap"><img class="image-wrap__image"
                                            :src="room.image.url" /></div>
                                    <div class="user-wrap__name-wrap">{{ room.name }}</div>
                                </div>
                            </perfect-scrollbar>
                        </div>
                    </div>
                </div>
            </div>
            <div class="max-chat-grid__max-chat-container">
                <div class="container__heading-wrap">
                    <div v-if="currentChatTitleData.roomType == 'friend'" @click="toggleMaxChatOpen">
                        <profile-link :profile-type="currentChatTitleData.profileType"
                            :profile-id="currentChatTitleData.profileId" class="friend-chat-title">
                            <div class="heading-wrap__text-wrap">{{ currentChatTitleData.name }}</div>
                        </profile-link>
                    </div>
                    <div v-else class="heading-wrap__text-wrap">{{ currentChatTitleData.name }}</div>
                    <div class="heading-wrap__settings-wrap" v-if="groupChatRoomSelected">
                        <img src="@/assets/images/icons/chat/settings_icon.png" class="settings-icon"
                            @click="openChatSettings()" />
                    </div>
                </div>
                <div v-if="currentChatRoomMessages.length === 0" class="messages-container empty-messages">
                    Empty Chat History :(</div>
                <perfect-scrollbar v-else class="messages-container">
                    <div v-for="(message, messageIndex) in currentChatRoomMessages" :key="message.id"
                        :class="messageClassFor(message)">
                        <div class="max-chat__message-content" @click="closeReactionMenu()">
                            <div class="user-reactions-container">
                                <div class="user-reactions-container__reaction" v-if="message.reactions[0]">
                                    <img class="reaction__img"
                                        :src="require(`@/assets/images/reactions/${message.reactions[0].reaction_type.emoji}`)" />
                                </div>
                                <div class="user-reactions-container__reaction" v-if="message.reactions[1]">
                                    <img class="reaction__img"
                                        :src="require(`@/assets/images/reactions/${message.reactions[1].reaction_type.emoji}`)" />
                                </div>
                                <div class="user-reactions-container__reactions-length"
                                    v-if="message.reactions.length > 2">
                                    {{ message.reactions.length }}
                                </div>
                            </div>
                            <div v-show="openElement === messageIndex" class="reactions-container">
                                <div class="reactions-container__reaction-icon"
                                    v-for="(reactionType, reactionTypeIndex) in reactionTypes" :key="reactionTypeIndex"
                                    @click="addReaction(reactionType, message)">
                                    <img class="reaction-icon__img"
                                        :src="require(`@/assets/images/reactions/${reactionType.emoji}`)" />
                                </div>
                            </div>
                            <p class="max-chat__message-text" v-html="formatMessage(message.body)"></p>
                            <div v-if="message.song_references.length > 0" class="max-chat__message-song">
                                <img class="max-chat__message-song-icon" src="@/assets/images/icons/play.png">
                                <a class="max-chat__message-song-name">
                                    {{ message.song_references[0].song.name }}
                                </a>
                            </div>

                            <div v-if="message.chat_message_image" class="max-chat__message-image-wrap"
                                @click="openChatImageModal(message.chat_message_image.image.url)">
                                <img class="max-chat__message-image" :src="message.chat_message_image.image.url">
                            </div>
                            <p class="max-chat__message-timestamp">
                                {{ getDate(message.created_at) }}
                            </p>
                        </div>
                        <div @click="toggleMaxChatOpen">
                            <profile-link :profile-type="message.user.profile_type"
                                :profile-id="message.user.profile_id">
                                <div class="atmo-avatar atmo-avatar--extra-small atmo-avatar--no-border"
                                    :style="{ 'background-image': 'url(' + getFeaturedImage(message.user.images, 'thumb') + ')' }"
                                    title="message.user.name" />
                            </profile-link>
                        </div>
                        <div class="reaction-trigger" @click="toggleReactions(messageIndex)">
                            ☻
                        </div>
                    </div>
                </perfect-scrollbar>
                <form class="max-chat__form" @submit.prevent="saveMessage(newMessageBody)">
                    <div class="input-emoji-container">
                        <input v-model="newMessageBody" class="atmo-input atmo-input--square max-chat__text-input"
                            placeholder="Type your message here" type="text">
                        <emoji-picker @emoji="insert" :search="search" class="emoji-selection-container">
                            <div slot="emoji-invoker" slot-scope="{ events: { click: clickEvent } }"
                                @click.stop="clickEvent">
                                <button type="button" class="emoji-picker-trigger">☻</button>
                            </div>
                            <div slot="emoji-picker" slot-scope="{ emojis, insert }" class="input-emojis-container">
                                <div>
                                    <perfect-scrollbar class="emojis-container">
                                        <div class="emoji-search-container">
                                            <input class="emoji-search" type="text" v-model="search"
                                                placeholder="Search for emojis">
                                        </div>
                                        <div v-for="(  emojiGroup, category  ) in   emojis  " :key="category"
                                            class="emoji-category-container">
                                            <p class="category-heading">{{ category }}</p>
                                            <div>
                                                <span v-for="(  emoji, emojiName  ) in   emojiGroup  " :key="emojiName"
                                                    @click=" insert(emoji)" :title="emojiName">{{ emoji }}</span>
                                            </div>
                                        </div>
                                    </perfect-scrollbar>
                                </div>
                            </div>
                        </emoji-picker>
                        <label for="file-input"
                            style="position: absolute; bottom: .5rem; left: 2rem; display: inline-block; height: 1rem; width: 1rem;">
                            <img src="@/assets/images/icons/chat/upload_image.png" class="image-upload-icon"
                                alt="Upload Image" />
                        </label>
                        <input id="file-input" type="file" @change="handleFileUpload" style="display: none" />
                    </div>
                </form>
            </div>
        </div>
    </VueFinalModal>
</template>

<script>
import Axios from 'axios';
import { sendMessage, setCallback, connectToAtmoChatChannel } from '@/cable-channels/atmoChat';
import EmojiPicker from 'vue3-emoji-picker'
import 'vue3-emoji-picker/css'
import devMockData from '@/devUtils/mockData';
import createGroupChatModal from '@/modals/atmo-chat/create-group-chat-modal.vue';
import groupChatSettingsModal from '@/modals/atmo-chat/group-chat-settings-modal.vue';
import { formatDateToDayTimeYear, getFeaturedImage, findOtherUsersInRoom } from '@/helpers/utilityFunctions';
import {
    callReactionTypesIndex,
    callReactionsCreate,
    callChatRoomUsersCreate,
    callChatRoomsCreate,
    callChatRoomsIndex,
    callChatMessagesCreate
} from '@/helpers/axiosCalls';
import NotificationDot from '@/components/notifications/notification-dot';
import { mapState } from 'vuex';
import ProfileLink from '@/components/profile-link'

export default {
    components: {
        EmojiPicker,
        createGroupChatModal,
        groupChatSettingsModal,
        NotificationDot,
        ProfileLink
    },
    data() {
        return {
            newMessageBody: '',
            search: '',
            devMockData,
            groupChatRoomSelected: false,
            searchQuery: '',
            reactionTypes: [],
            openElement: null,
        };
    },
    computed: {
        ...mapState('chatRooms', [
            'currentChatRoom',
            'currentChatTitleData',
            'currentChatRoomMessages',
            'groupChatRooms',
            'chatUserFriendships',
            'chatCurrentView',
        ]),
        acceptedFriendships() {
            return this.chatUserFriendships.filter(friendship =>
                friendship.state === 'accepted'
            );
        },
        filteredFriendList() {
            return this.acceptedFriendships.filter(friendship =>
                friendship.friend.name.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
    },
    methods: {
        getMaxChatData() {
            this.getUserFriendships();
            this.getGroupChatRooms();
            this.getReactionTypes();
        },
        toggleMaxChatOpen() {
            this.$store.commit('chatRooms/toggleMaxChatOpen');
        },
        filterList() {
            // This method is bound to the input event and updates the filtered list
            // based on the search query in real-time.
        },
        getFeaturedImage(userImages, type) {
            return getFeaturedImage(userImages, type);
        },
        getReactionTypes() {
            callReactionTypesIndex()
                .then(response => {
                    this.reactionTypes = response;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        addReaction(type, message) {
            callReactionsCreate(this.currentChatRoom.id, message.id, {
                user_id: this.$store.state.currentUserId,
                reactable_type: "ChatMessage",
                reactable_id: message.id,
                reaction_type_id: type.id,
            })
                .then(() => {
                    this.openElement = null;
                    sendMessage('', this.currentChatRoom.id, this.$store.state.currentUserId);
                    this.getRoomMessages(this.currentChatRoom.id);
                })
                .catch(error => {
                    console.error(error);
                });
        },
        toggleReactions(identifier) {
            this.openElement = this.openElement === identifier ? null : identifier;
        },
        closeReactionMenu() {
            this.openElement = null;
        },
        formatMessage(message) {
            const urlPattern = /https?:\/\/[^\s]+/g;
            return message.replace(urlPattern, (url) => `<a class="chat-link" href="${url}" target="_blank">${url}</a>`);
        },
        getDate(date) {
            return formatDateToDayTimeYear(date);
        },
        openChatImageModal(url) {
            this.$store.commit('chatRooms/setChatImageModalUrl', url);
            this.$store.commit('chatRooms/showChatImageModal');
        },
        async handleFileUpload(event) {
            const selectedFile = event.target.files[0];
            if (!selectedFile) return;

            callChatMessagesCreate(
                this.currentChatRoom.id,
                {
                    chat_room_id: this.currentChatRoom.id,
                    user_id: this.$store.state.currentUserId,
                    body: ''
                }
            )
                .then(async (res) => {
                    const messageId = res.id;
                    const formData = new FormData();
                    formData.append('image', selectedFile);
                    formData.append('chat_message_id', messageId);

                    await Axios.post('/api/v1/chat_message_images', formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });

                    sendMessage('', this.currentChatRoom.id, this.$store.state.currentUserId);
                    this.newMessageBody = '';
                    this.getRoomMessages(this.currentChatRoom.id);
                    setCallback(() => {
                        this.getRoomMessages(this.currentChatRoom.id);
                    });

                    const otherUsersInRoom = findOtherUsersInRoom(this.currentChatRoom, this.$store);
                    otherUsersInRoom.forEach(user => {
                        this.$store.dispatch('notifications/createNotification', {
                            userId: user.user_id,
                            payload: {
                                sender_id: this.$store.state.currentUserId,
                                receiver_id: user.user_id,
                                notification_type_name: 'Chat Message',
                                status: 'unread',
                                notifiable_type: 'ChatRoom',
                                notifiable_id: this.currentChatRoom.id
                            }
                        });
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        openChatSettings() {
            this.$store.commit('chatRooms/openGroupChatSettingsModal');
        },
        openCreateGroupChatModal() {
            this.$store.commit('chatRooms/openCreateGroupChatModal');
        },
        getGroupRoomMessages(room) {
            this.groupChatRoomSelected = true;
            this.$store.commit("chatRooms/setCurrentChatTitleData", {
                name: room.name,
                roomType: "group",
                profileType: null,
                profileId: null
            });

            this.$store.commit("chatRooms/setCurrentChatRoom", room);

            setCallback(() => {
                this.getRoomMessages(room.id);
            });
            connectToAtmoChatChannel(room.id);
            this.$store.commit('chatRooms/changeChatView', "messages");

            this.getRoomMessages(room.id);
        },
        insert(emoji) {
            this.newMessageBody += emoji;
        },
        messageClassFor(message) {
            return [
                'max-chat__message',
                message.user_id === this.$store.state.currentUser.profile_id ? 'max-chat__message--current-user' : ''
            ].join(' ');
        },
        getUserFriendships() {
            this.$store.dispatch('chatRooms/getUserFriendships', this.$store.state.currentUserId);
        },
        getGroupChatRooms() {
            this.$store.dispatch('chatRooms/getGroupChatRooms', this.$store.state.currentUserId);
        },
        getRoomMessages(roomId) {
            this.$store.dispatch('chatRooms/getRoomMessages', roomId);
        },
        scrollToBottom() {
            // Scroll logic here
        },
        retrieveRoomMessages(chatRoomId) {
            setCallback(() => {
                this.getRoomMessages(chatRoomId);
            });
            connectToAtmoChatChannel(chatRoomId);
            this.$store.commit('chatRooms/changeChatView', "messages");

            this.getRoomMessages(chatRoomId);
        },
        saveChatUser(roomId, userId) {
            callChatRoomUsersCreate(roomId, {
                chat_room_id: roomId,
                user_id: userId,
                is_admin: true
            })
                .then(() => {
                    console.log(`SAVING USER ${userId} TO ROOM ${roomId} SUCCESSFUL`);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        createRoom() {
            callChatRoomsCreate({
                name: this.userChatRoomId + "_" + this.friendChatRoomId,
                room_users_id: this.userChatRoomId + "_" + this.friendChatRoomId,
                room_type: "Friend",
                creator_id: this.userChatRoomId,
            })
                .then((res) => {
                    this.$store.commit("chatRooms/setCurrentChatRoom", res);
                    let userIdArr = [this.userChatRoomId, this.friendChatRoomId];

                    userIdArr.forEach(async (id) => {
                        await this.saveChatUser(this.currentChatRoom.id, id);
                    });

                    this.retrieveRoomMessages(this.currentChatRoom.id);
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        setChatRoomAttributes(userChatRoomId, friendChatRoom) {
            this.userChatRoomId = userChatRoomId;
            this.friendChatRoomId = friendChatRoom.id;
            this.$store.commit("chatRooms/setCurrentChatTitleData", {
                name: friendChatRoom.name,
                roomType: "friend",
                profileType: friendChatRoom.profile_type,
                profileId: friendChatRoom.profile_id
            });

            this.groupChatRoomSelected = false;
            callChatRoomsIndex({
                user_id: this.userChatRoomId,
                friend_id: this.friendChatRoomId
            })
                .then((response) => {
                    if (Object.keys(response).length === 0) {
                        this.createRoom(); // If no room exists, create one
                    } else {
                        this.$store.commit("chatRooms/setCurrentChatRoom", response);
                        this.retrieveRoomMessages(this.currentChatRoom.id);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        saveMessage(messageBody) {
            if (!this.currentChatRoom || !this.currentChatRoom.id) {
                console.error('No chat room selected or this.currentChatRoom.id is undefined');
                return;
            }

            callChatMessagesCreate(this.currentChatRoom.id, {
                chat_room_id: this.currentChatRoom.id,
                user_id: this.$store.state.currentUserId,
                body: messageBody
            })
                .then(() => {
                    sendMessage(messageBody, this.currentChatRoom.id, this.$store.state.currentUserId);
                    this.newMessageBody = '';
                    this.getRoomMessages(this.currentChatRoom.id);
                    setCallback(() => {
                        this.getRoomMessages(this.currentChatRoom.id);
                    });

                    const otherUsersInRoom = findOtherUsersInRoom(this.currentChatRoom, this.$store);
                    otherUsersInRoom.forEach(user => {
                        this.$store.dispatch('notifications/createNotification', {
                            userId: user.user_id,
                            payload: {
                                sender_id: this.$store.state.currentUserId,
                                receiver_id: user.user_id,
                                notification_type_name: 'Chat Message',
                                status: 'unread',
                                notifiable_type: 'ChatRoom',
                                notifiable_id: this.currentChatRoom.id
                            }
                        });
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        viewFriends() {
            this.$store.commit('chatRooms/changeChatView', "friends");
        },
    }
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

#max-chat {
    .atmo-modal-content {
        border-radius: 5px;
        border: none;
        width: 100vw;
        height: 97vh;
    }

    .modal-lg.modal-dialog {
        width: 98vw;
        margin: 1rem;
        max-width: unset;
    }

}

.max-chat-modal {

    .ps {
        max-height: 40rem;
    }

    .max-chat__hide-modal {
        position: absolute;
        top: 1rem;
        left: 1rem;
        height: 2rem;
        width: 2rem;
        opacity: 0.5;
        cursor: pointer;
    }

    .max-chat__header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-size: 1rem;
        margin-bottom: 2rem;

        .atmo-page-header-container__header-icon-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .atmo-page-header {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;
            margin-right: .5rem;
        }

        .header-icon-wrap__icon-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .atmo-popover__actions-trigger {
            height: 18px;
            cursor: pointer;
            padding: 5px 10px;
            box-sizing: content-box;
        }

        .icon-wrap__icon {
            height: 2rem;
        }
    }

    .atmo-modal-content {
        padding: 1rem;
    }

    .messages-container {
        min-height: 45rem;
    }

    .messages-container.empty-messages {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: white;
        font-weight: 600;
        text-transform: uppercase;
    }

    .max-chat-grid {
        // border: 1px solid white;
        display: grid;
        grid-template-columns: minmax(max-content, 20rem) 1fr;
        grid-gap: 1rem;
        // height: 100%;
    }

    .container__heading-wrap {
        font-size: 1.5rem;
        color: $atmo-blue--medium;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 2rem;
        // border: 1px solid white;
        width: 100%;
        display: flex;
        align-items: center;

        .heading-wrap__text-wrap {
            margin-right: .5rem;
        }

        .heading-wrap__icon-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .icon-wrap__icon {
            height: 1.5rem;
            width: 1.5rem;
        }
    }

    .heading-wrap__settings-wrap {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .settings-icon {
        height: 1.5rem;
        cursor: pointer;
        top: 0rem;
    }

    .max-chat-grid__friends-container {
        // border: 1px solid white;
        border-right: 1px solid rgba(255, 255, 255, .2);
        padding: 0rem 1rem 1rem 1rem;
    }

    .friends-container__search-wrap {
        margin-bottom: 1rem;
    }

    .search-field-rounded {
        background: transparent;
        border-radius: 20px;
        border: 1px solid rgba(255, 255, 255, .4);
        padding: .5rem .5rem .5rem 2.5rem;
        color: white;
        // width: 100%;
        position: relative;
        background: url('@/assets/images/icons/search.png') no-repeat scroll 7px 7px;
        background-size: 1rem;
        background-position: .8rem .5rem;
    }

    .friends-container__friends-wrap {
        margin-bottom: 1rem;
        min-height: 25rem;
    }

    .friend-chat-title {
        color: $atmo-blue--medium;
    }

    .friends-scroll-wrap {
        max-height: 20rem;
    }

    .rooms-scroll-wrap {
        max-height: 16rem;
    }

    .friends-wrap__header {
        font-size: .8rem;
        text-transform: uppercase;
        color: $atmo-gray--light--faded;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        margin-bottom: 1rem;
        padding: .3rem 0rem .3rem 0rem;
        display: flex;
        justify-content: space-between;
    }

    .friends-container__rooms-wrap {
        margin-bottom: 1rem;
        max-height: 16rem;
    }

    .rooms-wrap__header {
        font-size: .8rem;
        text-transform: uppercase;
        color: $atmo-gray--light--faded;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        margin-bottom: 1rem;
        padding: .3rem 0rem .3rem 0rem;
        display: flex;
        justify-content: space-between;
    }

    .create-room {
        cursor: pointer;
        color: white;
    }

    .members-wrap__header {
        font-size: .8rem;
        text-transform: uppercase;
        color: $atmo-gray--light--faded;
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        margin-bottom: 1rem;
        padding: .3rem 0rem .3rem 0rem;
    }

    .users-wrap__user-wrap {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 5px;
        margin-bottom: .5rem;
        cursor: pointer;
        padding: .3rem .3rem .3rem .3rem;

        &:hover {
            background-color: rgba(190, 167, 221, .3);

            .user-wrap__make-admin-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $atmo-blue--medium;
                font-weight: 500;
            }

            .user-wrap__delete-wrap {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: auto;
                margin-right: 1rem;
            }
        }

        .image-wrap__image {
            width: 2.2rem;
            height: 2.2rem;
            border-radius: 100%;
            margin-right: .5rem;
            object-fit: cover;
        }

        .user-wrap__name-wrap {
            font-size: .8rem;
            font-weight: 500;
            margin-right: .5rem;
        }

        .user-wrap__make-admin-wrap {
            display: none;
        }

        .user-wrap__delete-wrap {
            display: none;
        }

        .delete-wrap__icon {
            height: .7rem;
            opacity: .3;
        }
    }

    .max-chat__message {
        display: flex;
        padding: 7px 0;

        &:hover {
            .reaction-trigger {
                margin-right: 1rem;
                cursor: pointer;
                display: block;
                height: 1rem;
                width: 1rem;
                border-radius: 100%;
                font-size: 1.5rem;
            }
        }

        .reaction-trigger {
            display: none;
        }

        &--current-user {
            flex-direction: row-reverse;

            .max-chat__message-content {
                padding: 7px 6px 3px;
                background: rgba($atmo-purple--light, 0.3);
                border-radius: 2px;
                margin-left: 5px;
                border: none;
                max-width: 20rem;
                position: relative;

                .user-reactions-container {
                    // border: 1px solid red;
                    position: absolute;
                    bottom: -.5rem;
                    left: -.3rem;
                    display: flex;

                    .reaction__img {
                        height: 1rem;
                        width: 1rem;
                    }
                }

            }

            .chat-link {
                color: $atmo-blue--medium;
            }

            .max-chat__message-timestamp {
                align-self: flex-end;
                color: white;
            }

            .reactions-container {
                padding: .5rem;
                display: flex;
                gap: .5rem;
                background-color: $atmo-purple--medium-dark--overlay-faded;
                position: absolute;
                bottom: -1rem;
                left: 1rem;
                border-radius: 2px;
                max-width: 8rem;

                .reactions-container__reaction-icon {
                    cursor: pointer;
                    height: 1rem;
                    width: 1rem;
                    background-color: $atmo-purple--medium-dark--overlay-faded;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .reaction-icon__img {
                        height: 1rem;
                    }
                }
            }
        }

        .reactions-container {
            padding: .5rem;
            display: flex;
            gap: .5rem;
            background-color: $atmo-purple--medium-dark--overlay-faded;
            position: absolute;
            bottom: -1rem;
            right: 1rem;
            border-radius: 2px;
            max-width: 8rem;

            .reactions-container__reaction-icon {
                cursor: pointer;
                height: 1rem;
                width: 1rem;
                background-color: $atmo-purple--medium-dark--overlay-faded;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .reaction-icon__img {
                    height: 1rem;
                }
            }
        }

        .user-reactions-container__reactions-length {
            height: 1rem;
            width: 1rem;
            font-weight: 600;
            border: 1px solid $atmo-blue--medium;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $atmo-purple--medium-dark--overlay-faded;
            border-radius: 100%;
            color: white;
        }
    }

    .max-chat__message-content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 7px 6px 3px;
        border: 1px solid rgba(255, 255, 255, .2);
        border-radius: 2px;
        margin-right: 5px;
        max-width: 20rem;
        position: relative;

        .user-reactions-container {
            // border: 1px solid red;
            position: absolute;
            bottom: -.5rem;
            right: -.3rem;
            display: flex;

            .reaction__img {
                height: 1rem;
                width: 1rem;
            }
        }

        .chat-link {
            color: $atmo-blue--medium;
        }
    }

    .max-chat__message-text {
        color: white;
        font-size: .8rem;
        font-weight: 400;
        margin-bottom: 5px;
    }

    .max-chat__message-song {
        display: flex;
        cursor: pointer;
    }

    .max-chat__message-song-icon {
        height: 15px;
        width: 15px;
    }

    .max-chat__message-song-name {
        color: white;
        cursor: pointer;
        font-style: italic;
        font-size: .8rem;
        font-weight: 500;
        transition: color 0.2s ease-in-out;
        margin-bottom: 5px;

        &:hover {
            color: $atmo-purple--dark;
        }
    }

    .max-chat__message-image-wrap {
        display: flex;
        cursor: pointer;
        // padding: .5rem .5rem .5rem 0rem;
        margin-bottom: .5rem;
    }

    .max-chat__message-image {
        object-fit: cover;
        width: 100%;
        border-radius: 2px;
        // height: 15rem;
        // width: 15rem;
    }

    .max-chat__message-timestamp {
        color: white;
        font-size: .7rem;
        font-weight: 300;
    }

    .max-chat__messages-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .max-chat__form {
        width: 100%;
        margin: 5px 0;
        // display: flex;
    }

    .input-emoji-container {
        position: relative;
    }

    .max-chat__text-input {
        margin-top: 1rem;
        // min-width: 30rem;
        width: 100%;
        // height: 7rem;
        padding: 1rem .5rem 1rem 3.5rem;
        color: white;
        font-size: .8rem;
    }

    .max-chat__text-input::placeholder {
        font-size: .8rem;
    }

    .emoji-selection-container {
        // width: 20rem;
        position: absolute;
        left: .3rem;
        bottom: .2rem;
        z-index: 1;
        cursor: pointer;
    }

    .custom-file-input {
        // display: inline-block !important;
        // cursor: pointer;
        // position: absolute;
        // z-index: unset;
        // width: unset;
        // height: unset;
        // margin: unset;
        // overflow: unset;
        // opacity: unset;
    }

    .image-upload-icon {
        // position: absolute;
        // left: 2rem;
        // bottom: .55rem;
        height: .9rem;
        cursor: pointer;
    }

    .emoji-picker-trigger {
        //   position: absolute;
        right: 0rem;
        bottom: 0rem;
        z-index: 1;
        cursor: pointer;
        background: rgba(0, 0, 0, 0);
        border: none;
        color: white;
        font-size: 1.5rem;
        // border: 1px solid red;
    }

    .emoji-search-container {
        background-color: rgba(96, 72, 117, 0.7);
        backdrop-filter: blur(5px);
        padding: .5rem .5rem .5rem .5rem;
        border-radius: 5px 5px 0px 0px;
        // border: 1px solid white;
    }

    .emoji-search {
        background: transparent;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, .4);
        color: white;
        position: relative;
        background: url('@/assets/images/icons/search.png') no-repeat scroll 7px 7px;
        background-size: .5rem;
        background-position: 10px 7px;
        font-size: .7rem;
        padding: 5px 5px 5px 23px;
        min-width: 32rem;
        max-width: 35rem;
    }

    .input-emojis-container {
        height: 10rem;
        z-index: 1;
        position: absolute;
        bottom: 20rem;
        min-width: 32rem;
        max-width: 35rem;
    }

    .emojis-container {
        //   max-width: 10rem;
        border-radius: 0px 0px 5px 5px;
        min-width: 32rem;
        max-width: 35rem;
    }

    .emoji-category-container {
        z-index: 1;
        padding: .5rem;
        //   max-width: 10rem;
        background-color: rgba(96, 72, 117, 0.7);
        backdrop-filter: blur(5px);

        .category-heading {
            font-weight: 600;
            font-size: .8rem;
            margin-bottom: .5rem;
            text-transform: uppercase;
        }

        div {
            // max-width: 10rem;
            display: flex;
            gap: 2px;
            flex-wrap: wrap;
            cursor: pointer;
        }
    }

    .message-send-button {
        font-size: 1rem;
        text-transform: uppercase;
        color: white;
        background-color: transparent;
        background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
        border-radius: 5px;
        padding: .5rem 2rem .5rem 2rem;
        margin-right: 1rem;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        cursor: pointer;
    }

    textarea,
    textarea::placeholder {
        color: $atmo-white--medium-faded;
        font-family: 'Roboto', sans-serif;
        font-size: .8rem;
        font-weight: 400;
    }
}
</style>