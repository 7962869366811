import Axios from 'axios';

export function callUserAmiProfilesIndex(queryParams = {}) {
    return Axios.get(`/api/v1/user_ami_profiles`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserAmiProfilesShow(profileId, queryParams = {}) {
    return Axios.get(`/api/v1/user_ami_profiles/${profileId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUserAmiProfilesUpdate(profileId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/user_ami_profiles/${profileId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}