<template>
  <div class="atmo-checkout__atmo-points">
    <div class="atmo-checkout__atmo-points-actions">
      <span>Have Atmo Points?</span>
      <button class="atmo-button atmo-button--secondary atmo-checkout__atmo-points-redemption-button" @click="redeemAtmoPoints">Redeem Now</button>
      <button v-if="atmoPointsActive" class="atmo-button atmo-button--secondary atmo-checkout__atmo-points-reset-button" @click="resetAtmoPoints">Reset Points</button>
    </div>
    <div v-if="atmoPointsActive" class="atmo-checkout__atmo-points-redemption">
      <span>({{ availablePoints }} Points available)</span>
      <input v-model="numberOfPoints" type="number" :max="availablePoints" placeholder="# points" class="atmo-input atmo-checkout__input atmo-checkout__input--points" @input="validatePoints" />
      <span class="atmo-checkout__equals">=</span>
      <div class="atmo-checkout__point-value">
        <span v-if="numberOfPoints > 0">{{ returnCurrencyFormat(computedPointValue) }}</span>
        <span v-else>$ USD</span>
      </div>
      <button class="atmo-button atmo-button--secondary atmo-checkout__apply-points-button" @click="applyAtmoPoints">Apply</button>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { callPointsTransactionsIndex } from "@/helpers/axiosCalls";

export default {
  setup() {
    const store = useStore();
    const userId = computed(() => store.state.currentUserId);
    const atmoPointsActive = ref(false);
    const availablePoints = ref(0);
    const numberOfPoints = ref(0);
    const pointValue = ref(1000); // 1000 points = 1 dollar
    const computedPointValue = computed(() => numberOfPoints.value / pointValue.value);
    const usedPoints = computed(() => numberOfPoints.value);

    const redeemAtmoPoints = () => {
      atmoPointsActive.value = !atmoPointsActive.value;
    };

    const applyAtmoPoints = () => {
      if (numberOfPoints.value <= availablePoints.value) {
        const discountAmount = computedPointValue.value;
        store.dispatch("payments/setDiscount", discountAmount);
        store.commit("payments/setUsedPoints", numberOfPoints.value); // Set points used in checkoutDetails
      } else {
        window.alert("You cannot use more points than available.");
      }
    };

    const resetAtmoPoints = () => {
      numberOfPoints.value = 0;
      store.dispatch("payments/setDiscount", 0); // Reset discount
    };

    const validatePoints = () => {
      if (numberOfPoints.value > availablePoints.value) {
        numberOfPoints.value = availablePoints.value;
      }
    };

    onMounted(async () => {
      if (userId.value) {
        try {
          const response = await callPointsTransactionsIndex(userId.value);
          availablePoints.value = response.total_points;
        } catch (error) {
          console.error("Failed to fetch points:", error);
        }
      }
    });

    return {
      atmoPointsActive,
      availablePoints,
      numberOfPoints,
      pointValue,
      computedPointValue,
      redeemAtmoPoints,
      applyAtmoPoints,
      resetAtmoPoints,
      validatePoints,
    };
  },
  methods: {
    returnCurrencyFormat(value) {
      return `$${value.toFixed(2)}`;
    },
  },
};
</script>
