<!-- TODO: Come up with what should be in settings? -->

<!-- <template>
  <div id="prefPage" class="page">
    <h1>Settings Vue</h1>
    <div id="settings-page">
      <p class="settings-title">
        settings
        <a class="roundCornerLink" href>See Other Settings</a>
      </p>
      <div class="settings-container">
        <p>
          Keep me posted on
          <strong>these</strong> genres of music
        </p>
        <p>I would like to see the following on my homepage</p>
        <ul>
          <li>Music News Articles</li>
          <li>New Album/Song Releases</li>
          <li>Favorite Artist Tour Dates</li>
          <li>New Items In Online Store</li>
          <li>Contest Giveaways + Bonus Offers</li>
        </ul>
        <p>I would like songs automatically imported to my Library when</p>
        <ul>
          <li>
            When a song gets
            <input
              name="songdownloads"
              type="text"
              value="Write Number"
              size="9"
              maxlength="25"
            > downloads
          </li>
          <li>
            When an album gets
            <input
              name="albumsdownloads"
              type="text"
              value="Write Number"
              size="9"
              maxlength="25"
            > downloads
          </li>
          <li>When a song reaches the Top Charts</li>
          <li>
            Anytime a song comes out in
            <strong>these</strong> genres
          </li>
          <li>
            Anytime
            <input
              name="artistrelease"
              type="text"
              value="Write Name"
              size="9"
              maxlength="25"
            > puts out a song
          </li>
        </ul>
      </div>
    </div>
  </div>
</template> -->

<template>
  <div class="settings" data-cy="settings">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">
        Settings
      </h2>
    </div> -->
    <div class="settings-grid">
      <div class="left-column">
        <div class="left-column__settings-links-wrap">
          <router-link v-if="userProfileId" class="settings-links-wrap__settings-link" :to="{ name: 'user_profiles.edit', params: { userProfileId: userProfileId } }">
            <img class="settings-link__image" src="@/assets/images/icons/profile/edit.png" />
            <p class="settings-link__text">Profile Settings</p>
          </router-link>
          <router-link class="settings-links-wrap__settings-link" :to="{ name: 'AMI.settings' }">
            <img class="settings-link__image" src="@/assets/images/icons/atmo_logo.png" />
            <p class="settings-link__text">AMI Settings</p>
          </router-link>
        </div>
        <div class="left-column__recommend-genres-container">
          <div class="recommend-genres-container__heading-wrap">Recommend me these genres:</div>
          <div class="recommend-genres-container__search-container">
            <input class="search-field-rounded" type="text" placeholder="Search genres" />
            <div class="search-container__text">No Genres Selected!</div>
          </div>
        </div>
        <div class="left-column__notifications-container">
          <div class="notifications-container__heading-wrap">I would like to see the following in my notifications:</div>
          <div class="notifications-container__checkbox-group-wrap">
            <!-- <div class="checkbox-group-wrap__checkbox-wrap">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1">
              <label class="checkbox-wrap__label" for="option1">Music News Articles</label>
            </div> -->
            <!-- <div class="checkbox-group-wrap__checkbox-wrap">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1">
              <label class="checkbox-wrap__label" for="option1">Blocked Users</label>
            </div> -->
            <!-- <div class="checkbox-group-wrap__checkbox-wrap">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1">
              <label class="checkbox-wrap__label" for="option1">Friend Activity (whitelist or blacklist? do we need unfollow?)</label>
            </div> -->
            <div class="checkbox-group-wrap__checkbox-wrap">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
              <label class="checkbox-wrap__label" for="option1">Concerts</label>
            </div>
            <div class="checkbox-group-wrap__checkbox-wrap">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
              <label class="checkbox-wrap__label" for="option1">Events</label>
            </div>
            <div class="checkbox-group-wrap__checkbox-wrap">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
              <label class="checkbox-wrap__label" for="option1">Accomplishments</label>
            </div>
            <div class="checkbox-group-wrap__checkbox-wrap">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
              <label class="checkbox-wrap__label" for="option1">Contests</label>
            </div>
            <div class="checkbox-group-wrap__checkbox-wrap">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
              <label class="checkbox-wrap__label" for="option1">New Album/Song Releases</label>
            </div>
            <div class="checkbox-group-wrap__checkbox-wrap">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
              <label class="checkbox-wrap__label" for="option1">Favorite Artist Tour Dates</label>
            </div>
            <div class="checkbox-group-wrap__checkbox-wrap">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
              <label class="checkbox-wrap__label" for="option1">New Items in Online Store</label>
            </div>
          </div>
        </div>
      </div>
      <div class="right-column">
        <div class="right-column__option-container">
          <div class="check-box-wrap__option-label">I would like songs automatically imported to my library:</div>
        </div>
        <div class="right-column__option-container">
          <div class="option-container__check-box-wrap">
            <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
            <label class="check-box-wrap__option-label" for="option1">When a song gets a specific number of plays</label>
          </div>
          <div>
            <input class="input-field-slightly-rounded" type="text" placeholder="Number of plays" />
          </div>
        </div>
        <div class="right-column__option-container">
          <div class="option-container__check-box-wrap">
            <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
            <label class="check-box-wrap__option-label" for="option1">When an album gets a specific number of plays</label>
          </div>
        </div>
        <div class="right-column__option-container">
          <div class="option-container__check-box-wrap">
            <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
            <label class="check-box-wrap__option-label" for="option1">When a song reaches these charts (Most Played, Top Voted, or Top 2023)</label>
          </div>
          <div class="option-container__no-background-container">
            <div class="checkbox-wrap-with-background">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
              <label class="" for="option1">Most played</label>
            </div>
            <div class="checkbox-wrap-with-background">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
              <label class="" for="option1">Top Voted</label>
            </div>
            <div class="checkbox-wrap-with-background">
              <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
              <label class="" for="option1">Top 2023</label>
            </div>
          </div>
        </div>
        <div class="right-column__option-container">
          <div class="option-container__check-box-wrap">
            <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
            <label class="check-box-wrap__option-label" for="option1">Anytime a song comes out in these genres</label>
          </div>
          <div class="option-container__background-container">
            <input class="search-field-rounded" type="text" placeholder="Search genres" />
            <div class="background-container__options-wrap">
              <div class="checkbox-wrap-with-background">
                <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
                <label class="" for="option1">Hip-hop</label>
              </div>
              <div class="checkbox-wrap-with-background">
                <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
                <label class="" for="option1">Indie rock</label>
              </div>
            </div>
          </div>
        </div>
        <div class="right-column__option-container">
          <div class="option-container__check-box-wrap">
            <input class="checkbox" type="checkbox" id="option1" name="option1" value="option1" />
            <label class="check-box-wrap__option-label" for="option1">Any time these artists release a new song</label>
          </div>
          <div class="option-container__background-container">
            <input class="search-field-rounded" type="text" placeholder="Search artists" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {};
  },
  created() {
    this.$store.commit("setHudHeader", "Settings");
  },
  methods: {},
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.settings {
  margin: 0 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header-container {
    display: flex;
    // justify-content: center;
    align-items: center;
  }

  .settings-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    // border: 1px solid white;
    max-width: 60rem;
  }

  .left-column {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
  }

  .left-column__settings-links-wrap {
    display: flex;
    margin-bottom: 1rem;
  }

  .settings-links-wrap__settings-link {
    background: $atmo-purple--medium-dark;
    border-radius: 5px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 500;
  }

  .settings-links-wrap__settings-link + .settings-links-wrap__settings-link {
    margin-left: 1rem;
  }

  .settings-link__image {
    height: 1rem;
    width: auto;
    margin-right: 0.5rem;
  }

  .settings-link__text {
    margin-top: 1px;
  }

  .left-column__recommend-genres-container {
  }

  .recommend-genres-container__heading-wrap {
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .recommend-genres-container__search-container {
    background: rgba(216, 216, 216, 0.1);
    padding: 1rem;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 20rem;
    margin-bottom: 1rem;

    .search-container__search-field {
      background: transparent;
      border-radius: 20px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      color: white;
      margin-bottom: 1rem;
      width: 100%;
    }

    .search-container__text {
      text-transform: uppercase;
      color: $atmo-white--medium-faded;
    }
  }

  .left-column__notifications-container {
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .notifications-container__heading-wrap {
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .notifications-container__checkbox-group-wrap {
  }

  .checkbox-group-wrap__checkbox-wrap {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }

  label {
    margin-top: 5px;
    height: 1rem;
  }

  .right-column__option-container {
    margin-bottom: 1rem;
  }

  .option-container__check-box-wrap {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;
  }

  .check-box-wrap__option-label {
    font-weight: 500;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.4);
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.4);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.4);
  }

  .input-field-slightly-rounded {
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    color: white;
  }

  .search-field-rounded {
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    margin-bottom: 1rem;
    width: 100%;
    position: relative;
    background: url("@/assets/images/icons/search.png") no-repeat scroll 7px 7px;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
  }

  .option-container__no-background-container {
    display: flex;
    padding: 1rem;
  }

  .option-container__background-container {
    background: rgba(216, 216, 216, 0.1);
    border-radius: 5px;
    padding: 1rem;
  }

  .background-container__options-wrap {
    display: flex;
  }

  .checkbox-wrap-with-background {
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .checkbox-wrap-with-background + .checkbox-wrap-with-background {
    margin-left: 1rem;
  }

  //https://www.sliderrevolution.com/resources/css-checkbox/
  input[type="checkbox"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 4px;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: "";
      display: block;
      top: 2px;
      left: 6px;
      width: 4px;
      height: 10px;
      border-style: solid;
      border-color: $atmo-purple--dark;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border-color: $atmo-blue--medium;
      background: $atmo-blue--medium;

      &::before {
        opacity: 1;
      }

      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .checkbox {
    margin-right: 0.5rem;
    cursor: pointer;
  }
}
</style>
