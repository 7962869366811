import {
    callSongReferencesIndex,
    callSongReferencesCreate,
    callSongReferencesDelete,
} from '@/helpers/axiosCalls';

export default {
    namespaced: true,
    state: {
        debug: false,
        libraryLoading: true,
        librarySongs: [],
        library: [],
        miniLibraryCurrentView: 'songs', // Default view
        libraryOpen: false,
    },
    mutations: {
        toggleLibraryOpen(state) {
            state.libraryOpen = !state.libraryOpen;
            if (state.debug) console.log('Mutation: toggleLibraryOpen:', state.libraryOpen);
        },
        setLibrary(state, librarySongs) {
            if (state.debug) console.log('Mutation: setLibrary - librarySongs:', librarySongs);
            state.librarySongs = librarySongs;
            state.library = librarySongs.map((librarySong) => librarySong.song);
            state.libraryLoading = false;
        },
        setLibraryLoading(state, isLoading) {
            if (state.debug) console.log('Mutation: setLibraryLoading - isLoading:', isLoading);
            state.libraryLoading = isLoading;
        },
        changeMiniLibraryView(state, newView) {
            if (state.debug) console.log('Mutation: changeMiniLibraryView - newView:', newView);
            state.miniLibraryCurrentView = newView;
        },
    },
    actions: {
        async getLibrary({ commit, state, rootState }) {
            if (state.debug) console.log('Action: getLibrary - rootState.currentUserId:', rootState.currentUserId);
            commit('setLibraryLoading', true);
            try {
                const librarySongs = await callSongReferencesIndex({
                    songable_id: rootState.currentUserId,
                    songable_type: 'Library',
                    length: 20
                });                
                if (state.debug) console.log('Action: getLibrary - librarySongs fetched:', librarySongs);
                commit('setLibrary', librarySongs);
            } catch (error) {
                console.error('Action: getLibrary - Failed to fetch library:', error);
                commit('setLibraryLoading', false);
            }
        },
        async addToLibrary({ state, rootState, dispatch }, song) {
            if (state.debug) console.log('Action: addToLibrary - song:', song);
            try {
                await callSongReferencesCreate({
                    songable_id: rootState.currentUserId,
                    songable_type: 'Library',
                    song_id: song.id,
                    user_id: rootState.currentUserId,
                });
                if (state.debug) console.log('Action: addToLibrary - Song added to library');
                dispatch('getLibrary');
            } catch (error) {
                console.error('Action: addToLibrary - Failed to add song to library:', error);
            }
        },
        async removeFromLibrary({ state, dispatch }, song) {
            if (state.debug) console.log('Action: removeFromLibrary - song:', song);
            const librarySong = state.librarySongs.find(
                (librarySong) => librarySong.song.id === song.id
            );
            if (librarySong) {
                try {
                    await callSongReferencesDelete(librarySong.id);
                    if (state.debug) console.log('Action: removeFromLibrary - Song removed from library');
                    dispatch('getLibrary');
                } catch (error) {
                    console.error('Action: removeFromLibrary - Failed to remove song from library:', error);
                }
            } else {
                console.error('Action: removeFromLibrary - Song not found in library');
            }
        },
        changeMiniLibraryView({ commit, state }, newView) {
            if (state.debug) console.log('Action: changeMiniLibraryView - newView:', newView);
            commit('changeMiniLibraryView', newView);
        },
    },
    getters: {
        library: (state) => {
            if (state.debug) console.log('Getter: library - data:', state.library);
            return state.library;
        },
        libraryIsEmpty: (state) => {
            const isEmpty = state.library.length === 0;
            if (state.debug) console.log('Getter: libraryIsEmpty - result:', isEmpty);
            return isEmpty;
        },
        libraryLoading: (state) => {
            if (state.debug) console.log('Getter: libraryLoading - state:', state.libraryLoading);
            return state.libraryLoading;
        },
        miniLibraryCurrentView: (state) => {
            if (state.debug) console.log('Getter: miniLibraryCurrentView - state:', state.miniLibraryCurrentView);
            return state.miniLibraryCurrentView;
        },
    },
};
