<template>
  <div class="content atmo-single-purchase">
    <!-- <h2 class="atmo-page-header">Atmo Purchase</h2> -->
    <div class="atmo-cart__body">
      <aside class="atmo-cart__sidebar">
        <div class="atmo-charts-show__header">
          <span class="atmo-breadcrumbs router-link-active" @click="() => $router.go(-1)">
            <img src="@/assets/images/icons/back.png" />
            <span>Back to Purchases</span>
          </span>
        </div>
        <div class="atmo-cart__attributes">
          <div v-if="purchase.transaction_type === 'store'" class="atmo-cart__attribute">
            <div class="atmo-cart__attribute-label">Order No.</div>
            <div class="atmo-cart__attribute-value">{{ purchase.order_id }}</div>
          </div>
          <div class="atmo-cart__attribute">
            <div class="atmo-cart__attribute-label">Date Of Purchase</div>
            <div class="atmo-cart__attribute-value">{{ formatDate(purchase.created_at) }}</div>
          </div>
          <div class="atmo-cart__attribute atmo-cart__attribute--total">
            <div class="atmo-cart__attribute-label">Total</div>
            <div class="atmo-cart__attribute-value">${{ formatAmount(purchase.amount) }}</div>
          </div>
        </div>
      </aside>

      <perfect-scrollbar class="atmo-purchase__overview">
        <StorePurchase v-if="purchase.transaction_type === 'store'" :purchase="purchase" />
        <EventPurchase v-else-if="purchase.transaction_type === 'event'" :purchase="purchase" />
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import Moment from "moment";
import { callTransactionsShow } from "@/helpers/axiosCalls";
import StorePurchase from "@/routes/purchases/store-purchase.vue";
import EventPurchase from "@/routes/purchases/event-purchase.vue";

export default {
  components: { StorePurchase, EventPurchase },
  data: () => ({
    purchase: {},
    error: false,
  }),
  created() {
    this.getTransaction();
    this.$store.commit("setHudHeader", "Atmo Purchase");
  },
  methods: {
    formatAmount(value) {
      return (value / 100).toFixed(2); // Converts cents to dollars
    },
    formatDate(value) {
      return Moment(value).format("L");
    },
    getTransaction() {
      callTransactionsShow(this.$store.state.currentUserId, this.$route.params.id)
        .then((response) => {
          this.purchase = response;
        })
        .catch((error) => {
          console.error("Error fetching transaction:", error);
          this.error = true;
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.content.atmo-single-purchase {
  .atmo-cart__sidebar {
    flex: 1 1 40%;
  }
  .atmo-page-header {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .atmo-cart__body {
    padding: 0 40px;
    overflow: hidden;
  }
  .atmo-purchase {
    max-width: 100%;
    min-height: 1px;
    flex: 1 1 calc(60% - 30px);
    margin-left: 30px;
    &__overview {
      flex: 1 1 calc(60% - 30px);
      margin-left: 30px;
      overflow-y: auto;
      height: 100%;
    }
    &__card {
      flex: 1;
      box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.25);
      background-image: linear-gradient(-270deg, rgba($atmo-purple--light, 0.2) 0%);
      border-radius: 4px;
      padding: 7px;
      margin-bottom: 20px;
    }
    &__layout {
      display: flex;
    }
    &__image-container {
      flex: 1 1 20%;
      margin-right: 20px;
      box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.25);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      max-height: 100px;
      height: 100px;
      max-width: 95px;
      border-radius: 8px;
    }
    &__items {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }

    &__content {
      flex: 1;
      padding-right: 10px;
    }
    &__title {
      font-size: 15px;
      font-weight: 500;
      padding: 5px 0;
      border-bottom: 1px solid rgba($atmo-purple--light, 0.4);
      width: 100%;
    }
    &__item-attributes {
      display: flex;
      text-align: center;
      flex-direction: column;
      padding: 15px;
    }
    &__item-attribute {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: capitalize;
      text-align: center;
      &:first-child {
        margin-bottom: 15px;
        font-size: 12px;
      }
      &__color {
        height: 8px;
        width: 8px;
        border-radius: 100%;
        border: solid 1px white;
        margin-right: 5px;
      }
      font-weight: 500;
      font-size: 14px;
      .total {
        color: $atmo-gray--dark;
      }
    }
  }
}
</style>
