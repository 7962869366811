import { Howl, Howler } from "howler";

class HowlerManager {
  constructor() {
    this.songHowls = new Map();
  }

  getSongDuration(song) {
    const howl = this.getHowl(song);
    return howl ? howl.duration() : 0;
  }

  createHowl(song) {
    if (!this.songHowls.has(song.id)) {
      const howl = new Howl({
        src: [song.url],
        preload: true,
        onend: () => this.handleSongEnd(song),
      });
      this.songHowls.set(song.id, howl);
    }
    return this.songHowls.get(song.id);
  }

  handleSongEnd(song) {
    const store = require('@/store').default;
    const { repeatMode, autoplayEnabled } = store.state.player;
    const queueMode = store.state.atmoQueues.queueMode;
  
    if (queueMode !== 'queue' && queueMode !== 'radio') return;
  
    if (repeatMode === 'one') {
      const howl = this.getHowl(song);
      if (howl) {
        howl.seek(0);
        howl.play();
        store.commit("player/setIsPlaying", true);
      }
    } else if (autoplayEnabled && (repeatMode === 'all' || repeatMode === 'none')) {
      store.dispatch('player/playNextTrack');
    } else if (!autoplayEnabled) {
      store.commit('player/resetPlayer');
    }
  }

  getHowl(song) {
    const howl = this.songHowls.get(song.id);
    if (!howl) {
      console.error(`No Howl instance found for song: ${song.id}`);
    }
    return howl;
  }

  playHowl(song) {
    const howl = this.getHowl(song);
    if (howl) {
      howl.play();
    }
    return howl;
  }

  stopHowl(song) {
    const howl = this.getHowl(song);
    if (howl) howl.stop();
  }

  pauseHowl(song) {
    const howl = this.getHowl(song);
    if (howl) howl.pause();
  }

  seekHowl(song, seekPosition) {
    const howl = this.getHowl(song);
    if (howl) {
      howl.seek(seekPosition);
    }
  }

  /**
 * Returns the current playback time (in seconds) of the song.
 * Uses Howl.seek() without an argument.
 */
  getCurrentTime(song) {
    const howl = this.getHowl(song);
    if (howl) {
      return howl.seek();
    }
    return 0;
  }  

  getVolume() {
    return Howler.volume();
  }

  updateVolume(volume) {
    Howler.volume(volume);
  }

  toggleMute(isMuted) {
    Howler.mute(isMuted);
  }
}

export default new HowlerManager();
