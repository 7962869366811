<template>
  <div class="ami-settings" data-cy="ami-settings">
    <div class="back-grid-wrap">
      <atmo-back-link></atmo-back-link>
      <div class="ami-settings-grid">
        <div class="ami-settings-grid__settings-container">
          <!-- AMI Mode Selection -->
          <div class="settings-container__mode-container">
            <div class="font-500 margin-bottom-1">AMI Mode</div>
            <div class="two-column-grid light-background">
              <div>
                <div class="margin-bottom-1 flex-align-row-vertically">
                  <input class="radio" type="radio" id="ami-speech" value="speech" v-model="amiSoundType" />
                  <label class="font-500" for="ami-speech"> AMI Speech Mode </label>
                </div>
                <div class="speech-fx-description-wrap">This option enables AMI's speech capabilities.</div>
              </div>
              <div>
                <div class="margin-bottom-1 flex-align-row-vertically">
                  <input class="radio" type="radio" id="ami-fx" value="fx" v-model="amiSoundType" />
                  <label class="font-500" for="ami-fx"> AMI FX Mode </label>
                </div>
                <div class="speech-fx-description-wrap">This option enables AMI's FX Mode with sound effects.</div>
              </div>
            </div>
          </div>

          <!-- User's Spoken Name -->
          <div class="settings-container__name-audio-container">
            <div class="name-audio-container__name-container">
              <label for="ami-name" class="font-500 margin-bottom-1"> What do you want AMI to call you? </label>
              <input class="input-field-slightly-rounded" type="text" id="ami-name" v-model="spokenName" placeholder="For example: Jackie" />
            </div>

            <!-- AMI Audio Mute Toggle -->
            <div>
              <div class="font-500 margin-bottom-1">AMI Audio</div>
              <div class="ami-audio-container">
                <div class="ami-audio-container__radio-wrap">
                  <input class="radio" type="radio" id="mute" :value="true" v-model="amiAudioMuted" />
                  <label class="font-500" for="mute">
                    Mute
                    <img class="radio-icon" src="@/assets/images/icons/settings/volume_off.png" />
                  </label>
                </div>
                <div class="ami-audio-container__radio-wrap">
                  <input class="radio" type="radio" id="unmute" :value="false" v-model="amiAudioMuted" />
                  <label class="font-500" for="unmute">
                    Unmute
                    <img class="radio-icon" src="@/assets/images/icons/settings/volume_up.png" />
                  </label>
                </div>
              </div>
            </div>
          </div>

          <!-- Save Changes Button -->
          <div class="settings-container__save-changes-container">
            <button class="save-changes-container__save-button" @click="saveUserAmiProfile">Save Changes</button>
          </div>
        </div>

        <!-- Additional Links -->
        <div>
          <div class="font-500 margin-bottom-1">More AMI Settings</div>
          <div class="ami-settings-grid__links-container dark-background">
            <router-link class="links-container__link-wrap" :to="{ name: 'AMI.command_docs' }">
              <div class="link-wrap__icon-wrap">
                <img class="icon-wrap__icon" src="@/assets/images/icons/settings/link.png" />
              </div>
              AMI Command Docs
            </router-link>
            <a class="links-container__link-wrap" :href="devCenterURL" target="_blank">
              <div class="link-wrap__icon-wrap">
                <img class="icon-wrap__icon" src="@/assets/images/icons/settings/link.png" />
              </div>
              AMI Development Center
            </a>
          </div>
        </div>
      </div>

      <!-- Deactivate Account Button -->
      <div class="settings-container__deactivate-account">
        <button class="deactivate-account-button" @click="confirmDeactivateAccount">Deactivate Account</button>
      </div>
    </div>
  </div>
</template>

<script>
import AtmoBackLink from "@/components/atmo-back-link";
import { callUserAmiProfilesShow, callUserAmiProfilesUpdate, callUsersDelete } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoBackLink,
  },
  data() {
    return {
      spokenName: "",
      amiSoundType: "fx",
      amiAudioMuted: false,
      devCenterURL: process.env.VUE_APP_AMI_DEV_APP_URL,
    };
  },
  created() {
    this.fetchUserAmiProfile();
    this.$store.commit("setHudHeader", "Settings");
  },
  methods: {
    async fetchUserAmiProfile() {
      try {
        const response = await callUserAmiProfilesShow(this.$store.state.currentUserId);
        this.spokenName = response.user_spoken_name || "";
        this.amiSoundType = response.ami_sound_type || "fx";
        this.amiAudioMuted = response.ami_audio_muted;
      } catch (error) {
        console.error("Error fetching AMI profile:", error);
      }
    },
    async saveUserAmiProfile() {
      try {
        await callUserAmiProfilesUpdate(this.$store.state.currentUserId, {
          method: "patch",
          url: `/api/v1/user_ami_profiles/${this.$store.state.currentUserId}`,
          user_ami_profile: {
            user_spoken_name: this.spokenName,
            ami_sound_type: this.amiSoundType,
            ami_audio_muted: this.amiAudioMuted,
          },
        });
        console.log("AMI profile updated successfully!");
      } catch (error) {
        console.error("Error updating AMI profile:", error);
      }
    },
    confirmDeactivateAccount() {
      if (window.confirm("Are you sure you want to deactivate your account? This action cannot be undone.")) {
        this.deactivateAccount();
      }
    },
    async deactivateAccount() {
      try {
        await callUsersDelete(this.$store.state.currentUserId);
        this.logout();
      } catch (error) {
        console.error("Error deactivating account:", error);
      }
    },
    logout() {
      localStorage.removeItem("token");
      this.$store.dispatch("resetStore");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.ami-settings {
  margin: 0 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1.5rem;
    margin-right: auto;
    color: white;
    // border: 1px solid white;
  }

  .back-container__icon-container {
    margin-right: 0.5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .light-background {
    background: rgba(216, 216, 216, 0.1);
    border-radius: 5px;
    padding: 1rem;
  }

  .dark-background {
    background: $atmo-purple--medium-dark--faded;
    border-radius: 5px;
    padding: 1rem;
  }

  .radio {
    margin-right: 0.5rem;
  }

  .font-500 {
    font-weight: 500;
  }

  .margin-bottom-1 {
    margin-bottom: 1rem;
  }

  .margin-bottom-2 {
    margin-bottom: 2rem;
  }

  .flex-align-row-vertically {
    display: flex;
    align-items: center;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.4);
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.4);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.4);
  }

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: "";
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;

      &::before {
        opacity: 1;
      }

      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .ami-settings-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 20rem;
    // border: 1px solid white;
    max-width: 70rem;
  }

  .two-column-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 1.5rem;
  }

  .three-column-grid {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 1.5rem;
  }

  // .ami-settings-grid__settings-container {
  //   max-width: 50rem;
  // }

  .ami-audio-container {
    display: flex;
    background: rgba(216, 216, 216, 0.1);
    border-radius: 5px;
    padding: 0.5rem;
    max-width: 12rem;
  }

  .ami-audio-container__radio-wrap {
    // border: 1px solid white;
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ami-audio-container__radio-wrap + .ami-audio-container__radio-wrap {
    margin-left: 1rem;
  }

  .radio-icon {
    // display: block;
    margin-left: 0.5rem;
    height: 0.7rem;
  }

  .settings-container__name-audio-container {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 2rem;
  }

  .name-audio-container__name-container {
    display: flex;
    flex-direction: column;
  }

  .speech-fx-description-wrap {
    margin-left: 1.6rem;
    color: $atmo-white--medium-faded;
  }

  .input-field-slightly-rounded {
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    color: white;
  }

  .ami-settings-grid__links-container {
    display: flex;
    flex-direction: column;
  }

  .links-container__link-wrap {
    color: white;
    font-weight: 500;
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .link-wrap__icon-wrap {
    border-radius: 100%;
    height: 1.2rem;
    width: 1.2rem;
    background: rgba(216, 216, 216, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }

  .icon-wrap__icon {
    height: 0.8rem;
    width: auto;
  }

  .save-changes-container__save-button {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-right: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    border: none;
    cursor: pointer;
  }

  .deactivate-account-button {
    margin-bottom: 1rem;
    font-size: 1rem;
    color: white;
    background-color: $atmo-invalid;
    border: none;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    text-transform: uppercase;
    float: right;
  }
}
</style>
