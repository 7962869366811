import Axios from 'axios';

export function callUsersSearch(queryParams = {}) {
    return Axios.get(`/api/v1/users/search`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUsersShow(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUsersUpdate(userId, requestData, queryParams = {}) {
    return Axios.patch(`/api/v1/users/${userId}`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUsersCreate(requestData, queryParams = {}) {
    return Axios.post(`/api/v1/users`, requestData, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callUsersDelete(userId, queryParams = {}) {
    return Axios.delete(`/api/v1/users/${userId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}