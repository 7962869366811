<template>
  <div class="atmo-events">
    <router-view v-if="isLoaded" />
    <div>
      <div>
        <!-- <div>
          <h2 class="atmo-page-header">
            Atmo events
          </h2>
        </div> -->
      </div>
      <atmo-back-link></atmo-back-link>
      <div>
        <div>
          <div class="atmo-events__create">
            <h3>Create Event</h3>
            <router-link :to="{ name: 'events.new' }">
              <img src="@/assets/images/icons/add.png" alt="Create new" data-cy="create-event" />
            </router-link>
          </div>
        </div>
      </div>
      <perfect-scrollbar>
        <div class="atmo-events__container admin">
          <div>
            <table class="atmo-table" data-cy="atmo-events-user-table">
              <tbody>
                <tr v-for="event in events" :key="event.id">
                  <td class="text-center atmo-table__edit-actions">
                    <atmo-popover placement="bottom">
                      <img slot="trigger" class="atmo-events__event-actions" src="@/assets/images/icons/kebab_menu.png" alt="actions" data-cy="event-actions-dropdown" />
                      <div slot="content">
                        <router-link :to="{ name: 'events.edit', params: { eventId: event.id } }" class="atmo-popover__dropdown-action" data-cy="edit-event">
                          <div class="atmo-popover__dropdown-action-image-container">
                            <img class="atmo-popover__dropdown-action-image" src="@/assets/images/icons/edit.png" alt="Edit Event" />
                          </div>
                          Edit event
                        </router-link>
                        <a class="atmo-popover__dropdown-action" data-cy="delete-event" @click="deleteEvent(event)">
                          <div class="atmo-popover__dropdown-action-image-container">
                            <img class="atmo-popover__dropdown-action-image" src="@/assets/images/icons/delete.png" alt="Delete event" />
                          </div>
                          Delete event
                        </a>
                      </div>
                    </atmo-popover>
                  </td>
                  <router-link :to="{ name: 'events.show', params: { eventId: event.id } }" class="atmo-table__tr">
                    <td class="text-center atmo-table__table-image" data-cy="atmo-table__event-image">
                      <img class="atmo-table__event-image" :src="event.image.url" />
                    </td>
                    <td class="atmo-table__event-name" data-cy="event-name">
                      {{ event.name }}
                    </td>
                    <td class="atmo-table__event-price" data-cy="event-price">
                      {{ getPriceRange(event.tickets) }}
                    </td>
                    <td class="atmo-table__event-address" data-cy="event-address">
                      {{ formatAddress(event.address) }}
                    </td>
                    <td class="atmo-table__event-date" data-cy="event-date">
                      {{ formatDate(event.datetime) }}
                    </td>
                  </router-link>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AtmoPopover from "@/components/atmo-popover";
import { formatDateToMonthDayYearHour, formatAddress, getTicketPriceRange } from "@/helpers/utilityFunctions";
import AtmoBackLink from "@/components/atmo-back-link";

export default {
  components: {
    AtmoPopover,
    AtmoBackLink,
  },
  computed: {
    ...mapState("events", ["isLoaded"]),
    ...mapState("events", ["events"]),
  },
  created() {
    this.loadEvents(this.$route.params.userId);
    this.$store.commit("setHudHeader", "Atmo Events");
  },
  methods: {
    ...mapActions("events", ["loadEvents", "deleteEvent"]),
    ...mapActions("events", ["loadEvents"]),
    formatDate(dateString) {
      return formatDateToMonthDayYearHour(dateString);
    },
    formatAddress(address) {
      return formatAddress(address);
    },
    getPriceRange(tickets) {
      return getTicketPriceRange(tickets);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.atmo-events {
  display: flex;
  justify-content: center;

  .atmo-events__container.admin {
    max-width: 1200px;
    display: flex;
    justify-content: center;

    table {
      font-size: $atmo-subtitle-size;
      font-weight: bold;
      width: 100%;

      td {
        padding: 15px 11px;
        vertical-align: middle;
        color: white;
      }

      tr:nth-child(odd) {
        background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
      }
    }
  }

  .atmo-table__tr {
    cursor: pointer;
  }
}

.atmo-events__create {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin-bottom: 1rem;

  img {
    padding-left: 14px;
    width: 35px;
  }
}

.atmo-events {
  &__event-actions {
    width: 6px;
  }
}

.atmo-table {
  &__edit-actions {
    width: 5%;
  }

  &__table-image {
    width: 5%;
  }

  &__event-name {
    width: 35%;
  }

  &__event-price {
    width: 15%;
  }

  &__event-address {
    width: 20%;
  }

  &__event-date {
    width: 20%;
  }

  &__event-image {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 1.25rem;
  }
}
</style>
