// src/cable-channels/liveListeningPlayer.js
import createChannel from "@/cable";

let callback;
let liveListeningChannel = null;

/**
 * Connects to the LiveListeningPlayerChannel for a given room.
 */
function connectToLiveListeningPlayerChannel(roomId) {
  console.log("[Connecting to LiveListeningPlayerChannel] Room ID:", roomId);
  liveListeningChannel = createChannel(`LiveListeningPlayerChannel`, roomId, {
    received(data) {
      console.log("[Live Listening Update Received]:", data);
      if (callback) callback(data);
    }
  });
}

/**
 * Sets a callback to be invoked whenever data is received.
 */
function setCallback(fn) {
  callback = fn;
}

/**
 * Processes the incoming playback update.
 * (This helper is provided in case you wish to call it from Vuex actions.)
 */
function handleLiveListeningUpdates(store, data) {
  console.log("[Live Listening Update Received]", data);
  console.log("[Current Vuex Store State]", store.state.liveListeningRooms);
  switch (data.event_type) {
    case "sync":
      console.log("[Syncing Playback]");
      store.commit("liveListeningRooms/setCurrentTrack", data.track);
      store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
      store.commit("liveListeningRooms/setPlaybackState", data.is_playing);
      break;
    case "play_pause":
      console.log("[Toggle Play/Pause] Is Playing:", data.is_playing);
      store.commit("liveListeningRooms/setPlaybackState", data.is_playing);
      store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
      // Also update track in case it’s needed
      store.commit("liveListeningRooms/setCurrentTrack", data.track);
      break;
    case "seek":
      console.log("[Seek Event] Timestamp:", data.timestamp);
      store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
      // Also update track in case it’s needed
      store.commit("liveListeningRooms/setCurrentTrack", data.track);
      break;
    case "update_track":
      console.log("[Remote Update Track]", data.track);
      // When receiving a remote track update, update the store.
      store.commit("liveListeningRooms/setCurrentTrack", data.track);
      store.commit("liveListeningRooms/setCurrentTimestamp", data.timestamp);
      store.commit("liveListeningRooms/setPlaybackState", data.is_playing);
      break;
    default:
      console.warn("Unhandled live listening event_type:", data.event_type);
  }
}

/**
 * Sends a track update message.
 * Call this only when the user explicitly changes the track.
 */
function sendTrackUpdate(roomId, track) {
  if (liveListeningChannel) {
    liveListeningChannel.send({
      event_type: "update_track", // this key is used on the Rails side to route the message
      room: roomId,
      track: track  // Ensure the track object includes at least an id and a url.
    });
  } else {
    console.error("Live listening channel is not connected.");
  }
}

/**
 * Sends a play/pause event.
 * Call this only when the user explicitly clicks play or pause.
 * Now includes the current track data.
 */
function sendPlaybackState(roomId, isPlaying, timestamp, track) {
  if (liveListeningChannel) {
    liveListeningChannel.send({
      event_type: "play_pause",
      room: roomId,
      is_playing: isPlaying, // Rails expects 'is_playing'
      timestamp: timestamp,
      track: track
    });
  } else {
    console.error("Live listening channel is not connected.");
  }
}

export {
  connectToLiveListeningPlayerChannel,
  setCallback,
  handleLiveListeningUpdates,
  sendTrackUpdate,
  sendPlaybackState
};
