<template>
  <div class="user-profile-photos-container">
    <!-- <div class="header-container">
            <h2 class="atmo-page-header">Photos</h2>
        </div> -->
    <atmo-back-link></atmo-back-link>
    <atmo-profiles-photos-grid :photoArray="userPhotos" />
  </div>
</template>

<script>
import AtmoProfilesPhotosGrid from "@/components/profiles/atmo-profiles-photos-grid.vue";
import AtmoBackLink from "@/components/atmo-back-link";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    AtmoProfilesPhotosGrid,
    AtmoBackLink,
  },
  computed: {
    ...mapGetters("profiles", {
      userPhotos: "getUserPhotos",
    }),
    userId() {
      return this.$route.params.userId;
    },
  },
  created() {
    this.fetchUserPhotos(this.userId);
    this.$store.commit("setHudHeader", "Photos");
  },
  methods: {
    ...mapActions("profiles", ["fetchUserPhotos"]),
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.user-profile-photos-container {
  margin: 0 75px;

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
  }

  .back-container__icon-container {
    margin-right: 0.5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }
}
</style>
