<template>
  <div class="user-subscriptions-container">
    <div
      v-for="plan in atmoPlans"
      :key="plan.id"
      :class="['user-subscriptions-container__plan', plan.name === 'Atmo VIP' ? 'user-subscriptions-container__paid-plan' : 'user-subscriptions-container__free-plan']"
    >
      <div :class="plan.name === 'Atmo VIP' ? 'paid-plan__title-container' : 'free-plan__title-container'">
        <div v-if="plan.name === 'Atmo VIP'" class="title-container__image-container">
          <img class="image-container__vip_label" src="@/assets/images/subscriptions_images/vip_label.png" />
        </div>
        <div :class="plan.name === 'Atmo VIP' ? 'title-container__header-container' : ''">
          <p :class="plan.name === 'Atmo VIP' ? 'title-container__paid-header' : 'title-container__free-header'">{{ plan.description }}</p>
          <p v-if="plan.name === 'Atmo VIP'" class="title-container__paid-subheader">${{ plan.monthly_price }}/mo, ${{ plan.annual_price }}/yr</p>
        </div>
      </div>
      <perfect-scrollbar>
        <div :class="['plan__content-container', plan.name === 'Atmo VIP' ? 'paid-plan__content-container' : '']">
          <div v-for="feature in plan.atmo_plan_features" :key="feature.id" :class="['content-container__row', feature.id % 2 === 0 ? '--dark' : '']">
            <div v-if="plan.name === 'Atmo VIP'" class="row__image-container">
              <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
            </div>
            <div class="row__text-container">{{ feature.description }}</div>
          </div>
        </div>
      </perfect-scrollbar>
      <div v-if="plan.name === 'Atmo VIP'" class="paid-plan__button-container">
        <button class="button-container__button subscription-select" @click="setPlanData(plan)">Select Now</button>
      </div>
    </div>
  </div>
</template>

<script>
import { callAtmoPlansIndex } from "@/helpers/axiosCalls";

export default {
  data() {
    return {
      atmoPlans: [],
    };
  },
  created() {
    this.fetchPlans();
  },
  methods: {
    async fetchPlans() {
      try {
        const response = await callAtmoPlansIndex();
        this.atmoPlans = response;
      } catch (error) {
        console.error("Failed to fetch plans:", error);
      }
    },
    setPlanData(plan) {
      const planData = {
        name: plan.name,
        options: [
          {
            name: "Yearly",
            amount: plan.annual_price,
          },
          {
            name: "Monthly",
            amount: plan.monthly_price,
          },
        ],
      };
      console.log("Setting plan data:", planData); // Check the data structure
      this.$store.dispatch("payments/setSelectedPlan", planData);
      this.$router.push({
        name: "subscriptions.user_subscriptions.new",
        params: { userProfileId: this.$store.state.currentUserProfileId },
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.user-subscriptions {
  .user-subscriptions-container {
    // border: 1px solid white;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .user-subscriptions-container__free-plan {
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.09);
  }

  .free-plan__title-container {
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }

  .title-container__free-header {
    font-size: 2rem;
  }

  .paid-plan__title-container {
    height: 5rem;
    background: $atmo-purple--dark;
    display: flex;
    // justify-content: center;
    align-items: center;
    position: relative;
  }

  .title-container__image-container {
    position: absolute;
    top: -4px;
    margin-left: 1rem;
  }

  .image-container__vip_label {
    height: 5rem;
  }

  .title-container__header-container {
    margin-left: 4rem;
  }

  .title-container__paid-header {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .title-container__paid-subheader {
    font-size: 1.5rem;
  }

  .content-container__row {
    display: flex;
  }

  .--dark {
    opacity: 0.5;
    background: #604875;
  }

  .paid-plan__content-container {
    background: rgba(96, 72, 117, 0.39);
    // box-shadow: 0 6px 6px 0 rgba(0,0,0,0.09);
  }

  .row__image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
  }

  .image-container__check {
    height: 10px;
  }

  .row__text-container {
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }

  .paid-plan__button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(96, 72, 117, 0.39);
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.09);
    padding: 0.5rem 0rem 1rem 0rem;
  }

  .button-container__button {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  }

  .subscription-select {
    border: none;
    cursor: pointer;
  }
}
</style>
