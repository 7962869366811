<template>
  <VueFinalModal
    id="directory-filter"
    ref="directoryFilterRef"
    title="Filter"
    content-class="atmo-modal-content atmo-modal atmo-directory-filter"
    overlay-class="atmo-modal-overlay"
    :esc-to-close="true"
    @opened="setInitialFormProps"
  >
    <header class="atmo-modal__header">
      <h2 class="atmo-directory-filter__h2">Filter By:</h2>
      <img class="atmo-modal__close" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Filter" @click="$refs.directoryFilterRef.hide()" />
    </header>
    <form class="atmo-form atmo-directory-filter__form" @submit.prevent="submitForm">
      <fieldset class="atmo-directory-filter__radio-group">
        <div class="atmo-form__row" :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'location' }">
          <div class="atmo-form__radio-container">
            <input type="radio" value="location" v-model="formSelectedFilterType" />
          </div>
          <multiselect
            v-model="formSelectedState"
            :options="stateOptions"
            :searchable="false"
            :show-labels="false"
            :close-on-select="true"
            placeholder="State"
            @select="onSelectState"
            @open="setFilterType('location')"
          />
          <multiselect
            v-model="formSelectedCity"
            :class="'atmo-form__multiselect--not-first-child'"
            :disabled="!formSelectedState"
            :options="cityOptions"
            :searchable="false"
            :show-labels="false"
            :close-on-select="true"
            placeholder="City"
            @open="setFilterType('location')"
          />
        </div>
        <div class="atmo-form__row">
          <div class="atmo-form__radio-container" />
          <hr class="atmo-form__hr" />
        </div>
        <div class="atmo-form__row" :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'profession' }">
          <div class="atmo-form__radio-container">
            <input type="radio" value="profession" v-model="formSelectedFilterType" />
          </div>
          <multiselect
            v-model="formSelectedProfession"
            :options="professionOptions"
            label="name"
            :searchable="true"
            :show-labels="false"
            :close-on-select="true"
            placeholder="Type a profession"
            @open="setFilterType('profession')"
          >
            <span slot="noResult"> No Professions found. </span>
          </multiselect>
        </div>
        <div class="atmo-form__row">
          <div class="atmo-form__radio-container" />
          <hr class="atmo-form__hr" />
        </div>
        <div class="atmo-form__row" :class="{ 'atmo-form__row--blurred': formSelectedFilterType !== 'price' }">
          <div class="atmo-form__radio-container">
            <input type="radio" value="price" v-model="formSelectedFilterType" />
          </div>
          <div class="atmo-directory-filter__slider-container">
            <label for="priceSelect" @click="setFilterType('price')"> Price ($/hr) </label>
            <VueSlider
              v-model="formSelectedPriceRange"
              :min="minPrice"
              :max="maxPrice"
              :enable-cross="false"
              :tooltip="true"
              :marks="{ [minPrice]: `${minPrice}`, [maxPrice]: `${maxPrice}` }"
              @drag-start="setFilterType('price')"
              @change="onPriceSliderChange"
            />
          </div>
        </div>
      </fieldset>

      <button type="submit" :disabled="!formSelectedFilterValue" class="atmo-button atmo-button--primary atmo-button--stretch">Search Now</button>
    </form>
  </VueFinalModal>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import { VueSlider } from "vue3-slider";

const MIN_PRICE = 0;
const MAX_PRICE = 1000;

export default {
  components: {
    Multiselect,
    VueSlider,
  },
  props: {
    selectedFilterType: { type: String, required: true },
    statesAndCities: { type: Object, required: true },
    professions: { type: Array, required: true },
    selectedState: { type: String, default: null },
    selectedCity: { type: String, default: null },
    selectedProfession: { type: Object, default: null },
    selectedMinPrice: { type: Number, default: null },
    selectedMaxPrice: { type: Number, default: null },
    onSubmit: { type: Function, required: true },
  },
  data() {
    return {
      priceFormatter: "${value}",
      minPrice: MIN_PRICE,
      maxPrice: MAX_PRICE,
      formSelectedMinPrice: null,
      formSelectedMaxPrice: null,
      formSelectedFilterType: "location",
      formSelectedState: null,
      formSelectedCity: null,
      formSelectedProfession: null,
    };
  },
  computed: {
    formSelectedFilterValue() {
      switch (this.formSelectedFilterType) {
        case "location":
          return this.formSelectedState;
        case "profession":
          return this.formSelectedProfession;
        case "price":
          return this.formSelectedPriceRange;
        default:
          return null;
      }
    },
    formSelectedPriceRange() {
      return [this.formSelectedMinPrice, this.formSelectedMaxPrice];
    },
    stateOptions() {
      return Object.keys(this.statesAndCities);
    },
    cityOptions() {
      return this.statesAndCities[this.formSelectedState] || [];
    },
    professionOptions() {
      return this.professions;
    },
  },
  methods: {
    setInitialFormProps() {
      this.formSelectedFilterType = this.selectedFilterType;
      this.formSelectedState = this.selectedState;
      this.formSelectedCity = this.selectedCity;
      this.formSelectedProfession = this.selectedProfession;
      this.formSelectedMinPrice = this.selectedMinPrice || this.minPrice;
      this.formSelectedMaxPrice = this.selectedMaxPrice != null ? this.selectedMaxPrice : this.maxPrice;
    },
    setFilterType(newFilterType) {
      this.formSelectedFilterType = newFilterType;
    },
    onSelectState(newState) {
      const original = this.formSelectedState;
      this.formSelectedState = newState;
      if (original !== newState) {
        this.formSelectedCity = "All Cities";
      }
    },
    onPriceSliderChange([newMin, newMax]) {
      this.setFilterType("price");
      this.formSelectedMinPrice = newMin;
      this.formSelectedMaxPrice = newMax;
    },
    submitForm(evt) {
      evt.preventDefault();
      this.onSubmit({
        filterType: this.formSelectedFilterType,
        state: this.formSelectedState,
        city: this.formSelectedCity,
        profession: this.formSelectedProfession,
        minPrice: this.formSelectedMinPrice,
        maxPrice: this.formSelectedMaxPrice,
      });
      this.$refs.directoryFilterRef.hide();
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-directory-filter {
  .atmo-modal__header {
    padding: 1rem;
  }

  &__h2 {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__form {
    margin: 0 20px 25px;
  }

  &__radio-group {
    margin-bottom: 50px;
  }

  .multiselect {
    flex: 1;
  }

  .atmo-form__row--blurred .asd__wrapper {
    display: none;
  }

  &__slider-container {
    display: flex;
    align-items: center;
    width: 100%;

    label {
      margin-right: 10px;
      text-transform: capitalize;
    }
  }
}
</style>
