<template>
  <div class="atmo-album-new">
    <!-- <h2 class="atmo-page-header">
      Add New Album
    </h2> -->
    <atmo-breadcrumbs class="atmo-album-show__breadcrumbs" component-type="router-link" :to="`/artist_profiles/${artistProfileId}/albums`" :breadcrumbs="['Back to Albums']" />
    <album-form :is-new="true" />
  </div>
</template>

<script>
import AtmoBreadcrumbs from "@/components/atmo-breadcrumbs";
import AlbumForm from "./album-form";

export default {
  components: {
    AtmoBreadcrumbs,
    AlbumForm,
  },

  computed: {
    artistProfileId() {
      return Number(this.$route.params.artistProfileId);
    },
    canEditAlbum() {
      const { currentUserProfileType, currentUserProfileId } = this.$store.state;
      return currentUserProfileType === "ArtistProfile" && currentUserProfileId === this.artistProfileId;
    },
  },

  created() {
    if (!this.canEditAlbum) {
      this.redirectToAlbumIndex();
    }
    this.$store.commit("setHudHeader", "Add New Album");
  },

  methods: {
    redirectToAlbumIndex() {
      this.$router.push({
        name: "albums.index",
        params: { artistProfileId: this.artistProfileId },
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-album-new {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
