<template>
    <div class="ops-page-list-item">
        <router-link :to="{
            name: 'ops_pages.show',
            params: { opsPageId: page.id }
        }" class="ops-page-list-item__image"
            :style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${getFeaturedImage(page.images, 'full')})` }">
            <div class="donation-progress">
                <div class="donation-progress__donation-progress-wrap">
                    <div class="donation-progress-wrap__donation-progress-meter">
                        <atmo-progress-bar :percent="calculatePercent(page)" />
                    </div>
                    <div class="donation-progress-wrap__donation-goal-text">
                        <div>
                            <p class="donation-progress-header">Raised</p>
                            <p>US $8,000</p>
                        </div>
                        <div>
                            <p class="donation-progress-header donation-blue">Goal</p>
                            <p>US ${{ page.goal }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
        <div class="ops-page-list-item__content-wrap">
            <div class="content-wrap__title-description-wrap">
                <div class="title-description-wrap__title-edit-popover">
                    <router-link :to="{
                        name: 'ops_pages.show',
                        params: { opsPageId: page.id }
                    }" class="title-edit-popover__title">
                        {{ page.campaign_name }}
                    </router-link>
                    <atmo-popover v-if="$store.state.currentUserId === page.user_id" placement="bottom">
                        <img slot="trigger" class="campaign-trigger" src="@/assets/images/icons/kebab_menu.png"
                            alt="actions">
                        <div slot="content">
                            <router-link :to="{ name: 'ops_pages.edit', params: { opsPageId: page.id } }"
                                class="atmo-popover__dropdown-action">
                                <div class="atmo-popover__dropdown-action-image-container">
                                    <img class="atmo-popover__dropdown-action-image"
                                        src="@/assets/images/icons/edit.png" alt="Edit campaign">
                                </div>
                                Edit campaign
                            </router-link>
                            <a class="atmo-popover__dropdown-action" @click="deleteCampaign(page.id)">
                                <div class="atmo-popover__dropdown-action-image-container">
                                    <img class="atmo-popover__dropdown-action-image"
                                        src="@/assets/images/icons/delete.png" alt="Delete campaign">
                                </div>
                                Delete campaign
                            </a>
                        </div>
                    </atmo-popover>
                </div>
                <div class="title-description-wrap__description">
                    {{ page.campaign_description }}
                </div>
            </div>
            <div class="content-wrap__author-date-wrap">
                <div class="author-date-wrap__author-wrap">
                    <img class="atmo-avatar atmo-avatar--extra-small"
                        :style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${getFeaturedImage(page.user.images, 'full')})` }">
                    <div class="">
                        {{ page.user.name }}
                    </div>
                </div>
                <div class="author-date-wrap__date-wrap">
                    {{ timeLeft }} Left
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { distanceInWordsStrict } from 'date-fns';
//import User from '@/models/user';
import devMockData from '@/devUtils/mockData.js';
import { getFeaturedImage } from '@/helpers/utilityFunctions';
import AtmoPopover from "@/components/atmo-popover";
import AtmoProgressBar from "@/components/atmo-progress-bar.vue";
import { mapState, mapActions } from "vuex";

export default {
    components: {
        AtmoPopover,
        AtmoProgressBar
    },
    props: {
        page: {
            type: Object,
            required: true
        }
    },
    created() { },
    data: function () {
        return {
            devMockData: devMockData,
        }
    },
    methods: {
        ...mapActions("ops", ["deletePage", "loadOpsPages"]),
        calculatePercent: function () {
            return 75; // TBA: should be (campaign.goal / aggregated donations) *100
        },
        async deleteCampaign(pageId) {
            await this.deletePage(pageId);
            await this.loadOpsPages();
        },
        getFeaturedImage(userImages, type) {
            return getFeaturedImage(userImages, type);
        }
    },
    computed: {
        ...mapState("ops", ["pages"]),
        artist() {
            return this.page.user;
        },
        timeLeft() {
            return distanceInWordsStrict(
                new Date(),
                new Date(this.page.end_date)
            )
        }
    }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.ops-page-list-item {
    display: flex;
    align-items: center;
    // min-height: 12rem;
    color: white;
    padding: .5rem 0rem .5rem 0rem;

    &__image {
        width: 17rem;
        min-height: 10rem;
        border-radius: 5px;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $atmo-gray--light;
        background-size: cover;
        position: relative;

        &:hover {
            .donation-progress {
                display: flex;
            }
        }
    }

    .donation-progress {
        // max-width: 15rem;
        background-color: rgba(0, 0, 0, .4);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1rem;
        border-radius: 5px;
        display: none;
    }

    .donation-progress__donation-progress-wrap {
        margin-top: auto;
        width: 100%;
    }

    .donation-progress-wrap__donation-progress-meter {
        margin-bottom: .5rem;
    }

    .donation-progress-wrap__donation-goal-text {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        // margin-bottom: .5rem;
        font-weight: bold;
    }

    .donation-progress-header {
        margin-bottom: .2rem;
    }

    .donation-blue {
        color: $atmo-blue--medium;
    }

    &__content-wrap {
        background-color: rgba(96, 72, 117, 0.4);
        backdrop-filter: blur(5px);
        border-radius: 0px 5px 5px 0px;
        min-height: 8rem;
        width: 100%;
        padding: 1.3rem 0rem 1.3rem 0rem;
    }

    .campaign-trigger {
        height: 1rem;
    }

    .content-wrap__title-description-wrap {
        margin: 0rem 1rem 1rem 1rem;
        // padding: 1rem 0rem 0rem 0rem;
        border-bottom: 1px solid $atmo-gray--medium;
    }

    .title-description-wrap__title-edit-popover {
        display: flex;
        justify-content: space-between;
    }

    .title-edit-popover__title {
        color: $atmo-blue--medium;
        font-size: 1.1rem;
        font-weight: 600;
        margin-bottom: .5rem;
    }

    .title-description-wrap__description {
        margin-bottom: .5rem;
    }

    .content-wrap__author-date-wrap {
        padding: 0rem 1rem 0rem 1rem;
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .author-date-wrap__author-wrap {
        display: flex;
        align-items: center;
        gap: .3rem;
        font-weight: 500;
    }

    .author-date-wrap__date-wrap {
        font-weight: 500;
    }

}
</style>