<template>
  <div :class="`atmo-popover ${arrow ? '' : 'hide-arrow'}`">
    <div :id="triggerId" class="atmo-popover__trigger" tabindex="0" @click="show = !show">
      <slot name="trigger" />
    </div>

    <VDropdown v-model="show" :placement="placement" :triggers="Array.isArray(triggers) ? triggers : [triggers]" :hide-arrow="!arrow" boundary="viewport" :container="triggerId">
      <template #dropdown>
        <div class="atmo-popover__content">
          <slot name="content" />
        </div>
      </template>
    </VDropdown>
  </div>
</template>

<script>
import { VDropdown } from "floating-vue";
import { v4 } from "uuid";

export default {
  components: { VDropdown },
  props: {
    placement: {
      type: String,
      default: "right",
    },
    triggers: {
      type: Array,
      default: () => ["click"], // Use an array by default
    },
    arrow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uid: v4(),
      show: false,
    };
  },
  computed: {
    triggerId() {
      return `atmo-popover__trigger--${this.uid}`;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.hide-arrow .arrow {
  visibility: hidden;
}
.atmo-popover {
  &__trigger {
    outline: none;
    cursor: pointer;
  }

  .popover {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
    font-weight: 300;
    background-color: $atmo-purple--medium-dark;
    border-radius: 5px;
    border: none;
    box-shadow: 1px 1px 8px 0px rgba(black, 0.3);
  }

  .popover-body {
    padding: 5px;
  }

  .bs-popover-top .arrow:after {
    border-top-color: $atmo-purple--medium-dark;
  }

  .bs-popover-right .arrow:after {
    border-right-color: $atmo-purple--medium-dark;
  }

  .bs-popover-bottom .arrow:after {
    border-bottom-color: $atmo-purple--medium-dark;
  }

  .bs-popover-left .arrow:after {
    border-left-color: $atmo-purple--medium-dark;
  }
}
</style>
