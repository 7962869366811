<template>
  <div id="vue-app">
    <atmo-hud></atmo-hud>
  </div>
</template>

<script>
import Axios from "axios";
// Main HUD component
import AtmoHud from "@/atmo-hud";
import "@/assets/stylesheets/_variables.scss";
import "@/assets/stylesheets/fonts.scss";
// Standalone vue component css
import "@/components/atmo-button.scss";
import "@/components/atmo-modal.scss";
import "@/components/atmo-table.scss";
import "@/components/atmo-form.scss";
import "@/components/atmo-empty-state.scss";
import "@/components/atmo-datepicker.scss";
import "@/assets/stylesheets/application.scss"; // NOT SURE THIS IS WHAT WE WANT -> BUT LOADING ALL SCSS HERE FIRST
// Config
import router from "@/routes";
import store from "@/store";

export default {
  name: "App",
  components: {
    AtmoHud,
  },
  router,
  store,
  created() {
    console.log("============== VUE CLIENT APP INIT ==============");
  }
};
</script>

<style>
  #vue-app {
    height: 100vh;
    width: 100vw;
  }
</style>
