<template>
    <VueFinalModal 
        id="choose-contest-winners" 
        title="Choose Contest Winners"
        content-class="atmo-modal-content atmo-modal choose-contest-winners" 
        ref="chooseWinnersRef" 
        v-model="localValue"
        overlay-class="atmo-modal-overlay"
        :esc-to-close="true"
        @opened="getContestPrizes"
        >
        <header class="atmo-modal__header">
            <span class="atmo-modal__header-side">
                <img class="atmo-modal__close" src="@/assets/images/icons/close--light.png"
                    @click="$refs.chooseWinnersRef.hide()">
            </span>
            <div class="atmo-page-header-container">
                <h2 class="atmo-page-header">
                    Choose Winner Prize
                </h2>
            </div>
            <span class="atmo-modal__header-side" />
        </header>
        <div class="choose-contest-winners__body">
            <!-- <button @click="updateWinner">Update Winner</button> -->
            <div class="body__prizes-container">
                <div class="prizes-container__prize-wrap" v-for="(prize, index) in prizes" :key="index"
                    @click="choosePrize(prize)">
                    <div v-if="prize.contest_prize_type.name == 'Merch'" class="prize-wrap__container">
                        <div class="prize-wrap__name">{{ prize.name }}</div>
                        <div class="prize-wrap__info">
                            <!-- <div>{{ prize.contest_prize_type.name }}</div> -->
                            <div>{{ prize.merch_description }}</div>
                            <!-- <div><img :src="prize.merch_image.url" /></div> -->
                        </div>
                    </div>
                    <div v-if="prize.contest_prize_type.name == 'Credits'" class="prize-wrap__container">
                        <div class="prize-wrap__name">{{ prize.name }}</div>
                        <!-- <div>{{ prize.contest_prize_type.name }}</div> -->
                        <div class="prize-wrap__info">
                            <div>${{ prize.credit_amount }} in Atmosphere credits</div>
                        </div>

                    </div>
                    <div v-if="prize.contest_prize_type.name == 'Other'" class="prize-wrap__container">
                        <div class="prize-wrap__name">{{ prize.name }}</div>
                        <!-- <div>{{ prize.contest_prize_type.name }}</div> -->
                        <div class="prize-wrap__info">
                            <div>{{ prize.other_description }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </VueFinalModal>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
    callContestPrizeWinnersCreate,
    callContestPrizesIndex
} from '@/helpers/axiosCalls';

export default {
    props: {
        value: Boolean, // v-model prop
        submission: Object
    },
    computed: {
        ...mapState('contests', ['contestPrizeWinners']),
        localValue: {
            get() {
                return this.value;
            },
            set(newValue) {
                // Emit input event to update the v-model value
                this.$emit('input', newValue);
            }
        }
    },
    data() {
        return {
            prizes: []
        }
    },
    methods: {
        ...mapActions('contests', ['fetchContestPrizeWinners']),
        async getContestPrizes() {
            try {
                const response = await callContestPrizesIndex(this.$route.params.contestId);

                this.prizes = response.sort((a, b) => {
                    // Convert strings to Date objects for comparison
                    const dateA = new Date(a.created_at);
                    const dateB = new Date(b.created_at);
                    // Sort by ascending order of created_at date
                    return dateA - dateB;
                });
            } catch (error) {
                console.error(error);
            }
        },
        choosePrize(prize) {
            console.log({
                contest_prize_id: prize.id,
                contest_submission_id: this.submission.id
            })
            callContestPrizeWinnersCreate(this.submission.contest.id, {
                contest_id: this.submission.contest.id,
                contest_prize_id: prize.id,
                contest_submission_id: this.submission.id
            })
                .then(async response => {
                    //FETCH CONTEST PRIZES AGAIN
                    console.log(response);
                    await this.fetchContestPrizeWinners(this.submission.contest.id);
                    this.$refs.chooseWinnersRef.hide();
                })
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/stylesheets/_variables.scss';

#choose-contest-winners {
    .atmo-modal-content {
        border-radius: 5px;
        border: none;
        // width: 100vw;
        // height: 97vh;
    }

    .ps {
        // min-height: 70vh;
    }
}

.choose-contest-winners {
    .modal-lg.modal-dialog {
        // max-width: 100vw;
        // margin: 1rem;
    }

    .atmo-modal__header {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        margin-top: 2rem;
    }

    .atmo-modal__header-side {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: .5rem;

        img {
            height: 1.5rem;
        }
    }

    .atmo-page-header-container {
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
            margin: 0;
        }
    }

    &__body {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
    }


    .body__prizes-container {
        margin-bottom: 2rem;
        width: 100%;
        min-height: 5rem;
    }

    .prizes-container__prize-wrap {
        margin-bottom: 1rem;
        border-radius: 5px;
        font-size: 1rem;
        cursor: pointer;
        background-color: rgba(255, 255, 255, .1);

        &:hover {
            background-color: rgba(255, 255, 255, .2)
        }
    }

    .prize-wrap__container {
        display: flex;
        gap: 1rem;
        // padding: 1rem;
    }

    .prize-wrap__name {
        padding: 1rem;
        background-color: $atmo-purple--dark;
        border-radius: 5px 0px 0px 5px;
        min-width: 8rem;
    }

    .prize-wrap__info {
        display: flex;
        align-items: center;
        padding: 1rem;
        font-size: .9rem;
    }

}
</style>