<template>
  <div class="ops-donation">
    <div class="ops-donation__donation-amount text-center">
      {{ returnCurrencyFormat(amount) }}
    </div>
    <div class="ops-donation__donation-description">
      {{ description }}
    </div>
    <button @click="navToCheckout" class="ops-donation__donate-amount text-center">Donate {{ returnCurrencyFormat(amount) }}</button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    amount: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      taxRate: 0.075,
    };
  },
  methods: {
    ...mapActions("ops", ["saveOpsPageDonationData"]),
    ...mapActions("payments", ["setCheckout"]),
    returnCurrencyFormat(number) {
      // Create number formatter (Javascript - Internationalization API)
      let formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        //These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501) // make validation to only make whole numbers
      });

      return formatter.format(number);
    },
    navToCheckout() {
      // Prepare data for ops page donation
      const opsPageDonationData = {
        campaign: this.campaign,
        amount: this.amount,
        description: this.description,
      };

      // Save data to Vuex store
      this.saveOpsPageDonationData(opsPageDonationData);

      console.log("TEST FOR SETH", {
        type: "ops_page",
        amount: parseFloat(this.amount),
        taxRate: this.taxRate,
        shippingCost: 0,
      });

      this.setCheckout({
        type: "ops_page",
        amount: parseFloat(this.amount),
        taxRate: this.taxRate,
        shippingCost: 0,
      });

      // Navigate to the donation page
      this.$router.push({ name: "ops_pages.donate" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.ops-donation {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);

  & + & {
    margin-top: 1.5rem;
  }

  &__donation-amount {
    padding: 5px 4px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 5px;
    background-color: $atmo-purple--medium-dark--faded;
    margin-bottom: 0.5rem;
    display: inline-block;
    align-self: middle;
    margin-bottom: 1rem;
  }

  &__donation-description {
    margin-bottom: 1rem;
  }

  &__donate-amount {
    font-size: 1rem;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    text-align: center;
    font-weight: 500;
    border: none;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 1rem;
  }
}
</style>
