<template>
  <!-- <atmo-music-items
    type="genres_moods"
    :current-page-number="Pagination_currentPageNumber"
    :max-page-number="Pagination_maxPageNumber"
    :items-for-current-page="Pagination_itemsForCurrentPage"
    :on-previous-click="Pagination_onPreviousClick"
    :on-next-click="Pagination_onNextClick"
  /> -->
  <div>
    <div v-if="isLoading">LOADING</div>
    <atmo-music-genres-moods-grid :genres-moods="genresMoods" />
  </div>
</template>
<script>
// import AtmoMusicItems from '@/components/atmo-music-items';
import PaginationMixin from '@/mixins/pagination';
import devMockData from '@/devUtils/mockData.js';
import AtmoMusicGenresMoodsGrid from '@/components/music/atmo-music-genres-moods-grid';
import {
  callGenresMoodsIndex
} from '@/helpers/axiosCalls';

export default {
  components: { AtmoMusicGenresMoodsGrid },
  mixins: [PaginationMixin],
  props: { showNewReleases: { type: Boolean, default: false } },
  data() {
    return {
      isLoading: true,
      genresMoods: [],
      highResImageArray: devMockData.highResImageArray
    }
  },
  created() {
    this.getGenres();
  },
  methods: {
    getGenres() {
      callGenresMoodsIndex()
      .then((response) => {
        this.genresMoods = response;
        console.log("this.genresMoods: ", this.genresMoods);
        this.isLoading = false;
      }).catch((error) => {
        console.error(error);
      });
    }
  }
}
</script>