<template>
  <div class="artist-donate-page">
    <!-- <h2 class="atmo-page-header artist-donate-page__header">Donate</h2> -->
    <perfect-scrollbar class="artist-donate-page__body">
      <section class="artist-donate-page__donation-amount">
        <div class="donation-amount__header">Donation Amount ($)</div>
        <input v-model="donationInput" type="number" placeholder="100" class="atmo-input atmo-checkout__input" @input="updateDonationAmount" />
      </section>
      <aside class="artist-donate-page__sidebar">
        <div class="artist-donate-page__attributes">
          <div class="artist-donate-page__attribute">
            <div class="artist-donate-page__attribute-label">Donation Amount</div>
            <div class="artist-donate-page__attribute-value">
              {{ donationAmount ? formatPriceAsCurrency(donationAmount) : "$0.00" }}
            </div>
          </div>
          <div class="artist-donate-page__attribute">
            <div class="artist-donate-page__attribute-label">Tax ({{ taxRate * 100 }}%)</div>
            <div class="artist-donate-page__attribute-value">
              {{ donationTax ? formatPriceAsCurrency(donationTax) : "$0.00" }}
            </div>
          </div>
          <div class="artist-donate-page__attribute artist-donate-page__attribute--total">
            <div class="artist-donate-page__attribute-label">Total</div>
            <div class="artist-donate-page__attribute-value">
              {{ totalCost }}
            </div>
          </div>
        </div>
        <div class="artist-donate-page__action-buttons">
          <router-link :to="{ name: 'products.index' }" class="artist-donate-page__action-button atmo-button atmo-button--tertiary">
            <span>Cancel</span>
          </router-link>
          <router-link :to="{ name: 'checkout', params: { checkoutType: 'donation' } }" class="artist-donate-page__action-button atmo-button atmo-button--primary"> Checkout </router-link>
        </div>
      </aside>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { formatPriceAsCurrency } from "@/helpers/utilityFunctions";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      donationInput: 0, // Holds the input value
      taxRate: 0.075, // Define tax rate here
    };
  },
  created() {
    this.$store.commit("setHudHeader", "Donate");
  },
  computed: {
    ...mapState("payments", {
      donationAmount: (state) => state.checkoutDetails.amount,
    }),

    donationTax() {
      return this.donationAmount * this.taxRate;
    },
    totalCost() {
      return formatPriceAsCurrency(this.donationAmount + this.donationTax);
    },
  },
  methods: {
    ...mapActions("payments", ["setCheckout"]),

    formatPriceAsCurrency(number) {
      return formatPriceAsCurrency(number);
    },
    updateDonationAmount() {
      // Dispatch the `setCheckout` action with donation details
      this.setCheckout({
        type: "donation",
        amount: parseFloat(this.donationInput),
        taxRate: this.taxRate,
        shippingCost: 0,
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.artist-donate-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  &__header {
    margin: 35px 0;
  }

  &__body {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-basis: 550px;
    flex-wrap: wrap;
  }

  &__donation-amount {
    flex-grow: 1;
    max-width: 600px;
    min-width: 350px;

    .atmo-input::placeholder {
      color: $atmo-gray--medium;
    }

    .donation-amount__header {
      margin-left: 0.1rem;
      font-size: 1rem;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }
  }

  &__empty-state {
    font-size: 1.5em;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }

  &__item {
    display: flex;
    background-color: rgba($atmo-purple--light, 0.4);
    margin: 5px 0;
    border-radius: 4px;
    padding: 4px;
  }

  &__item-image {
    height: 80px;
    width: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $atmo-purple--light;
    background-size: 100%;
    border-radius: 3px;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__item-header {
    display: flex;
    margin: 4px;
    margin-left: 8px;
  }

  &__item-title {
    flex-grow: 1;
    font-size: 1.1em;
    font-weight: 400;
  }

  &__delete-item {
    height: 10px;
    cursor: pointer;
  }

  &__item-hr {
    align-self: center;
    width: 95%;
    border: 0;
    height: 0;
    border-bottom: 1px solid $atmo-purple--light;
  }

  &__item-attributes {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    flex-grow: 1;
  }

  &__item-attribute {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  &__item-attribute-label {
    font-size: 0.8em;
  }

  &__item-attribute-value {
    font-size: 0.9em;
    margin-bottom: 5px;

    &--color {
      display: flex;
      font-size: 0.8em;
      align-items: baseline;
    }

    &--total-price {
      color: $atmo-purple--dark;
      font-weight: 600;
    }
  }

  &__color {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    border: 1px solid white;
    margin: 0 2px;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__attributes {
    display: flex;
    flex-direction: column;
    background-color: rgba($atmo-purple--dark, 0.8);
    border-radius: 5px;
  }

  &__attribute {
    display: flex;
    justify-content: space-between;
    font-size: 1.3em;
    text-transform: uppercase;
    padding: 17px 20px;
    border-bottom: 1px solid $atmo-purple--light;

    &:last-child {
      border-bottom: none;
    }

    &--total {
      color: $atmo-blue--medium;
      font-weight: 600;
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: space-evenly;
    margin: 15px 0;
    flex-wrap: wrap;
  }
}
</style>
