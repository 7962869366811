<template>
  <div class="atmo-contest-prize">
    <remove-winners-modal :prize-winners="winners" v-model="localModalVisible" />
    <div class="atmo-contest-prize__rank-container" :style="{ 'background-image': `${rankBackgroundImage}` }">
      <div class="atmo-contest-prize__rank">
        {{ prize.name }}
      </div>
    </div>
    <div class="atmo-contest-prize__info">
      <div class="atmo-contest-prize__name" @click="openRemoveWinnersModal()" :style="{ cursor: canRemoveWinners() ? 'pointer' : 'default' }">
        <perfect-scrollbar v-if="winners.length > 0">
          <div class="name__winner" v-for="winner in winners" :key="winner.id">
            <span>{{ winner.contest_submission.user.name }}</span>
          </div>
        </perfect-scrollbar>
        <div v-else>Not selected</div>
      </div>
    </div>
    <atmo-popover placement="bottom" :triggers="['hover']" class="atmo-contest-prize__see-prize">
      <a slot="trigger" class="atmo-contest-prize__see-prize-trigger"> See Prize </a>
      <div slot="content" class="atmo-contest-prize__see-prize-container">
        <div v-if="prizeImageSrc" class="atmo-contest-prize__see-prize-image-container">
          <img :src="prizeImageSrc" />
        </div>
        <div class="atmo-contest-prize__see-prize-description">
          {{ prizeDescription }}
        </div>
      </div>
    </atmo-popover>
  </div>
</template>

<script>
//import User from '@/models/user';
import AtmoPopover from "@/components/atmo-popover";
import RemoveWinnersModal from "@/modals/contests/remove-winners";

export default {
  components: { AtmoPopover, RemoveWinnersModal },

  props: {
    winners: {
      type: Array,
      required: true,
    },
    contest: {
      type: Object,
      required: true,
    },
    prize: {
      type: Object,
      required: true,
    },
    submissions: {
      type: Array,
      required: true,
    },
  },

  computed: {
    submission() {
      return this.submissions.find((submission) => {
        return Number(submission.id) === Number(this.prize.contest_submission_id);
      });
    },
    user() {
      return this.submission ? this.submission.user : null;
    },
    rankBackgroundImage() {
      if (this.user) {
        return `url(${this.user.profile_image.image.url})`;
      }
      return "none";
    },
    prizeImageSrc() {
      return this.prize.merch_image.url || this.prize.other_image.url;
    },
    prizeDescription() {
      const { credit_amount, merch_description, other_description } = this.prize;
      return credit_amount ? `${credit_amount} Atmosphere credits` : merch_description || other_description;
    },
  },
  data() {
    return {
      localModalVisible: false,
    };
  },
  async created() {},
  methods: {
    canRemoveWinners() {
      if (this.winners.length === 0) {
        return false;
      }

      if (this.$store.state.currentUserId !== this.contest.user_id) {
        return false;
      }

      return true;
    },
    openRemoveWinnersModal() {
      if (this.canRemoveWinners()) {
        this.localModalVisible = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-contest-prize {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 3rem;
  border-radius: 5px;
  background-color: rgba($atmo-purple--dark, 0.5);
  width: 100%;
  overflow: hidden;
  justify-content: space-between;

  .ps {
    max-height: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // align-items: center;
  }

  &__rank-container {
    background-color: $atmo-purple--dark;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 0.2rem 0rem 0.2rem;
  }

  &__rank {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    font-weight: 700;
    background-color: rgba($atmo-purple--dark, 0.7);
    min-width: 7rem;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__name {
    font-size: 0.8rem;
    font-weight: 500;
    padding: 0.5rem;
    cursor: pointer;
  }

  .name__winner {
    // display: inline-block;
    margin-bottom: 0.1rem;
  }

  &__file {
    font-size: 0.5rem;
  }

  &__see-prize-trigger {
    font-size: 0.8rem;
    color: white;
    padding-right: 8px;
    font-weight: 500;
  }

  &__see-prize.atmo-popover {
    .popover {
      background-color: white;
    }

    .bs-popover-bottom .arrow:after {
      border-bottom-color: white;
    }
  }

  &__see-prize-container {
    display: flex;
    align-items: center;
  }

  &__see-prize-image-container {
    height: 50px;

    img {
      height: 100%;
    }
  }

  &__see-prize-description {
    font-size: 0.7em;
    font-weight: 300;
    color: $atmo-purple--extra-dark;
    padding: 3px;
  }
}
</style>
