import Axios from "axios";

export function callAtmoPlansIndex(queryParams = {}) {
  return Axios.get(`/api/v1/atmo_plans`, { params: queryParams })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function callAtmoPlansShow(planId, queryParams = {}) {
  return Axios.get(`/api/v1/atmo_plans/${planId}`, { params: queryParams })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
}
