import Axios from 'axios';

export function callPointsTransactionsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/points_transactions`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}