import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import router from './routes';
import Axios from 'axios';
import { createI18n } from 'vue-i18n';
import Notifications from '@kyvg/vue3-notification';
import '@/assets/stylesheets/style.css';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
import 'vue3-perfect-scrollbar/style.css';
import { globalMixin } from './devUtils/devFunctions';
// import { createBootstrap } from 'bootstrap-vue-next'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'
import VideoBackground from 'vue-responsive-video-background-player'
import { vfmPlugin } from 'vue-final-modal'
import VueApexCharts from "vue3-apexcharts";
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'; 

Axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

const app = createApp(App);

app.component('video-background', VideoBackground);

app.mixin(globalMixin);

app.use(store);
app.use(router);

// Initialize VueI18n if needed
const i18n = createI18n({
    locale: 'en', // default language
    fallbackLocale: 'en', // fallback if a translation is missing
});

app.use(VueApexCharts);
app.use(i18n);
app.use(Notifications);
// app.use(createBootstrap())
app.use(PerfectScrollbarPlugin);
app.use(FloatingVue);

app.use(vfmPlugin({
    key: '$vfm',
    componentName: 'VueFinalModal',
    dynamicContainerName: 'ModalsContainer'
}));

app.mount('#vue-app');