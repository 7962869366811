<template>
  <div>
    <div v-for="(ticket, index) in purchase.user_atmo_event_tickets" :key="index" class="atmo-purchase__card">
      <div class="atmo-purchase__layout">
        <div class="atmo-purchase__image-container" :style="`background-image: url('${ticket.atmo_event_ticket.atmo_event.image.url}')`"></div>
        <div class="atmo-purchase__content">
          <h2 class="atmo-purchase__title">{{ ticket.atmo_event_ticket.title }}</h2>
          <div class="atmo-purchase__items">
            <div class="atmo-purchase__item-attributes">
              <div class="atmo-purchase__item-attribute">Event</div>
              <div class="atmo-purchase__item-attribute">{{ ticket.atmo_event_ticket.atmo_event.name }}</div>
            </div>
            <div class="atmo-purchase__item-attributes">
              <div class="atmo-purchase__item-attribute">Date</div>
              <div class="atmo-purchase__item-attribute">
                {{ new Date(ticket.atmo_event_ticket.atmo_event.datetime).toLocaleString() }}
              </div>
            </div>
            <div class="atmo-purchase__item-attributes">
              <div class="atmo-purchase__item-attribute">Price</div>
              <div class="atmo-purchase__item-attribute">${{ parseFloat(ticket.atmo_event_ticket.price).toFixed(2) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    purchase: {
      type: Object,
      required: true,
    },
  },
};
</script>
