<template>
  <div class="subscriptions-page">
    <!-- <h2 class="atmo-page-header">Your Subscriptions</h2> -->
    <perfect-scrollbar v-if="subscriptions.length > 0" class="subscriptions-page__list">
      <div v-for="subscription in subscriptions" :key="subscription.id" class="subscription-card">
        <router-link class="subscription-card__name" :to="{ name: 'subscriptions.show', params: { subscriptionId: subscription.id } }">
          {{ subscription.name }}
        </router-link>
        <p class="subscription-card__status">
          Status: <span :class="`status-${subscription.status}`">{{ subscription.status }}</span>
        </p>
        <router-link
          v-if="$store.state.currentUserProfileId"
          class="subscription-card__link"
          :to="{ name: 'subscriptions.user_invoices.index', params: { userProfileId: $store.state.currentUserProfileId, subscriptionId: subscription.id } }"
        >
          View Invoices
        </router-link>
      </div>
    </perfect-scrollbar>
    <div v-else class="subscriptions-page__no-subscriptions">
      <p>No subscriptions found.</p>
    </div>

    <div class="subscriptions-page__actions">
      <router-link v-if="noSubsActive && $store.state.currentUserProfileId" class="atmo-button atmo-button--primary" :to="{ name: 'subscriptions.user_plans.index' }">
        Resubscribe to Plus!
      </router-link>
      <router-link
        v-if="$store.state.currentUserProfileId"
        class="atmo-button atmo-button--secondary"
        :to="{ name: 'subscriptions.cards.index', params: { userProfileId: $store.state.currentUserProfileId } }"
      >
        View Card Information
      </router-link>
    </div>
  </div>
</template>

<script>
import { callSubscriptionsIndex } from "@/helpers/axiosCalls";

export default {
  data() {
    return {
      subscriptions: [],
      noSubsActive: null,
    };
  },
  created() {
    callSubscriptionsIndex(this.$store.state.currentUserId).then((response) => {
      // Sort subscriptions: active status at the top, followed by others
      this.subscriptions = response.sort((a, b) => (a.status === "active" ? -1 : 1));
      // Check if there are no active subscriptions
      this.noSubsActive = !this.subscriptions.some((subscription) => subscription.status === "active");
    });
    this.$store.commit("setHudHeader", "Your Subscriptions");
  },
  methods: {
    isCanceled(subscription) {
      return subscription.status === "canceled";
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.subscriptions-page {
  max-width: 70vw;
  margin: 0 auto;
  padding: 20px;

  &__no-subscriptions {
    text-align: center;
    font-size: 1rem;
    margin-top: 30px;
  }

  &__actions {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 30px;
  }
}

.subscription-card {
  background-color: $atmo-purple--medium-dark--faded;
  padding: 1.2rem;
  border-radius: 5px;
  color: white;
  min-width: 30vw;

  & + & {
    margin-top: 1rem;
  }

  &__name {
    font-size: 1.2rem;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
    margin-bottom: 1rem;
    display: block;
  }

  &__name:hover {
    text-decoration: underline;
  }

  &__status {
    font-size: 1rem;
    margin-bottom: 1rem;

    .status-active {
      color: $atmo-blue--light;
      font-weight: bold;
    }
    .status-canceled {
      color: $atmo-pink--medium;
      font-weight: bold;
    }
    .status-pending {
      color: $atmo-purple--medium;
      font-weight: bold;
    }
  }

  &__link {
    font-size: 0.9rem;
    color: white;
  }

  &__link:hover {
    text-decoration: underline;
  }
}
</style>
