<template>
  <div class="dots">
    <div
      v-for="index in [4,3,2,0]"
      :id="`strength-${index}`"
      :key="index"
      class="dot"
      :class="{ active: password.length ? passwordStrengthCalculation.score >= index : false }"
      @mouseenter="showPopover(index)"
      @mouseleave="hidePopover"
    />
    <div
      v-if="showPopoverContent && popoverTarget === passwordStrengthCalculation.score"
      class="popover passwordmeter"
    >
      <div :class="`title-${passwordStrengthCalculation.score}`">
        {{ popOverTitleBasedOnPasswordStrength }}
      </div>
      <div class="popover-body">
        {{ passwordStrengthCalculation.feedback.warning || 'Your password is strong.' }}
        <br />
        {{ passwordStrengthCalculation.feedback.suggestions.join('\n') }}
      </div>
    </div>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn';

export default {
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      passwordStrengthTitle: {
        '0': 'Very low security',
        '1': 'Low security',
        '2': 'Medium security',
        '3': 'Strong security',
        '4': 'Very strong security',
      },
      showPopoverContent: false,
      popoverTarget: null,
    };
  },
  computed: {
    passwordStrengthCalculation() {
      return zxcvbn(this.password);
    },
    popOverTitleBasedOnPasswordStrength() {
      return this.passwordStrengthTitle[this.passwordStrengthCalculation.score];
    },
  },
  methods: {
    showPopover(index) {
      this.popoverTarget = index;
      this.showPopoverContent = true;
    },
    hidePopover() {
      this.showPopoverContent = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.passwordmeter {
  position: absolute;
  left: 60px;
  top: 0;
  background-color: #fff;
  padding: 10px;
  border: 1px solid $atmo-gray--light;
  border-radius: 5px;
  width: 200px;
  z-index: 10;

  .title-0, .title-1 {
    color: $atmo-invalid;
  }

  .title-2 {
    color: $atmo-warning;
  }

  .title-3 {
    color: $atmo-light-green;
  }

  .title-4 {
    color: $atmo-green;
  }

  .popover-body {
    padding: 0.5rem;
    font-size: 0.9rem;
  }
}

.dots {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $atmo-gray--light;
  margin-top: 3.5px;

  &.active {
    &#strength-0, .strength-0 {
      background-color: $atmo-invalid;
    }
    &#strength-2, .strength-2 {
      background-color: orange;
    }
    &#strength-3, .strength-3 {
      background-color: greenyellow;
    }
    &#strength-4, .strength-4 {
      background-color: green;
    }
  }
}
</style>
