<template>
  <!-- TODO: Make a reusable component out of table for user/artist plans -->
  <div class="artist-subscriptions" data-cy="artist-subscriptions">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">
          UPGRADE TODAY
        </h2>
    </div> -->
    <div class="artist-subscriptions-container">
      <div class="artist-subscriptions-container__free-plan">
        <div class="free-plan__title-container">
          <p class="title-container__free-header">General Admission</p>
        </div>
        <div class="free-plan__content-container">
          <div class="content-container__row --dark">
            <div class="row__text-container">Social Playlists</div>
          </div>
          <div class="content-container__row">
            <div class="row__text-container">Chat Capability</div>
          </div>
          <div class="content-container__row --dark">
            <div class="row__text-container">Live Listening Sessions</div>
          </div>
          <div class="content-container__row">
            <div class="row__text-container">Song Voting/Commenting</div>
          </div>
          <div class="content-container__row --dark">
            <div class="row__text-container">Online Store</div>
          </div>
          <div class="content-container__row">
            <div class="row__text-container">Contests & Giveaways</div>
          </div>
          <div class="content-container__row --dark">
            <div class="row__text-container">Playlist Creation</div>
          </div>
          <div class="content-container__row">
            <div class="row__text-container">Library Creation</div>
          </div>
          <div class="content-container__row --dark">
            <div class="row__text-container">AMI (Atmosphere Music Interface)</div>
          </div>
          <div class="content-container__row">
            <div class="row__text-container">Atmo Events</div>
          </div>
        </div>
      </div>
      <div class="artist-subscriptions-container__paid-plan">
        <div class="paid-plan__title-container">
          <div class="title-container__image-container">
            <img class="image-container__vip_label" src="@/assets/images/subscriptions_images/vip_label.png" />
          </div>
          <div class="title-container__header-container">
            <p class="title-container__paid-header">Upgrade to VIP</p>
            <p class="title-container__paid-subheader">$29.99/mo, $240/yr</p>
          </div>
        </div>
        <perfect-scrollbar>
          <div class="paid-plan__content-container">
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Everything in General Admission</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Directory Access</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Selling Physical Products (Tiered Model)</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Direct Artist to Fan Messaging</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Donate to Artist Features</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Request Venue Feature</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Poll Fans Feature</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Social Gifts Feature</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Map Your Career Feature</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Atmo Channels</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Everything in General Admission (repeat)</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Mobile (Offline listening?)</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Exclusive Artist Content / Pre-release content</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Gamification/Accomplishment/Atmosphere Points Program</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Direct Artist to Fan Messaging</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Smart Program Feature (maybe roll this into AMI)</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Discount Sponsor Products</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Atmosphere Directory</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Atmo Events (premium)</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Atmo Channels</div>
            </div>
          </div>
        </perfect-scrollbar>
        <div class="paid-plan__button-container">
          <router-link class="button-container__button" :to="{ name: 'subscriptions.user_subscriptions.new', params: { userProfileId: userProfile.id } }">Select Now</router-link>
        </div>
      </div>
      <div class="artist-subscriptions-container__paid-plan">
        <div class="paid-plan__title-container">
          <div class="title-container__image-container">
            <img class="image-container__vip_label" src="@/assets/images/subscriptions_images/p_label.png" />
          </div>
          <div class="title-container__header-container">
            <p class="title-container__paid-header">Upgrade to Platinum</p>
            <p class="title-container__paid-subheader">$99.99/mo, $840/yr</p>
          </div>
        </div>
        <perfect-scrollbar>
          <div class="paid-plan__content-container">
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Everything in VIP</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Analytics (fan/venue information)</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Sponsor Access</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Socil Monetization</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Ops Pages</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Unlimited physical merch</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Map Your Career Feature (?)</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container"></div>
              <div class="row__text-container"></div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container"></div>
              <div class="row__text-container"></div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container"></div>
              <div class="row__text-container"></div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Direct Artist to Fan Messaging</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Smart Program Feature (maybe roll this into AMI)</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Discount Sponsor Products</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Atmosphere Directory</div>
            </div>
            <div class="content-container__row --dark">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Atmo Events (premium)</div>
            </div>
            <div class="content-container__row">
              <div class="row__image-container">
                <img class="image-container__check" src="@/assets/images/subscriptions_images/blue_check.png" />
              </div>
              <div class="row__text-container">Atmo Channels</div>
            </div>
          </div>
        </perfect-scrollbar>
        <div class="paid-plan__button-container">
          <router-link class="button-container__button" :to="{ name: 'subscriptions.user_subscriptions.new', params: { userProfileId: userProfile.id } }">Select Now</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import storeTest from "@/store";
import { callUserProfilesShow, callUsersShow } from "@/helpers/axiosCalls";

export default {
  data: function () {
    return {
      user: {},
      userProfile: {},
      customerId: "",
      customer: {},
    };
  },
  created() {
    callUsersShow(storeTest.state.currentUserId).then((response) => {
      this.user = response;
      callUserProfilesShow(this.user.profile_id).then((response2) => (this.userProfile = response2));
    });
    this.$store.commit("setHudHeader", "Upgrade Today");
  },
  methods: {},
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.artist-subscriptions {
  margin: 0 75px;

  .ps {
    max-height: 22.1rem;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .artist-subscriptions-container {
    // border: 1px solid white;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .artist-subscriptions-container__free-plan {
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.09);
  }

  .free-plan__title-container {
    height: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }

  .title-container__free-header {
    font-size: 2rem;
  }

  .paid-plan__title-container {
    height: 5rem;
    background: $atmo-purple--dark;
    display: flex;
    // justify-content: center;
    align-items: center;
    position: relative;
  }

  .title-container__image-container {
    position: absolute;
    top: -4px;
    margin-left: 1rem;
  }

  .image-container__vip_label {
    height: 5rem;
  }

  .title-container__header-container {
    margin-left: 4rem;
  }

  .title-container__paid-header {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  .title-container__paid-subheader {
    font-size: 1.5rem;
  }

  .content-container__row {
    display: flex;
  }

  .--dark {
    opacity: 0.5;
    background: #604875;
  }

  .paid-plan__content-container {
    background: rgba(96, 72, 117, 0.39);
    // box-shadow: 0 6px 6px 0 rgba(0,0,0,0.09);
  }

  .row__image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    padding: 0.8rem;
  }

  .image-container__check {
    height: 10px;
  }

  .row__text-container {
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }

  .paid-plan__button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(96, 72, 117, 0.39);
    box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.09);
    padding: 0.5rem 0rem 1rem 0rem;
  }

  .button-container__button {
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  }
}
</style>
