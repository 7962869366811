<template>
  <div v-if="isLoading" class="genres-show genres-show--loading">
    <atmo-loading size="large" />
  </div>
  <div v-else class="genres-show">
    <!-- <h2 class="atmo-page-header">
            {{ genre.name }}
        </h2> -->
    <div class="genre-container">
      <atmo-back-link class="back-link"></atmo-back-link>
      <div class="genres-show__body">
        <div class="genres-show__genre-info">
          <div class="genres-show__genre-info">
            <div class="genre-card atmo-genre-card">
              <div class="genre-card__image-container">
                <img class="image-container__image" :src="genre.image.url" />
                <div class="image-container__overlay"></div>
                <div class="genre-card__upper-left"></div>
                <!-- <div class="genre-card__upper-right">
                                <div class="atmo-genre-card__album-year"><span>2020</span></div>
                            </div> -->
              </div>
              <div class="genre-card__body"></div>
              <div class="genre-card__footer">
                <div class="atmo-genre-card__genre-footer">
                  <div class="atmo-genre-card__genre-info">
                    <div class="atmo-genre-card__genre-name">
                      <span>{{ genre.name }}</span>
                    </div>
                    <!-- <div class="atmo-genre-card__album-genre"><span>Jazz</span></div> -->
                  </div>
                  <div class="atmo-genre-card__genre-length"><img :src="require('@/assets/images/icons/clock.png')" class="atmo-genre-card__genre-length-icon" /> 123 min</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="genres-show__songs">
          <div v-if="genreSongs == null">Loading…</div>
          <perfect-scrollbar>
            <table class="atmo-table atmo-songs-table">
              <tbody>
                <tr v-for="(song, index) in genreSongs" :key="index" :class="index % 2 !== 0 ? 'atmo-table__tr' : 'atmo-table__tr--light'" @click="$store.dispatch('player/prependAndPlaySong', song)">
                  <td class="atmo-table__td atmo-table__td--action-arrow">
                    <song-actions-popover :song="song" :actions="songActions" />
                  </td>
                  <td data-cy="table-row-song-name" class="atmo-table__td atmo-table__td--stretch">
                    {{ song.name }}
                  </td>
                  <!-- TODO: real song length and genre -->
                  <td class="atmo-table__td">
                    {{ getSongDuration(song) }}
                  </td>
                  <td class="atmo-table__td">
                    {{ genre.name }}
                  </td>
                  <td v-if="showCommentsLinks" class="atmo-table__td atmo-table__td--tight">
                    <router-link :to="{ name: 'songs.show', params: { songId: song.id } }">
                      <img class="atmo-songs-table__comment-icon" src="@/assets/images/icons/comment.png" alt="comments" />
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AtmoLoading from "@/components/atmo-loading";
import SongActionsPopover from "@/components/song-actions-popover";
import AtmoBackLink from "@/components/atmo-back-link";
import { callGenresShow, callGenreSongsIndex } from "@/helpers/axiosCalls";
import { getSongDuration } from "@/helpers/utilityFunctions";

export default {
  components: {
    AtmoLoading,
    SongActionsPopover,
    AtmoBackLink,
  },
  data() {
    return {
      isLoading: true,
      genre: null,
      genreSongs: null,
      isLink: false,
      showCommentsLinks: true,
      songActions: ["add-to-queue", "add-to-library", "add-to-genre"],
    };
  },
  async created() {
    await this.getGenre();
    this.$store.commit("setHudHeader", this.genre.name);
  },
  methods: {
    getSongDuration(song) {
      return getSongDuration(song);
    },
    getGenre() {
      callGenresShow(this.$route.params.genreId)
        .then((response) => {
          this.genre = response;
          console.log("this.genre", this.genre);
          this.getGenreSongs();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getGenreSongs() {
      this.isLoading = true;
      callGenreSongsIndex(this.$route.params.genreId)
        .then((response) => {
          this.genreSongs = response;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.genres-show {
  display: flex;
  flex-direction: column;
  align-items: center;

  .genre-container {
    width: 100%;
    margin-left: 2rem;
  }

  &__body {
    display: flex;
    margin-top: 20px;
    width: 100%;
  }

  &__genre-info {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
  }

  &__edit-button {
    margin-top: 15px;
  }

  &__songs {
    flex: 1;
    width: 100%;
    margin-right: 2rem;
  }

  .genre-card {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    // overflow: hidden;
    // box-shadow: 1px 1px 1px 0px rgba(black, 0.2);

    .genre-card__image-container {
      position: relative;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      border-radius: 5px 5px 0px 0px;
    }

    .image-container__image {
      width: 100%;
      position: relative;
      border-radius: 5px 5px 0px 0px;
    }

    .image-container__overlay {
      height: 100%;
      width: 100%;
      position: absolute;
      font-weight: bold;
      text-align: right;
      font-size: 30px;
      border-radius: 5px 5px 0px 0px;
      background-image: linear-gradient(-270deg, rgba($atmo-blue--medium, 0.5) 0%, rgba($atmo-pink--medium, 0.5) 100%);
    }

    &--clickable {
      cursor: pointer;
    }

    &--with-background-gradient {
      background-image: linear-gradient(-270deg, rgba($atmo-blue--medium, 0.5) 0%, rgba($atmo-pink--medium, 0.5) 100%);
    }

    &__background-image-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    &__background-image {
      position: absolute;
      z-index: -1;
      opacity: 0.7;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba($atmo-purple--light, 0.4);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      border-radius: 5px 5px 0 0;
      height: 100%;
      margin: auto;
    }

    &:hover .genre-card__link-icon-container {
      visibility: visible;
      background-color: rgba($atmo-purple--dark, 0.7);
    }

    &:hover .genre-card__link-icon {
      opacity: 1;
    }

    &__upper-left {
      position: absolute;
      top: 0;
      left: 0;
    }

    &__upper-right {
      position: absolute;
      top: 0;
      right: 0;
    }

    &__lower-left {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &__lower-right {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    &__image-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($atmo-purple--light, 0.4);
      border-radius: 5px 5px 0 0;
      position: relative;
    }

    &__link-icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($atmo-purple--dark, 0);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: background-color 0.2s ease-in;
      visibility: hidden;
      border-radius: inherit;
    }

    .atmo-genre-card__genre-footer {
      display: flex;
      flex-direction: column;
      background-image: linear-gradient(-269deg, rgba(68, 174, 220, 0.39) 1%, rgba(217, 82, 167, 0.39) 100%);
      border-radius: 5px 5px 5px 5px;
    }

    .atmo-genre-card__genre-info {
      display: flex;
      flex-direction: column;
      padding: 15px;
    }

    .atmo-genre-card__genre-length {
      color: white;
      text-transform: uppercase;
      font-size: 0.9em;
      padding: 11px;
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .atmo-genre-card__genre-length-icon {
      height: 18px;
      margin-right: 2px;
    }

    .atmo-genre-card__genre-name {
      color: white;
      font-size: 1.2em;
      font-weight: 600;
      margin-bottom: 5px;
      text-align: center;
    }
  }
}
</style>
