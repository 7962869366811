<template>
  <div class="select-card">
    <p v-if="!isAddCardOpen" class="select-card__heading">Select Card</p>
    <div v-if="cards.length > 0 && !isAddCardOpen">
      <perfect-scrollbar class="existing-card-container">
        <div v-for="card in cards" :key="card.id" class="card-option">
          <input type="radio" :value="card.id" v-model="selectedCardId" class="custom-radio" />
          <label>{{ card.card.brand }} ending in {{ card.card.last4 }}</label>
        </div>
      </perfect-scrollbar>
      <a class="card-link" @click="toggleAddCard">Add New Card</a>
    </div>
    <div v-else-if="!isAddCardOpen">
      <add-card @cardAdded="fetchCards" />
    </div>
    <div v-if="isAddCardOpen">
      <add-card @cardAdded="fetchCards" />
    </div>
  </div>
</template>

<script>
import AddCard from "@/routes/subscriptions/cards/add-card.vue";
import { ref, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { callStripeCustomerSourcesGet } from "@/helpers/axiosCalls";

export default {
  components: { AddCard },
  setup() {
    const store = useStore();
    const cards = ref([]);
    const selectedCardId = ref(null);

    const fetchCards = async () => {
      try {
        const customerId = store.state.currentUser.customer_id;
        const response = await callStripeCustomerSourcesGet(customerId);
        cards.value = response.data;
        console.log("RES DATA", response.data);
      } catch (error) {
        console.error("Failed to fetch cards:", error);
      }
    };

    onMounted(fetchCards);

    const isAddCardOpen = computed(() => store.getters["payments/isAddCardOpen"]);

    const toggleAddCard = () => {
      store.dispatch("payments/toggleAddCard");
    };

    const onSelectCard = (cardId) => {
      const selectedCard = cards.value.find((card) => card.id === cardId);

      // Dispatch the action to set the selected card in the store
      store.dispatch("payments/setSelectedCard", selectedCard);

      // Use the card's id as the token and dispatch to set it in the store
      store.dispatch("payments/setToken", selectedCard.id);
    };

    // Watch for changes in selectedCardId and trigger onSelectCard
    watch(selectedCardId, (newCardId) => {
      if (newCardId) {
        onSelectCard(newCardId);
      }
    });

    return {
      cards,
      selectedCardId,
      toggleAddCard,
      isAddCardOpen,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.select-card {
  .select-card__heading {
    margin-bottom: 0.5rem;
  }

  .existing-card-container {
    max-height: 45rem;
  }

  .card-option {
    display: block;
    margin-bottom: 0.5rem;

    input[type="radio"] {
      accent-color: $atmo-purple--medium;
      cursor: pointer;
    }
  }

  .card-link {
    cursor: pointer;
    margin-top: 0.5rem;
    color: $atmo-purple--dark;
    display: inline-block;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
