<template>
  <div class="atmo-album-edit">
    <!-- <h2 class="atmo-page-header">
      Edit Album
    </h2> -->
    <atmo-breadcrumbs class="atmo-album-show__breadcrumbs" component-type="router-link" :to="`/artist_profiles/${artistProfileId}/albums`" :breadcrumbs="['Back to Albums']" />
    <div v-if="isLoadingAlbum || isLoadingAlbumSongs" class="atmo-album-edit__loading-container">
      <atmo-loading />
    </div>
    <album-form v-else-if="!isError" :album="album" :album-songs="albumSongs" />
  </div>
</template>

<script>
import Axios from "axios";
import AtmoBreadcrumbs from "@/components/atmo-breadcrumbs";
import AtmoLoading from "@/components/atmo-loading";
import AlbumForm from "./album-form";
import { callAlbumSongsIndex } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoBreadcrumbs,
    AtmoLoading,
    AlbumForm,
  },

  data() {
    return {
      isLoadingAlbum: true,
      isLoadingAlbumSongs: true,
      isError: false,
      album: null,
      albumSongs: null,
    };
  },

  computed: {
    artistProfileId() {
      return Number(this.$route.params.artistProfileId);
    },
    albumId() {
      return Number(this.$route.params.albumId);
    },
    canEditAlbum() {
      const { currentUserProfileType, currentUserProfileId } = this.$store.state;
      return currentUserProfileType === "ArtistProfile" && currentUserProfileId === this.artistProfileId;
    },
  },

  created() {
    if (this.canEditAlbum) {
      this.getAlbum();
      this.getAlbumSongs();
    } else {
      this.redirectToAlbumIndex();
    }
    this.$store.commit("setHudHeader", "Edit Album");
  },

  methods: {
    redirectToAlbumIndex() {
      this.$router.push({
        name: "albums.index",
        params: { artistProfileId: this.artistProfileId },
      });
    },
    getAlbum() {
      this.isLoadingAlbum = true;
      Axios.get(`/api/v1/artist_profiles/${this.artistProfileId}/albums/${this.albumId}`)
        .then((response) => {
          this.album = response.data;
          this.isError = false;
        })
        .catch((error) => {
          console.error(error);
          this.$notify({ group: "vue-app", type: "error", title: "Error fetching album" });
          this.isError = true;
        })
        .finally(() => {
          this.isLoadingAlbum = false;
        });
    },
    getAlbumSongs() {
      this.isLoadingAlbumSongs = true;
      callAlbumSongsIndex(this.artistProfileId, this.albumId)
        .then((response) => {
          this.albumSongs = response;
          this.isError = false;
        })
        .catch((error) => {
          console.error(error);
          this.$notify({ group: "vue-app", type: "error", title: "Error fetching album songs" });
          this.isError = true;
        })
        .finally(() => {
          this.isLoadingAlbumSongs = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-album-edit {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__loading-container {
    padding: 50px;
  }
}
</style>
