<template>
  <div class="user-billboard__posts-container">
    <div class="user-billboard__post-form">
      <label class="user-billboard__input-wrapper">
        <div class="user-billboard__avatar-container">
          <div class="atmo-avatar atmo-avatar--small atmo-avatar--border-white"
            :style="{ 'background-image': 'url(' + getFeaturedImage($store.state.user?.images || [], 'full') + ')' }" />
        </div>
        <input v-model="newPostContent" placeholder="Write something…" type="text" class="user-billboard__input">
      </label>
      <div class="user-billboard__form-controls">
        <div v-for="(image, index) in images" class="user-billboard__pill-container" :key="`image-${index}`">
          <span class="user-billboard__pill">
            {{ image.name }}
          </span>
          <div aria-label="Remove" class="user-billboard__pill-remove" @click="removeImage(image)">
            &times;
          </div>
        </div>
        <div v-for="(video, index) in videos" class="user-billboard__pill-container" :key="`video-${index}`">
          <span class="user-billboard__pill">
            {{ video.name }}
          </span>
          <div aria-label="Remove" class="user-billboard__pill-remove" @click="removeVideo(video)">
            &times;
          </div>
        </div>
        <div v-for="(song, index) in $store.state.addSongsModalList" class="user-billboard__pill-container"
          :key="`song-${index}`">
          <span class="user-billboard__pill">
            {{ song.name }}
          </span>
          <div aria-label="Remove" class="user-billboard__pill-remove" @click="removeSong(song)">
            &times;
          </div>
        </div>
        <div class="user-billboard__button-group">
          <button class="
            atmo-button
            atmo-button--tertiary
            upload-song
          " @click="openAddSongsModal()">
            <img class="atmo-button__icon" src="@/assets/images/icons/upload_music.png">
          </button>
          <label for="file-input" class="
            atmo-button
            atmo-button--tertiary
            post-image-upload
          ">
            <img class="atmo-button__icon" src="@/assets/images/icons/attachment.png">
          </label>
          <input id="file-input" type="file" @change="handleFileUpload" style="display: none" />
        </div>
        <input type="submit" class="atmo-button atmo-button--primary" value="Post" @click="submitNewPost()">
      </div>
    </div>
    <div class="user-billboard__posts-list">
      <atmo-post v-for="post in posts" :key="post.id" :type="type" :post="post" :base-url="baseUrl"
        :user-profile="userProfile" :comments-enabled="true" :comments-expanded="postIdOfExpandedComments === post.id"
        :toggle-comments="toggleComments" />
    </div>
  </div>
</template>

<script>
import AtmoPost from '@/components/atmo-posts/atmo-post';
import {
  callImagesCreate,
  callVideosCreate,
  callSongReferencesCreate,
  callPreviewImageCreate
} from '@/helpers/axiosCalls';
import { getFeaturedImage } from '@/helpers/utilityFunctions';
import { mapActions, mapState } from 'vuex';

export default {
  components: { AtmoPost },
  props: {
    type: { type: String, default: 'billboard' },
    userProfile: { type: Object, required: true },
    posts: { type: Array, required: true },
  },
  data() {
    return {
      postIdOfExpandedComments: null,
      newPostContent: '',
      images: [],
      videos: []
    }
  },
  computed: {
    ...mapState('profiles', ['profile', 'user', 'isLoading', 'profileType']),
    baseUrl() {
      return this.type === 'broadcast'
        ? `/api/v1/users/${this.userProfile.user.id}/broadcast_posts`
        : `/api/v1/users/${this.userProfile.user.id}/billboard_posts`;
    },
  },
  methods: {
    ...mapActions('posts', ['createPost', 'likePost', 'dislikePost', 'fetchPosts']),
    ...mapActions('userActivities', ['getBroadcastPosts']),
    ...mapActions('accomplishments', ['createUserAccomplishments']),
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    openAddSongsModal() {
      this.$store.commit("openAddSongsModal");
    },
    removeSong(song) {
      const indexToRemove = this.$store.state.addSongsModalList.findIndex(item => item === song);
      const newAddSongsModalSongs = this.$store.state.addSongsModalList.filter((item, index) => index !== indexToRemove);
      this.$store.commit('setAddSongsModalList', newAddSongsModalSongs);
    },
    removeVideo(file) {
      const indexToRemove = this.videos.findIndex(item => item === file);
      this.videos = this.videos.filter((item, index) => index !== indexToRemove);
    },
    removeImage(file) {
      const indexToRemove = this.images.findIndex(item => item === file);
      this.images = this.images.filter((item, index) => index !== indexToRemove);
    },
    async handleFileUpload(event) {
      const inputElement = event.target;
      const selectedFile = inputElement.files[0];

      if (!selectedFile) {
        return; // Handle no file selected error
      }

      const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];
      const videoMimeTypes = ['video/mp4', 'video/mpeg', 'video/quicktime'];

      if (imageMimeTypes.includes(selectedFile.type)) {
        this.images.push(selectedFile);
      } else if (videoMimeTypes.includes(selectedFile.type)) {
        this.videos.push(selectedFile);
      } else {
        console.error('Invalid file type. Please select a valid image or video file.');
        inputElement.value = null; // Reset the file input
      }
    },
    submitNewPost() {
      const payload = {
        content: this.newPostContent,
        user_id: this.$store.state.currentUserId,
        billboard_id: this.type === 'billboard' ? this.user.id : this.$store.state.currentUserId
      };

      this.createPost({
        userId: this.type === 'billboard' ? this.user.id : this.$store.state.currentUserId,
        postPayload: payload,
      })
        .then(async (response) => {
          const postId = response.id;

          let imagePromises = [];
          if (this.images.length > 0) {
            imagePromises = this.images.map(async (image) => {
              const formData = new FormData();
              formData.append('image', image);
              formData.append('imageable_id', postId)
              formData.append('imageable_type', "Post")
              formData.append('is_featured_image', false)
              formData.append('user_id', this.$store.state.currentUserId)

              return callImagesCreate(formData).catch(console.error);
            });
          }

          let songPromises = [];
          if (this.$store.state.addSongsModalList.length > 0) {
            songPromises = this.$store.state.addSongsModalList.map(async (song) => {
              return callSongReferencesCreate({
                songable_id: postId,
                songable_type: "Post",
                song_id: song.id,
                user_id: this.$store.state.currentUserId
              }).catch(console.error);
            });
          }

          let newVideoIds = [];
          let videoPromises = [];
          let previewImagePromises = [];

          if (this.videos.length > 0) {
            videoPromises = this.videos.map(async (video) => {
              const formData = new FormData();
              formData.append('video', video);
              formData.append('videoable_id', postId)
              formData.append('videoable_type', "Post")
              formData.append('user_id', this.$store.state.currentUserId)
              formData.append('is_featured_video', true);

              return callVideosCreate(formData)
                .then((response) => {
                  newVideoIds.push(response.id);
                })
                .catch(console.error);
            });
          }

          Promise.all([...imagePromises, ...songPromises, ...videoPromises]).then(() => {
            previewImagePromises = newVideoIds.map(async (videoId) => {
              return callPreviewImageCreate({ video_id: videoId });
            });

            Promise.all([...previewImagePromises]).then(() => {
              this.$notify({ group: 'vue-app', title: 'Post Saved' });

              if (this.type == 'billboard') {
                this.fetchPosts(this.user.id);
              } else {
                this.getBroadcastPosts({
                  userId: this.$store.state.currentUserId,
                  params: {
                    date_range: 'all',
                    limit: 100,
                    show_current_user_posts: 'true'
                  }
                });
              }

              this.newPostContent = '';
              this.images = [];
              this.videos = [];
              this.$store.commit('setAddSongsModalList', []);
            });

            this.createUserAccomplishments({
              userId: this.$store.state.currentUserId,
              accomplishmentData: {
                payloads: [
                  {
                    user_id: this.$store.state.currentUserId,
                    category: 'posts',
                    sub_category: 'postNumber'
                  },
                  {
                    user_id: this.$store.state.currentUserId,
                    category: 'photos',
                    sub_category: 'photoNumber'
                  },
                  {
                    user_id: this.$store.state.currentUserId,
                    category: 'videos',
                    sub_category: 'videoNumber'
                  },
                  {
                    user_id: this.$store.state.currentUserId,
                    category: 'shares',
                    sub_category: 'songShareNumber'
                  }
                ]
              }
            });
          });

          if (this.images.length === 0 && this.$store.state.addSongsModalList.length === 0 && this.videos.length === 0) {
            if (this.type == 'billboard') {
              this.fetchPosts(this.user.id);
            } else {
              this.getBroadcastPosts({
                userId: this.$store.state.currentUserId,
                params: {
                  date_range: 'all',
                  limit: 100,
                  show_current_user_posts: 'true'
                }
              });
            }
            this.newPostContent = '';
          }
        });
    },
    likePost(post) {
      this.$store.dispatch('posts/likePost', post.id);
    },
    dislikePost(post) {
      this.$store.dispatch('posts/dislikePost', post.id);
    },
    toggleComments(post) {
      const postId = post.id;
      this.postIdOfExpandedComments = this.postIdOfExpandedComments === postId ? null : postId;
    }
  }
}
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';

.user-billboard__button-group {
  .post-image-upload {
    border-radius: 0px 5px 5px 0px;
  }
}

.user-billboard {
  &__header {
    font-size: 1.5em;
    text-transform: uppercase;
    padding: 30px;
    font-weight: 300;
  }

  &__post-form {
    margin-bottom: 1rem;
  }

  &__posts-container {
    flex-grow: 1;
    padding: 10px;
  }

  &__new-post {
    display: flex;
    flex-direction: column;
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
  }

  &__avatar-container {
    display: flex;
    justify-content: flex-end;
    width: 72px;
    padding: 0 10px;
  }

  &__input,
  input[type=text] {
    flex-grow: 1;
    border: 1px solid white;
    min-width: 400px;
    height: 35px;
    border-radius: 5px;
    background: transparent;
    margin: 0;
    box-shadow: none;
    padding: 0 10px;
    font-size: 1em;
    font-weight: 300;
    color: white;

    &:focus {
      outline-color: $atmo-purple--medium;
    }
  }

  .user-billboard__input--small,
  input[type=text].user-billboard__input--small {
    height: 28px;
    font-size: 0.8em;
  }

  &__form-controls {
    display: flex;
    height: 50px;
    align-items: center;
    justify-content: flex-end;
  }

  &__button-group {
    display: flex;
    margin-right: 10px;

    button {
      padding: 0;
      width: 45px;
      height: 38px;
      border-radius: 0;
      border-left-width: 0;

      &:first-child {
        border-left-width: 1px;
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
      }

      &:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
      }
    }
  }

  &__pill-container {
    position: relative;
  }

  &__pill {
    background-color: $atmo-purple--medium;
    margin: 0 6px;
    padding: 7px 15px;
    font-size: 0.8em;
    font-weight: 300;
    border-radius: 10px;
  }

  &__pill-remove {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -9px;
    right: 8px;
    background-color: white;
    color: #4d2969;
    border-radius: 50%;
    font-size: 0.8em;
    font-weight: 300;
    width: 12px;
    height: 12px;
    cursor: pointer;
  }

  &__post-comments-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &__comment {
    display: flex;

    .user-billboard__post-container {
      margin-left: 10px;
    }

    .user-billboard__main-content-container {
      font-size: 0.9em;
      background: inherit;
      padding-left: 0;
      margin-bottom: 0;
    }
  }
}
</style>
