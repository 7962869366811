<template>
  <div class="new-subscription-page">
    <!-- <h2 class="atmo-page-header new-subscription-page__header">New Subscription</h2> -->
    <perfect-scrollbar class="new-subscription-page__body">
      <section class="new-subscription-page__plan-details">
        <h3 class="plan-details__header">Plan Overview</h3>
        <div class="plan-details__item">
          <div class="plan-details__label">Name of Plan:</div>
          <div class="plan-details__value">{{ selectedPlan.name }}</div>
        </div>
        <div class="plan-details__item">
          <div class="plan-details__label">Billing Frequency:</div>
          <div class="plan-details__options">
            <label v-for="option in selectedPlan.options" :key="option.name" class="plan-details__option">
              <input type="radio" name="subscription" :value="option.name.toLowerCase()" :id="option.name.toLowerCase()" @change="selectPlanOption(option)" />
              <span class="plan-details__option-text"> {{ option.name }} - {{ formatPriceAsCurrency(option.amount) }} </span>
            </label>
          </div>
        </div>
      </section>
      <aside class="new-subscription-page__sidebar">
        <div class="new-subscription-page__attributes">
          <div class="new-subscription-page__attribute">
            <div class="new-subscription-page__attribute-label">Plan Amount</div>
            <div class="new-subscription-page__attribute-value">
              {{ planAmount ? formatPriceAsCurrency(planAmount) : "$0.00" }}
            </div>
          </div>
          <div class="new-subscription-page__attribute">
            <div class="new-subscription-page__attribute-label">Tax ({{ this.planTaxRate * 100 }}%)</div>
            <div class="new-subscription-page__attribute-value">
              {{ this.planTax ? formatPriceAsCurrency(this.planTax) : "$0.00" }}
            </div>
          </div>
          <div class="new-subscription-page__attribute new-subscription-page__attribute--total">
            <div class="new-subscription-page__attribute-label">Total</div>
            <div class="new-subscription-page__attribute-value">
              {{ totalCost }}
            </div>
          </div>
        </div>
        <div class="new-subscription-page__action-buttons">
          <button @click="goBack" class="new-subscription-page__action-button atmo-button atmo-button--tertiary">
            <span>Cancel</span>
          </button>
          <router-link :to="{ name: 'checkout', params: { checkoutType: 'subscription' } }" class="new-subscription-page__action-button atmo-button atmo-button--primary">Checkout</router-link>
        </div>
      </aside>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { formatPriceAsCurrency } from "@/helpers/utilityFunctions";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      planTaxRate: 0.075,
      planAmount: null,
    };
  },
  created() {
    this.$store.commit("setHudHeader", "New Subscription");
  },
  computed: {
    ...mapGetters("payments", ["getSelectedPlan"]), // Fetch selectedPlan from Vuex
    selectedPlan() {
      console.log("this.getSelectedPlan", this.getSelectedPlan);
      return this.getSelectedPlan || { name: "", options: [] };
    },
    totalCost() {
      const amount = Number(this.planAmount) || 0;
      const tax = Number(this.planTax) || 0;
      const total = amount + tax;
      console.log("planAmount:", amount, "planTax:", tax, "total:", total); // Should log as numbers
      return formatPriceAsCurrency(total);
    },
    planTax() {
      return this.planAmount * this.planTaxRate;
    },
  },
  methods: {
    // Method to set the selected plan option in Vuex based on user choice
    ...mapActions("payments", ["setCheckout"]),

    selectPlanOption(option) {
      const planDetails = {
        type: "subscription",
        name: this.selectedPlan.name, // Include name
        option: option.name, // Include billing frequency (e.g., "monthly" or "yearly")
        amount: option.amount,
        taxRate: this.planTaxRate,
      };
      this.planAmount = option.amount || 0;
      this.setCheckout(planDetails);
    },
    formatPriceAsCurrency(number) {
      return formatPriceAsCurrency(number);
    },
    goBack() {
      this.$store.dispatch("payments/setSelectedPlan", { name: "", options: [] });
      this.$router.push({
        name: "subscriptions.user_subscriptions.new",
        params: { userProfileId: this.$store.state.currentUserProfileId },
      });
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.new-subscription-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  &__header {
    margin: 35px 0;
  }

  &__body {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-basis: 550px;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__plan-details {
    flex-grow: 1;
    max-width: 600px;
    min-width: 350px;
    padding: 20px;
    background-color: $atmo-purple--dark;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    .plan-details__header {
      font-size: 1.2rem;
      color: white;
      margin-bottom: 1rem;
      text-align: center;
      text-transform: uppercase;
    }

    .plan-details__item {
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      border-bottom: 1px solid $atmo-purple--light;

      &:last-child {
        border-bottom: none;
      }
    }

    .plan-details__label {
      font-size: 1rem;
      font-weight: 500;
      color: white;
    }

    .plan-details__value {
      font-size: 1.1rem;
      font-weight: 600;
      color: $atmo-blue--medium;
    }

    .plan-details__options {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 8px;
    }

    .plan-details__option {
      display: flex;
      align-items: center;
      padding: 6px;
      border-radius: 6px;
    }

    .plan-details__option-text {
      margin-left: 10px;
      font-size: 1rem;
      color: white;
    }

    input[type="radio"] {
      accent-color: $atmo-purple--medium;
      cursor: pointer;
    }
  }

  &__empty-state {
    font-size: 1.5em;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }

  &__item {
    display: flex;
    background-color: rgba($atmo-purple--light, 0.4);
    margin: 5px 0;
    border-radius: 4px;
    padding: 4px;
  }

  &__item-image {
    height: 80px;
    width: 80px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $atmo-purple--light;
    background-size: 100%;
    border-radius: 3px;
  }

  &__item-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__item-header {
    display: flex;
    margin: 4px;
    margin-left: 8px;
  }

  &__item-title {
    flex-grow: 1;
    font-size: 1.1em;
    font-weight: 400;
  }

  &__delete-item {
    height: 10px;
    cursor: pointer;
  }

  &__item-hr {
    align-self: center;
    width: 95%;
    border: 0;
    height: 0;
    border-bottom: 1px solid $atmo-purple--light;
  }

  &__item-attributes {
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    flex-grow: 1;
  }

  &__item-attribute {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  &__item-attribute-label {
    font-size: 0.8em;
  }

  &__item-attribute-value {
    font-size: 0.9em;
    margin-bottom: 5px;

    &--color {
      display: flex;
      font-size: 0.8em;
      align-items: baseline;
    }

    &--total-price {
      color: $atmo-purple--dark;
      font-weight: 600;
    }
  }

  &__color {
    height: 8px;
    width: 8px;
    border-radius: 100%;
    border: 1px solid white;
    margin: 0 2px;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__attributes {
    display: flex;
    flex-direction: column;
    background-color: rgba($atmo-purple--dark, 0.8);
    border-radius: 5px;
  }

  &__attribute {
    display: flex;
    justify-content: space-between;
    font-size: 1.3em;
    text-transform: uppercase;
    padding: 17px 20px;
    border-bottom: 1px solid $atmo-purple--light;

    &:last-child {
      border-bottom: none;
    }

    &--total {
      color: $atmo-blue--medium;
      font-weight: 600;
    }
  }

  &__action-buttons {
    display: flex;
    justify-content: space-evenly;
    margin: 15px 0;
    flex-wrap: wrap;
  }
}
</style>
