<template>
  <div class="atmo-card">
    <!-- <h2 class="atmo-page-header">Add New Card</h2> -->
    <div v-if="customer.invoice_settings?.default_payment_method && sources.length" class="atmo-card__default-info">
      <h2 class="atmo-card__title">Default Card</h2>
      <div class="atmo-card__detail"><span>Type:</span> {{ defaultCard?.card.brand || "N/A" }}</div>
      <div class="atmo-card__detail"><span>Last 4:</span> {{ defaultCard?.card.last4 || "N/A" }}</div>
      <div class="atmo-card__detail"><span>Expiration:</span> {{ defaultCard?.card.exp_month || "N/A" }}/{{ defaultCard?.card.exp_year || "N/A" }}</div>
    </div>
    <div v-else class="atmo-card__no-default">No default card available.</div>

    <div class="atmo-card__input-section">
      <StripeElements v-if="stripeLoaded" v-slot="{ elements }" :stripe-key="stripeKey" ref="elms">
        <div class="atmo-card__inputs">
          <StripeElement type="cardNumber" ref="card" class="atmo-card__input" :elements="elements" :options="cardNumberOptions" />
          <StripeElement type="cardExpiry" ref="cardExpiry" class="atmo-card__input" :elements="elements" :options="expiryOptions" />
          <StripeElement type="cardCvc" ref="cardCvc" class="atmo-card__input" :elements="elements" :options="cvcOptions" />
        </div>
      </StripeElements>
    </div>

    <!-- Shipping Address Form -->
    <form>
      <div class="atmo-checkout__form-section-header">Shipping Address</div>
      <div class="atmo-checkout__input-row">
        <input v-model="addressData.firstName" placeholder="First Name*" class="atmo-input atmo-checkout__input" />
        <input v-model="addressData.lastName" placeholder="Last Name*" class="atmo-input atmo-checkout__input" />
      </div>
      <div class="atmo-checkout__input-row">
        <input v-model="addressData.addressLine1" placeholder="Address Line 1*" class="atmo-input atmo-checkout__input" />
      </div>
      <div class="atmo-checkout__input-row">
        <input v-model="addressData.addressLine2" placeholder="Address Line 2*" class="atmo-input atmo-checkout__input" />
      </div>
      <div class="atmo-checkout__input-row">
        <select v-model="addressData.country" @change="onCountryChange" class="atmo-input atmo-checkout__input atmo-checkout__input--purple dropdown-group">
          <option value="" disabled>Select Country*</option>
          <option v-for="country in countries" :key="country.id" :value="country.id">{{ country.name }}</option>
        </select>
        <select v-model="addressData.state" @change="onStateChange" class="atmo-input atmo-checkout__input dropdown-group">
          <option value="" disabled>Select State*</option>
          <option v-for="state in states" :key="state.id" :value="state.id">{{ state.name }}</option>
        </select>
      </div>
      <div class="atmo-checkout__input-row">
        <select v-model="addressData.city" class="atmo-input atmo-checkout__input dropdown-group">
          <option value="" disabled>Select City*</option>
          <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
        </select>
        <input v-model="addressData.zipCode" placeholder="Zip Code*" class="atmo-input atmo-checkout__input" />
      </div>
      <div class="atmo-checkout__input-row">
        <input v-model="addressData.phoneNumber" placeholder="Phone Number*" class="atmo-input atmo-checkout__input" />
      </div>
    </form>

    <button class="atmo-button atmo-button--primary atmo-card__add-button" @click="addCard">Add Card</button>
  </div>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { StripeElements, StripeElement } from "vue-stripe-js";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { callCardsCreate, callStripeCustomersGet, callStripeCustomerSourcesGet } from "@/helpers/axiosCalls";

export default {
  components: { StripeElements, StripeElement },
  setup() {
    const store = useStore();
    const router = useRouter();
    const stripeKey = ref("pk_test_UkAcQat2PhllsZTtEnv5Zvqw");
    const stripeLoaded = ref(false);
    const customer = reactive({});
    const sources = ref([]);
    const card = ref();
    const elms = ref();

    const cardNumberOptions = reactive({
      style: {
        base: { color: "#ffffff", fontSize: ".9rem", "::placeholder": { color: "#D8D8D8" } },
        invalid: { color: "#fa755a" },
      },
    });
    const expiryOptions = reactive({
      style: {
        base: { color: "#ffffff", fontSize: ".9rem", "::placeholder": { color: "#D8D8D8" } },
        invalid: { color: "#fa755a" },
      },
    });
    const cvcOptions = reactive({
      style: {
        base: { color: "#ffffff", fontSize: ".9rem", "::placeholder": { color: "#D8D8D8" } },
        invalid: { color: "#fa755a" },
      },
    });

    const addressData = reactive({
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
      phoneNumber: "",
    });

    // Computed properties for dropdown data
    const countries = computed(() => store.state.address.countries);
    const states = computed(() => store.state.address.states);
    const cities = computed(() => store.state.address.cities);

    // Computed properties for selected values
    const selectedCountryAbbreviation = computed(() => {
      const country = countries.value.find((c) => c.id === addressData.country);
      return country ? country.abbreviation : "";
    });

    const selectedStateName = computed(() => {
      const state = states.value.find((s) => s.id === addressData.state);
      return state ? state.name : "";
    });

    const selectedCityName = computed(() => {
      const city = cities.value.find((c) => c.id === addressData.city);
      return city ? city.name : "";
    });

    const fetchCustomerAndSources = async () => {
      const customerId = store.state.currentUser.customer_id;
      try {
        const customerResponse = await callStripeCustomersGet(customerId);
        Object.assign(customer, customerResponse);
        const sourcesResponse = await callStripeCustomerSourcesGet(customerId);
        sources.value = sourcesResponse.data;
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    const defaultCard = computed(() => sources.value.find((source) => source.id === customer.invoice_settings?.default_payment_method));

    // Trigger fetching of country and state data when mounted
    onMounted(() => {
      loadStripe(stripeKey.value).then(() => {
        stripeLoaded.value = true;
      });
      fetchCustomerAndSources();
      store.dispatch("address/fetchCountries");
      store.commit("setHudHeader", "Add New Card");
    });

    // Event handlers for dropdown changes
    const onCountryChange = () => {
      addressData.state = "";
      addressData.city = "";
      store.dispatch("address/fetchStates", addressData.country);
    };

    const onStateChange = () => {
      addressData.city = "";
      store.dispatch("address/fetchCities", addressData.state);
    };

    const addCard = async () => {
      try {
        if (card.value && card.value.stripeElement) {
          const cardElement = card.value.stripeElement;
          const result = await elms.value.instance.createToken(cardElement, {
            name: `${addressData.firstName} ${addressData.lastName}`,
            address_line1: addressData.addressLine1,
            address_line2: addressData.addressLine2,
            address_city: selectedCityName.value,
            address_state: selectedStateName.value,
            address_zip: addressData.zipCode,
            address_country: selectedCountryAbbreviation.value,
          });

          if (result.error) {
            console.error("Failed to create Stripe token:", result.error.message);
            return;
          }

          // Include address data in the API call to create the card
          await callCardsCreate(store.state.currentUserId, {
            card_token: result.token.id,
            user_id: store.state.currentUser.id,
            customer_id: store.state.currentUser.customer_id,
            address: {
              firstName: addressData.firstName,
              lastName: addressData.lastName,
              addressLine1: addressData.addressLine1,
              addressLine2: addressData.addressLine2,
              city: selectedCityName.value,
              state: selectedStateName.value,
              zipCode: addressData.zipCode,
              country: selectedCountryAbbreviation.value,
              phoneNumber: addressData.phoneNumber,
            },
          });

          router.push({
            name: "subscriptions.cards.index",
            params: { userProfileId: store.state.currentUserProfileId },
          });
        }
      } catch (error) {
        console.error("Error adding card:", error);
      }
    };

    return {
      stripeKey,
      stripeLoaded,
      customer,
      sources,
      card,
      elms,
      cardNumberOptions,
      expiryOptions,
      cvcOptions,
      defaultCard,
      addCard,
      addressData,
      countries,
      states,
      cities,
      onCountryChange,
      onStateChange,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 70vw;
  margin: 0 auto;

  &__title {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  &__default-info {
    background-color: $atmo-purple--medium-dark--faded;
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 20px;
    color: white;
  }

  &__detail {
    font-size: 0.9rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    span {
      font-weight: 600;
      color: white;
    }
  }

  &__no-default {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 20px;
  }

  &__input-section {
    width: 100%;
    margin-bottom: 20px;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__input {
    flex-grow: 1;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
    color: white;

    &--purple {
      background-color: $atmo-purple--dark;
    }

    &:focus {
      border-color: #0070f3;
    }
  }

  &__add-button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    background-color: #0070f3;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    width: 100%;
  }
}
</style>
