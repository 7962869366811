<template>
  <div class="player-bar__container" style="display: flex; justify-content: center; align-items: center; gap: 1rem">
    <p v-if="currentTrack">Now Playing: {{ currentTrack.name }}</p>
    <p v-if="currentTrack">Timestamp: {{ currentTimestamp }}</p>
    <button @click="togglePlayPause" class="player-bar__toggle">
      <span v-if="isPlaying">Pause</span>
      <span v-else>Play</span>
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    currentTrack() {
      return this.$store.getters["liveListeningRooms/currentTrack"];
    },
    isPlaying() {
      return this.$store.getters["liveListeningRooms/isPlaying"];
    },
    currentTimestamp() {
      return this.$store.getters["liveListeningRooms/currentTimestamp"];
    },
  },
  methods: {
    togglePlayPause() {
      this.$store.dispatch("liveListeningRooms/togglePlayPause");
    },
  },
};
</script>

<style scoped>
.player-bar__container {
  padding: 1rem;
}
.player-bar__toggle {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}
.player-bar__toggle:hover {
  background-color: #0056b3;
}
</style>
