<template>
  <div>
    <div v-for="(item, index) in items" :key="index" class="atmo-purchase__card">
      <div class="atmo-purchase__layout">
        <!-- Thumbnail Image -->
        <div class="atmo-purchase__image-container" :style="`background-image: url('${item.thumbnail}')`"></div>

        <!-- Item Details -->
        <div class="atmo-purchase__content">
          <h2 class="atmo-purchase__title">{{ item.name }}</h2>
          <div class="atmo-purchase__items">
            <!-- Color -->
            <div class="atmo-purchase__item-attributes">
              <div class="atmo-purchase__item-attribute">Color</div>
              <div class="atmo-purchase__item-attribute">{{ item.color }}</div>
            </div>

            <!-- Size -->
            <div class="atmo-purchase__item-attributes">
              <div class="atmo-purchase__item-attribute">Size</div>
              <div class="atmo-purchase__item-attribute">{{ item.size }}</div>
            </div>

            <!-- Price -->
            <div class="atmo-purchase__item-attributes">
              <div class="atmo-purchase__item-attribute">Price</div>
              <div class="atmo-purchase__item-attribute">${{ item.price.toFixed(2) }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    purchase: {
      type: Object,
      required: true,
    },
  },
  computed: {
    items() {
      // Transform purchase items
      const transaction = this.purchase;

      return (
        transaction.order?.order_items?.map((orderItem) => {
          const product = orderItem.product || {};
          const variant = orderItem.product_variant || {}; // Use product_variant directly
          const productVariantImages = variant.product_variant_images || [];
          const productImages = product.images || [];
          const thumbnail = productVariantImages[0]?.image?.image?.url || productImages[0]?.image?.url;

          return {
            name: product.title || "Unknown Product",
            description: product.description || "",
            color: variant.product_color?.name || "N/A",
            colorHex: variant.product_color?.name || null, // Use hex if available
            size: variant.product_size?.name || "N/A",
            price: parseFloat(variant.price) || 0,
            quantity: 1, // Default quantity, adjust if provided in response
            thumbnail,
          };
        }) || []
      );
    },
  },
};
</script>
