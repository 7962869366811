import Axios from 'axios';

export function callTransactionsIndex(userId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/transactions`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}

export function callTransactionsShow(userId, transactionId, queryParams = {}) {
    return Axios.get(`/api/v1/users/${userId}/transactions/${transactionId}`, { params: queryParams })
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
}