<template>
  <div class="coming-soon-container">
    <h2 class="atmo-page-header atmo-store__header">Coming Soon!</h2>
    <p>Keep an eye out!</p>
  </div>
</template>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.coming-soon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
