<template>
  <div class="hud-header__icon" data-cy="settings-dropdown" id="settings-link">
    <notification-dot dot-id="settings-dot" style="position: absolute; top: -5px; left: 23px"></notification-dot>
    <img @click="toggleDropdown" class="dropbtn" src="@/assets/images/icons/settings.png" />
    <div v-if="isDropdownVisible" class="hud-dropdown-menu">
      <router-link :to="{ name: 'notifications.index', params: { userId: user.id } }"> Notifications </router-link>
      <router-link v-if="!isCustomerPaying()" :to="getUpgradeRoute()"> Upgrade to Plus </router-link>
      <router-link v-if="isCustomerPaying()" :to="getProfileRoute('subscriptions.index')"> View Billing </router-link>
      <router-link :to="getProfileRoute(getProfileEditRouteName())"> Edit Profile </router-link>
      <router-link :to="{ name: 'purchases.index', params: { userId: user.id } }"> My Purchases </router-link>
      <router-link :to="{ name: 'AMI.settings' }"> My Settings </router-link>
      <router-link data-cy="directory-profiles" :to="{ name: 'directory_profiles.index' }"> Atmo Directory </router-link>
      <router-link :to="{ name: 'accomplishments.index', params: { userId: user.id } }"> My Accomplishments </router-link>
      <!-- <router-link :to="{ name: 'advertisements.index', params: { userId: user.id } }"> My Ads </router-link> -->
      <a @click="toggleMusicProfileOpen" style="cursor: pointer"> Music Profile </a>
      <a @click="toggleAtmoAdModalOpen" style="cursor: pointer"> Testing Ad Modal </a>
      <a style="cursor: pointer" @click="logout"> Logout </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import NotificationDot from "@/components/notifications/notification-dot";

export default {
  components: {
    NotificationDot,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      isDropdownVisible: false,
    };
  },
  methods: {
    ...mapMutations(["resetStore"]),
    ...mapMutations(["toggleAtmoAdModalOpen"]),
    ...mapMutations("musicProfiles", ["toggleMusicProfileOpen"]),
    ...mapActions("musicProfiles", ["fetchMusicProfileData"]),
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target) && this.isDropdownVisible) {
        this.isDropdownVisible = false;
      }
    },
    isCustomerPaying() {
      return this.user.customer_id !== null;
    },
    getUpgradeRoute() {
      switch (this.user.profile_type) {
        case "UserProfile":
          return { name: "subscriptions.user_plans.index", params: { userProfileId: this.user.profile_id } };
        case "ArtistProfile":
        case "LabelProfile":
          return { name: "subscriptions.artist_plans.index" };
      }
    },
    getProfileRoute(routeName) {
      return {
        name: routeName,
        params: this.getProfileParams(),
      };
    },
    getProfileParams(paramKey = "userProfileId") {
      switch (this.user.profile_type) {
        case "UserProfile":
          return { [paramKey]: this.user.profile_id };
        case "ArtistProfile":
          return { artistProfileId: this.user.profile_id };
        case "LabelProfile":
          return { labelProfileId: this.user.profile_id };
      }
    },
    getProfileEditRouteName() {
      switch (this.user.profile_type) {
        case "UserProfile":
          return "user_profiles.edit";
        case "ArtistProfile":
          return "artist_profiles.edit";
        case "LabelProfile":
          return "label_profiles.edit";
      }
    },
    logout() {
      localStorage.removeItem("token");
      this.resetStore();
      this.$router.push("/login");
    },
  },
  mounted() {
    window.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

#settings-link {
  position: relative;
}

.hud-dropdown-menu {
  background-color: rgba(96, 72, 117, 0.8);
  backdrop-filter: blur(5px);
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  top: 40px;
  left: -90px;
  border-radius: 5px;
  position: absolute;
  z-index: 10000000;

  a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;

    &:hover {
      background-color: #383054;
    }
  }
}

.dropbtn {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.show {
  display: block;
}
</style>
