<template>
  <div class="song-details" data-cy="song-details" v-if="song">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">Song Details</h2>
    </div> -->
    <atmo-back-link></atmo-back-link>
    <div class="song-details-container">
      <div class="song-details-container__image-container">
        <div
          v-if="song.album.image.url"
          class="image-container__image-wrap"
          v-bind:style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${song.album.image.url})` }"
        >
          <div class="image-wrap__song-info-container">
            <profile-link profile-type="ArtistProfile" :profile-id="song.primary_artist.id" class="song-info-container__artist-wrap">{{ song.primary_artist.user.name }}</profile-link>
            <router-link
              v-if="song.primary_artist.id && song.album_id"
              :to="{ name: 'albums.show', params: { artistProfileId: song.primary_artist.id, albumId: song.album_id } }"
              class="song-info-container__album-wrap"
              >{{ song.album.name }}</router-link
            >
            <router-link v-if="song.song_profile.primary_genre.id" :to="{ name: 'genres.show', params: { genreId: song.song_profile.primary_genre.id } }" class="song-info-container__genre-wrap">{{
              song.song_profile.primary_genre.name
            }}</router-link>
          </div>
        </div>
        <div class="image-container__song-wrap">
          <div class="song-wrap__play-button-wrap">
            <a class="player-bar__play-pause">
              <!-- Show the pause button when a song is playing -->
              <img v-show="isPlaying" class="player-bar__icon player-bar__icon--play-pause" src="@/assets/images/icons/pause.png" @click="pauseSong" />

              <!-- Show the play button when a song is not playing -->
              <img
                v-show="!isPlaying"
                class="player-bar__icon player-bar__icon--play-pause"
                :class="{ 'player-bar__icon--disabled': !currentSong }"
                src="@/assets/images/icons/play.png"
                @click="playFirstSong"
              />
            </a>
          </div>
          <div class="song-wrap__title-artist-wrap">
            <div class="title-artist-wrap__title">{{ song.name }}</div>
            <profile-link v-if="song.primary_artist.id" profile-type="ArtistProfile" :profile-id="song.primary_artist.id" class="title-artist-wrap__artist">{{
              song.primary_artist.user.name
            }}</profile-link>
          </div>
          <div class="song-wrap__vote-buttons">
            <atmo-voting-buttons :entity="song" voteableType="Song" />
          </div>
        </div>
      </div>

      <div class="song-details-container__comments-container">
        <div class="comments-container__title-container">
          <div class="title-container__icon-wrap">
            <img class="icon-wrap__icon" src="@/assets/images/icons/song_details/comment.png" />
          </div>
          <div class="title-container__text-wrap">All Comments</div>
        </div>
        <div class="comments-container">
          <song-comments-list :song="song" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import devMockData from "@/devUtils/mockData.js";
import AtmoVotingButtons from "@/components/atmo-voting-buttons";
import AtmoBackLink from "@/components/atmo-back-link";
import ProfileLink from "@/components/profile-link";
import SongCommentsList from "@/components/song-comments-list";
import { callSongsShow, callCommentsIndex } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoVotingButtons,
    AtmoBackLink,
    ProfileLink,
    SongCommentsList,
  },
  data() {
    return {
      // artists: null,
      // publicPath: process.env.BASE_URL
      devMockData,
      song: null,
      comments: null,
    };
  },
  computed: {
    // Get the current song and playing state from Vuex store
    currentSong() {
      return this.$store.state.player.currentSong;
    },
    isPlaying() {
      return this.$store.state.player.isPlaying;
    },
  },
  created() {
    this.getSongAndComments();
    this.$store.commit("setHudHeader", "Song Details");
  },
  methods: {
    async playFirstSong() {
      // Set the queue mode to 'queue'
      await this.$store.dispatch("atmoQueues/setQueueMode", "queue");

      // Ensure activeQueue is an array before attempting to merge it with the current song
      const currentQueue = Array.isArray(this.$store.state.player.activeQueue) ? this.$store.state.player.activeQueue : [];

      // Prepend the current song to the existing queue
      const queueWithCurrentSong = [this.song, ...currentQueue];

      // Set the updated queue as the active queue
      this.$store.commit("atmoQueues/setActiveQueue", queueWithCurrentSong);

      // Load the Howls for the updated queue
      await this.$store.dispatch("player/updateSongHowls", queueWithCurrentSong);

      // Play the first song in the updated queue
      this.$store.dispatch("player/prependAndPlaySong", queueWithCurrentSong[0]);
    },

    pauseSong() {
      this.$store.dispatch("player/pauseSong");
    },
    getSongAndComments() {
      callSongsShow(this.$route.params.songId)
        .then((song) => {
          this.song = song;

          callCommentsIndex({
            commentable_id: this.song.id,
            commentable_type: "Song",
          }).then((comments) => {
            this.comments = comments;
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    // getArtists() {
    //   console.log("BASE_URL", publicPath);
    // },
    toggleSelectedCategory(event) {
      console.log("EVENT", event);
      event.target.classList.toggle("selected-category");
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.song-details {
  margin: 0 75px;

  .ps {
    max-height: 27rem;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
    // border: 1px solid white;
  }

  .back-container__icon-container {
    margin-right: 0.5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .song-details-container {
    // border: 1px solid white;
    // display: grid;
    // gap: 30px;
    // grid-template-columns: repeat(2, 1fr);
    display: flex;
    gap: 1rem;
  }

  .song-details-container__image-container {
    // margin-bottom: 1.5rem;
    // min-height: 50vh;
    // height: 5rem;
    min-height: 22rem;
    min-width: 22rem;
    border-radius: 5px;
  }

  .image-container__image-wrap {
    height: 22rem;
    width: 22rem;
    border-radius: 5px;
    // display: flex;
    // flex-direction: column;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-bottom: 1rem;
  }

  .image-wrap__song-info-container {
    position: absolute;
    bottom: 0;
    padding: 0.6rem;
    background: rgba(0, 0, 0, 0.4);
    width: 100%;
    border-radius: 0px 0px 5px 5px;
  }

  .song-info-container__artist-wrap {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 0.2rem;
    display: block;
  }

  .song-info-container__album-wrap {
    font-weight: 800;
    font-size: 1.2rem;
    color: $atmo-blue--medium;
    margin-bottom: 0.2rem;
    display: block;
  }

  .song-info-container__genre-wrap {
    color: white;
    display: block;
  }

  .image-container__song-wrap {
    display: flex;
  }

  .song-wrap__play-button-wrap {
    display: flex;
    margin-right: 1rem;

    a {
      padding: 0;
    }
  }

  .song-wrap__title-artist-wrap {
    margin-right: 1rem;
    width: 100%;
  }

  .title-artist-wrap__title {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .title-artist-wrap__artist {
    color: $atmo-gray--light;
  }

  .song-wrap__vote-buttons {
    // width: 100%;
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: end;

    //CLEAN THIS UP
    .atmo-voting-buttons {
      height: 2rem;
      position: relative;
      flex-direction: row;
      max-width: none;
      width: 10rem;

      // .atmo-button {
      // }
    }
  }

  .song-details-container__comments-container {
    padding: 0rem 0.5rem 0rem 0.5rem;
    width: 100%;
  }

  .comments-container__title-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  .title-container__icon-wrap {
    margin-right: 0.5rem;

    .icon-wrap__icon {
      height: 1.5rem;
      object-fit: contain;
    }
  }

  .title-container__text-wrap {
    font-size: 1.5rem;
  }

  .comments-container__input-wrap {
    margin-bottom: 1rem;
    width: 100%;

    .input-wrap__input {
      width: 100%;
      padding: 10px 10px 10px 15px;
      background: rgba(255, 255, 255, 0);
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 20px;
      color: white;
    }
  }

  .comment-container__image-name-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.2rem;

    .image-wrap__image {
      height: 1.7rem;
      width: 1.7rem;
      object-fit: cover;
      border-radius: 100%;
      margin-right: 0.5rem;
    }
  }

  .image-name-container__name-wrap {
    font-weight: 500;
  }

  .comment-container__content-container {
    background: rgba(255, 255, 255, 0.1);
    padding: 0.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
  }

  .content-container__content-wrap {
    margin-bottom: 0.5rem;
  }

  .content-container__time-wrap {
    font-weight: 400;
    color: rgba(255, 255, 255, 0.3);
    font-size: 0.6rem;
  }
}
</style>
