<template>
  <div class="directory-profile-page" v-if="profile">
    <!-- <div class="header-container">
            <h2 class="atmo-page-header">
                {{ profile.name }}
            </h2>
        </div> -->
    <router-link class="back-container" :to="{ name: 'directory_profiles.index' }">
      <div class="back-container__icon-container">
        <img class="icon-container__back-icon" src="@/assets/images/icons/back.png" />
      </div>
      <div class="back-container__text-container">Back To Directory</div>
    </router-link>
    <div class="directory-profile-grid">
      <div
        class="directory-profile-grid__image-container"
        v-bind:style="{
          'background-image': `linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%), url(${profile.user.profile_image.image.url})`,
        }"
      ></div>
      <div class="directory-profile-grid__details-container">
        <div class="details-container__links-wrap">
          <div class="links-wrap__header-wrap">
            <h3 class="pt-2 atmo-subtitle">
              {{ profile.name }}
            </h3>
          </div>
        </div>
        <div class="details-container__profile-info-container">
          <div class="profile-info-container__profile-detail-wrap" data-cy="">
            <div class="label-wrap">Profession:</div>
            <div class="text-wrap">{{ profile.profession }}</div>
          </div>
          <div class="profile-info-container__profile-detail-wrap" data-cy="">
            <div class="label-wrap">Rate:</div>
            <div class="text-wrap">${{ profile.hourly_rate }}/hr</div>
          </div>
          <div class="profile-info-container__profile-detail-wrap" data-cy="">
            <div class="label-wrap">Location:</div>
            <div class="text-wrap">{{ profile.location }}</div>
          </div>
          <div class="profile-info-container__profile-detail-wrap" data-cy="">
            <div class="label-wrap">Contact:</div>
            <div class="text-wrap">{{ profile.contact_info }}</div>
          </div>
          <div class="profile-info-container__profile-detail-wrap" data-cy="">
            <div class="label-wrap">Website:</div>
            <div class="text-wrap">{{ profile.website }}</div>
          </div>
          <div class="profile-info-container__profile-detail-wrap" data-cy="">
            <div class="text-wrap">
              <router-link :to="{ name: 'advertisements.index' }"> View My Ads </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="directory-profile-grid__ads-container">
        <atmo-directory-ad />
        <atmo-directory-ad />
        <atmo-directory-ad />
        <atmo-directory-ad />
        <atmo-directory-ad />
        <atmo-directory-ad />
        <atmo-directory-ad />
        <atmo-directory-ad />
      </div>
    </div>
  </div>
</template>

<script>
import AtmoDirectoryAd from "@/components/atmo-directory/atmo-directory-ad";
import { callDirectoryProfilesShow } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoDirectoryAd,
  },
  data: function () {
    return {
      profile: null,
    };
  },
  async created() {
    await this.fetchDirectoryProfile();
    this.$store.commit("setHudHeader", this.profile.name);
  },
  methods: {
    fetchDirectoryProfile() {
      const profileId = this.$route.params.directoryProfileId;
      callDirectoryProfilesShow(null, { profile_id: profileId })
        .then((data) => {
          this.profile = data;
        })
        .catch((error) => {
          console.error("Failed to fetch profile:", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/stylesheets/_variables.scss";

.directory-profile-page {
  margin: 0 75px;

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .back-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    max-width: 10rem;
    margin-bottom: 1rem;
  }

  .back-container__icon-container {
    margin-right: 0.5rem;
  }

  .icon-container__back-icon {
    height: 1rem;
  }

  .directory-profile-grid {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    gap: 2rem;
  }

  .directory-profile-grid__image-container {
    width: 100%;
    max-height: 30rem;
    min-width: 20rem;
    background-size: cover;
    position: relative;
    padding: 1rem;
  }

  .details-container__links-wrap {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .details-container__profile-info-container {
    &__share-link {
      width: 2.5rem;
    }

    img {
      height: 1.2rem;
      color: white;
    }

    .label-wrap {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-right: 0.5rem;
    }

    .text-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .profile-info-container__profile-detail-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 1rem;
      font-weight: 500;
    }
  }

  .directory-profile-grid__ads-container {
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .directory-ad-container__directory-ad-wrap + .directory-ad-container__directory-ad-wrap {
      margin-top: 1rem;
    }
  }
}
</style>
