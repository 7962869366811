<template>
  <div class="edit-advertisement" data-cy="edit-advertisements">
    <div class="header-container">
      <h2 class="atmo-page-header">Edit Ad</h2>
    </div>

    <div class="edit-advertisement-grid-container">
      <perfect-scrollbar class="edit-advertisement-grid-container__campaign-info-container">
        <div class="field-group">
          <div class="field-wrap">
            <label for="adImage">Contest Image</label>
            <atmo-upload-card id="adImage" :image-src="formSelectedImage" :is-dropzone="true" :on-files-selected="onImageSelected" alt="Upload Image" componentStyle="width:auto; height:9rem;">
              Contest Image
            </atmo-upload-card>
          </div>
          <div class="field-wrap">
            <label for="button-label">Duration</label>
            <div class="light-background">
              <fieldset>
                <div class="radio-field-wrap">
                  <input class="radio-field" type="radio" id="continuous" name="duration" value="continuous" v-model="durationType" />
                  <label class="radio-field-label" for="continuous">Run ads continuously until paused</label>
                </div>
                <div class="radio-field-wrap">
                  <input class="radio-field" type="radio" id="specific-duration" name="duration" value="duration" v-model="durationType" />
                  <label class="radio-field-label" for="specific-duration">Choose a specific duration (From - To)</label>
                </div>
              </fieldset>
              <div v-if="durationType === 'duration'">
                <div class="datepicker-container">
                  <label for="start-date">Start Date</label>
                  <DatePicker v-model="formSelectedStartDate" :format="'yyyy-MM-dd'" placeholder="Select Start Date" aria-invalid="submitAttempted && !formSelectedStartDate" />

                  <label for="end-date">End Date</label>
                  <DatePicker v-model="formSelectedEndDate" :format="'yyyy-MM-dd'" placeholder="Select End Date" aria-invalid="submitAttempted && !formSelectedEndDate" />
                </div>
              </div>
            </div>
          </div>
          <div class="field-wrap">
            <div class="column-title">Budget</div>
            <div class="light-background">
              <fieldset class="radio-group" id="ad-budget">
                <div class="radio-field-wrap">
                  <input class="radio-field" type="radio" id="daily" name="budget" value="daily" v-model="budgetType" />
                  <label class="radio-field-label" for="daily">Daily budget</label>
                </div>
                <div class="radio-field-wrap">
                  <input class="radio-field" type="radio" id="total" name="budget" value="total" v-model="budgetType" />
                  <label class="radio-field-label" for="total">Total budget</label>
                </div>
              </fieldset>
              <div v-if="budgetType === 'daily' || budgetType === 'total'">
                <input v-model="budget" class="text-field" type="number" placeholder="Enter your budget" />
              </div>
            </div>
          </div>
          <!-- Targeting Section -->
          <div class="field-group">
            <h3 class="targeting-header">Targeting Options</h3>

            <!-- Country -->
            <div class="field-wrap">
              <label for="country">Country</label>
              <select id="country" v-model="selectedCountry" @change="fetchStates(selectedCountry)" class="dropdown-group">
                <option value="" disabled>Select Country</option>
                <option v-for="country in countries" :key="country.id" :value="country.id">
                  {{ country.name }}
                </option>
              </select>
            </div>

            <!-- State -->
            <div class="field-wrap">
              <label for="state">State</label>
              <select id="state" v-model="selectedState" @change="fetchCities(selectedState)" :disabled="!states.length" class="dropdown-group">
                <option value="" disabled>
                  {{ states.length ? "Select State" : "No States Available" }}
                </option>
                <option v-for="state in states" :key="state.id" :value="state.id">
                  {{ state.name }}
                </option>
              </select>
            </div>

            <!-- City -->
            <div class="field-wrap">
              <label for="city">City</label>
              <select id="city" v-model="selectedCity" class="dropdown-group">
                <option value="" disabled>Select City</option>
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
            </div>

            <!-- Gender -->
            <div class="field-wrap">
              <label for="gender">Gender</label>
              <fieldset class="radio-group" id="gender">
                <div class="radio-field-wrap">
                  <input class="radio-field" type="radio" id="male" name="gender" value="male" v-model="selectedGender" />
                  <label class="radio-field-label" for="male">Male</label>
                </div>
                <div class="radio-field-wrap">
                  <input class="radio-field" type="radio" id="female" name="gender" value="female" v-model="selectedGender" />
                  <label class="radio-field-label" for="female">Female</label>
                </div>
                <div class="radio-field-wrap">
                  <input class="radio-field" type="radio" id="all" name="gender" value="all" v-model="selectedGender" />
                  <label class="radio-field-label" for="all">All</label>
                </div>
              </fieldset>
            </div>

            <!-- Age Range -->
            <div class="field-wrap">
              <label for="age-range">Age Range</label>
              <div class="age-range-dropdowns">
                <select v-model="selectedAgeFrom" class="dropdown-group">
                  <option v-for="age in ageOptions" :key="'from-' + age" :value="age">
                    {{ age }}
                  </option>
                </select>

                <span class="age-range-separator">to</span>

                <select v-model="selectedAgeTo" class="dropdown-group">
                  <option v-for="age in ageOptions" :key="'to-' + age" :value="age">
                    {{ age }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
      <div class="edit-advertisement-grid-container__ad-info-container">
        <div class="field-group">
          <div class="field-wrap">
            <label for="ad-title">Ad Title</label>
            <input v-model="adTitle" class="text-field" type="text" id="ad-title" name="ad-title" placeholder="Ad Title" />
          </div>
          <div class="field-wrap">
            <label for="button-label">Button Label</label>
            <input v-model="adButtonLabel" class="text-field" type="text" id="button-label" name="button-label" placeholder="See details" />
          </div>
          <div class="field-wrap">
            <label for="ad-link">Ad Link</label>
            <input v-model="adLink" class="text-field" type="text" id="ad-link" name="ad-link" placeholder="Ad link here" />
          </div>
          <div class="field-wrap">
            <label for="ad-description">Ad description</label>
            <textarea v-model="adDescription" class="textarea-field" id="ad-description" name="ad-description" rows="4" cols="50" placeholder="Ad description text here"></textarea>
          </div>

          <div class="ad-info-container__toggle-container">
            <div class="column-title">Ad Is Live?</div>
            <div class="toggle-container__toggle-wrap">
              <atmo-toggle :on="adIsLive" :on-change="toggleAdIsLive" size="large" />
            </div>
          </div>
        </div>

        <div class="ad-info-container__button-wrap">
          <button class="button-wrap__save-button" @click="updateAd">Update Ad</button>
        </div>
      </div>
      <div class="edit-advertisement-grid-container__ad-preview-container">
        <div class="ad-preview-container__ad-wrap">
          <div class="column-title">Ads Preview</div>
          <div class="ad-wrap__card">
            <div
              class="card__image-wrap"
              v-bind:style="{
                'background-image': selectedImage ? 'url(' + selectedImage + ')' : 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)',
              }"
            ></div>
            <div class="card__text-wrap">
              <div class="text-wrap__title-wrap">{{ adTitle }}</div>
              <div class="text-wrap__body-wrap">{{ adDescription }}</div>
            </div>
            <div class="text-wrap__button-popover-wrap">
              <div class="button-popover-wrap__button-wrap">
                <a class="button-wrap__button" :href="adLink" target="_blank">
                  {{ adButtonLabel || "See details" }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="ad-preview-container__budget-info-container">
          <div class="budget-info-container__text-wrap">
            <div class="text-wrap__text">
              Ads will run
              <span v-if="durationType === 'duration'"> from {{ formattedStartDate }} to {{ formattedEndDate }} </span>
              <span v-if="durationType === 'continuous'"> until paused </span>
            </div>
          </div>
          <div class="budget-info-container__budget-wrap">
            <div class="budget-button-container__budget-wrap">{{ budget ? returnCurrencyFormat(budget) : "N/A" }}</div>
            <div class="budget-button-container__budget-type-wrap">{{ budgetType }} Budget</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import { callPlatformAdsCreate, callPlatformAdsShow, callPlatformAdsUpdate } from "@/helpers/axiosCalls";
import AtmoUploadCard from "@/components/atmo-upload-card";
import dayjs from "dayjs";
import DatePicker from "vue3-datepicker";
import AtmoToggle from "@/components/atmo-toggle";
import { formatPriceAsCurrency } from "@/helpers/utilityFunctions";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    AtmoUploadCard,
    DatePicker,
    AtmoToggle,
  },
  data: function () {
    return {
      adId: null, // Hold the ad ID for editing
      adTitle: "",
      adButtonLabel: "",
      adLink: "",
      adDescription: "",
      durationType: "continuous", // Default to continuous
      budgetType: "daily", // Default to daily
      budget: 0,
      selectedImage: null,
      formSelectedImage: null,
      formSelectedImageFile: null,
      submitAttempted: false,
      formSelectedStartDate: null,
      formSelectedEndDate: null,
      adIsLive: null,
      // Targeting Data
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      selectedGender: "all", // Default to All
      // Age dropdown options from 13 to 100
      ageOptions: Array.from({ length: 88 }, (_, i) => i + 13), // [13, 14, ..., 100]

      // Default age range selection
      selectedAgeFrom: null,
      selectedAgeTo: null,
    };
  },
  computed: {
    ...mapState("address", ["countries", "states", "cities"]),
    formattedStartDate() {
      if (this.formSelectedStartDate) {
        return dayjs(this.formSelectedStartDate).format("MMMM DD, YYYY");
      }
      return "N/A";
    },
    formattedEndDate() {
      if (this.formSelectedEndDate) {
        return dayjs(this.formSelectedEndDate).format("MMMM DD, YYYY");
      }
      return "N/A";
    },
  },
  methods: {
    ...mapActions("address", ["fetchCountries", "fetchStates", "fetchCities"]),
    returnCurrencyFormat(price) {
      return formatPriceAsCurrency(price);
    },
    toggleAdIsLive() {
      this.adIsLive = !this.adIsLive;
    },
    onImageSelected(files) {
      this.formSelectedImageFile = files[0];
      this.selectedImage = URL.createObjectURL(files[0]);
    },
    updateAd() {
      const formData = new FormData();

      // Append data based on the current ad form
      formData.append("title", this.adTitle);
      formData.append("copy", this.adDescription);
      formData.append("link_label", this.adButtonLabel);
      formData.append("link", this.adLink);
      formData.append("duration_type", this.durationType);
      formData.append("budget_type", this.budgetType);
      formData.append("budget", this.budget);
      formData.append("user_id", 1); // Example, replace with actual user ID
      formData.append("status", "active"); // Initially set the ad as active
      formData.append("start_date", this.formSelectedStartDate);
      formData.append("end_date", this.formSelectedEndDate);
      formData.append("is_live", this.adIsLive);

      formData.append("country_id", this.selectedCountry);
      formData.append("state_id", this.selectedState);
      formData.append("city_id", this.selectedCity);
      formData.append("gender", this.selectedGender);
      formData.append("age_min", this.selectedAgeFrom);
      formData.append("age_max", this.selectedAgeTo);

      if (this.formSelectedImageFile) {
        formData.append("image", this.formSelectedImageFile);
      }

      callPlatformAdsUpdate(this.$store.state.currentUserId, this.adId, formData)
        .then((response) => {
          console.log("Ad updated successfully:", response);

          this.$router.push({
            name: "advertisements.index",
            params: { userId: this.$store.state.currentUserId },
          });
        })
        .catch((error) => {
          console.error("Error updating ad:", error);
        });
    },
    fetchAdDetails(adId) {
      callPlatformAdsShow(this.$store.state.currentUserId, adId)
        .then(async (adData) => {
          this.adId = adId;
          this.adTitle = adData.title;
          this.adButtonLabel = adData.link_label;
          this.adLink = adData.link;
          this.adDescription = adData.copy;
          this.durationType = adData.duration_type;
          this.budgetType = adData.budget_type;
          this.budget = adData.budget;
          // Parse start_date and end_date to JavaScript Date objects
          this.formSelectedStartDate = this.formSelectedStartDate || dayjs().toDate(); // Set default date if null
          this.formSelectedEndDate = this.formSelectedEndDate || dayjs().add(1, "month").toDate(); // Set a default end date

          this.selectedImage = adData.image.url;
          this.adIsLive = adData.is_live;

          this.selectedCountry = adData.country_id;
          this.selectedState = adData.state_id;
          this.selectedCity = adData.city_id;
          this.selectedGender = adData.gender;
          this.selectedAgeFrom = adData.age_min;
          this.selectedAgeTo = adData.age_max;

          await this.fetchCountries();

          if (this.selectedCountry) {
            await this.fetchStates(this.selectedCountry);
          }

          if (this.selectedState) {
            await this.fetchCities(this.selectedState);
          }
        })
        .catch((error) => {
          console.error("Error fetching ad details:", error);
        });
    },
  },
  mounted() {
    const adId = this.$route.params.adId; // Assuming the adId is passed via route params

    if (adId) {
      this.fetchAdDetails(adId);
    }
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.edit-advertisement {
  margin: 0 75px;

  input[type="radio"] {
    position: relative;
    width: 1.2em;
    height: 1.2em;
    color: #363839;
    border-radius: 100%;
    appearance: none;
    outline: 0;
    cursor: pointer;
    transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
    background: $atmo-purple--dark;

    &::before {
      position: absolute;
      content: "";
      display: block;
      transform: rotate(45deg);
      opacity: 0;
    }

    &:checked {
      color: $atmo-purple--dark;
      border: 2px solid $atmo-purple--dark;
      background: $atmo-pink--medium;

      &::before {
        opacity: 1;
      }

      ~ label::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-advertisement-grid-container {
    display: grid;
    gap: 30px;
    grid-template-columns: repeat(3, 1fr);
  }

  .column-title {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .edit-advertisement-grid-container__campaign-info-container {
    .datepicker-container {
      margin-top: 1rem;

      input {
        margin-bottom: 0.5rem;
        background: transparent;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.4);
        padding: 0.5rem 1rem 0.5rem 1rem;
        color: white;
        width: 100%;
        position: relative;
      }

      .v3dp__element__button__day {
        &.selected {
          span {
            background-color: $atmo-purple--medium-dark;
          }
        }

        &:hover {
          span {
            background-color: $atmo-purple--medium-dark;
          }
        }

        label {
          display: block;
          margin-bottom: 0.5rem;
        }
      }
    }

    .targeting-header {
      margin: 1rem 0rem 1rem 0rem;
    }

    .age-range-dropdowns {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .age-range-separator {
      font-size: 1rem;
      font-weight: 500;
      color: white;
    }
  }

  .edit-advertisement-grid-container__ad-info-container {
  }

  .ad-info-container__toggle-container {
    margin-top: 2rem;
  }

  .field-group {
    margin-bottom: 1rem;
  }

  .field-wrap + .field-wrap {
    margin-top: 1rem;
  }

  .field-wrap {
    display: flex;
    flex-direction: column;

    label {
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-size: 0.9rem;
      font-weight: 500;
    }

    .text-field {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      color: white;
    }

    .textarea-field {
      background: transparent;
      border-radius: 5px;
      border: 1px solid rgba(255, 255, 255, 0.4);
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;
      color: white;
      font-family: "Roboto", sans-serif;
    }

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(255, 255, 255, 0.4);
      opacity: 1;
      /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: rgba(255, 255, 255, 0.4);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: rgba(255, 255, 255, 0.4);
    }

    .dropdown-group {
      background: $atmo-purple--medium-dark;
      color: white;
      border: none;
      border-radius: 5px;
      padding: 0.5rem;
      font-weight: 500rem;
      cursor: pointer;
    }

    .dropdown-field {
    }

    .radio-group {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0.6rem 0rem 0.6rem 0rem;
    }

    .radio-field-wrap {
      margin-right: 1rem;
      display: flex;
      align-items: center;
    }

    .radio-field-wrap + .radio-field-wrap {
      margin-top: 1rem;
    }

    .radio-field {
      margin-right: 0.5rem;
      cursor: pointer;
    }

    .radio-field-label {
      margin: 2px 0px 0px 0px;
      text-transform: none;
    }
  }

  .upload-image-video-container {
    border: 1px dashed rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    padding: 1rem;
    cursor: pointer;
  }

  .upload-image-video-container__text-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.4);
  }

  .button-wrap__save-button {
    font-size: 1rem;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    margin-right: 1rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }

  .edit-advertisement-grid-container__ad-preview-container {
    // border: 1px solid white;
  }

  .ad-wrap__card {
    background: rgba(255, 255, 255, 0.1);
    min-height: 15rem;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    border-radius: 5px;
  }

  .card__image-wrap {
    height: 7rem;
    margin-bottom: 1rem;
    position: relative;
  }

  .card__text-wrap {
    // display: flex;
    // flex-direction: column;
    // flex: 1;
    // justify-content: space-between;
    // margin-top: 1rem;
  }

  .text-wrap__title-wrap {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }

  .text-wrap__body-wrap {
    margin-bottom: 1rem;
  }

  .button-wrap__button {
    display: inline-block;
    color: white;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid white;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
  }

  .ad-preview-container__ad-wrap {
    margin-bottom: 1rem;
  }

  .ad-preview-container__budget-info-container {
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 5px;
  }

  .text-wrap__text {
    font-size: 0.9rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .budget-info-container__budget-wrap {
    display: flex;
    gap: 1rem;
  }

  .budget-button-container__budget-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
  }

  .budget-button-container__budget-type-wrap {
    margin-left: auto;
    background-color: rgba(96, 72, 117, 0.4);
    border-radius: 5px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    text-transform: uppercase;
    font-weight: 500;
  }

  .light-background {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 0.5rem;
  }
}
</style>
