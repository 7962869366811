import { getFromLocalStorage, saveToLocalStorage } from '@/helpers/utilityFunctions';
import {
    callProductCategoriesIndex,
    callProductSizeSystemsIndex,
    callProductColorsIndex,
    callProductSizesIndex,
    callMeasurementSystemsIndex,
    callMeasurementUnitsIndex,
    callProductsIndex,
} from '@/helpers/axiosCalls';

const initialState = {
    debug: false,
    cart: [],
    cartCost: 0,
    cartSummary: {
        cartTaxRate: 0,
        cartTax: 0,
        cartShippingCost: 0,
        totalCost: 0,
    },
    categories: [],
    sizeSystems: [],
    colors: [],
    sizes: [],
    measurementSystems: [],
    measurementUnits: [],
    newProductData: {},
    editProductData: {},
    products: [],
    selectedCategoryId: null,
};

export default {
    namespaced: true,
    state: { ...initialState },
    mutations: {
        setCategories(state, categories) {
            if (state.debug) console.log('setCategories:', categories);
            state.categories = categories;
        },
        setSizeSystems(state, sizeSystems) {
            if (state.debug) console.log('setSizeSystems:', sizeSystems);
            state.sizeSystems = sizeSystems;
        },
        setColors(state, colors) {
            if (state.debug) console.log('setColors:', colors);
            state.colors = colors;
        },
        setSizes(state, sizes) {
            if (state.debug) console.log('setSizes:', sizes);
            state.sizes = sizes;
        },
        setMeasurementSystems(state, systems) {
            if (state.debug) console.log('setMeasurementSystems:', systems);
            state.measurementSystems = systems;
        },
        setMeasurementUnits(state, units) {
            if (state.debug) console.log('setMeasurementUnits:', units);
            state.measurementUnits = units;
        },
        setNewProductData(state, data) {
            if (state.debug) console.log('setNewProductData:', data);
            state.newProductData = data;
        },
        setEditProductData(state, data) {
            if (state.debug) console.log('setEditProductData:', data);
            state.editProductData = data;
        },
        getCartFromStorage(state) {
            const cartValue = getFromLocalStorage('shoppingCart');
            if (cartValue) {
                if (state.debug) console.log('getCartFromStorage: Cart loaded from storage', cartValue);
                state.cart = cartValue;
            } else {
                if (state.debug) console.log('getCartFromStorage: No cart found in storage');
            }
        },
        addToCart(state, productVariant) {
            if (state.debug) console.log('addToCart: Adding product variant to cart', productVariant);
            state.cart.push(productVariant);
            saveToLocalStorage('shoppingCart', state.cart, 60);
        },
        removeFromCart(state, variantIndex) {
            if (state.debug) console.log('removeFromCart: Removing product variant from cart', state.cart[variantIndex]);
            state.cart.splice(variantIndex, 1);
            saveToLocalStorage('shoppingCart', state.cart, 60);
        },
        removeGroupFromCart(state, variant) {
            if (state.debug) console.log('removeGroupFromCart: Removing group of product variants', variant);
            state.cart = state.cart.filter(item => item.id !== variant.id);
            saveToLocalStorage('shoppingCart', state.cart, 60);
        },
        updateCartCost(state) {
            state.cartCost = state.cart.reduce((acc, curr) => acc + parseFloat(curr.price), 0);
            if (state.debug) console.log('updateCartCost: Cart cost updated', state.cartCost);
        },
        setCartSummary(state, { cartTaxRate, cartTax, cartShippingCost, totalCost }) {
            if (state.debug) {
              console.log('setCartSummary:', { cartTaxRate, cartTax, cartShippingCost, totalCost });
            }
            state.cartSummary.cartTaxRate = cartTaxRate;
            state.cartSummary.cartTax = cartTax;
            state.cartSummary.cartShippingCost = cartShippingCost;
            state.cartSummary.totalCost = totalCost + cartTax + cartShippingCost;
        },
        setProducts(state, products) {
            if (state.debug) console.log('setProducts:', products);
            state.products = products;
        },
        setSelectedCategoryId(state, id) {
            if (state.debug) console.log('setSelectedCategoryId:', id);
            state.selectedCategoryId = id;
        },
        resetState(state) {
            Object.assign(state, { ...initialState, cart: [], cartCost: 0 });
            if (state.debug) console.log('resetState: State has been reset');
        },
    },
    actions: {
        async fetchCategories({ commit, state }) {
            try {
                const response = await callProductCategoriesIndex();
                if (state.debug) console.log('fetchCategories: Fetched Categories', response);
                commit('setCategories', response);
            } catch (error) {
                console.error('fetchCategories: Error fetching categories', error);
            }
        },
        async fetchSizeSystems({ commit, state }) {
            const response = await callProductSizeSystemsIndex();
            if (state.debug) console.log('fetchSizeSystems: Fetched Size Systems', response);
            commit('setSizeSystems', response);
        },
        async fetchColors({ commit, state }) {
            const response = await callProductColorsIndex();
            if (state.debug) console.log('fetchColors: Fetched Colors', response);
            commit('setColors', response);
        },
        async fetchSizes({ commit, state }) {
            const response = await callProductSizesIndex();
            if (state.debug) console.log('fetchSizes: Fetched Sizes', response);
            commit('setSizes', response);
        },
        async fetchMeasurementSystems({ commit, state }) {
            const response = await callMeasurementSystemsIndex();
            if (state.debug) console.log('fetchMeasurementSystems: Fetched Measurement Systems', response);
            commit('setMeasurementSystems', response);
        },
        async fetchMeasurementUnits({ commit, state }) {
            const response = await callMeasurementUnitsIndex();
            if (state.debug) console.log('fetchMeasurementUnits: Fetched Measurement Units', response);
            commit('setMeasurementUnits', response);
        },
        async fetchProducts({ commit, state }, params = {}) {
            const response = await callProductsIndex(params);
            if (state.debug) console.log('fetchProducts: Fetched Products', response);
            commit('setProducts', response);
        },
        clearCart({ commit, state }) {
            localStorage.removeItem('shoppingCart');
            commit('resetState');
            commit('updateCartCost');  // Recalculate cart cost immediately after resetting
            if (state.debug) console.log('clearCart: Cart cleared and state reset');
        }        
    },
    getters: {
        getCategoryById: (state) => (id) => {
            const category = state.categories.find(category => category.id === id);
            if (state.debug) console.log('getCategoryById:', category);
            return category;
        },
        getSizeSystemById: (state) => (id) => {
            const sizeSystem = state.sizeSystems.find(sizeSystem => sizeSystem.id === id);
            if (state.debug) console.log('getSizeSystemById:', sizeSystem);
            return sizeSystem;
        },
        getColorById: (state) => (id) => {
            const color = state.colors.find(color => color.id === id);
            if (state.debug) console.log('getColorById:', color);
            return color;
        },
        getSizeById: (state) => (id) => {
            const size = state.sizes.find(size => size.id === id);
            if (state.debug) console.log('getSizeById:', size);
            return size;
        },
        getMeasurementSystemById: (state) => (id) => {
            const system = state.measurementSystems.find(system => system.id === id);
            if (state.debug) console.log('getMeasurementSystemById:', system);
            return system;
        },
        getMeasurementUnitById: (state) => (id) => {
            const unit = state.measurementUnits.find(unit => unit.id === id);
            if (state.debug) console.log('getMeasurementUnitById:', unit);
            return unit;
        },
        filteredProducts: (state) => {
            const filtered = state.products.filter(product => {
                if (state.selectedCategoryId === null) {
                    return true;
                } else {
                    return product.product_category_id === state.selectedCategoryId;
                }
            });
            if (state.debug) console.log('filteredProducts:', filtered);
            return filtered;
        },
        cartCount: (state) => {
            if (state.debug) console.log('cartCount:', state.cart.length);
            return state.cart.length;
        },
        cartSummary: (state) => {
            if (state.debug) console.log('cartSummary:', state.cartSummary);
            return state.cartSummary;
        },
    },
};
