import {
    callUserFriendshipsIndex,
    callChatMessagesIndex,
    callChatRoomsIndex
} from '@/helpers/axiosCalls';

export default {
    namespaced: true,
    state: {
        debug: false,
        maxChatOpen: false,
        chatImageModalOpen: false,
        chatImageModalUrl: "",
        currentChatTitleData: {
            name: "Messages",
            roomType: "group",
            profileType: null,
            profileId: null
        },
        currentChatRoom: {},
        groupChatRooms: [],
        currentChatRoomMessages: [],
        chatUserFriendships: [],
        createGroupChatModalOpen: false,
        groupChatSettingsModalOpen: false,
        chatCurrentView: "friends",
        currentChatRoomUsers: [],
    },
    mutations: {
        toggleMaxChatOpen(state) {
            state.maxChatOpen = !state.maxChatOpen;
            if (state.debug) console.log('Mutation: toggleMaxChatOpen', state.maxChatOpen);
        },
        showChatImageModal(state) {
            if (state.debug) console.log('Mutation: showChatImageModal');
            state.chatImageModalOpen = true;
        },
        hideChatImageModal(state) {
            if (state.debug) console.log('Mutation: hideChatImageModal');
            state.chatImageModalOpen = false;
        },
        setChatImageModalUrl(state, value) {
            if (state.debug) console.log('Mutation: setChatImageModalUrl', value);
            state.chatImageModalUrl = value;
        },
        setCurrentChatTitleData(state, value) {
            if (state.debug) console.log('Mutation: setCurrentChatTitleData', value);
            state.currentChatTitleData = value;
        },
        setCurrentChatRoom(state, value) {
            if (state.debug) console.log('Mutation: setCurrentChatRoom', value);
            state.currentChatRoom = value;
        },
        setGroupChatRooms(state, rooms) {
            if (state.debug) console.log('Mutation: setGroupChatRooms', rooms);
            state.groupChatRooms = rooms;
        },
        setChatRoomMessages(state, messages) {
            if (state.debug) console.log('Mutation: setChatRoomMessages', messages);
            state.currentChatRoomMessages = messages;
        },
        setChatUserFriendships(state, userFriendships) {
            if (state.debug) console.log('Mutation: setChatUserFriendships', userFriendships);
            state.chatUserFriendships = userFriendships;
        },
        openCreateGroupChatModal(state) {
            if (state.debug) console.log('Mutation: openCreateGroupChatModal');
            state.createGroupChatModalOpen = true;
        },
        openGroupChatSettingsModal(state) {
            if (state.debug) console.log('Mutation: openGroupChatSettingsModal');
            state.groupChatSettingsModalOpen = true;
        },
        changeChatView(state, newView) {
            if (state.debug) console.log('Mutation: changeChatView', newView);
            state.chatCurrentView = newView;
        },
        setRoomUsers(state, updatedUsers) {
            if (state.debug) console.log('Mutation: setRoomUsers', updatedUsers);
            state.currentChatRoomUsers = updatedUsers;
        },
    },
    actions: {
        getGroupChatRooms({ commit, state }, userId) {
            if (state.debug) console.log('Action: getGroupChatRooms', userId);
            callChatRoomsIndex({
                type: "group",
                user_id: userId
            })
                .then((response) => {
                    if (state.debug) console.log('Response: getGroupChatRooms', response);
                    commit("setGroupChatRooms", response);
                })
                .catch((error) => {
                    console.error('Error: getGroupChatRooms', error);
                });
        },
        getRoomMessages({ commit, state }, roomId) {
            if (state.debug) console.log('Action: getRoomMessages', roomId);
            callChatMessagesIndex(roomId)
                .then((response) => {
                    if (state.debug) console.log('Response: getRoomMessages', response);
                    commit("setChatRoomMessages", response);
                })
                .catch((error) => {
                    console.error('Error: getRoomMessages', error);
                });
        },
        getUserFriendships({ commit, state }, userId) {
            if (state.debug) console.log('Action: getUserFriendships', userId);
            callUserFriendshipsIndex({ user_id: userId })
                .then((response) => {
                    if (state.debug) console.log('Response: getUserFriendships', response);
                    commit('setChatUserFriendships', response);
                })
                .catch((error) => {
                    console.error('Error: getUserFriendships', error);
                });
        }
    },
    getters: {
        isMaxChatOpen: state => {
            if (state.debug) console.log('Getter: isMaxChatOpen', state.maxChatOpen);
            return state.maxChatOpen;
        },
        isChatImageModalOpen: state => {
            if (state.debug) console.log('Getter: isChatImageModalOpen', state.chatImageModalOpen);
            return state.chatImageModalOpen;
        },
        chatImageModalUrl: state => {
            if (state.debug) console.log('Getter: chatImageModalUrl', state.chatImageModalUrl);
            return state.chatImageModalUrl;
        },
        currentChatTitleData: state => {
            if (state.debug) console.log('Getter: currentChatTitleData', state.currentChatTitleData);
            return state.currentChatTitleData;
        },
        currentChatRoom: state => {
            if (state.debug) console.log('Getter: currentChatRoom', state.currentChatRoom);
            return state.currentChatRoom;
        },
        groupChatRooms: state => {
            if (state.debug) console.log('Getter: groupChatRooms', state.groupChatRooms);
            return state.groupChatRooms;
        },
        currentChatRoomMessages: state => {
            if (state.debug) console.log('Getter: currentChatRoomMessages', state.currentChatRoomMessages);
            return state.currentChatRoomMessages;
        },
        chatUserFriendships: state => {
            if (state.debug) console.log('Getter: chatUserFriendships', state.chatUserFriendships);
            return state.chatUserFriendships;
        }
    }
};
