<template>
  <div class="atmo-radio-page" data-cy="atmo-radio-page">
    <template>
      <div class="atmo-radio-page" data-cy="atmo-radio-page">
        <VueFinalModal
          id="atmo-radio-settings-modal"
          ref="atmoRadioSettingsRef"
          v-model="settingsOpen"
          title="Radio Settings"
          size="lg"
          content-class="atmo-modal-content atmo-modal atmo-radio-settings-modal"
          overlay-class="atmo-modal-overlay"
          :esc-to-close="true"
          @opened="getRadioStations"
        >
          <div class="close-button-container">
            <img class="atmo-modal__close" src="@/assets/images/icons/close--light.png" @click="closeSettings()" />
          </div>
          <header class="atmo-modal__header">
            <div class="atmo-page-header-container">
              <h2 class="atmo-page-header">Radio Settings</h2>
            </div>
          </header>
          <div class="atmo-radio-settings-modal__body">
            <div class="excluded-artists-heading-wrap">
              <h3>Excluded Artists</h3>
            </div>
            <perfect-scrollbar class="excluded-artists-container" v-if="excludedArtists.length > 0">
              <div v-for="(reference, index) in excludedArtists" :key="index" class="artist-result-wrap">
                <div class="artist-result-wrap__image-wrap"><img class="image-wrap__image" :src="reference.user.profile_image.image.url" /></div>
                <div class="artist-result-wrap__name-wrap">{{ reference.user.name }}</div>
                <div class="artist-result-wrap__manage-wrap">
                  <div @click="removeExcludedArtist(reference)">Remove Artist</div>
                </div>
              </div>
            </perfect-scrollbar>
            <div class="empty-exluded-artists-container" v-else>Search below to exclude artists from stations</div>
            <div class="artist-search-container">
              <input type="text" v-model="searchArtistQuery" @input="searchArtists" placeholder="Search artists to exclude" class="search-field-square" />
            </div>
            <perfect-scrollbar class="artist-search-results-container" v-if="artistSearchResults.length > 0">
              <div v-for="(user, index) in artistSearchResults" :key="index" class="artist-result-wrap">
                <div class="artist-result-wrap__image-wrap"><img class="image-wrap__image" :src="user.profile_image.image.url" /></div>
                <div class="artist-result-wrap__name-wrap">{{ user.name }}</div>
                <div class="artist-result-wrap__manage-wrap">
                  <div @click="addExcludedArtist(user)">Add Artist</div>
                </div>
              </div>
            </perfect-scrollbar>
            <div class="empty-search-results-container" v-else>No Results</div>
          </div>
        </VueFinalModal>
      </div>
    </template>

    <!-- <h2 class="atmo-page-header">Atmo Radio</h2> -->

    <div class="atmo-radio-page__content-grid">
      <div class="content-grid__ads-container">
        <atmo-ad :showAtmoAdvertLink="false" />
        <atmo-ad :showAtmoAdvertLink="false" />
      </div>
      <div class="atmo-radio-container">
        <div class="search-container__background-container">
          <input
            class="search-field-rounded"
            type="text"
            v-model="searchRadioStationsQuery"
            @keypress.enter="submitRadioStationsSearchQuery"
            placeholder="Search artists, albums, songs, genres, and moods"
          />
        </div>
        <div class="selected-radio-container" v-if="!stationDataIsEmpty">
          <div class="selected-radio-container_heading-container">
            <h3>{{ stationData.type }} - {{ stationData.query }} Radio</h3>
            <song-actions-popover :song="song" placement="right" :actions="['create-playlist-from-station', 'add-to-library', 'add-to-queue', 'add-to-playlist']" />
          </div>
          <div class="selected-radio-container__details-container">
            <div class="details-container__album-container">
              <div class="album-container__album-wrap">
                <img v-if="stationSongs.length > 0" class="album-wrap__image" :src="currentSong.album.image_url" alt="Radio Song Image" />
              </div>
            </div>
            <div class="details-container__recent-stations-container">
              <div class="recent-stations-container__heading-wrap">
                <p>Recent Stations</p>
              </div>
              <perfect-scrollbar class="recent-stations-container__stations-container">
                <div class="stations-container__station" v-for="(station, index) in userRadioStations" :key="index" @click="getUserRadioStationSongs(station.id)">{{ station.name }}</div>
              </perfect-scrollbar>

              <div class="recent-stations-container__settings-container" @click="openSettings()">
                <div class="settings-container__heading">Radio Settings</div>
                <img src="@/assets/images/icons/chat/settings_icon.png" class="settings-icon" />
              </div>
            </div>
          </div>
          <div class="selected-radio-container__song-details-container">
            <div class="song-details-container__song-artist-container">
              <div class="song-artist-container__song-container">
                <router-link class="white-link" :to="{ name: 'songs.show', params: { artistProfileId: currentSong.primary_artist.id, songId: currentSong.id } }">
                  {{ currentSong.name }}
                </router-link>
              </div>
              <div class="song-artist-container__artist-container">
                <router-link class="white-link" :to="{ name: 'artist_profiles.show', params: { artistProfileId: currentSong.primary_artist.id } }">
                  {{ currentSong.primary_artist.user.name }}
                </router-link>
              </div>
              <div class="song-artist-container__album-container">
                <router-link class="white-link" :to="{ name: 'albums.show', params: { artistProfileId: currentSong.primary_artist.id, albumId: currentSong.album.id } }">
                  {{ currentSong.album.name }}
                </router-link>
              </div>
              <div class="song-artist-container__label-container">
                <router-link class="white-link" :to="{ name: 'label_profiles.show', params: { labelProfileId: currentSong.album.album_profile.primary_label.id } }">
                  {{ currentSong.album.album_profile.primary_label.user.name }}
                </router-link>
              </div>
            </div>
            <div class="song-details-container__share-container">
              <song-actions-popover :song="song" placement="right" :actions="['add-to-library', 'add-to-queue', 'add-to-playlist']" />
            </div>
          </div>
        </div>
        <div class="selected-radio-container" v-if="stationDataIsEmpty">
          <div class="selected-radio-container_heading-container">
            <h3>Search for a radio station</h3>
          </div>
          <div class="selected-radio-container__details-container">
            <div class="details-container__album-container">
              <div class="album-container__album-wrap">
                <div class="empty-radio-placeholder"></div>
              </div>
            </div>
            <div class="details-container__recent-stations-container">
              <div class="recent-stations-container__heading-wrap">
                <p>Recent Stations</p>
              </div>
              <perfect-scrollbar class="recent-stations-container__stations-container">
                <div class="stations-container__station" v-for="(station, index) in userRadioStations" :key="index" @click="getUserRadioStationSongs(station.id)">{{ station.name }}</div>
              </perfect-scrollbar>

              <div class="recent-stations-container__settings-container" @click="openSettings()">
                <div class="settings-container__heading">Radio Settings</div>
                <img src="@/assets/images/icons/chat/settings_icon.png" class="settings-icon" />
              </div>
            </div>
          </div>
          <div class="empty-radio-song-details-container"></div>
        </div>
      </div>
      <div class="content-grid__ads-container">
        <atmo-ad :showAtmoAdvertLink="false" />
        <atmo-ad :showAtmoAdvertLink="false" />
      </div>
    </div>
  </div>
</template>

<script>
import SongActionsPopover from "@/components/song-actions-popover";
import AtmoAd from "@/components/atmo-ad";
import { callAtmoRadioStationsConfigurationsShow, callArtistProfilesSearch, callArtistProfileReferencesDelete, callArtistProfileReferencesCreate } from "@/helpers/axiosCalls";

export default {
  components: { AtmoAd, SongActionsPopover },
  data() {
    return {
      searchArtistQuery: "", // Holds the query for searching artists
      artistSearchResults: [], // Stores the artist search results
      excludedArtists: [], // Stores the excluded artists
      searchRadioStationsQuery: "",
      settingsOpen: false,
      newArtistName: "",
    };
  },
  created() {
    this.$store.commit("setHudHeader", "Atmo Radio");
  },
  computed: {
    // Get the current song and playing state from Vuex store
    currentSong() {
      return this.$store.state.player.currentSong;
    },
    isPlaying() {
      return this.$store.state.player.isPlaying;
    },
    stationDataIsEmpty() {
      return this.$store.getters["atmoRadioStations/stationDataIsEmpty"];
    },
    stationData() {
      return this.$store.getters["atmoRadioStations/stationData"];
    },
    userRadioStations() {
      return this.$store.getters["atmoRadioStations/userRadioStations"];
    },
    stationSongs() {
      return this.$store.getters["atmoRadioStations/stationSongs"];
    },
    song() {
      return this.$store.getters["atmoRadioStations/currentSong"];
    },
  },
  methods: {
    getRadioStations() {
      this.$store.dispatch("atmoRadioStations/fetchUserRadioStations");
    },
    async searchArtists() {
      if (!this.searchArtistQuery) {
        this.artistSearchResults = [];
        return;
      }

      try {
        const response = await callArtistProfilesSearch({ query: this.searchArtistQuery });

        // Filter out artists that are already in the excludedArtists list
        this.artistSearchResults = response.filter((user) => {
          return !this.excludedArtists.some((excludedArtist) => excludedArtist.artist_profile_id === user.profile_id);
        });
      } catch (error) {
        console.error("Failed to search for artists:", error);
      }
    },
    async addExcludedArtist(user) {
      try {
        await callArtistProfileReferencesCreate({
          artistable_id: this.$store.state.currentUserId,
          artistable_type: "AtmoRadioStationsConfiguration",
          artist_profile_id: user.profile_id,
          primary: false,
        });

        // Refresh the excluded artists list
        await this.fetchExcludedArtists();
        this.searchArtistQuery = ""; // Clear the search input
        this.artistSearchResults = []; // Clear the search results
      } catch (error) {
        console.error("Failed to add excluded artist:", error);
      }
    },
    async fetchExcludedArtists() {
      try {
        const response = await callAtmoRadioStationsConfigurationsShow(this.$store.state.currentUserId);
        this.excludedArtists = response.excluded_artists;
      } catch (error) {
        console.error("Failed to fetch excluded artists:", error);
      }
    },
    async removeExcludedArtist(reference) {
      try {
        await callArtistProfileReferencesDelete(reference.id); // Call API to remove the artist

        // Refresh the excluded artists list
        await this.fetchExcludedArtists();
      } catch (error) {
        console.error("Failed to remove excluded artist:", error);
      }
    },
    openSettings() {
      this.settingsOpen = true;
      this.fetchExcludedArtists();
    },
    closeSettings() {
      this.settingsOpen = false;
    },
    async switchToRadioMode() {
      if (this.stationSongs.length > 0) {
        // Set the queue mode to 'radio'
        await this.$store.dispatch("atmoQueues/setQueueMode", "radio");
        console.log("Queue mode set to:", this.$store.state.atmoQueues.queueMode);

        // Commit the switchToRadioMode mutation to configure the player for radio mode
        this.$store.commit("player/switchToRadioMode");
        console.log("Radio mode configured: autoplay enabled, shuffle off, repeat off");

        // Set the active queue to the station songs
        this.$store.commit("atmoQueues/setActiveQueue", this.stationSongs);

        // Ensure Howl instances are loaded for all songs in the queue
        await this.$store.dispatch("player/updateSongHowls", this.stationSongs);

        console.log("SETH==================", this.stationSongs[0]);

        // Now play the first song in the station
        this.$store.dispatch("player/playSong", this.stationSongs[0]);
      }
    },
    pauseSong() {
      // Pause the current song
      this.$store.dispatch("player/pauseSong");
    },
    async getUserRadioStationSongs(id) {
      await this.$store.dispatch("atmoRadioStations/fetchUserRadioStationSongs", {
        station_id: id,
        user_id: this.$store.state.currentUserId,
        length: 10,
      });
      this.switchToRadioMode();
    },
    async submitRadioStationsSearchQuery() {
      if (this.searchRadioStationsQuery) {
        await this.$store.dispatch("atmoRadioStations/fetchSearchResults", { query: this.searchRadioStationsQuery });
        this.openSearchResultsModal();
      }
    },
    openSearchResultsModal() {
      this.$store.commit("atmoRadioStations/toggleSearchResultsModal");
      this.searchRadioStationsQuery = null;
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-radio-settings-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .atmo-modal-body {
    position: relative;

    .close-button-container {
      position: absolute;
      top: 1rem;
      left: 1rem;
    }

    .atmo-modal__close {
      height: 1.5rem;
    }
  }

  .modal-lg.modal-dialog {
    max-width: 100vw;
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .atmo-modal-content {
    max-width: 50vw;
  }

  .atmo-modal__header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  .atmo-page-header-container {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin: 0;
    }
  }

  &__body {
    padding: 2rem;
  }

  .artist-search-container {
    margin-bottom: 1rem;
    width: 100%;
  }

  .search-field-square {
    background: transparent;
    border-radius: 2px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    position: relative;
    background: url("@/assets/images/icons/search.png") no-repeat scroll 7px 7px;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
    width: 100%;
  }

  .excluded-artists-heading-wrap {
    margin-bottom: 1rem;
  }

  .excluded-artists-container {
    height: 12rem;
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .empty-exluded-artists-container {
    font-size: 0.8rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .artist-search-results-container {
    height: 12rem;
    font-size: 1.2rem;
    width: 100%;
  }

  .empty-search-results-container {
    height: 10rem;
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .artist-result-wrap {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem;
    // border-radius: 5px;
    // background-color: rgba(96, 72, 117, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 2px;
    // backdrop-filter: blur(5px);
    margin-bottom: 1rem;
    cursor: pointer;

    .image-wrap__image {
      width: 2rem;
      height: 2rem;
      border-radius: 100%;
      margin-right: 0.5rem;
    }

    .artist-result-wrap__name-wrap {
      font-size: 0.9rem;
      font-weight: 400;
    }

    .artist-result-wrap__manage-wrap {
      display: none;
    }

    &:hover {
      background-color: rgba(190, 167, 221, 0.3);
      backdrop-filter: blur(5px);

      .artist-result-wrap__manage-wrap {
        font-size: 0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: 1rem;
        gap: 1rem;
        text-transform: uppercase;
        font-weight: 400;
      }
    }
  }
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.atmo-radio-page {
  margin: 0 75px;
}

.atmo-page-header {
  display: flex;
  justify-content: center;
}

.atmo-radio-page__content-grid {
  display: grid;
  grid-template-columns: 11rem 1fr 11rem;
}

.atmo-radio-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .search-container__background-container {
    background: rgba(216, 216, 216, 0.1);
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 2rem;
    min-width: 20vw;
    max-width: 50vw;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgba(255, 255, 255, 0.4);
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(255, 255, 255, 0.4);
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgba(255, 255, 255, 0.4);
  }

  .search-field-rounded {
    background: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    padding: 0.5rem 0.5rem 0.5rem 2.5rem;
    color: white;
    width: 100%;
    position: relative;
    background: url("@/assets/images/icons/search.png") no-repeat scroll 7px 7px;
    background-size: 1rem;
    background-position: 0.8rem 0.5rem;
  }

  .selected-radio-container_heading-container {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    gap: 1rem;
  }

  .selected-radio-container__details-container {
    display: flex;
    gap: 2rem;
    margin-bottom: 1rem;
  }

  .album-wrap__image {
    max-height: 20rem;
    max-width: 20rem;
    border-radius: 5px;
  }

  .recent-stations-container__heading-wrap {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 600;
  }

  .recent-stations-container__stations-container {
    margin-bottom: 2rem;
    max-height: 10rem;
  }

  .stations-container__station {
    cursor: pointer;
  }

  .stations-container__station + .stations-container__station {
    margin-top: 0.5rem;
  }

  .recent-stations-container__settings-container {
    cursor: pointer;
    display: flex;
    gap: 0.1rem;
    align-items: center;

    .settings-icon {
      height: 1.3rem;
    }
  }

  .settings-container__heading {
    font-size: 1rem;
    font-weight: 600;
  }

  .excluded-artists__heading {
    cursor: pointer;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  .excluded-artists__artist + .excluded-artists__artist {
    margin-top: 0.5rem;
  }

  // .song-artist-container__song-container {
  //     display: flex;
  //     justify-content: center;
  // }

  .selected-radio-container__song-details-container {
    margin-bottom: 0.5rem;
    display: flex;
    gap: 1rem;
  }

  .white-link {
    display: inline-block;
    color: white;
  }

  .blue-link {
    color: $atmo-blue--medium;
  }

  .song-artist-container__song-container {
    margin-bottom: 0.5rem;
    font-size: 1rem;
  }

  .song-artist-container__album-container {
    margin-bottom: 0.5rem;
  }

  .song-artist-container__artist-container {
    margin-bottom: 0.5rem;
  }

  // .song-artist-container__label-container {

  // }

  .song-details-container__share-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-radio-placeholder {
    height: 240px;
    width: 305px;
    border-radius: 5px;
    background-image: linear-gradient(-270deg, $atmo-pink--medium 0%, $atmo-blue--light 100%);
  }

  .empty-radio-song-details-container {
    height: 67px;
    width: 460px;
  }
}
</style>
