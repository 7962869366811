<template>
  <!-- TODO: Make a reusable component out of table for user/artist plans -->
  <div class="user-subscriptions" data-cy="user-subscriptions">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">UPGRADE TODAY</h2>
    </div> -->
    <plans-table />
  </div>
</template>

<script>
import PlansTable from "@/routes/subscriptions/plans/user_plans/plans-table.vue";
export default {
  components: {
    PlansTable,
  },
  created() {
    this.$store.commit("setHudHeader", "Upgrade Today");
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.user-subscriptions {
  margin: 0 75px;

  .ps {
    max-height: 22.1rem;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
