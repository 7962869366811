import Axios from 'axios';

// Fetch a Stripe customer by ID from the Rails backend
export function callStripeCustomersGet(customerId) {
    return Axios.get(`/api/v1/stripe_customers/${customerId}`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

// Create a new Stripe customer through the Rails backend
export function callStripeCustomersPost(customerData = {}) {
    return Axios.post(`/api/v1/stripe_customers`, customerData)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

// Update an existing Stripe customer through the Rails backend
export function callStripeCustomersPut(customerId, customerData = {}) {
    return Axios.put(`/api/v1/stripe_customers/${customerId}`, customerData)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}

// Fetch sources for a Stripe customer from the Rails backend
export function callStripeCustomerSourcesGet(customerId) {
    return Axios.get(`/api/v1/stripe_customers/${customerId}/sources`)
        .then(response => response.data)
        .catch(error => {
            throw error;
        });
}
