<template>
  <div class="add-card">
    <StripeElements v-if="stripeLoaded" v-slot="{ elements }" :stripe-key="stripeKey" :instance-options="instanceOptions" :elements-options="elementsOptions" ref="elms">
      <div class="credit-card-inputs" :class="{ complete }">
        <div class="atmo-checkout__input-row">
          <StripeElement type="cardNumber" ref="card" class="atmo-input atmo-checkout__input" :elements="elements" :options="cardNumberOptions" @change="onCardNumberChange" />
          <div class="atmo-checkout__card-brand">{{ brand }}</div>
        </div>

        <div class="atmo-checkout__input-row">
          <label for="atmo-checkout__input--expiry" class="atmo-checkout__inline-label">Expiry Date</label>
          <StripeElement type="cardExpiry" ref="cardExpiry" class="atmo-checkout__input atmo-checkout__input--purple" :elements="elements" :options="expiryOptions" @change="onExpiryChange" />
          <StripeElement type="cardCvc" ref="cardCvc" class="atmo-checkout__input card-cvc" :elements="elements" :options="cvcOptions" @change="onCvcChange" />
        </div>
      </div>
    </StripeElements>
    <add-address />
    <a class="choose-card-link" @click="chooseExistingCard">Choose Existing Card</a>
  </div>
</template>

<script>
import { ref, reactive, onBeforeMount, onMounted, watch } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { StripeElements, StripeElement } from "vue-stripe-js";
import AddAddress from "@/routes/subscriptions/cards/add-address.vue";
import { useStore } from "vuex";

export default {
  components: { StripeElements, StripeElement, AddAddress },
  setup(props, { emit }) {
    const store = useStore();
    const stripeKey = ref("pk_test_UkAcQat2PhllsZTtEnv5Zvqw");
    const stripeLoaded = ref(false);

    const instanceOptions = reactive({});
    const elementsOptions = reactive({});
    const options = {
      style: {
        base: { color: "#ffffff", fontSize: ".9rem", "::placeholder": { color: "#D8D8D8" } },
        invalid: { color: "#fa755a" },
      },
    };
    const cardNumberOptions = reactive(options);
    const expiryOptions = reactive(options);
    const cvcOptions = reactive(options);

    const brand = ref("unknown");
    const number = ref(false);
    const expiry = ref(false);
    const cvc = ref(false);
    const elms = ref();
    const card = ref();

    onBeforeMount(() => {
      const stripePromise = loadStripe("pk_test_UkAcQat2PhllsZTtEnv5Zvqw");
      stripePromise.then(() => {
        stripeLoaded.value = true;
      });
    });

    onMounted(() => {
      loadStripe(stripeKey.value).then(() => {
        stripeLoaded.value = true;
      });
    });

    const onCardNumberChange = async (evt) => {
      brand.value = evt.brand;
      number.value = evt.complete;
      updateTemporaryCard(); // Update store with current field states
      checkAndCreateToken(); // Check if all fields are complete and create token if so
    };

    const onExpiryChange = (evt) => {
      expiry.value = evt.complete;
      updateTemporaryCard();
      checkAndCreateToken();
    };

    const onCvcChange = (evt) => {
      cvc.value = evt.complete;
      updateTemporaryCard();
      checkAndCreateToken();
    };

    const updateTemporaryCard = () => {
      store.dispatch("payments/setTemporaryCard", {
        brand: brand.value,
        numberComplete: number.value,
        expiryComplete: expiry.value,
        cvcComplete: cvc.value,
      });
    };

    const checkAndCreateToken = async () => {
      if (number.value && expiry.value && cvc.value) {
        console.log("All fields are complete. Creating token...");
        const result = await elms.value.instance.createToken(card.value.stripeElement);

        if (result.error) {
          console.error("Failed to create Stripe token:", result.error.message);
          return;
        }

        store.dispatch("payments/setToken", result.token.id);
        console.log("Stripe token created and set in store:", result.token.id);
      }
    };

    const chooseExistingCard = () => {
      store.dispatch("payments/toggleAddCard");
    };

    return {
      stripeKey,
      instanceOptions,
      elementsOptions,
      cardNumberOptions,
      expiryOptions,
      cvcOptions,
      brand,
      number,
      expiry,
      cvc,
      card,
      stripeLoaded,
      elms,
      chooseExistingCard,
      onCardNumberChange,
      onExpiryChange,
      onCvcChange,
    };
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";
.add-card {
  .choose-card-link {
    cursor: pointer;
    color: $atmo-purple--dark;
    display: inline-block;
    margin-top: unset;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
