<template>
  <VueFinalModal id="atmo-library"
    ref="atmoLibraryRef" 
    v-model="$store.state.libraries.libraryOpen" title="Library" 
    @opened="getAlbumsAndPlaylists" 
    overlay-class="atmo-modal-overlay"
    content-class="atmo-library atmo-modal-content" 
    :esc-to-close="true">
    <notifications group="main" position="top right" />
    <header class="atmo-modal__header">
      <span class="atmo-modal__header-side">
        <img class="atmo-modal__close" src="@/assets/images/icons/close--light.png" @click="toggleLibraryOpen()">
      </span>
      <div class="atmo-page-header-container">
        <h2 class="atmo-page-header">
          Atmo Library
        </h2>
      </div>
      <span class="atmo-modal__header-side" />
    </header>
    <atmo-library-body component-location="atmoLibrary" :albums="albums" :playlists="playlists"></atmo-library-body>
  </VueFinalModal>
</template>
<script>
import AtmoLibraryBody from '@/components/atmo-library/atmo-library-body';
import { mapMutations } from 'vuex';
import { callAtmoLibrariesIndex, callPlaylistsIndex } from "@/helpers/axiosCalls";

export default {
  components: {
    AtmoLibraryBody
  },
  data() {
    return {
      albums: [],
      playlists: []
    }
  },
  methods: {
    ...mapMutations('libraries', ['toggleLibraryOpen']),
    async getAlbumsAndPlaylists() {
      await this.getAlbums();
      await this.getPlaylists();
    },
    async getAlbums() {
      console.log("LOADING ALBUMS IN LIB SJ")
      if (this.$store.state.currentUserProfileId) {
        callAtmoLibrariesIndex(this.$store.state.currentUserProfileId).then(async (response) => {
          this.albums = response;
        });
      }
    },
    async getPlaylists() {
      console.log("LOADING PLAYLISTS IN LIB SJ")
      if (this.$store.state.currentUserId) {
        callPlaylistsIndex(this.$store.state.currentUserId).then(async (response) => {
          // sort alphabetically
          this.playlists = response.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        });
      }
    }
  }
}
</script>