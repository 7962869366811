<template>
  <div class="cards-on-file">
    <!-- <h2 class="atmo-page-header">Cards On File</h2> -->
    <perfect-scrollbar>
      <div v-for="source in sources" :key="source.id" class="card-item">
        <div class="card-item__details">
          <p><span class="label">Type: </span> {{ source.card.brand }}</p>
          <p><span class="label">Last 4: </span> {{ source.card.last4 }}</p>
          <p><span class="label">Expiration Date: </span> {{ source.card.exp_month }}/{{ source.card.exp_year }}</p>

          <!-- Displaying Address Information with Updated Layout -->
          <p v-if="source.billing_details && source.billing_details.name"><span class="label">Name:</span> {{ source.billing_details.name }}</p>
          <p v-if="source.billing_details && source.billing_details.address">
            <span class="label">Address:</span>
            {{ source.billing_details.address.line1 }}
            <span v-if="source.billing_details.address.line2">, {{ source.billing_details.address.line2 }}</span
            >, {{ source.billing_details.address.city }}, {{ source.billing_details.address.state }}, {{ source.billing_details.address.postal_code }},
            {{ source.billing_details.address.country }}
          </p>
          <p v-if="source.billing_details && source.billing_details.phone"><span class="label">Phone:</span> {{ source.billing_details.phone }}</p>
        </div>

        <div class="card-item__actions">
          <button v-if="customer.invoice_settings && customer.invoice_settings.default_payment_method !== source.id" class="atmo-button atmo-button--secondary" @click="updateDefaultCard(source.id)">
            Make Default
          </button>
          <p v-if="customer.invoice_settings && customer.invoice_settings.default_payment_method === source.id" class="card-item__default">Default Card</p>
        </div>
      </div>
    </perfect-scrollbar>

    <router-link
      v-if="$store.state.currentUserProfileId"
      class="atmo-button atmo-button--primary cards-on-file__add-button"
      :to="{ name: 'subscriptions.cards.new', params: { userProfileId: $store.state.currentUserProfileId } }"
    >
      Add Card
    </router-link>
  </div>
</template>

<script>
import { callStripeCustomersGet, callStripeCustomerSourcesGet, callStripeCustomersPut } from "@/helpers/axiosCalls";

export default {
  data() {
    return {
      customer: {},
      sources: [],
    };
  },
  created() {
    // Fetch customer data
    this.getStripeCustomer(this.$store.state.currentUser.customer_id);

    // Fetch payment methods (renamed to `sources` for consistency with original naming)
    callStripeCustomerSourcesGet(this.$store.state.currentUser.customer_id, "card").then((response) => {
      this.sources = response.data;
      console.log("SOURCES RES", this.sources);
    });
    this.$store.commit("setHudHeader", "Cards On File");
  },
  methods: {
    updateDefaultCard(cardId) {
      callStripeCustomersPut(this.$store.state.currentUser.customer_id, {
        invoice_settings: { default_payment_method: cardId },
      })
        .then((response) => {
          console.log(response);
          this.getStripeCustomer(this.$store.state.currentUser.customer_id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getStripeCustomer(userCustomerId) {
      callStripeCustomersGet(userCustomerId).then((response) => {
        this.customer = response;
      });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.cards-on-file {
  max-width: 70vw;
  margin: 0 auto;
  padding: 1rem;

  &__heading {
    font-size: 1.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }

  &__add-button {
    display: block;
    margin: 20px auto;
    width: fit-content;
  }
}

.card-item {
  background-color: $atmo-purple--medium-dark--faded;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 40vw;

  &__details {
    font-size: 1rem;
    color: white;

    p {
      margin: 5px 0;
      display: flex;
      justify-content: space-between;

      .label {
        color: white;
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__default {
    color: $atmo-purple--extra-dark;
    font-weight: bold;
    font-size: 1rem;
  }
}
</style>
