<template>
  <div class="atmo-input__wrapper">
    <label class="atmo-input__label">{{ label }}</label>
    <div class="atmo-input__container">
      <input
        class="atmo-input__input"
        :maxlength="maxlength"
        :value="modelValue"
        :type="computedType"
        :placeholder="placeholder"
        :name="name"
        @input="$emit('update:modelValue', $event.target.value)"
      >
      <i
        v-if="type === 'password'"
        class="atmo-input__atmo-button-eye"
        :class="`atmo-input__fa-eye${visible ? '': '-slash'}`"
        @click="toggleVisibility()"
      />
      <slot name="extra" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: { type: String, default: "" },
    modelValue: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: "",
    },
    maxlength: { type: Number, default: 50 },
    label: { type: String, default: "" },
    type: { type: String, default: "text" }
  },
  data() {
    return { visible: false };
  },
  computed: {
    computedType() { 
      return this.type === 'password' ? (this.visible ? 'text' : 'password') : this.type;
    }
  },
  methods: {
    toggleVisibility() { 
      this.visible = !this.visible;
    },
  }
};
</script>

<style lang="scss">
@import '~@/assets/stylesheets/_variables.scss';
.atmo-input {
  &__wrapper {
    margin-top: 1rem;
  }
  &__input {
    font-size: 1rem;
    flex: 1;
    padding: 0.5rem 0;
    border-width: 0px;
    &:focus {
      outline: unset!important;
    }
  }
  &__container {
    display: flex;
    border-bottom: solid 1px grey;
    &:focus-within {
      border-bottom: solid 1px $atmo-blue--dark;
      caret-color: unset!important;
    }
  }
  &__label {
    font-size: 0.75rem;
    font-weight: 100;
    color: $atmo-gray--medium-dark;
  }
  &__atmo-button-eye {
    box-sizing: border-box;
    padding: 0.75rem;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-origin: padding-box;
    margin-right: 0.75rem;
  }
  &__fa-eye-slash {
    background-image: url(@/assets/images/icons/fa-eye-slash.png)!important;
  }
  &__fa-eye {
    background-image: url(@/assets/images/icons/fa-eye.png)!important;
  }
}
</style>
