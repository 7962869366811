<template>
  <VueFinalModal
    id="edit-directory-profile"
    ref="editDirectoryProfileRef"
    title="Edit Profile"
    content-class="atmo-modal-content atmo-modal edit-directory-profile"
    overlay-class="atmo-modal-overlay"
    :esc-to-close="true"
    v-model="$store.state.directoryProfiles.isEditProfileModalOpen"
    @opened="setInitialFormProps"
  >
    <header class="atmo-modal__header">
      <h2 class="edit-directory-profile__h2">Edit Directory Profile</h2>
      <img class="atmo-modal__close" src="@/assets/images/icons/close_popup.png" alt="close" title="Hide Filter" @click="$store.dispatch('directoryProfiles/toggleEditProfileModal')" />
    </header>
    <form v-if="v$ && v$.formSelectedName" class="atmo-form edit-directory-profile__form" @submit.prevent="submitForm">
      <div class="atmo-form__row">
        <div class="form-group">
          <label for="name">Name</label>
          <input id="name" v-model="v$.formSelectedName.$model" :aria-invalid="v$.formSelectedName.$error" type="text" placeholder="Name" data-cy="input-name" class="atmo-input" />
        </div>
        <div class="form-group">
          <label for="rate">Hourly Rate</label>
          <input id="rate" v-model="v$.formSelectedRate.$model" :aria-invalid="v$.formSelectedRate.$error" type="text" placeholder="Hourly Rate" data-cy="input-hourly-rate" class="atmo-input" />
        </div>
      </div>
      <div class="atmo-form__row">
        <div class="form-group">
          <label for="email">Email</label>
          <input id="email" v-model="v$.formSelectedEmail.$model" :aria-invalid="v$.formSelectedEmail.$error" type="text" placeholder="Email" data-cy="input-email" class="atmo-input" />
        </div>
        <div class="form-group">
          <label for="website">Website</label>
          <input id="website" v-model="v$.formSelectedWebsite.$model" :aria-invalid="v$.formSelectedWebsite.$error" type="text" placeholder="Website" data-cy="input-website" class="atmo-input" />
        </div>
      </div>
      <div class="atmo-form__row">
        <multiselect
          v-model="v$.formSelectedState.$model"
          :aria-invalid="v$.formSelectedState.$error"
          :options="stateOptions"
          :searchable="false"
          :show-labels="false"
          :close-on-select="true"
          placeholder="State"
          data-cy="input-state"
          @select="onSelectState"
        />
        <multiselect
          v-model="v$.formSelectedCity.$model"
          :aria-invalid="v$.formSelectedCity.$error"
          :disabled="!formSelectedState"
          :options="cityOptions"
          :searchable="false"
          :show-labels="false"
          :close-on-select="true"
          placeholder="City"
          data-cy="input-city"
        />
      </div>
      <div class="atmo-form__row">
        <multiselect
          v-model="v$.formSelectedProfession.$model"
          :aria-invalid="v$.formSelectedProfession.$error"
          :options="professionOptions"
          label="name"
          :searchable="false"
          :show-labels="false"
          :close-on-select="true"
          placeholder="Profession"
          data-cy="input-profession"
        />
      </div>
      <div class="atmo-form__row">
        <div class="edit-directory-profile__public-toggle">
          <atmo-toggle data-cy="directory-profile-public-toggle" size="large" :on="formIsPublic" :on-change="toggleIsPublic" />
          <span>{{ formIsPublic ? "Public" : "Private" }}</span>
        </div>
        <button type="submit" class="atmo-button atmo-button--primary atmo-button--stretch" data-cy="submit-edit-profile-modal">Save Changes</button>
      </div>
    </form>
  </VueFinalModal>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Multiselect from "@vueform/multiselect";
import AtmoToggle from "@/components/atmo-toggle";

export default {
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  components: {
    Multiselect,
    AtmoToggle,
  },
  props: {
    statesAndCities: { type: Object, required: true },
    professions: { type: Array, required: true },
    selectedName: { type: String, default: null },
    selectedRate: { type: String, default: null },
    selectedEmail: { type: String, default: null },
    selectedWebsite: { type: String, default: null },
    selectedLocation: { type: String, default: null },
    selectedProfession: { type: Object, default: null },
    selectedIsPublic: { type: Boolean, default: false },
    onSubmit: { type: Function, required: true },
  },
  data() {
    return {
      formSelectedName: this.selectedName || "",
      formSelectedRate: this.selectedRate || "",
      formSelectedEmail: this.selectedEmail || "",
      formSelectedWebsite: this.selectedWebsite || "",
      formSelectedState: null,
      formSelectedCity: null,
      formSelectedProfession: this.selectedProfession || null,
      formIsPublic: this.selectedIsPublic || false,
      submitAttempted: false,
    };
  },
  validations() {
    return {
      formSelectedName: { required },
      formSelectedRate: { required },
      formSelectedEmail: { required },
      formSelectedWebsite: { required },
      formSelectedState: { required },
      formSelectedCity: { required },
      formSelectedProfession: { required },
    };
  },
  computed: {
    stateOptions() {
      return Object.keys(this.statesAndCities);
    },
    cityOptions() {
      return this.statesAndCities[this.formSelectedState] || [];
    },
    professionOptions() {
      return this.professions;
    },
  },
  methods: {
    setInitialFormProps() {
      this.formSelectedName = this.selectedName;
      this.formSelectedRate = this.selectedRate;
      this.formSelectedEmail = this.selectedEmail;
      this.formSelectedWebsite = this.selectedWebsite;
      const locationParts = (this.selectedLocation || ", ").split(", ");
      this.formSelectedCity = locationParts[0];
      this.formSelectedState = locationParts[1];
      this.formSelectedProfession = this.selectedProfession;
      this.formIsPublic = this.selectedIsPublic;
    },
    onSelectState(newState) {
      const original = this.formSelectedState;
      this.formSelectedState = newState;
      if (original !== newState) {
        this.formSelectedCity = "All Cities";
      }
    },
    toggleIsPublic() {
      this.formIsPublic = !this.formIsPublic;
    },
    submitForm() {
      this.v$.$touch();
      if (this.v$.$invalid) {
        return this.$notify({
          group: "vue-app",
          type: "error",
          title: "Missing Required Fields",
        });
      }
      this.onSubmit({
        name: this.formSelectedName,
        hourly_rate: this.formSelectedRate,
        contact_info: this.formSelectedEmail,
        website: this.formSelectedWebsite,
        location: `${this.formSelectedCity}, ${this.formSelectedState}`,
        directory_profession_id: this.formSelectedProfession.id,
        is_public: this.formIsPublic,
      });
      this.$refs.editDirectoryProfileRef.hide();
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.edit-directory-profile {
  .atmo-modal__header {
    padding: 1rem;
  }

  &__h2 {
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 500;
  }

  &__form {
    margin: 0 20px 25px;
  }

  .multiselect {
    flex: 1;
  }

  &__public-toggle {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}
</style>
