<template>
  <div class="atmo-progress-bar-background">
    <div class="atmo-progress-bar-meter">
      <div
        class="progress-bar"
        :style="{ width: percent + '%' }"
      ></div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      percent: {
        type: Number,
        required: true
      }
    }
  };
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-progress-bar-background {
  width: 100%;
  background-color: $atmo-gray--light;
  border-radius: 3px;
  overflow: hidden;

  .atmo-progress-bar-meter {
    height: 6px;
    width: 100%;
    background-color: $atmo-gray--light;
    
    .progress-bar {
      height: 100%;
      background-color: $atmo-blue--medium; // Custom color
      transition: width 0.3s ease;
    }
  }
}
</style>
