<template>
  <div v-if="loadingSongProfileOptions || savingForm" class="atmo-album-form__loading-container">
    <atmo-loading />
  </div>
  <div v-else class="atmo-album-form__container">
    <song-profile-modal
      v-if="activeSongForProfileModal"
      :key="activeSongForProfileModal && activeSongForProfileModal.id"
      :song="activeSongForProfileModal"
      :song-profile-types="songProfileTypes"
      :song-profile-options="songProfileOptions"
      :close-modal="closeSongProfileModal"
      :update-song-profile="updateSongProfile"
    />
    <form class="atmo-form atmo-album-form" autocomplete="off" @submit.prevent="submitForm">
      <atmo-steps>
        <atmo-step :active="currentPage === 'album-info'" :complete="currentPage !== 'album-info'"> Album Information </atmo-step>
        <atmo-step-divider :active="currentPage !== 'album-info'" />
        <atmo-step :active="currentPage !== 'album-info'"> Song Uploads </atmo-step>
      </atmo-steps>
      <div v-show="currentPage === 'album-info'" class="atmo-album-form__main-content">
        <div class="atmo-album-form__sidebar">
          <atmo-upload-card key="album-image" :image-src="existingAlbumImageSrc" :is-dropzone="true" :on-files-selected="onAlbumImageSelected" :max-kb="600" alt="Upload Album Image">
            Album Image
          </atmo-upload-card>
        </div>
        <div class="atmo-album-form__main-column">
          <div class="form-group">
            <label for="albumTitle">Album title</label>
            <input id="albumTitle" v-model="formAlbumTitle" :aria-invalid="submitAttempted && !formAlbumTitle" type="text" placeholder="Hello Album" class="form-control" />
          </div>
          <div class="form-group">
            <label for="yearOfProduction">Year of Production</label>
            <input id="yearOfProduction" v-model="formYearOfProduction" :aria-invalid="submitAttempted && !formYearOfProduction" type="text" placeholder="2019" class="form-control" />
          </div>
          <div class="form-group">
            <label for="albumGenre">Genre</label>
            <multiselect
              v-if="songProfileOptions && songProfileOptions.genre"
              id="albumGenre"
              v-model="formAlbumGenre"
              :options="songProfileOptions.genre"
              label="name"
              :searchable="false"
              :show-labels="false"
              :close-on-select="true"
              placeholder="Select Genre"
            />
          </div>
        </div>
      </div>
      <div v-show="currentPage !== 'album-info'" class="atmo-album-form__main-content">
        <div class="atmo-album-form__sidebar">
          <atmo-upload-card key="album-songs" alt="Upload Songs" icon="attachment--xl.png" icon-size="large" accept="audio/*" :allow-multiple-files="true" :on-files-selected="onSongsSelected">
            Upload Songs
          </atmo-upload-card>
          <atmo-progress v-show="formAlbumSongs.length > 0 && songsProgress < 100" :progress="songsProgress" />
        </div>
        <div class="atmo-album-form__main-column">
          <div class="atmo-album-form__main-column-label">Album's Songs</div>
          <div v-if="formAlbumSongs.length === 0" class="atmo-album-form__empty-songs">
            <img class="atmo-album-form__empty-songs-icon" src="@/assets/images/icons/music-squares.png" />
            <span class="atmo-album-form__empty-songs-message">You haven't uploaded any songs yet</span>
            <div class="atmo-album-form__empty-songs-cta">
              <span>Upload Now</span>
              <img src="@/assets/images/icons/upload-arrow.png" />
            </div>
          </div>
          <div v-else class="atmo-album-form__songs">
            <div v-for="song in formAlbumSongs" :key="song.id || song.fileName" class="atmo-album-form__song" :class="{ 'atmo-album-form__song--disabled': !(song.processed && song.uploaded) }">
              <div class="atmo-album-form__song-filename-badge-container">
                <atmo-badge class="atmo-album-form__song-filename-badge" :label="song.fileName" removable :on-remove="() => removeSong(song)" />
              </div>
              <div class="atmo-album-form__song-length">{{ song.length || "--:--" }}</div>
              <input v-model="song.name" :aria-invalid="submitAttempted && !song.name" size="sm" class="form-control atmo-album-form__song-title" type="text" placeholder="Song Title" />
              <button
                v-if="song.processed && song.uploaded"
                type="button"
                class="atmo-button atmo-button--primary atmo-button--small atmo-album-form__song-edit-button"
                @click="setSongForProfileModal(song)"
              >
                <img class="atmo-button__icon atmo-button__icon--small" src="@/assets/images/icons/edit.png" alt="edit info" />
                <span>Edit Info</span>
              </button>
              <span v-else>Loading…</span>
            </div>
          </div>
        </div>
      </div>
      <div class="atmo-album-form__control-buttons">
        <button type="button" :style="{ visibility: currentPage === 'album-info' ? 'hidden' : 'visible' }" class="atmo-button" @click="currentPage = 'album-info'">‹ Back</button>
        <button v-show="currentPage === 'album-info'" type="button" class="atmo-button atmo-button--primary" @click="currentPage = 'song-uploads'">Next ›</button>
        <button v-show="currentPage !== 'album-info'" :disabled="formAlbumSongs.length > 0 && songsProgress < 100" type="submit" class="atmo-button atmo-button--primary">
          <template v-if="isNew">Finish</template>
          <template v-else>Save</template>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Axios from "axios";
import SongProfileModal from "./song-profile-modal";
import AtmoSteps from "@/components/atmo-steps";
import AtmoStep from "@/components/atmo-steps/step";
import AtmoStepDivider from "@/components/atmo-steps/divider";
import AtmoUploadCard from "@/components/atmo-upload-card";
import Multiselect from "@vueform/multiselect";
import AtmoBadge from "@/components/atmo-badge";
import AtmoLoading from "@/components/atmo-loading";
import AtmoProgress from "@/components/atmo-progress";
import { extractAudioFileData } from "@/helpers/audio-processing";
import songProfileTypes from "./song-profile-types";

export default {
  components: {
    SongProfileModal,
    AtmoSteps,
    AtmoStep,
    AtmoStepDivider,
    AtmoUploadCard,
    Multiselect,
    AtmoBadge,
    AtmoLoading,
    AtmoProgress,
  },
  props: {
    isNew: {
      type: Boolean,
      default: false,
    },
    album: {
      type: Object,
      default: null,
    },
    albumSongs: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      albumId: null,
      albumImageFile: null,
      loadingSongProfileOptions: true,
      songProfileTypes: {},
      songProfileOptions: null,
      existingAlbumImageSrc: null,
      formAlbumTitle: null,
      formYearOfProduction: null,
      formAlbumGenre: null,
      formAlbumSongs: [],
      activeSongForProfileModal: null,
      submitAttempted: false,
      savingForm: false,
    };
  },
  computed: {
    artistProfileId() {
      return this.$route.params.artistProfileId;
    },
    currentPage: {
      get() {
        return this.$route.query.step || "album-info";
      },
      set(newValue) {
        this.$router.push({ query: { ...this.$route.query, step: newValue } });
      },
    },
    songsProgress() {
      const totalSongs = this.formAlbumSongs.length;
      if (totalSongs === 0) return 0;
      let processedSongs = 0;
      this.formAlbumSongs.forEach((song) => {
        if (song.processed) processedSongs += 0.5;
        if (song.uploaded) processedSongs += 0.5;
      });

      return Math.floor((processedSongs / totalSongs) * 100);
    },
  },
  created() {
    if (!this.isNew) this.setInitialFormProps();
    this.getSongProfileOptions();
  },
  methods: {
    setInitialFormProps() {
      const { id, image, name, release_date: releaseDate, genre } = this.album;
      this.albumId = id;
      this.existingAlbumImageSrc = image.url;
      this.formAlbumTitle = name;
      this.formYearOfProduction = releaseDate;
      this.formAlbumGenre = genre;
      this.formAlbumSongs = this.albumSongs.map((song) => ({
        uploaded: true,
        processed: true,
        id: song.id,
        fileName: song.name,
        name: song.name,
        length: "04:33", // TODO: use real song length
        songProfile: song.song_profile,
      }));
    },
    getSongProfileOptions() {
      this.songProfileTypes = songProfileTypes;
      const songProfileOptions = {};
      const promises = Object.keys(songProfileTypes).map((label) => {
        const songProfileType = songProfileTypes[label];
        return Axios.get(`/api/v1/${songProfileType.endpoint}`).then((response) => {
          songProfileOptions[label] = response.data;
        });
      });
      Promise.all(promises)
        .then(() => {
          this.songProfileOptions = songProfileOptions;
        })
        .catch((error) => {
          console.error("Error loading song profile options:", error);
        })
        .finally(() => {
          this.loadingSongProfileOptions = false;
        });
    },
    onAlbumImageSelected(files) {
      this.albumImageFile = files[0];
    },
    async onSongsSelected(files) {
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const song = { originalFile: file, fileName: file.name, uploaded: false, processed: false };
        this.formAlbumSongs.push(song);
        await this.processAndUploadAudioFile(song);
      }
    },
    async processAndUploadAudioFile(song) {
      const audioDataPromise = extractAudioFileData(song.originalFile)
        .then((response) => {
          song.newAudioData = response;
        })
        .catch((error) => {
          console.error(error);
          this.$notify({ group: "vue-app", type: "warning", title: "Unable to create song profile" });
          song.newAudioData = {};
        })
        .finally(() => {
          song.processed = true;
        });

      const songUploadPromise = this.uploadSong(song.originalFile);
      songUploadPromise.then((response) => {
        const responseData = response.data;
        song.id = responseData.id;
        song.length = responseData.length;
        song.uploaded = true;
      });

      await Promise.all([audioDataPromise, songUploadPromise]);
      const { tempo, key_id, tonality_id } = song.newAudioData;
      await this.postSongProfile(song, { song_id: song.id, tempo, song_key_1: key_id, song_tonality_id: tonality_id });
    },
    uploadSong(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("artist_profile_id", this.artistProfileId);
      if (this.albumId) formData.append("album_id", this.albumId);
      return Axios.post(`/api/v1/artist_profiles/${this.artistProfileId}/songs`, formData);
    },
    async postSongProfile(song, props) {
      try {
        const response = await Axios.post(`/api/v1/songs/${song.id}/song_profiles`, { song_profile: props });
        song.songProfile = response.data;
      } catch (error) {
        console.error(error);
        this.$notify({ group: "vue-app", type: "error", title: "Error saving song profile" });
      }
    },
    setSongForProfileModal(song) {
      this.activeSongForProfileModal = song;
    },
    async updateSongProfile(song, props) {
      if (!song.songProfile || !song.songProfile.id) {
        props.song_id = song.id;
        return this.postSongProfile(song, props);
      }
      try {
        const response = await Axios.patch(`/api/v1/song_profiles/${song.songProfile.id}`, { song_profile: props });
        song.songProfile = response.data;
      } catch (error) {
        console.error(error);
        this.$notify({ group: "vue-app", type: "error", title: "Error saving song profile" });
      }
    },
    closeSongProfileModal() {
      this.activeSongForProfileModal = null;
    },
    async removeSong(song) {
      if (!confirm(`Are you sure you want to delete ${song.name}?`)) return;
      const index = this.formAlbumSongs.findIndex((formSong) => formSong.id === song.id);
      if (index !== -1) {
        this.formAlbumSongs.splice(index, 1);
        await Axios.delete(`/api/v1/artist_profiles/${this.artistProfileId}/songs/${song.id}`);
      }
    },
    submitForm() {
      this.submitAttempted = true;
      if (!this.formAlbumTitle || !this.formYearOfProduction) {
        this.$notify({ group: "vue-app", type: "error", title: "Missing required fields" });
        this.currentPage = "album-info";
        return false;
      }
      if (!this.formAlbumSongs.every((song) => song.name)) {
        this.$notify({ group: "vue-app", type: "error", title: "Missing song names" });
        return false;
      }
      this.savingForm = true;
      this.saveAlbum()
        .then((response) => {
          this.albumId = response.data.id;
          return this.saveSongs();
        })
        .then(() => {
          this.$router.push({ name: "albums.show", params: { artistProfileId: this.artistProfileId, albumId: this.albumId } });
        })
        .catch((error) => {
          console.error(error);
          this.$notify({ group: "vue-app", type: "error", title: "Error saving album" });
        })
        .finally(() => {
          this.savingForm = false;
        });
    },
    saveAlbum() {
      const formData = new FormData();
      if (this.albumImageFile) formData.append("image", this.albumImageFile);
      formData.append("name", this.formAlbumTitle);
      formData.append("release_date", this.formYearOfProduction);
      if (this.formAlbumGenre && this.formAlbumGenre.id) formData.append("genre_id", this.formAlbumGenre.id);
      const baseUrl = `/api/v1/artist_profiles/${this.artistProfileId}/albums`;
      return Axios({ method: this.isNew ? "post" : "patch", url: this.isNew ? baseUrl : `${baseUrl}/${this.albumId}`, data: formData });
    },
    async saveSongs() {
      const promises = this.formAlbumSongs.map((song) => {
        const alreadySavedSong = this.albumSongs?.find((savedSong) => savedSong.id === song.id) || {};
        const changedProps = {};
        if (song.name !== alreadySavedSong.name) changedProps.name = song.name;
        if (!alreadySavedSong.album_id) changedProps.album_id = this.albumId;
        return Object.keys(changedProps).length ? Axios.patch(`/api/v1/artist_profiles/${this.artistProfileId}/songs/${song.id}`, changedProps) : null;
      });
      return Promise.all(promises);
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-album-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  width: 95%;
  max-width: 700px;

  &__loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &__container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__main-content {
    display: flex;
    flex-wrap: wrap-reverse;
    width: 100%;
    min-height: 300px;
    margin-top: 25px;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    max-width: 185px;

    .atmo-upload-card__container {
      min-height: 185px;
    }
  }

  &__main-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;
  }

  &__main-column-label {
    text-transform: uppercase;
  }

  &__control-buttons {
    display: flex;
    width: 100%;
    margin-top: 20px;
    justify-content: space-between;
  }

  &__empty-songs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(white, 0.8);
    padding: 22px;
    margin-top: 20px;
    border-radius: 5px;
  }

  &__empty-songs-icon {
    height: 65px;
  }

  &__empty-songs-message {
    color: $atmo-purple--dark;
    margin: 10px;
  }

  &__empty-songs-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-transform: uppercase;
    font-size: 1.1em;

    img {
      height: 30px;
      margin-top: 10px;
    }
  }

  &__songs {
    display: flex;
    flex-direction: column;
  }

  &__song {
    display: flex;
    align-items: center;
    margin: 5px 0;

    &--disabled {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  &__song-filename-badge-container {
    width: 100px;
  }

  &__song-filename-badge {
    word-break: break-all;
  }

  $space-between-song-form-controls: 8px;

  &__song-length {
    background-color: $atmo-purple--light;
    color: $atmo-purple--dark;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border-radius: 5px;
    margin: 0 $space-between-song-form-controls;
    min-width: 3.7em;
  }

  &__song-title {
    flex: 1;
    margin-right: $space-between-song-form-controls;
  }
}
</style>
