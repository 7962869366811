<template>
  <VueFinalModal
    id="ami-options"
    ref="amiOptionsRef"
    v-model="$store.state.amiOptionsOpen"
    title="Ami Options"
    content-class="atmo-modal-content atmo-modal atmo-queue"
    overlay-class="atmo-modal-overlay"
    :esc-to-close="true"
  >
    <header class="atmo-queue__header atmo-modal__header">
      <img
        class="atmo-queue__hide-queue"
        src="@/assets/images/icons/close_popup.png"
        alt="close"
        title="Hide Queue"
        @click="$refs.amiOptionsRef.hide()"
      >
      <h2>AMI Options</h2>
      <span title="Queue Actions" />
    </header>
    <div v-for="option in $store.state.amiOptions" :key="option">
      <p>
        {{ option }}
      </p>
    </div>
  </VueFinalModal>
</template>

<script>
  export default {
    data() {
      return {
        isOpen: true
      }
    },
    methods: {
      hideModal() {
        this.$store.commit('hideModal');
      },
    }
  }
</script>

<style lang="scss">
  @import '~@/assets/stylesheets/_variables.scss';

  .atmo-library {
    .modal .modal-lg.modal-dialog {
      max-width: 1000px;
    }

    &__body {
      display: flex;
    }

    &__main-column {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__sidebar {
      display: flex;
      flex-direction: column;
      width: 200px;
      margin: 0 20px 10px 30px;
    }

    &__sidebar-section {
      display: flex;
      flex-direction: column;
      margin: 10px 0;
    }

    &__back-to-library {
      display: flex;
      align-items: flex-end;
      font-size: 1.1em;
      font-weight: 300;
      cursor: pointer;

      img {
        height: 12px;
        margin-right: 3px;
      }
    }
  }
</style>
