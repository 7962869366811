import { callPaymentsOneTimePayment, callPaymentsSubscription } from "@/helpers/axiosCalls";

export default {
  namespaced: true,
  state: {
    debug: false,
    selectedPlan: { name: "", options: [] },
    selectedCard: null,
    selectedAddress: {
      firstName: "",
      lastName: "",
      addressLine1: "",
      addressLine2: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
      phoneNumber: "",
    },
    addCardIsOpen: false,
    temporaryCard: {
      brand: null,
      numberComplete: false,
      expiryComplete: false,
      cvcComplete: false,
    },
    token: null,
    checkoutDetails: {
      type: "", // e.g., "subscription", "donation", "store", "ops_page"
      numberOfItems: 0,
      amount: 0,
      taxRate: 0,
      taxTotal: 0,
      shippingCost: 0,
      subTotal: 0,
      overallTotal: 0,
      discountAmount: 0,
      usedPoints: 0,
    }
  },
  mutations: {
    setSelectedPlan(state, planData) {
      if (state.debug) console.log("setSelectedPlan called with:", planData);
      state.selectedPlan = planData;
    },
    setCheckoutDetails(state, { type, numberOfItems, name, option, amount, taxRate, shippingCost = 0 }) {
      state.checkoutDetails.type = type;
      state.checkoutDetails.numberOfItems = numberOfItems;
      state.checkoutDetails.name = name || "";
      state.checkoutDetails.option = option || "";
      state.checkoutDetails.amount = parseFloat(amount) || 0;
      state.checkoutDetails.taxRate = parseFloat(taxRate) || 0;
      state.checkoutDetails.shippingCost = parseFloat(shippingCost) || 0;
      
      // Calculate the subtotal (before discount)
      const subtotal = state.checkoutDetails.amount;
      
      // Apply any existing discount to adjust the taxable amount
      const taxableAmount = subtotal - state.checkoutDetails.discountAmount;
      
      // Calculate the adjusted tax and overall total
      state.checkoutDetails.taxTotal = taxableAmount * state.checkoutDetails.taxRate;
      state.checkoutDetails.subTotal = subtotal;
      state.checkoutDetails.overallTotal = taxableAmount + state.checkoutDetails.taxTotal + state.checkoutDetails.shippingCost;
  
      if (state.debug) {
        console.log("setCheckoutDetails called with:", state.checkoutDetails);
      }
    },
    setDiscount(state, discountAmount) {
      if (state.debug) console.log("setDiscount called with:", discountAmount);
      state.checkoutDetails.discountAmount = discountAmount;
      
      // Adjust the taxable amount and recalculate the tax
      const taxableAmount = state.checkoutDetails.amount - discountAmount;
      state.checkoutDetails.taxTotal = taxableAmount * state.checkoutDetails.taxRate;
      
      // Update the overall total
      state.checkoutDetails.overallTotal = taxableAmount + state.checkoutDetails.taxTotal + state.checkoutDetails.shippingCost;
    },    
    setOverallTotal(state) {
      if (state.debug) console.log("setOverallTotal called");
      const total = state.subTotal || 0;
      const discount = state.discountAmount || 0;
      state.overallTotal = total - discount;
    },
    setCard(state, card) {
      if (state.debug) console.log("setCard called with:", card);
      state.selectedCard = card;
    },
    setAddress(state, address) {
      if (state.debug) console.log("setAddress called with:", address);
      state.selectedAddress = address;
    },
    toggleAddCard(state) {
      if (state.debug) console.log("toggleAddCard called");
      state.addCardIsOpen = !state.addCardIsOpen;
    },
    setTemporaryCard(state, cardDetails) {
      if (state.debug) console.log("setTemporaryCard called with:", cardDetails);
      state.temporaryCard = { ...state.temporaryCard, ...cardDetails };
    },
    setToken(state, token) {
      if (state.debug) console.log("setToken called with:", token);
      state.token = token;
    },
    setUsedPoints(state, points) {
      state.checkoutDetails.usedPoints = points;
    },
    resetState(state) {
      if (state.debug) console.log("resetState called");
    
      state.selectedPlan = { name: "", options: [] };
      state.selectedCard = null;
      state.selectedAddress = {
        firstName: "",
        lastName: "",
        addressLine1: "",
        addressLine2: "",
        country: "",
        state: "",
        city: "",
        zipCode: "",
        phoneNumber: "",
      };
      state.addCardIsOpen = false;
      state.temporaryCard = {
        brand: null,
        numberComplete: false,
        expiryComplete: false,
        cvcComplete: false,
      };
      state.token = null;
      state.checkoutDetails = {
        type: "",
        numberOfItems: 0,
        amount: 0,
        taxRate: 0,
        taxTotal: 0,
        shippingCost: 0,
        subTotal: 0,
        overallTotal: 0,
        discountAmount: 0,
        usedPoints: 0,
      };
    },
  },
  actions: {
    async createOneTimePayment({ commit, state, dispatch }, paymentData) {
      if (state.debug) console.log("createOneTimePayment called with:", paymentData);
      try {
        const response = await callPaymentsOneTimePayment(paymentData);
        
        if (response.created_accomplishments && response.created_accomplishments.length > 0) {
          if (state.debug) console.log("response.created_accomplishments", response.created_accomplishments);
          commit('accomplishments/setCreatedAccomplishments', response.created_accomplishments, { root: true });
          dispatch('accomplishments/toggleUserAccomplishmentModal', null, { root: true });
        }
        
        return response.transaction;
      } catch (error) {
        if (state.debug) console.error("Failed to create one-time payment:", error);
        throw error;
      }
    },
    async createSubscription({ commit, state, dispatch }, subscriptionData) {
      if (state.debug) console.log("createSubscription called with:", subscriptionData);
      try {
        const response = await callPaymentsSubscription(subscriptionData);
        
        if (response.created_accomplishments && response.created_accomplishments.length > 0) {
          if (state.debug) console.log("response.created_accomplishments", response.created_accomplishments);
          commit('accomplishments/setCreatedAccomplishments', response.created_accomplishments, { root: true });
          dispatch('accomplishments/toggleUserAccomplishmentModal', null, { root: true });
        }
        
        return response.subscription;
      } catch (error) {
        if (state.debug) console.error("Failed to create subscription:", error);
        throw error;
      }
    },
    setDiscount({ commit, state }, discountAmount) {
      if (state.debug) console.log("setDiscount action called with:", discountAmount);
      commit("setDiscount", discountAmount);
    },
    setCheckout({ commit, state }, { type, numberOfItems, name, option, amount, taxRate, shippingCost }) {
      if (state.debug) console.log("setCheckout action called with:", { type, numberOfItems, name, option, amount, taxRate, shippingCost });
      commit("setCheckoutDetails", { type, numberOfItems, name, option, amount, taxRate, shippingCost });
    },
    setSelectedPlan({ commit, state }, planData) {
      if (state.debug) console.log("setSelectedPlan action called with:", planData);
      commit("setSelectedPlan", planData);
    },
    setSelectedCard({ commit, state }, card) {
      if (state.debug) console.log("setSelectedCard action called with:", card);
      commit("setCard", card);
    },
    setSelectedAddress({ commit, state }, address) {
      if (state.debug) console.log("setSelectedAddress action called with:", address);
      commit("setAddress", address);
    },
    toggleAddCard({ commit, state }) {
      if (state.debug) console.log("toggleAddCard action called");
      commit("toggleAddCard");
    },
    setTemporaryCard({ commit, state }, cardDetails) {
      if (state.debug) console.log("setTemporaryCard action called with:", cardDetails);
      commit("setTemporaryCard", cardDetails);
    },
    setToken({ commit, state }, token) {
      if (state.debug) console.log("setToken action called with:", token);
      commit("setToken", token);
    },
    resetStore({ commit, state }) {
      if (state.debug) console.log("resetStore action called");
      commit("resetState");
    }
  },
  getters: {
    getSelectedPlan(state) {
      if (state.debug) console.log("getSelectedPlan called");
      return state.selectedPlan;
    },
    getPlanForCheckout(state) {
      if (state.debug) console.log("getPlanForCheckout called");
      return state.planForCheckout;
    },
    isAddCardOpen(state) {
      if (state.debug) console.log("isAddCardOpen called, result:", state.addCardIsOpen);
      return state.addCardIsOpen;
    },
    getToken(state) {
      if (state.debug) console.log("getToken called");
      return state.token;
    },
    getCheckoutDetails(state) {
      if (state.debug) console.log("getCheckoutDetails called");
      return state.checkoutDetails;
    },
    getSubTotal(state) {
      if (state.debug) console.log("getSubTotal called");
      return state.checkoutDetails.subTotal;
    },
    getOverallTotal(state) {
      if (state.debug) console.log("getOverallTotal called");
      return state.checkoutDetails.overallTotal;
    },
  },
};
