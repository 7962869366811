<template>
  <!-- <div v-if="isLoading" class="atmo-contests-show__loading-container">
    <atmo-loading />
  </div> -->
  <div v-if="contest" class="atmo-contests-show">
    <!-- <div class="header-container">
      <h2 class="atmo-page-header">
        {{ contest.name }}
      </h2>
    </div> -->
    <atmo-back-link></atmo-back-link>
    <section class="atmo-contests-show__body">
      <aside class="atmo-contests-show__sidebar">
        <div v-if="contest && contest.image">
          <img class="atmo-contests-show__image" :src="contest.image.url" />
        </div>
        <span class="atmo-contests-show__section-label">
          {{ contest.name }}
        </span>
        <div class="atmo-contests-show__sidebar-label">Accepted File Types</div>
        <div class="atmo-contests-show__sidebar-badges">
          <atmo-badge v-for="contestFileType in contest.contest_file_types" :key="contestFileType.id" :label="contestFileType.file_type.name" :icon="fileTypeIconFor(contestFileType.file_type)" />
        </div>
        <div class="atmo-contests-show__sidebar-label">Start Date</div>
        <div class="atmo-contests-show__sidebar-badges">
          <atmo-badge :label="getDate(contest.start_date)" />
        </div>
        <div class="atmo-contests-show__sidebar-label">End Date</div>
        <div class="atmo-contests-show__sidebar-badges">
          <atmo-badge :label="getDate(contest.end_date)" />
        </div>
      </aside>
      <router-view />
      <div v-if="!onSubmissionsShowPage" class="atmo-contests-show__main-content">
        <p class="atmo-contests-show__description">
          {{ contest.description }}
        </p>
        <div class="atmo-contests-show__main-row">
          <perfect-scrollbar>
            <div class="atmo-contests-show__submissions-container">
              <div class="atmo-contests-show__section-label">
                <span class="submissions-label">Submissions</span>
                <atmo-badge :label="submissions.length" />
              </div>
              <div v-if="isLoadingSubmissions" class="atmo-loading-container">
                <atmo-loading />
              </div>
              <div v-else class="atmo-contests-show__submissions">
                <contest-submission v-for="submission in submissions" :key="submission.id" :submission="submission" class="atmo-contests-show__submission" />
              </div>
            </div>
          </perfect-scrollbar>
          <div class="atmo-contests-show__side-column">
            <atmo-countdown :end-time="contest.end_date" />
            <div class="atmo-contests-show__prizes-container">
              <header class="atmo-contests-show__prizes-header">
                <img src="@/assets/images/icons/winner.png" />
                <span class="prizes-header__winners">Winners</span>
              </header>
              <div v-if="!isLoadingPrizes && !isLoadingSubmissions">
                <contest-prize v-for="prize in prizes" :key="prize.id" :prize="prize" :submissions="submissions" :contest="contest" :winners="getWinnersByPrize(prize.id)" />
              </div>
            </div>
          </div>
        </div>
        <form v-if="!currentUserHasSubmission" class="atmo-form atmo-contests-show__new-submission-form" autocomplete="off" @submit.prevent="submitNewSubmission">
          <div class="atmo-contests-show__submission-form-row">
            <div class="atmo-contests-show__avatar-container">
              <div
                v-if="contest.user"
                class="atmo-avatar atmo-avatar--small atmo-avatar--border-white"
                :style="{ 'background-image': 'linear-gradient(-270deg, rgba(68, 174, 220, 0.5) 0%, rgba(217, 82, 167, 0.5) 100%)' + ',' + `url(${contest.user.profile_image.image.url})` }"
              />
            </div>
            <div class="atmo-contests-show__new-submission-input">
              <label for="newSubmissionText">New submission</label>
              <input id="newSubmissionText" v-model="newSubmissionText" :aria-invalid="submitAttempted && !newSubmissionText" type="text" placeholder="Submission Description" />
            </div>
          </div>
          <div class="atmo-contests-show__submission-form-row atmo-contests-show__submission-form-bottom-row">
            <div v-for="(image, index) in images" class="user-billboard__pill-container" :key="`image-${index}`">
              <span class="user-billboard__pill">
                {{ image.name }}
              </span>
              <div aria-label="Remove" class="user-billboard__pill-remove" @click.prevent="removeImage(image)">&times;</div>
            </div>
            <div v-for="(video, index) in videos" class="user-billboard__pill-container" :key="`video-${index}`">
              <span class="user-billboard__pill">
                {{ video.name }}
              </span>
              <div aria-label="Remove" class="user-billboard__pill-remove" @click.prevent="removeVideo(video)">&times;</div>
            </div>
            <!-- UPDATE THIS LOGIC FOR UPLOADING SONGS -->
            <div v-for="(song, index) in songs" class="user-billboard__pill-container" :key="`image-${index}`">
              <span class="user-billboard__pill">
                {{ song.name }}
              </span>
              <div aria-label="Remove" class="user-billboard__pill-remove" @click.prevent="removeImage(image)">&times;</div>
            </div>
            <div v-for="(song, index) in $store.state.addSongsModalList" class="user-billboard__pill-container" :key="`song-${index}`">
              <span class="user-billboard__pill">
                {{ song.name }}
              </span>
              <div aria-label="Remove" class="user-billboard__pill-remove" @click.prevent="removeSong(song)">&times;</div>
            </div>
            <div class="user-billboard__button-group">
              <button class="atmo-button atmo-button--tertiary upload-song" @click.prevent="openAddSongsModal()">
                <img class="atmo-button__icon" src="@/assets/images/icons/upload_music.png" />
              </button>
              <label for="file-input" class="atmo-button atmo-button--tertiary post-image-upload">
                <img class="atmo-button__icon" src="@/assets/images/icons/attachment.png" />
              </label>
              <input id="file-input" type="file" @change="handleFileUpload" style="display: none" />
            </div>
            <button type="submit" class="atmo-contests-show__submit-button">Submit</button>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import {
  callContestPrizesIndex,
  callContestSubmissionsIndex,
  callContestSubmissionsCreate,
  callContestFileTypesIndex,
  callImagesCreate,
  callVideosCreate,
  callSongReferencesCreate,
  callPreviewImageCreate,
} from "@/helpers/axiosCalls";
import atmoLoading from "@/components/atmo-loading";
import AtmoBackLink from "@/components/atmo-back-link";
import atmoBadge from "@/components/atmo-badge";
import atmoCountdown from "@/components/atmo-countdown";
import contestSubmission from "./contest-submission";
import contestPrize from "./contest-prize";
import { utilsGetDate, getFeaturedImage } from "@/helpers/utilityFunctions";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    atmoLoading,
    AtmoBackLink,
    atmoBadge,
    atmoCountdown,
    contestSubmission,
    contestPrize,
  },

  data() {
    return {
      isLoading: true,
      isLoadingSubmissions: true,
      isLoadingPrizes: true,
      submissions: [],
      submitAttempted: false,
      newSubmissionText: null,
      newSubmissionFile: null,
      images: [],
      videos: [],
      songs: [],
    };
  },

  computed: {
    ...mapState("contests", ["contest", "contestPrizeWinners"]),
    onSubmissionsShowPage() {
      return this.$route.name === "contests.show.submissions.show";
    },
    currentUserHasSubmission() {
      const currentUserId = this.$store.state.currentUserId;
      return (
        this.submissions &&
        this.submissions.some((submission) => {
          return Number(submission.user_id) === currentUserId;
        })
      );
    },
  },

  async created() {
    this.isLoading = true;
    await this.fetchContest(this.$route.params.contestId);
    await this.getFileTypes();
    this.isLoading = false;
    this.getSubmissions();
    this.getPrizes();
    if (this.contest) {
      await this.fetchContestPrizeWinners(this.contest.id);
    }
    this.$store.commit("setHudHeader", this.contest.name);
  },

  methods: {
    ...mapActions("contests", ["fetchContest", "fetchContestPrizeWinners"]),
    getWinnersByPrize(prizeId) {
      return this.contestPrizeWinners.filter((winner) => winner.contest_prize_id === prizeId);
    },
    getFeaturedImage(userImages, type) {
      return getFeaturedImage(userImages, type);
    },
    getDate(date) {
      return utilsGetDate(date);
    },
    async getSubmissions() {
      this.isLoadingSubmissions = true;
      try {
        const response = await callContestSubmissionsIndex(this.$route.params.contestId);
        this.submissions = response;
      } catch (error) {
        console.error(error);
        this.$notify({ group: "vue-app", type: "error", title: "Error loading submissions" });
      }
      this.isLoadingSubmissions = false;
    },
    async getPrizes() {
      this.isLoadingPrizes = true;
      try {
        const response = await callContestPrizesIndex(this.$route.params.contestId);
        this.prizes = response.sort((a, b) => {
          const dateA = new Date(a.created_at);
          const dateB = new Date(b.created_at);
          return dateA - dateB;
        });
      } catch (error) {
        console.error(error);
        this.$notify({ group: "vue-app", type: "error", title: "Error loading prizes" });
      }
      this.isLoadingPrizes = false;
    },
    async getFileTypes() {
      try {
        const response = await callContestFileTypesIndex();
        this.fileTypes = response;
      } catch (error) {
        console.error(error);
      }
    },
    onNewSubmissionFileSelected(files) {
      this.newSubmissionFile = files[0];
    },
    removeNewSubmissionFile() {
      this.newSubmissionFile = null;
    },
    fileTypeIconFor(fileType) {
      switch (fileType.name) {
        case "Audio":
          return "notes.png";
        case "Video":
          return "video.png";
        default:
          return "attachment.png";
      }
    },
    openAddSongsModal() {
      this.$store.commit("openAddSongsModal");
    },
    removeSong(song) {
      const indexToRemove = this.$store.state.addSongsModalList.findIndex((item) => item === song);
      const newAddSongsModalSongs = this.$store.state.addSongsModalList.filter((item, index) => index !== indexToRemove);
      this.$store.commit("setAddSongsModalList", newAddSongsModalSongs);
    },
    removeVideo(file) {
      const indexToRemove = this.videos.findIndex((item) => item === file);
      this.videos = this.videos.filter((item, index) => index !== indexToRemove);
    },
    removeImage(file) {
      const indexToRemove = this.images.findIndex((item) => item === file);
      this.images = this.images.filter((item, index) => index !== indexToRemove);
    },
    async handleFileUpload(event) {
      const inputElement = event.target;
      const selectedFile = inputElement.files[0];

      if (!selectedFile) {
        return;
      }

      const mimeTypesMap = {
        Image: ["image/jpeg", "image/png", "image/gif"],
        Video: ["video/mp4", "video/mpeg", "video/quicktime"],
        Audio: ["audio/mp3", "audio/mpeg", "audio/wav", "audio/ogg"],
      };

      const allowedFileTypes = this.contest.contest_file_types.map((entry) => entry.file_type.name);

      let fileType = "";

      for (const type of allowedFileTypes) {
        if (mimeTypesMap[type] && mimeTypesMap[type].includes(selectedFile.type)) {
          fileType = type;
          break;
        }
      }

      if (!fileType) {
        console.error("Invalid file type. Please select a valid file.");
        this.$notify({ type: "error", group: "vue-app", title: "Invalid file type. Please select a valid file." });
        inputElement.value = null;
        return;
      }

      switch (fileType) {
        case "Image":
          this.images.push(selectedFile);
          break;
        case "Video":
          this.videos.push(selectedFile);
          break;
        case "Audio":
          this.songs.push(selectedFile);
          break;
        default:
          console.error("Invalid file type:", selectedFile.type);
          this.$notify({ type: "error", group: "vue-app", title: `Invalid file type: ${selectedFile.type}` });
          break;
      }
    },
    async submitNewSubmission() {
      this.isLoadingSubmissions = true;
      const formData = new FormData();
      if (this.newSubmissionFile) {
        formData.append("file", this.newSubmissionFile);
      }
      formData.append("text", this.newSubmissionText);
      formData.append("user_id", this.$store.state.currentUserId);

      callContestSubmissionsCreate(this.$route.params.contestId, formData)
        .then(async (response) => {
          const submissionId = response.id;

          let imagePromises = [];
          if (this.images.length > 0) {
            imagePromises = this.images.map(async (image) => {
              const formData = new FormData();
              formData.append("image", image);
              formData.append("imageable_id", submissionId);
              formData.append("imageable_type", "ContestSubmission");
              formData.append("is_featured_image", false);
              formData.append("user_id", this.$store.state.currentUserId);

              return callImagesCreate(formData).catch((error) => {
                console.error(error);
              });
            });
          }

          let songPromises = [];
          if (this.$store.state.addSongsModalList.length > 0) {
            songPromises = this.$store.state.addSongsModalList.map(async (song) => {
              return callSongReferencesCreate({
                songable_id: submissionId,
                songable_type: "ContestSubmission",
                song_id: song.id,
                user_id: this.$store.state.currentUserId,
              }).catch((error) => {
                console.error(error);
              });
            });
          }

          let newVideoIds = [];
          let videoPromises = [];
          let previewImagePromises = [];

          if (this.videos.length > 0) {
            videoPromises = this.videos.map(async (video) => {
              const formData = new FormData();
              formData.append("video", video);
              formData.append("videoable_id", submissionId);
              formData.append("videoable_type", "ContestSubmission");
              formData.append("user_id", this.$store.state.currentUserId);
              formData.append("is_featured_video", true);

              return callVideosCreate(formData)
                .then((response) => {
                  newVideoIds.push(response.id);
                })
                .catch((error) => {
                  console.error(error);
                });
            });
          }

          Promise.all([...imagePromises, ...songPromises, ...videoPromises]).then(() => {
            previewImagePromises = newVideoIds.map(async (videoId) => {
              return callPreviewImageCreate({
                video_id: videoId,
              });
            });

            Promise.all([...previewImagePromises]).then(async () => {
              this.$notify({ group: "vue-app", title: "Submision Saved" });
              await this.getSubmissions();
              this.isLoadingSubmissions = false;
              this.images = [];
              this.videos = [];
              this.$store.commit("setAddSongsModalList", []);
            });
          });

          if (this.images.length === 0 && this.$store.state.addSongsModalList.length === 0 && this.videos.length === 0) {
            await this.getSubmissions();
            this.isLoadingSubmissions = false;
          }
        })
        .catch((error) => {
          console.error(error);
          this.$notify({ group: "vue-app", type: "error", title: "An error occurred" });
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/stylesheets/_variables.scss";

.atmo-contests-show {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 75px;
  height: 100%;

  .ps {
    height: 20rem;
  }

  &__loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    margin: 35px 0;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__breadcrumbs {
    margin-right: auto;
  }

  &__body {
    display: grid;
    grid-template-columns: 15rem 1fr;
    gap: 1rem;
    width: 100%;
    margin-top: 10px;
  }

  &__sidebar {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 50px;

    .atmo-badge {
      text-transform: uppercase;
      font-weight: 500;
      background-color: $atmo-purple--medium-dark--overlay-faded;
      padding: 7px;
    }
  }

  &__sidebar-badges {
    display: flex;
    gap: 0.2rem;
  }

  &__image {
    width: 160px;
    margin-bottom: 10px;
    box-shadow: 2px 0 20px 0 $atmo-purple--extra-dark;
    margin-bottom: 1rem;
  }

  &__section-label {
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  &__sidebar-label {
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: uppercase;
    margin: 5px 0;
  }

  &__main-content {
  }

  &__description {
    margin-bottom: 1rem;
    font-size: 0.9rem;
    font-weight: 500;
  }

  &__main-row {
    display: grid;
    grid-template-columns: 70% 30%;
  }

  &__submissions-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: rgba(96, 72, 117, 0.4);
    backdrop-filter: blur(5px);
    border-radius: 7px;
    margin-right: 15px;
    padding: 15px;
  }

  .submissions-label {
    font-size: 1.1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 0.5rem;
  }

  .atmo-badge {
    border-radius: 5px;
  }

  &__side-column {
    display: flex;
    flex-direction: column;
  }

  &__prizes-container {
    display: flex;
    flex-direction: column;
  }

  &__prizes-header {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin: 1rem 0rem 1rem 0rem;

    img {
      height: 20px;
      margin-right: 5px;
    }

    .prizes-header__winners {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &__new-submission-form {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  &__submission-form-row {
    display: flex;
    align-items: center;
  }

  &__submission-form-bottom-row {
    margin-left: auto;
  }

  &__avatar-container {
    margin-right: 8px;
  }

  &__new-submission-input {
    flex: 1;
  }

  &__song-badge {
    margin-right: 15px;
  }

  &__submit-button {
    margin-left: 5px;
    font-size: 1rem;
    text-transform: uppercase;
    color: white;
    background-color: transparent;
    background-image: linear-gradient(-270deg, rgba(217, 82, 167, 0.8) 0%, rgba(68, 174, 220, 0.8) 100%);
    border-radius: 5px;
    padding: 0.5rem 2rem 0.5rem 2rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }
}
</style>
