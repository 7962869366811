export const notificationMap = {
  'Friend Request': {
    link: null,
    linkLabel: null
  },
  'Chat Message': {
    link: null,
    linkLabel: null
  },
  'Live Listening Message': {
    link: null,
    linkLabel: null
  },
  'Live Listening Request': {
    link: null,
    linkLabel: null
  },
  'Group Chat Request': {
    link: null,
    linkLabel: null
  },
  'Billboard Comment': {
    link: {
      name: 'posts.show',
      params: (notification) => notification.notifiable_id ? ({ postId: notification.notifiable_id }) : null,
    },
    linkLabel: 'billboard',
  },
  'Billboard Like': {
    link: {
      name: 'posts.show',
      params: (notification) => notification.notifiable_id ? ({ postId: notification.notifiable_id }) : null,
    },
    linkLabel: 'billboard',
  },
  'Billboard Dislike': {
    link: {
      name: 'posts.show',
      params: (notification) => notification.notifiable_id ? ({ postId: notification.notifiable_id }) : null,
    },
    linkLabel: 'billboard',
  },
  'Billboard Post': {
    link: {
      name: 'posts.show',
      params: (notification) => notification.notifiable_id ? { postId: notification.notifiable_id } : null,
    },
    linkLabel: 'billboard',
  },
  'Comment Like': {
    link: {
      name: 'posts.show',
      params: (notification) => notification.notifiable_id ? ({ commentId: notification.notifiable_id }) : null,
    },
    linkLabel: 'comment',
  },
  'Comment Dislike': {
    link: {
      name: 'posts.show',
      params: (notification) => notification.notifiable_id ? ({ commentId: notification.notifiable_id }) : null,
    },
    linkLabel: 'comment',
  },
  'Playlist Like': {
    link: {
      name: 'playlists.show',
      params: (notification) => notification.notifiable_id ? ({ playlistId: notification.notifiable_id }) : null,
    },
    linkLabel: 'playlist',
  },
  'Playlist Dislike': {
    link: {
      name: 'playlists.show',
      params: (notification) => notification.notifiable_id ? ({ playlistId: notification.notifiable_id }) : null,
    },
    linkLabel: 'playlist',
  },
};
